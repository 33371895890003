import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, pickBy, values } from 'ramda'
import {
  Table,
  Button,
  FiltersModal,
  DatePicker,
  TableSkeleton,
  EmptyContent
} from '../..'
import { formatAmount, formatTimestampToDate } from '../../../helpers/utils'
import {
  fetchLoans,
  loadNextLoans,
  loadPrevLoans
} from '../../../containers/OkoaFees/store'

function OkoaFeeRequests({
  translate,
  okoaUser,
  onDeclineRequest,
  onAcceptRequest,
  onConfirmRequest,
  onIgnoreRequest
}) {
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const dispatch = useDispatch()
  const {
    loans,
    isLoading,
    loansPagination: pagination
  } = useSelector((state) => state.okoaFees)
  const { currency } = useSelector((state) => state.auth)
  const [filters, setFilters] = useState({
    sort: '',
    status: '',
    from: '',
    to: ''
  })

  const hasEmptyFilter = values(filters).every((value) => isEmpty(value))
  const selectedFilters = values(filters).filter((value) => !isEmpty(value)).length
  const isSchool = okoaUser.value === 'school'

  const handleStatusChange = (e) => {
    setFilters((prevState) => ({
      ...prevState,
      status: e.target.value
    }))
  }

  const handleSortChange = (e) => {
    setFilters((prevState) => ({
      ...prevState,
      sort: e.target.value
    }))
  }

  const handleFromDateChange = (date, dateString) => {
    setFilters((prevState) => ({
      ...prevState,
      from: dateString
    }))
  }

  const handleToDateChange = (date, dateString) => {
    setFilters((prevState) => ({
      ...prevState,
      to: dateString
    }))
  }

  const handleReset = () =>
    setFilters({
      from: '',
      to: '',
      action_type: '',
      status: ''
    })

  const onApplyFilters = () => {
    const formattedFilters = pickBy((val, key) => val !== '' && val !== 'all', filters)
    dispatch(fetchLoans(formattedFilters, false))
    setShowFiltersModal(false)
  }

  return (
    <>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <>
          {isEmpty(loans) ? (
            <div className='flex flex-col'>
              <div className='overflow-x-auto shadow-md sm:rounded-lg'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='p-4'>
                    <h2 className='font-gotham-rounded-medium text-body text-base'>
                      {translate('okoa_fees.requests')}
                    </h2>
                    <EmptyContent
                      title={translate('common.no_requests')}
                      subTitle={
                        hasEmptyFilter
                          ? translate('common.no_requests_help_text')
                          : translate('common.no_requests_help_text_with_filter')
                      }
                      actionButton={
                        <>
                          {!hasEmptyFilter && (
                            <Button
                              label={translate('common.reset')}
                              classes='uppercase'
                              onClick={() => {
                                handleReset()
                                dispatch(fetchLoans())
                              }}
                            />
                          )}
                        </>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='flex flex-col'>
              <div className='overflow-x-auto shadow-md sm:rounded-lg w-full'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='overflow-hidden p-4'>
                    <div className='flex justify-between items-center'>
                      <h2 className='font-gotham-rounded-medium text-body text-base mb-2'>
                        {translate('okoa_fees.requests')}
                      </h2>
                      <div className='ml-auto'>
                        <button
                          className='primary-button flex items-center justify-between rounded py-2 w-40 px-4 transition ease-in-out duration-200 focus:outline-none'
                          onClick={() => setShowFiltersModal(true)}
                        >
                          <div>
                            <i className='bi bi-funnel text-white text-xl'></i>
                          </div>
                          <span className='text-white text-sm font-gotham-rounded-medium'>
                            {translate('common.filters')}
                          </span>
                          <div className='bg-white rounded-full p-3 w-6 h-6 text-secondary-900 font-gotham-rounded-bold flex justify-center items-center'>
                            <div className='mt-1'>
                              {hasEmptyFilter ? 0 : selectedFilters}
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>

                    <section className='mt-4'>
                      <Table
                        header={
                          <tr>
                            <th
                              scope='col'
                              className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                            >
                              {translate('okoa_fees.name')}
                            </th>
                            <th
                              scope='col'
                              className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                            >
                              {translate('okoa_fees.school_name')}
                            </th>
                            <th
                              scope='col'
                              className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                            >
                              {translate('okoa_fees.amount')}
                            </th>
                            <th
                              scope='col'
                              className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                            >
                              {translate('okoa_fees.request_status')}
                            </th>
                            <th
                              scope='col'
                              className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                            >
                              {isSchool
                                ? translate('common.confirmed_by_user')
                                : translate('common.confirmed')}
                            </th>
                            <th scope='col' className='p-4'>
                              <span className='sr-only'>
                                {translate('okoa_shopping.action')}
                              </span>
                            </th>
                          </tr>
                        }
                        body={
                          <>
                            {loans.map((loan) => (
                              <tr key={loan.id}>
                                <td className='pl-2 py-4 whitespace-nowrap'>
                                  <div className='text-medium font-medium text-body'>
                                    {loan.beneficiary?.name}
                                  </div>
                                  <div className='text-xs text-gray-500'>
                                    {loan.beneficiary && (
                                      <span>
                                        {translate('okoa_fees.nemis')}:{' '}
                                        {loan.beneficiary.nemis}
                                      </span>
                                    )}
                                  </div>
                                  <div className='text-xs text-gray-500'>
                                    {formatTimestampToDate(loan.createdAt)}
                                  </div>
                                </td>
                                <td className='pl-2 py-4 w-40 text-medium text-body'>
                                  <div className='text-medium font-medium text-body'>
                                    {`${loan.premise.name}`}
                                    <div>
                                      {loan.premise.location && (
                                        <div>{loan.premise.location}</div>
                                      )}
                                    </div>
                                  </div>
                                  <div className='text-xs text-gray-500'>
                                    {loan.paylendNumber}
                                  </div>
                                </td>
                                <td className='pl-2 py-4 text-medium text-body'>
                                  <div className='text-medium font-medium text-body'>
                                    Okoa: {currency} {formatAmount(loan.amountBorrowed)}
                                  </div>
                                  <div className='text-medium font-medium text-body'>
                                    {translate('common.fee')}: {currency}{' '}
                                    {formatAmount(loan.amountDue - loan.amountBorrowed)}
                                  </div>
                                  <div className='text-medium font-medium text-body'>
                                    {translate('okoa_shopping.total_due')}: {currency}{' '}
                                    {formatAmount(loan.amountDue)}
                                  </div>
                                </td>
                                <td className='pl-2 py-4 text-medium text-body'>
                                  <div
                                    className={
                                      isSchool ? '' : 'flex justify-around items-center'
                                    }
                                  >
                                    <span className={isSchool ? '' : 'mr-auto'}>
                                      {loan.status}
                                    </span>
                                  </div>
                                </td>
                                <td className='pl-2 py-4 text-medium text-body'>
                                  {loan.status === 'Confirmed'
                                    ? translate('common.yes')
                                    : translate('common.no')}
                                </td>
                                {isSchool && loan.status === 'Pending' && (
                                  <td className='px-2 py-4 text-medium text-body'>
                                    <div className='flex items-center gap-3'>
                                      <button
                                        className='cursor-pointer font-gotham-rounded-medium text-green-600 mr-2'
                                        onClick={() => onAcceptRequest(loan)}
                                      >
                                        {translate('common.approve')}
                                      </button>
                                      <button
                                        className='cursor-pointer font-gotham-rounded-medium text-red-600'
                                        onClick={() => onDeclineRequest(loan)}
                                      >
                                        {translate('okoa_fees.decline')}
                                      </button>
                                    </div>
                                  </td>
                                )}

                                {!isSchool && loan.status === 'Approved' && (
                                  <td className='px-2 py-4 text-medium text-body'>
                                    <div className='flex items-center gap-3'>
                                      <button
                                        className='cursor-pointer font-gotham-rounded-medium text-green-600 mr-2'
                                        onClick={() => onConfirmRequest(loan)}
                                      >
                                        {translate('common.confirm')}
                                      </button>
                                      <button
                                        className='cursor-pointer font-gotham-rounded-medium text-red-600'
                                        onClick={() => onIgnoreRequest(loan)}
                                      >
                                        {translate('okoa_fees.decline')}
                                      </button>
                                    </div>
                                  </td>
                                )}
                              </tr>
                            ))}
                          </>
                        }
                        pagination={
                          <div className='flex justify-between pl-2 pr-6 py-4 w-full border border-gray-200 border-t-0'>
                            <div className='text-medium text-muted'>
                              {pagination.count} {translate('common.of')}{' '}
                              {pagination.total} {translate('okoa_fees.requests')}
                            </div>
                            <div className='flex justify-between gap-4'>
                              <button
                                className={`text-medium ${
                                  pagination.hasPrev
                                    ? 'text-secondary-900'
                                    : 'text-muted pointer-events-none'
                                }`}
                                onClick={() => dispatch(loadPrevLoans({}))}
                              >
                                {translate('common.prev')}
                              </button>
                              <button
                                className={`text-medium ${
                                  pagination.hasNext
                                    ? 'text-secondary-900'
                                    : 'text-muted pointer-events-none'
                                }`}
                                onClick={() => dispatch(loadNextLoans({}))}
                              >
                                {translate('common.next')}
                              </button>
                            </div>
                          </div>
                        }
                      />
                    </section>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <FiltersModal
        t={translate}
        showModal={showFiltersModal}
        onCancel={() => setShowFiltersModal(false)}
        content={
          <>
            <div className='w-full'>
              <div className='space-y-3'>
                <section>
                  <h3 className='lex justify-between w-full text-left focus:outline-none font-gotham-rounded-medium py-2'>
                    {translate('common.request_status')}
                  </h3>

                  <div className='py-2 rounded-md'>
                    <div className='grid grid-cols-3'>
                      <div className=''>
                        <div className='inline-block mr-3'>
                          <input
                            id='Approved'
                            name='Approved'
                            type='radio'
                            value='Approved'
                            className='h-4 w-4 text-secondary-900 outline-none focus:outline-none focus:ring-0 border-gray-300 cursor-pointer'
                            checked={filters.status === 'Approved'}
                            onChange={handleStatusChange}
                          />
                        </div>
                        <label className='font-gotham-rounded-book text-medium'>
                          {translate('common.approved')}
                        </label>
                      </div>
                      <div className=''>
                        <div className='inline-block mr-3'>
                          <input
                            id='Pending'
                            name='Pending'
                            type='radio'
                            value='Pending'
                            className='h-4 w-4 text-secondary-900 outline-none focus:outline-none focus:ring-0 border-gray-300 cursor-pointer'
                            checked={filters.status === 'Pending'}
                            onChange={handleStatusChange}
                          />
                        </div>
                        <label className='font-gotham-rounded-book text-medium'>
                          {translate('common.pending')}
                        </label>
                      </div>
                      <div className=''>
                        <div className='inline-block mr-3'>
                          <input
                            id='Declined'
                            name='Declined'
                            type='radio'
                            value='Declined'
                            className='h-4 w-4 text-secondary-900 outline-none focus:outline-none focus:ring-0 border-gray-300 cursor-pointer'
                            checked={filters.status === 'Declined'}
                            onChange={handleStatusChange}
                          />
                        </div>
                        <label className='font-gotham-rounded-book text-medium'>
                          {translate('common.declined')}
                        </label>
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <h3 className='lex justify-between w-full text-left focus:outline-none font-gotham-rounded-medium py-2'>
                    {translate('common.sort')}
                  </h3>

                  <div className='py-2 rounded-md'>
                    <div className='grid grid-cols-3'>
                      <div className=''>
                        <div className='inline-block mr-3'>
                          <input
                            id='-created_at'
                            name='sort'
                            type='radio'
                            value='-created_at'
                            className='h-4 w-4 text-secondary-900 outline-none focus:outline-none focus:ring-0 border-gray-300 cursor-pointer'
                            checked={filters.sort === '-created_at'}
                            onChange={handleSortChange}
                          />
                        </div>
                        <label className='font-gotham-rounded-book text-medium'>
                          {translate('common.latest_first')}
                        </label>
                      </div>
                      <div className=''>
                        <div className='inline-block mr-3'>
                          <input
                            id='created_at'
                            name='sort'
                            type='radio'
                            value='created_at'
                            className='h-4 w-4 text-secondary-900 outline-none focus:outline-none focus:ring-0 border-gray-300 cursor-pointer'
                            checked={filters.sort === 'created_at'}
                            onChange={handleSortChange}
                          />
                        </div>
                        <label className='font-gotham-rounded-book text-medium'>
                          {translate('common.oldest_first')}
                        </label>
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <h3 className='lex justify-between w-full text-left focus:outline-none font-gotham-rounded-medium py-2'>
                    {translate('common.date_range')}
                  </h3>
                  <div className='py-2 flex justify-between items-center gap-2'>
                    <DatePicker
                      placeholder={translate('common.start_date')}
                      onChange={handleFromDateChange}
                      className='bg-white border border-secondary-300 text-body rounded focus:ring-0 outline-none hover:border-secondary-300 focus:outline-none focus:border-secondary-500 block w-full pr-4 py-2 font-gotham-rounded-book text-xs placeholder:text-body'
                      name='from'
                      showToday={true}
                      {...(filters.from !== '' && { value: filters.from })}
                    />
                    <span className='font-gotham-rounded-book text-medium'>
                      {translate('common.to')}
                    </span>
                    <DatePicker
                      placeholder={translate('common.end_date')}
                      onChange={handleToDateChange}
                      className='bg-white border border-secondary-300 text-body rounded focus:ring-0 outline-none hover:border-secondary-300 focus:outline-none focus:border-secondary-500 block w-full pr-4 py-2 font-gotham-rounded-book text-xs placeholder:text-body'
                      name='to'
                      showToday={true}
                      {...(filters.to !== '' && { value: filters.to })}
                    />
                  </div>
                </section>
              </div>
            </div>
          </>
        }
        onReset={handleReset}
        onSubmit={onApplyFilters}
      />
    </>
  )
}

OkoaFeeRequests.propTypes = {
  translate: PropTypes.func,
  okoaUser: PropTypes.object,
  onDeclineRequest: PropTypes.func,
  onAcceptRequest: PropTypes.func,
  onConfirmRequest: PropTypes.func,
  onIgnoreRequest: PropTypes.func
}

export default OkoaFeeRequests
