import { Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dialog, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import { Button, FormGroup, Input, Select } from '../..'
import { icons } from '../../../assets/img'

function OkoaAmountModal({
  translate,
  showModal,
  onCancel,
  onSubmit,
  modalTitle,
  description,
  errors,
  sendingOtp
}) {
  const [state, setState] = useState({
    amount_borrowed: '',
    repayment_plan: ''
  })

  const { hasSavedStepDetails } = useSelector((state) => state.shopping)

  useEffect(() => {
    hasSavedStepDetails && setState({ amount_borrowed: '', repayment_plan: '' })
  }, [hasSavedStepDetails])

  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={onCancel}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block p-6 w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button onClick={onCancel}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>

                <div className='text-center'>
                  <header className='uppercase text-medium text-muted font-gotham-rounded-medium mx-auto flex justify-center'>
                    <img src={icons.iconShopPrimary} alt='Shop' className='w-20 h-20' />
                  </header>

                  <div className='mt-4 font-gotham-rounded-bold text-primary text-3xl'>
                    {modalTitle}
                  </div>
                  <div className='mt-4 text-body text-sm px-10'>{description}</div>

                  <section className='mt-3'>
                    <FormGroup>
                      <Input
                        type='number'
                        placeholder={translate('okoa_shopping.enter_amount')}
                        classes='placeholder:text-body'
                        name='amount_borrowed'
                        value={state.amount_borrowed}
                        onChange={(e) =>
                          setState({ ...state, amount_borrowed: e.target.value })
                        }
                        hasError={Object.keys(errors).includes('amount_borrowed')}
                        errorMessage={errors.amount_borrowed}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Select
                        options={[
                          {
                            label: translate('okoa_shopping.seven_days'),
                            value: '7'
                          },
                          {
                            label: translate('okoa_shopping.fourteen_days'),
                            value: '14'
                          },
                          {
                            label: translate('okoa_shopping.thirty_days'),
                            value: '30'
                          },
                          {
                            label: translate('okoa_shopping.sixty_days'),
                            value: '60'
                          }
                        ]}
                        hasPlaceholder={true}
                        placeholderText={translate('okoa_shopping.repayment_plan')}
                        placeholderClasses='font-gotham-rounded-bold opacity-80 uppercase text-body'
                        onChange={(e) =>
                          setState({ ...state, repayment_plan: e.target.value })
                        }
                        name='repayment_plan'
                        hasError={Object.keys(errors).includes('repayment_plan')}
                        errorMessage={errors.repayment_plan}
                      />
                    </FormGroup>

                    <div className='w-full'>
                      <Button
                        label={translate('okoa_shopping.next')}
                        classes='uppercase'
                        onClick={() => onSubmit(state)}
                        disabled={sendingOtp}
                      />
                    </div>
                  </section>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

OkoaAmountModal.propTypes = {
  showModal: PropTypes.bool,
  translate: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  modalTitle: PropTypes.string,
  description: PropTypes.string,
  errors: PropTypes.object,
  sendingOtp: PropTypes.bool
}

export default OkoaAmountModal
