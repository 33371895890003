import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import { icons } from '../../../assets/img'
import { FormGroup, Input, Select, Button } from '../..'
import { isEmpty } from 'ramda'
import { useSelector } from 'react-redux'

function OkoaFeesModal({
  translate,
  showModal,
  onCancel,
  onSubmit,
  activeBeneficiary,
  errors,
  handleChange,
  state,
  onChangeBeneficiary,
  isDisabled
}) {
  const { beneficiaries } = useSelector((state) => state.okoaFees)
  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={onCancel}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button onClick={onCancel} className='outline-none focus:outline-none'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>
                <div className=''>
                  <div className='flex justify-center'>
                    <img src={icons.iconBeneficiary} alt='Beneficiary' className='w-20' />
                  </div>
                  <div className='mt-4 font-gotham-rounded-bold text-primary text-2xl sm:text-3xl text-center'>
                    {translate('okoa_fees.beneficiary_details')}
                  </div>
                  {!isEmpty(activeBeneficiary) ? (
                    <div className='mt-4 font-gotham-rounded-bold text-body opacity-80 text-xl sm:text-2xl text-center'>
                      {!isEmpty(activeBeneficiary) &&
                        `${activeBeneficiary.firstName} ${activeBeneficiary.surname}`}
                    </div>
                  ) : null}

                  <div className='mt-4 text-body opacity-80 sm:px-10 text-sm sm:text-base text-center'>
                    {!isEmpty(activeBeneficiary) ? (
                      <>
                        {translate('okoa_fees.okoa_fees_request_help_text', {
                          student: `${activeBeneficiary.firstName} ${activeBeneficiary.surname}`,
                          nemis: activeBeneficiary.nemisNumber,
                          mwanafunzi: `${activeBeneficiary.firstName} ${activeBeneficiary.surname}`
                        })}
                      </>
                    ) : (
                      <>
                        {translate(
                          'okoa_fees.okoa_request_without_beneficiary_help_text'
                        )}
                      </>
                    )}
                  </div>
                  <div className='mt-4'>
                    <FormGroup>
                      <Select
                        options={beneficiaries.map((beneficiary) => ({
                          label: `${beneficiary.firstName} ${beneficiary.surname}`,
                          value: beneficiary.id
                        }))}
                        hasPlaceholder={true}
                        placeholderText={translate('okoa_fees.select_a_beneficiary')}
                        placeholderClasses='font-gotham-rounded-bold opacity-80 uppercase text-body'
                        onChange={(e) =>
                          onChangeBeneficiary(
                            beneficiaries.find(
                              (beneficiary) => beneficiary.id === e.target.value
                            )
                          )
                        }
                        name='beneficiary_id'
                        hasError={Object.keys(errors).includes('beneficiary_id')}
                        errorMessage={errors.beneficiary_id}
                        value={state.beneficiary_id}
                        disabled={!isEmpty(activeBeneficiary)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type='number'
                        placeholder={translate('okoa_fees.enter_amount')}
                        classes='placeholder:text-body'
                        hasError={Object.keys(errors).includes('amount_borrowed')}
                        errorMessage={errors.amount_borrowed}
                        value={state.amount_borrowed}
                        onChange={handleChange}
                        name='amount_borrowed'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Select
                        options={[
                          {
                            label: translate('okoa_shopping.seven_days'),
                            value: '7'
                          },
                          {
                            label: translate('okoa_shopping.fourteen_days'),
                            value: '14'
                          },
                          {
                            label: translate('okoa_shopping.thirty_days'),
                            value: '30'
                          },
                          {
                            label: translate('okoa_shopping.sixty_days'),
                            value: '60'
                          }
                        ]}
                        hasPlaceholder={true}
                        placeholderText={translate('okoa_shopping.repayment_plan')}
                        placeholderClasses='font-gotham-rounded-bold opacity-80 uppercase text-body'
                        onChange={handleChange}
                        name='repayment_plan'
                        hasError={Object.keys(errors).includes('repayment_plan')}
                        errorMessage={errors.repayment_plan}
                      />
                    </FormGroup>

                    <div className='mt-4'>
                      <Button
                        label={translate('common.next')}
                        onClick={onSubmit}
                        classes='uppercase'
                        disabled={isDisabled}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

OkoaFeesModal.propTypes = {
  translate: PropTypes.func,
  showModal: PropTypes.bool,
  onCancelRequest: PropTypes.func,
  onConfirmRequest: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  showRequestModal: PropTypes.bool,
  onCancelRequestModal: PropTypes.func,
  activeBeneficiary: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  state: PropTypes.object,
  onChangeBeneficiary: PropTypes.func,
  isDisabled: PropTypes.bool
}

export default OkoaFeesModal
