import { Fragment, useEffect, useState, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { Button, FormGroup, PhoneInput, Select, Alert } from '../..'
import { icons } from '../../../assets/img'

function PremiseTypeModal({
  translate,
  showModal,
  onCancel,
  onSubmit,
  errors,
  state,
  handleChange,
  handlePhoneChange,
  disabled,
  searchingUser,
  currentPremiseState,
  searchUser
}) {
  const inputRef = useRef()
  const timeout = useRef()
  const [error, setError] = useState('')
  useEffect(() => {
    setError(errors.apiError)
  }, [errors.apiError])

  const [selectedUser, setSelectedUser] = useState(currentPremiseState.user || {})

  useEffect(() => {
    setSelectedUser(currentPremiseState.user || {})
  }, [currentPremiseState])

  const handleDebounceSearch = (phone) => {
    clearTimeout(timeout.current)

    if (!isEmpty(selectedUser) || phone.length >= 12) {
      timeout.current = setTimeout(() => {
        searchUser({ phone })
      }, 100)
    }
  }

  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={onCancel}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block p-6 w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button onClick={onCancel}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>

                <div className='text-center'>
                  <div className='mt-4 font-gotham-rounded-bold text-primary text-3xl'>
                    {translate('agent_dashboard.premise_registration')}
                  </div>
                  <div className='mt-4 text-body text-sm sm:px-10'>
                    {translate('agent_dashboard.premise_registration_help_text')}
                  </div>

                  {searchingUser && (
                    <div className='mt-4 flex justify-center motion-safe:animate-spin'>
                      <img src={icons.iconSpinner} alt='spinner' className='w-12 h-12' />
                    </div>
                  )}

                  {!isEmpty(selectedUser) && (
                    <>
                      <div className='flex justify-center w-full mt-4'>
                        <img
                          src={selectedUser?.profile_picture || icons.iconElsie}
                          alt='Avatar'
                          className='w-16 h-16 rounded-full'
                        />
                      </div>
                      <div className='mt-4 font-gotham-rounded-bold text-secondary-900 text-xl text-center'>
                        {selectedUser.name}
                      </div>
                    </>
                  )}
                </div>
                {error && (
                  <Alert type='error' message={error} onCancel={() => setError('')} />
                )}
                <section className='mt-3'>
                  <FormGroup>
                    <Select
                      name='premise_type'
                      onChange={handleChange}
                      options={[
                        { label: translate('agent_dashboard.shop'), value: 'shop' },
                        { label: translate('agent_dashboard.school'), value: 'school' }
                        // { label: translate('agent_dashboard.gas_station'), value: 'logistics' },
                      ]}
                      hasPlaceholder={true}
                      placeholderText={translate('agent_dashboard.select_premise_type')}
                      hasError={Object.keys(errors).includes('premise_type')}
                      errorMessage={errors.premise_type}
                    />
                  </FormGroup>
                  {state.premise_type.length > 0 && (
                    <FormGroup>
                      <PhoneInput
                        value={state.phone}
                        onChange={(phone) => handleDebounceSearch(phone)}
                        placeholder={translate('forgot_password.phone_placeholder')}
                        hasError={Object.keys(errors).includes('phone')}
                        errorMessage={errors.phone}
                        name='phone'
                        inputRef={inputRef}
                      />
                    </FormGroup>
                  )}

                  <div className='w-full'>
                    <Button
                      label={translate('common.next')}
                      classes='uppercase'
                      onClick={onSubmit}
                      disabled={isEmpty(selectedUser) || isEmpty(state.premise_type)}
                    />
                  </div>
                </section>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

PremiseTypeModal.propTypes = {
  showModal: PropTypes.bool,
  translate: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  modalTitle: PropTypes.string,
  description: PropTypes.string,
  errors: PropTypes.object,
  state: PropTypes.object,
  handleChange: PropTypes.func,
  handlePhoneChange: PropTypes.func,
  disabled: PropTypes.bool,
  searchingUser: PropTypes.bool,
  currentPremiseState: PropTypes.object,
  searchUser: PropTypes.func
}

export default PremiseTypeModal
