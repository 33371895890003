import { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Transition } from '..'

function Dropdown({
  dropdownTrigger,
  width,
  content,
  dropdownOpen,
  setDropdownOpen,
  position
}) {
  const trigger = useRef(null)
  const dropdown = useRef(null)

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return
      setDropdownOpen(false)
    }
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return
      setDropdownOpen(false)
    }
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })

  return (
    <div className='relative inline-flex'>
      <button
        ref={trigger}
        className='inline-flex group'
        aria-haspopup='true'
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        {dropdownTrigger}
      </button>
      <Transition
        className={`origin-top-right z-50 absolute top-full ${position} min-w-44 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1`}
        show={dropdownOpen}
        enter='transition ease-out duration-200 transform'
        enterStart='opacity-0 -translate-y-2'
        enterEnd='opacity-100 translate-y-0'
        leave='transition ease-out duration-200'
        leaveStart='opacity-100'
        leaveEnd='opacity-0'
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
          className={width}
        >
          {content}
        </div>
      </Transition>
    </div>
  )
}

Dropdown.defaultProps = {
  width: 'w-40',
  position: 'right-0'
}

Dropdown.propTypes = {
  dropdownTrigger: PropTypes.node.isRequired,
  width: PropTypes.string,
  content: PropTypes.node.isRequired,
  dropdownOpen: PropTypes.bool.isRequired,
  setDropdownOpen: PropTypes.func.isRequired,
  position: PropTypes.string
}

export default Dropdown
