import PropTypes from 'prop-types'

function Badge({ type, label, size }) {
  const getBadgeType = () => {
    switch (type) {
      case 'success':
        return 'bg-green-500 bg-opacity-90'
      case 'danger':
        return 'bg-red-500 bg-opacity-90'
      case 'warning':
        return 'bg-yellow-500 bg-opacity-90'
      case 'primary':
        return 'bg-primary bg-opacity-90'
      case 'purple':
        return 'bg-purple-500 bg-opacity-90'
      default:
        return 'bg-gray-500 bg-opacity-90'
    }
  }

  const getBadgeSize = () => {
    switch (size) {
      case 'small':
        return 'px-2 py-1 text-small'
      case 'base':
        return 'p-2 text-xs'
      default:
        return 'p-2 text-xs'
    }
  }

  return (
    <span
      className={` text-white ${getBadgeSize(
        size
      )} font-semibold leading-5 ${getBadgeType(type)} rounded-full`}
    >
      {label}
    </span>
  )
}

Badge.defaultProps = {
  type: 'default',
  size: 'small'
}

Badge.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  size: PropTypes.string
}

export default Badge
