import PropTypes from 'prop-types'
import { DatePicker as Date } from 'antd'
import moment from 'moment'

function DatePicker({
  onChange,
  dateFormat,
  placeholder,
  classes,
  picker,
  disabled,
  readonly,
  mode,
  position,
  defaultDate,
  value,
  name,
  showToday,
  hasError,
  errorMessage,
  disabledDate
}) {
  const valueProps = {
    ...(value && { value: moment(value, dateFormat) }),
    ...(defaultDate && { defaultValue: moment(defaultDate, dateFormat) })
  }

  return (
    <>
      <Date
        onChange={onChange}
        className={`bg-white border ${
          hasError ? 'border-red-300' : 'border-secondary-300'
        } text-body rounded focus:ring-0 outline-none focus:outline-none placeholder:text-body hover:border-secondary-300 shadow-none ${
          hasError ? 'focus:border-red-500' : 'focus:border-secondary-500'
        } block w-full pr-4 py-2.5 font-gotham-rounded-book text-medium ${classes}`}
        format={dateFormat}
        picker={picker}
        disabled={disabled}
        placeholder={placeholder}
        inputReadOnly={readonly}
        mode={mode}
        placement={position}
        name={name}
        showToday={showToday}
        {...valueProps}
        disabledDate={disabledDate}
      />
      <span className='text-xs font-gotham-rounded-book block mt-1 text-red-600'>
        {errorMessage}
      </span>
    </>
  )
}

DatePicker.defaultProps = {
  picker: 'date',
  showToday: false,
  dateFormat: 'YYYY-MM-DD'
}

DatePicker.propTypes = {
  onChange: PropTypes.func,
  dateFormat: PropTypes.string,
  placeholder: PropTypes.string,
  classes: PropTypes.string,
  picker: PropTypes.string,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  mode: PropTypes.string,
  position: PropTypes.string,
  defaultDate: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  showToday: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabledDate: PropTypes.string
}

export default DatePicker
