import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Alert } from '..'
import { icons } from '../../assets/img'
import { languages } from '../../containers/App/constants'
import { HOME } from '../../containers/App/RouteConstants'
import { getUserDetails } from '../../helpers/utils'

function AuthLayout({ content, title, subTitle, helpText, pageTitle, action }) {
  const { i18n } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { notification } = useSelector((state) => state.auth)
  const [alert, setAlert] = useState({
    type: '',
    message: ''
  })
  const isAuthenticated = !isEmpty(getUserDetails())

  const language =
    localStorage.getItem('language') ||
    languages.filter((lang) => lang.value === 'english')[0].value

  useEffect(() => {
    const userLanguage = languages.filter((lang) => lang.value === language)[0]
    if (userLanguage) {
      i18n.changeLanguage(userLanguage.code)
    }
  }, [language, i18n])

  useEffect(() => {
    isAuthenticated && history.push(HOME)
  }, [history, isAuthenticated])

  useEffect(() => {
    setAlert({
      type: notification.type,
      message: notification.message
    })
  }, [notification, dispatch])

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <div className='flex items-center flex-col justify-center min-h-screen bg-white sm:bg-gray-100'>
        <div className='px-8 py-6 mt-4 text-left bg-white sm:shadow-lg w-full sm:rounded sm:w-1/3'>
          <div className='mb-7 flex justify-center'>
            <img src={icons.logo} alt='Logo' className='w-16 h-16' />
          </div>
          <h3 className='text-2xl mb-4 font-medium font-gotham-rounded-medium text-center text-secondary-900'>
            {title}
          </h3>

          {subTitle && (
            <div className='text-slate px-5 text-xl font-normal text-center font-gotham-rounded-medium mb-4'>
              {subTitle}
            </div>
          )}
          {helpText && (
            <div className='text-sm px-5 font-normal font-gotham-rounded-book text-center'>
              {helpText}
            </div>
          )}

          <Alert
            type={alert.type}
            message={alert.message}
            onCancel={() => setAlert({ type: '', message: '' })}
            action={action}
          />

          <section className='mt-5'>{content}</section>
        </div>
      </div>
    </>
  )
}

AuthLayout.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  helpText: PropTypes.string,
  pageTitle: PropTypes.string.isRequired,
  action: PropTypes.node
}

export default AuthLayout
