import PropTypes from 'prop-types'

function Table({ header, body, pagination }) {
  return (
    <>
      <table className='min-w-full divide-y divide-gray-200 border table-fixed'>
        <thead className='bg-gray-100 rounded'>{header}</thead>
        <tbody className='bg-white divide-y divide-gray-200'>{body}</tbody>
      </table>
      {pagination && pagination}
    </>
  )
}

Table.propTypes = {
  header: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  pagination: PropTypes.node
}

export default Table
