import PropTypes from 'prop-types'

function Metric({
  label,
  count,
  icon,
  showPercentage,
  type,
  statPercent,
  t,
  iconNode,
  showIcon
}) {
  return (
    <div className='rounded-md mb-4 lg:mb-0 bg-white shadow-md px-4 py-4 border-gray-200 w-full'>
      <div className='flex flex-wrap items-center justify-between relative'>
        <div>
          <h5 className='mx-0 uppercase block text-xs text-muted font-gotham-rounded-medium'>
            {label}
          </h5>
          <h3 className='py-1 my-2 font-gotham-rounded-bold text-xl text-secondary-900 flex justify-start'>
            {count}
          </h3>
          {showPercentage && (
            <p className='block'>
              {type === 'increase' ? (
                <span className='text-green-500 mr-2'>
                  <i className='bi bi-caret-up-fill text-medium text-green-500 mr-1'></i>
                  <span className='text-sm'>{statPercent}</span>
                </span>
              ) : (
                <span className='text-red-500 mr-2'>
                  <i className='bi bi-caret-down-fill text-red-500 mr-1'></i>
                  <span className='text-sm'>{statPercent}</span>
                </span>
              )}
              <span className='block text-muted text-medium'>
                {t('home.since_last_month')}
              </span>
            </p>
          )}
        </div>
        <div className='absolute right-1'>
          {iconNode && !showIcon ? (
            <>{iconNode}</>
          ) : (
            <>{showIcon && <img src={icon} alt='Metric Icon' className='w-14 h-14' />}</>
          )}
        </div>
      </div>
    </div>
  )
}

Metric.defaultProps = {
  showPercent: false,
  showIcon: true
}

Metric.propTypes = {
  label: PropTypes.string,
  count: PropTypes.any,
  icon: PropTypes.string,
  showPercentage: PropTypes.bool,
  statPercent: PropTypes.string,
  type: PropTypes.string,
  t: PropTypes.func,
  iconNode: PropTypes.node,
  showIcon: PropTypes.bool
}

export default Metric
