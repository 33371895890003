import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import { Button, FormGroup, Input, PhoneInput, Alert } from '../..'

function ShopManagerModal({
  translate,
  showModal,
  onCancel,
  onSubmit,
  errors,
  savingShop,
  handleChange,
  state,
  handleFileChange,
  handlePhoneChange
}) {
  const [error, setError] = useState('')
  useEffect(() => {
    setError(errors.apiError)
  }, [errors.apiError])

  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={onCancel}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block p-6 w-full max-w-2xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button onClick={onCancel}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>

                <div className=''>
                  <header className='uppercase text-medium text-muted font-gotham-rounded-medium block text-center'>
                    <i className='bi bi-person text-primary text-6xl'></i>
                  </header>
                  {error && (
                    <Alert type='error' message={error} onCancel={() => setError('')} />
                  )}
                  <div className='text-body mt-3 text-2xl font-gotham-rounded-medium text-center'>
                    {translate('okoa_shopping.shop_manager')}
                  </div>
                  <div className='mt-4 font-gotham-rounded-medium text-body text-2xl sm:px-20 text-center'>
                    {translate('okoa_shopping.shop_detail_title_text')}
                  </div>
                  <div className='mt-1 text-body text-sm sm:px-20 text-center'>
                    {translate('okoa_shopping.shop_detail_help_text')}
                  </div>

                  <div className='mt-1 sm:grid sm:grid-cols-2 gap-3'>
                    <FormGroup>
                      <Input
                        type='text'
                        name='manager_firstname'
                        placeholder={translate('okoa_shopping.first_name')}
                        hasError={Object.keys(errors).includes('manager_firstname')}
                        errorMessage={errors.manager_firstname}
                        value={state.manager_firstname}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type='text'
                        name='manager_lastname'
                        placeholder={translate('okoa_shopping.second_name')}
                        hasError={Object.keys(errors).includes('manager_lastname')}
                        errorMessage={errors.manager_lastname}
                        value={state.manager_lastname}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </div>

                  <div className='mt-1 sm:grid sm:grid-cols-2 gap-3'>
                    <FormGroup>
                      <PhoneInput
                        value={state.manager_phone_number}
                        onChange={(phone) => handlePhoneChange(phone)}
                        placeholder={translate('okoa_shopping.phone_number')}
                        hasError={Object.keys(errors).includes('manager_phone_number')}
                        errorMessage={errors.manager_phone_number}
                        name='manager_phone_number'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type='text'
                        name='manager_email'
                        placeholder={translate('okoa_shopping.email_address')}
                        hasError={Object.keys(errors).includes('manager_email')}
                        errorMessage={errors.manager_email}
                        value={state.manager_email}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </div>

                  <div className='mt-1'>
                    <FormGroup>
                      <div className='relative'>
                        <label
                          htmlFor='manager_identification'
                          className={`bg-white border ${
                            Object.keys(errors).includes('manager_identification')
                              ? 'border-red-300'
                              : 'border-secondary-300'
                          } text-body rounded focus:ring-0 outline-none focus:outline-none focus:border-secondary-500 block w-full pr-4 py-2.5 font-gotham-rounded-book text-medium pl-4`}
                        >
                          {state.manager_identification !== '' ? (
                            <>{`${
                              state.manager_identification.name.length > 30
                                ? `${state.manager_identification.name.substring(
                                    0,
                                    30
                                  )}...`
                                : state.manager_identification.name
                            }`}</>
                          ) : (
                            <span className='opacity-50'>
                              {translate('okoa_shopping.id_placeholder')}
                            </span>
                          )}
                        </label>
                        <div className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 justify-between'>
                          <div className='mr-3 -rotate-45'>
                            <i className='bi bi-paperclip text-secondary-900 text-base'></i>
                          </div>
                          <div>
                            <i className='bi bi-camera-fill text-secondary-900 text-base'></i>
                          </div>
                        </div>
                      </div>
                      <Input
                        type='file'
                        name='manager_identification'
                        placeholder={translate('okoa_shopping.id_placeholder')}
                        hasError={Object.keys(errors).includes('manager_identification')}
                        errorMessage={errors.manager_identification}
                        onChange={handleFileChange}
                        classes='hidden'
                        id='manager_identification'
                      />
                    </FormGroup>
                  </div>

                  <div className='mt-4 sm:px-5 sm:w-72 flex justify-center mx-auto'>
                    <Button
                      label={translate('okoa_shopping.add_shop')}
                      classes='uppercase'
                      onClick={() => onSubmit(state)}
                      disabled={savingShop}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

ShopManagerModal.propTypes = {
  showModal: PropTypes.bool,
  translate: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  errors: PropTypes.object,
  savingShop: PropTypes.bool,
  state: PropTypes.object,
  handleChange: PropTypes.func,
  handleFileChange: PropTypes.func,
  handlePhoneChange: PropTypes.func
}

export default ShopManagerModal
