import config from '../helpers/config'
import api from '.'

const CLIENT_SECRET = config('CLIENT_SECRET')

export const login = (payload) =>
  api.post(`/auth/signin?client_secret=${CLIENT_SECRET}`, payload)

export const register = (payload) =>
  api.post(`/auth/signup?client_secret=${CLIENT_SECRET}`, payload)

export const logout = (token) =>
  api.post(`/auth/signout?client_secret=${CLIENT_SECRET}`, { token })

export const forgotPassword = (payload) =>
  api.post(`/auth/forgot-password?client_secret=${CLIENT_SECRET}`, payload)

export const resetPassword = (payload) =>
  api.put(`/auth/reset-password?client_secret=${CLIENT_SECRET}`, payload)

export const otpVerification = (payload) =>
  api.post(`/auth/verify-otp?client_secret=${CLIENT_SECRET}`, payload)

export const sendOtp = (payload) =>
  api.post(`/auth/send-otp?client_secret=${CLIENT_SECRET}`, payload)

export const verifyEmail = (payload) =>
  api.post(`/auth/verify-email?client_secret=${CLIENT_SECRET}`, payload)

export const updateProfile = ({ userId, payload }) =>
  api.put(`/auth/update-profile/${userId}?client_secret=${CLIENT_SECRET}`, payload)

export const fetchUsers = (userId) =>
  api.get(`/auth?client_secret=${CLIENT_SECRET}`, { user_id: userId })

export const getUserByPhone = (phone) =>
  api.get(`/auth?client_secret=${CLIENT_SECRET}`, { phone })
