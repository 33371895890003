import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'ramda'
import { icons } from '../../../assets/img'
import { Table, TableSkeleton, EmptyContent, Button } from '../..'
import {
  loadNextBeneficiaries,
  loadPrevBeneficiaries
} from '../../../containers/OkoaFees/store'
import { formatTimestampToDate } from '../../../helpers/utils'

function Beneficiaries({ t, onOkoa }) {
  const dispatch = useDispatch()
  const {
    beneficiaries,
    isLoading,
    beneficiariesPagination: pagination
  } = useSelector((state) => state.okoaFees)

  return (
    <>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <>
          {isEmpty(beneficiaries) ? (
            <div className='flex flex-col'>
              <div className='overflow-x-auto shadow-md sm:rounded-lg'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='p-4'>
                    <h2 className='font-gotham-rounded-medium text-body text-base'>
                      {t('okoa_fees.beneficiaries')}
                    </h2>
                    <EmptyContent
                      title={t('common.no_beneficiaries')}
                      subTitle={t('common.no_beneficiaries_help_text')}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='flex flex-col'>
              <div className='overflow-x-auto shadow-md sm:rounded-lg w-full'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='p-4 flex'>
                    <h2 className='font-gotham-rounded-medium text-body text-base mb-2'>
                      {t('okoa_fees.beneficiaries')}
                    </h2>
                  </div>
                  <div className='overflow-hidden p-4'>
                    <Table
                      header={
                        <tr>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_fees.name')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_fees.date_added')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_fees.school')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_fees.nemis_number')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_fees.school_paylend_number')}
                          </th>
                          <th scope='col' className='p-4'>
                            <span className='sr-only'>{t('okoa_shopping.action')}</span>
                          </th>
                        </tr>
                      }
                      body={
                        <>
                          {beneficiaries.map((beneficiary) => (
                            <tr key={beneficiary.id}>
                              <td className='pl-2 py-4 whitespace-nowrap'>
                                <div className='flex items-center'>
                                  <div className='flex-shrink-0 h-10 w-10'>
                                    <img
                                      className='h-10 w-10 rounded-full'
                                      src={
                                        !isEmpty(beneficiary.studentPhoto)
                                          ? beneficiary.studentPhoto
                                          : icons.iconElsie
                                      }
                                      alt={beneficiary.firstName}
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null
                                        currentTarget.src = icons.iconElsie
                                      }}
                                    />
                                  </div>
                                  <div className='ml-4'>
                                    <div className='text-medium font-medium text-body'>
                                      {`${beneficiary.firstName} ${beneficiary.surname}`}
                                    </div>
                                    <div className='text-xs text-gray-500'>
                                      {beneficiary.studentLevel}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className='pl-2 py-4 text-medium text-body'>
                                {formatTimestampToDate(beneficiary.createdAt)}
                              </td>
                              <td className='pl-2 py-4 text-medium text-body'>
                                <div>{beneficiary.premise.name}</div>
                                <div>{beneficiary.premise.location}</div>
                              </td>
                              <td className='pl-2 py-4 text-medium text-body'>
                                {beneficiary.nemisNumber}
                              </td>
                              <td className='pl-2 py-4 text-medium text-body'>
                                {beneficiary.paylendNumber}
                              </td>
                              <td className='pl-2 pr-2 py-4 text-medium text-body'>
                                <Button
                                  label='Okoa'
                                  classes='uppercase'
                                  onClick={() => onOkoa(beneficiary)}
                                />
                              </td>
                            </tr>
                          ))}
                        </>
                      }
                      pagination={
                        <div className='flex justify-between pl-2 pr-6 py-4 w-full border border-gray-200 border-t-0'>
                          <div className='text-medium text-muted'>
                            {pagination.count} {t('common.of')} {pagination.total}{' '}
                            {t('okoa_fees.beneficiaries')}
                          </div>
                          <div className='flex justify-between gap-4'>
                            <button
                              className={`text-medium ${
                                pagination.hasPrev
                                  ? 'text-secondary-900'
                                  : 'text-muted pointer-events-none'
                              }`}
                              onClick={() => dispatch(loadPrevBeneficiaries())}
                            >
                              {t('common.prev')}
                            </button>
                            <button
                              className={`text-medium ${
                                pagination.hasNext
                                  ? 'text-secondary-900'
                                  : 'text-muted pointer-events-none'
                              }`}
                              onClick={() => dispatch(loadNextBeneficiaries())}
                            >
                              {t('common.next')}
                            </button>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

Beneficiaries.propTypes = {
  t: PropTypes.func,
  onOkoa: PropTypes.func
}

export default Beneficiaries
