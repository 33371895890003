import config from '../helpers/config'
import api from '.'

const CLIENT_SECRET = config('CLIENT_SECRET')

export const getTotalMetrics = (userId) =>
  api.get(`/metrics/${userId}?client_secret=${CLIENT_SECRET}`)

export const changePassword = (userId, payload) =>
  api.put(`/auth/change-password/${userId}?client_secret=${CLIENT_SECRET}`, payload)

export const migrate = (userId) =>
  api.post(`/migration/${userId}?client_secret=${CLIENT_SECRET}`)

export const getProductMetrics = (userId, type) =>
  api.get(`/metrics/product/${userId}?client_secret=${CLIENT_SECRET}&type=${type}`)
