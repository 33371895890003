import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Tab } from '@headlessui/react'
import { isEmpty } from 'ramda'
import { Table, TableSkeleton, EmptyContent, Badge } from '../..'
import {
  formatAmount,
  formatDateToTime,
  formatTimestampToDate
} from '../../../helpers/utils'
import {
  loadNextWithdrawals as loadNextTransactions,
  loadPrevWithdrawals as loadPrevTransactions,
  loadNextLoans,
  loadPrevLoans,
  loadNextRepayments,
  loadPrevRepayments
} from '../../../containers/InuaBiashara/store'
import services from '../../../helpers/services'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function InuaBizTransactions({ t }) {
  const dispatch = useDispatch()
  const {
    withdrawals: transactions,
    isLoading,
    withdrawalsPagination: pagination,
    loans,
    loansPagination,
    repayments,
    repaymentsPagination
  } = useSelector((state) => state.inuaBiashara)
  const { currency } = useSelector((state) => state.auth)

  return (
    <>
      {isLoading ? (
        <>
          <TableSkeleton showSearch={true} showPagination={true} />
        </>
      ) : (
        <>
          <div className='flex'>
            <div className='overflow-x-auto shadow-md sm:rounded-lg w-full'>
              <div className='inline-block min-w-full align-middle'>
                <Tab.Group>
                  <Tab.List className='flex space-x-1 border-b border-gray-300'>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          'inline-block py-4 px-4 text-sm -mb-px w-full',
                          '',
                          selected
                            ? 'border-b-2 border-primary font-gotham-rounded-bold text-primary'
                            : 'text-gray-400 font-gotham-rounded-bold'
                        )
                      }
                    >
                      {t('common.requests')}
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          'inline-block py-4 px-4 text-sm -mb-px w-full',
                          '',
                          selected
                            ? 'border-b-2 border-primary font-gotham-rounded-bold text-primary'
                            : 'text-gray-400 font-gotham-rounded-bold'
                        )
                      }
                    >
                      {t('common.repayments')}
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          'inline-block py-4 px-4 text-sm -mb-px w-full',
                          '',
                          selected
                            ? 'border-b-2 border-primary font-gotham-rounded-bold text-primary'
                            : 'text-gray-400 font-gotham-rounded-bold'
                        )
                      }
                    >
                      {t('common.withdrawals')}
                    </Tab>
                  </Tab.List>

                  <Tab.Panels className='mt-2 focus:outline-none outline-none'>
                    <Tab.Panel className='focus:outline-none outline-none py-2'>
                      {isEmpty(loans) ? (
                        <div className='flex flex-col'>
                          <div className='overflow-x-auto'>
                            <div className='inline-block min-w-full align-middle'>
                              <div className='p-4'>
                                <EmptyContent
                                  title={t('common.no_requests')}
                                  subTitle={t('common.no_requests_help_text_inua_biz')}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className='flex flex-col'>
                          <div className='overflow-x-auto w-full'>
                            <div className='inline-block min-w-full align-middle'>
                              <div className='overflow-hidden p-4'>
                                <section className='mt-2'>
                                  <Table
                                    header={
                                      <tr>
                                        <th
                                          scope='col'
                                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                        >
                                          {t('okoa_shopping.date_requested')}
                                        </th>
                                        <th
                                          scope='col'
                                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                        >
                                          {t('common.premise')}
                                        </th>
                                        <th
                                          scope='col'
                                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                        >
                                          {t('okoa_fees.amount')}
                                        </th>
                                        <th
                                          scope='col'
                                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                        >
                                          {t('okoa_shopping.due_date')}
                                        </th>
                                        <th
                                          scope='col'
                                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                        >
                                          {t('okoa_shopping.status')}
                                        </th>
                                      </tr>
                                    }
                                    body={
                                      <>
                                        {loans.map((loan) => (
                                          <tr key={loan.id}>
                                            <td className='pl-2 py-4 whitespace-nowrap'>
                                              <div className='text-medium font-medium text-body'>
                                                {formatTimestampToDate(loan.createdAt)}
                                              </div>
                                              <div className='text-xs text-gray-500'>
                                                {formatDateToTime(loan.createdAt)}
                                              </div>
                                            </td>
                                            <td className='pl-2 py-4 w-40 text-medium capitalize text-body'>
                                              <div className='text-medium font-medium text-body'>
                                                {loan.premiseName}
                                              </div>
                                              <div className='text-xs text-gray-500'>
                                                {loan.paylendNumber}
                                              </div>
                                            </td>
                                            <td className='pl-2 py-4 text-medium text-body'>
                                              <div className='text-medium font-medium text-body'>
                                                {services().INUA_BIZ}: {currency}{' '}
                                                {formatAmount(loan.amountBorrowed)}
                                              </div>
                                              {loan.status !== 'Business denied' &&
                                                loans.status !== 'Ignored' &&
                                                loan.status !== 'Business pending' && (
                                                  <div className='text-medium font-medium text-body'>
                                                    {t('okoa_shopping.total_due')}:{' '}
                                                    {currency}{' '}
                                                    {formatAmount(loan.amountDue)}
                                                  </div>
                                                )}
                                            </td>
                                            <td className='pl-2 py-4 text-medium capitalize text-body'>
                                              {isEmpty(loan.dueDate)
                                                ? t('common.not_available')
                                                : loan.formatedDueDate}
                                            </td>
                                            <td className='px-2 py-4 text-medium capitalize text-body'>
                                              {(loan.status === 'Approved' ||
                                                loan.status === 'Accepted' ||
                                                loan.status === 'Business accepted') && (
                                                <Badge
                                                  type={'primary'}
                                                  label={t('common.accepted')}
                                                />
                                              )}
                                              {loan.status === 'Fully Paid' && (
                                                <Badge
                                                  type={'success'}
                                                  label={t('common.fully_paid')}
                                                />
                                              )}
                                              {loan.status === 'On Payment' && (
                                                <Badge
                                                  type={'warning'}
                                                  label={t('common.on_payment')}
                                                />
                                              )}
                                              {(loan.status === 'Business denied' ||
                                                loan.status === 'Ignored') && (
                                                <Badge
                                                  type={'danger'}
                                                  label={t('common.denied')}
                                                />
                                              )}
                                              {loan.status === 'Business pending' && (
                                                <Badge
                                                  type={'warning'}
                                                  label={t('common.pending')}
                                                />
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                      </>
                                    }
                                    pagination={
                                      <div className='flex justify-between pl-2 pr-6 py-4 w-full border border-gray-200 border-t-0'>
                                        <div className='text-medium text-muted'>
                                          {loansPagination.count} {t('common.of')}{' '}
                                          {loansPagination.total}{' '}
                                          {t('okoa_fees.requests')}
                                        </div>
                                        <div className='flex justify-between gap-4'>
                                          <button
                                            className={`text-medium ${
                                              loansPagination.hasPrev
                                                ? 'text-secondary-900'
                                                : 'text-muted pointer-events-none'
                                            }`}
                                            onClick={() => dispatch(loadPrevLoans())}
                                          >
                                            {t('common.prev')}
                                          </button>
                                          <button
                                            className={`text-medium ${
                                              loansPagination.hasNext
                                                ? 'text-secondary-900'
                                                : 'text-muted pointer-events-none'
                                            }`}
                                            onClick={() => dispatch(loadNextLoans())}
                                          >
                                            {t('common.next')}
                                          </button>
                                        </div>
                                      </div>
                                    }
                                  />
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Tab.Panel>
                    <Tab.Panel className='focus:outline-none outline-none py-2'>
                      {isEmpty(repayments) ? (
                        <div className='flex flex-col'>
                          <div className='overflow-x-auto'>
                            <div className='inline-block min-w-full align-middle'>
                              <div className='p-4'>
                                <EmptyContent
                                  title={t('common.no_repayments')}
                                  subTitle={t('common.no_inua_repayments_help_text')}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className='flex flex-col'>
                            <div className='overflow-x-auto w-full'>
                              <div className='inline-block min-w-full align-middle'>
                                <div className='overflow-hidden p-4'>
                                  <section className='mt-2'>
                                    <Table
                                      header={
                                        <tr>
                                          <th
                                            scope='col'
                                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                          >
                                            {t('okoa_shopping.date')}
                                          </th>
                                          <th
                                            scope='col'
                                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                          >
                                            {t('okoa_shopping.transaction_number')}
                                          </th>
                                          <th
                                            scope='col'
                                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                          >
                                            {t('common.premise')}
                                          </th>
                                          <th
                                            scope='col'
                                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                          >
                                            {t('okoa_shopping.amount')}
                                          </th>
                                          <th
                                            scope='col'
                                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                          >
                                            {t('okoa_shopping.status')}
                                          </th>
                                          <th
                                            scope='col'
                                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                          >
                                            {t('okoa_shopping.type')}
                                          </th>
                                        </tr>
                                      }
                                      body={
                                        <>
                                          {repayments.map((transaction) => (
                                            <tr key={transaction.id}>
                                              <td className='pl-2 py-4 whitespace-nowrap'>
                                                <div className='text-medium font-medium text-body'>
                                                  {formatTimestampToDate(
                                                    transaction.createdAt
                                                  )}
                                                </div>
                                                <div className='text-xs text-gray-500'>
                                                  {formatDateToTime(
                                                    transaction.createdAt
                                                  )}
                                                </div>
                                              </td>
                                              <td className='pl-2 py-4 w-40 text-medium uppercase text-body'>
                                                {transaction.receiptNumber}
                                              </td>
                                              <td className='pl-2 py-4 w-40 text-medium text-body'>
                                                {transaction.premiseName ? (
                                                  <>
                                                    <div className='text-medium font-medium text-body'>
                                                      {transaction.premiseName}
                                                    </div>
                                                    <div className='text-xs text-gray-500'>
                                                      {transaction.paylendNumber}
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>{t('common.not_applicable')}</>
                                                )}
                                              </td>
                                              <td className='pl-2 py-4 text-medium text-body'>
                                                {transaction.actionType ===
                                                'give_loan_to_user' ? (
                                                  <span className='text-green-500'>
                                                    + {currency}{' '}
                                                    {formatAmount(transaction.amount)}
                                                  </span>
                                                ) : (
                                                  <span className='text-primary'>
                                                    - {currency}{' '}
                                                    {formatAmount(transaction.amount)}
                                                  </span>
                                                )}
                                              </td>
                                              <td className='pl-2 py-4 text-medium capitalize text-body'>
                                                {transaction.status}
                                              </td>
                                              <td className='pl-2 py-4 text-medium capitalize text-body'>
                                                {transaction.actionType ===
                                                  'repay_loan_by_premise' && (
                                                  <span>{t('common.repayment')}</span>
                                                )}
                                              </td>
                                            </tr>
                                          ))}
                                        </>
                                      }
                                      pagination={
                                        <div className='flex justify-between pl-2 pr-6 py-4 w-full border border-gray-200 border-t-0'>
                                          <div className='text-medium text-muted'>
                                            {repaymentsPagination.count} {t('common.of')}{' '}
                                            {repaymentsPagination.total}{' '}
                                            {t('okoa_shopping.transactions')}
                                          </div>
                                          <div className='flex justify-between gap-4'>
                                            <button
                                              className={`text-medium ${
                                                repaymentsPagination.hasPrev
                                                  ? 'text-secondary-900'
                                                  : 'text-muted pointer-events-none'
                                              }`}
                                              onClick={() =>
                                                dispatch(loadPrevRepayments({}))
                                              }
                                            >
                                              {t('common.prev')}
                                            </button>
                                            <button
                                              className={`text-medium ${
                                                repaymentsPagination.hasNext
                                                  ? 'text-secondary-900'
                                                  : 'text-muted pointer-events-none'
                                              }`}
                                              onClick={() =>
                                                dispatch(loadNextRepayments({}))
                                              }
                                            >
                                              {t('common.next')}
                                            </button>
                                          </div>
                                        </div>
                                      }
                                    />
                                  </section>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </Tab.Panel>
                    <Tab.Panel className='focus:outline-none outline-none py-2'>
                      {isEmpty(transactions) ? (
                        <div className='flex flex-col'>
                          <div className='overflow-x-auto'>
                            <div className='inline-block min-w-full align-middle'>
                              <div className='p-4'>
                                <EmptyContent
                                  title={t('common.no_withdrawals')}
                                  subTitle={t('common.no_withdrawals_help_text')}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className='flex flex-col'>
                            <div className='overflow-x-auto w-full'>
                              <div className='inline-block min-w-full align-middle'>
                                <div className='overflow-hidden p-4'>
                                  <section className='mt-2'>
                                    <Table
                                      header={
                                        <tr>
                                          <th
                                            scope='col'
                                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                          >
                                            {t('okoa_shopping.date')}
                                          </th>
                                          <th
                                            scope='col'
                                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                          >
                                            {t('okoa_shopping.transaction_number')}
                                          </th>
                                          <th
                                            scope='col'
                                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                          >
                                            {t('common.premise')}
                                          </th>
                                          <th
                                            scope='col'
                                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                          >
                                            {t('okoa_shopping.amount')}
                                          </th>
                                          <th
                                            scope='col'
                                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                          >
                                            {t('okoa_shopping.status')}
                                          </th>
                                          <th
                                            scope='col'
                                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                          >
                                            {t('okoa_shopping.type')}
                                          </th>
                                        </tr>
                                      }
                                      body={
                                        <>
                                          {transactions.map((transaction) => (
                                            <tr key={transaction.id}>
                                              <td className='pl-2 py-4 whitespace-nowrap'>
                                                <div className='text-medium font-medium text-body'>
                                                  {formatTimestampToDate(
                                                    transaction.createdAt
                                                  )}
                                                </div>
                                                <div className='text-xs text-gray-500'>
                                                  {formatDateToTime(
                                                    transaction.createdAt
                                                  )}
                                                </div>
                                              </td>
                                              <td className='pl-2 py-4 w-40 text-medium uppercase text-body'>
                                                {transaction.receiptNumber}
                                              </td>
                                              <td className='pl-2 py-4 w-40 text-medium text-body'>
                                                {transaction.premiseName ? (
                                                  <>
                                                    <div className='text-medium font-medium text-body'>
                                                      {transaction.premiseName}
                                                    </div>
                                                    <div className='text-xs text-gray-500'>
                                                      {transaction.paylendNumber}
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>{t('common.not_applicable')}</>
                                                )}
                                              </td>
                                              <td className='pl-2 py-4 text-medium text-body'>
                                                {transaction.actionType ===
                                                'withdraw_premise_wallet' ? (
                                                  <span className='text-green-500'>
                                                    + {currency}{' '}
                                                    {formatAmount(transaction.amount)}
                                                  </span>
                                                ) : (
                                                  <span className='text-primary'>
                                                    - {currency}{' '}
                                                    {formatAmount(transaction.amount)}
                                                  </span>
                                                )}
                                              </td>
                                              <td className='pl-2 py-4 text-medium capitalize text-body'>
                                                {transaction.status}
                                              </td>
                                              <td className='pl-2 py-4 text-medium capitalize text-body'>
                                                {transaction.actionType ===
                                                  'withdraw_premise_wallet' && (
                                                  <span>{t('common.withdrawal')}</span>
                                                )}
                                              </td>
                                            </tr>
                                          ))}
                                        </>
                                      }
                                      pagination={
                                        <div className='flex justify-between pl-2 pr-6 py-4 w-full border border-gray-200 border-t-0'>
                                          <div className='text-medium text-muted'>
                                            {pagination.count} {t('common.of')}{' '}
                                            {pagination.total}{' '}
                                            {t('okoa_shopping.transactions')}
                                          </div>
                                          <div className='flex justify-between gap-4'>
                                            <button
                                              className={`text-medium ${
                                                pagination.hasPrev
                                                  ? 'text-secondary-900'
                                                  : 'text-muted pointer-events-none'
                                              }`}
                                              onClick={() =>
                                                dispatch(loadPrevTransactions({}))
                                              }
                                            >
                                              {t('common.prev')}
                                            </button>
                                            <button
                                              className={`text-medium ${
                                                pagination.hasNext
                                                  ? 'text-secondary-900'
                                                  : 'text-muted pointer-events-none'
                                              }`}
                                              onClick={() =>
                                                dispatch(loadNextTransactions({}))
                                              }
                                            >
                                              {t('common.next')}
                                            </button>
                                          </div>
                                        </div>
                                      }
                                    />
                                  </section>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

InuaBizTransactions.propTypes = {
  t: PropTypes.func
}

export default InuaBizTransactions
