import config from '../helpers/config'
import api from '.'

const CLIENT_SECRET = config('CLIENT_SECRET')

export const createBeneficiary = (payload) =>
  api.post(`/beneficiaries?client_secret=${CLIENT_SECRET}`, payload)

export const getBeneficiaries = (payload) =>
  api.get(`/beneficiaries?client_secret=${CLIENT_SECRET}`, payload)

export const getBeneficiaryById = (beneficiaryId) =>
  api.get(`/beneficiaries/${beneficiaryId}?client_secret=${CLIENT_SECRET}`)

export const updateBeneficiary = ({ beneficiaryId, payload }) =>
  api.put(`/beneficiaries/${beneficiaryId}?client_secret=${CLIENT_SECRET}`, payload)
