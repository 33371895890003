import PropTypes from 'prop-types'

function EmptyContent({ title, subTitle, actionButton }) {
  return (
    <div className='text-center sm:px-48 sm:py-12'>
      <h3 className='font-gotham-rounded-bold text-sm sm:text-base mb-2'>{title}</h3>
      <div className='text-body text-medium'>{subTitle}</div>
      <div className='w-32 mt-4 mx-auto'>{actionButton}</div>
    </div>
  )
}

EmptyContent.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  actionButton: PropTypes.node
}

export default EmptyContent
