import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Transition } from '@headlessui/react'
import { Button } from '../..'

function InitiativeInfoModal({ t, showModal, onCancel, onSubmit, disabled, initiative }) {
  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={onCancel}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button onClick={onCancel}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>

                <div className=''>
                  <div className='capitalize text-2xl text-primary font-gotham-rounded-bold text-center'>
                    {initiative.name}
                  </div>

                  <div className='text-body text-sm sm:px-6 text-center mt-4'>
                    <div>
                      <span className='font-gotham-rounded-medium'>
                        {t('changisha.target_amount')}:{' '}
                      </span>
                      Ksh {initiative.targetAmount}
                    </div>
                    <div className='mt-1'>
                      <span className='font-gotham-rounded-medium'>
                        {t('home.total_raised')}:{' '}
                      </span>
                      Ksh {initiative.totalRaised}
                    </div>
                    <div className='mt-1'>
                      <span className='font-gotham-rounded-medium'>
                        {t('changisha.end_date')}:{' '}
                      </span>
                      {initiative.endDate}
                    </div>
                  </div>

                  <div className='w-28 mt-4 text-center flex justify-center mx-auto'>
                    <Button
                      label={t('common.okay')}
                      onClick={onCancel}
                      classes='uppercase'
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

InitiativeInfoModal.propTypes = {
  t: PropTypes.func,
  showModal: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  disabled: PropTypes.func,
  initiative: PropTypes.object
}

export default InitiativeInfoModal
