import PropTypes from 'prop-types'
import { Tab } from '@headlessui/react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'ramda'
import { Table, TableSkeleton, EmptyContent, Badge } from '../..'
import {
  formatAmount,
  formatDateToTime,
  formatTimestampToDate,
  getUserDetails
} from '../../../helpers/utils'
import {
  loadNextVoucherTransactions as loadNextTransactions,
  loadPrevVoucherTransactions as loadPrevTransactions,
  loadNextLoans,
  loadPrevLoans,
  loadPrevRepayments,
  loadNextRepayments
} from '../../../containers/OkoaFees/store'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Transactions({
  t,
  okoaUser,
  onDeclineRequest,
  onAcceptRequest,
  onConfirmRequest,
  onIgnoreRequest
}) {
  const dispatch = useDispatch()
  const {
    voucherTransactions: transactions,
    isLoading,
    voucherTransactionsPagination: pagination,
    loans,
    loansPagination,
    repayments,
    repaymentsPagination
  } = useSelector((state) => state.okoaFees)
  const { currency } = useSelector((state) => state.auth)
  const user = getUserDetails()

  const isSchool = okoaUser.value === 'school'

  return (
    <>
      {isLoading ? (
        <>
          <TableSkeleton showSearch={true} showPagination={true} />
        </>
      ) : (
        <>
          <div className='flex'>
            <div className='overflow-x-auto shadow-md sm:rounded-lg w-full'>
              <div className='inline-block min-w-full align-middle'>
                <Tab.Group>
                  <Tab.List className='flex space-x-1 border-b border-gray-300'>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          'inline-block py-4 px-4 text-sm -mb-px w-full',
                          '',
                          selected
                            ? 'border-b-2 border-primary font-gotham-rounded-bold text-primary'
                            : 'text-gray-400 font-gotham-rounded-bold'
                        )
                      }
                    >
                      {isSchool ? t('common.okoa_requests') : t('common.requests')}
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          'inline-block py-4 px-4 text-sm -mb-px w-full',
                          '',
                          selected
                            ? 'border-b-2 border-primary font-gotham-rounded-bold text-primary'
                            : 'text-gray-400 font-gotham-rounded-bold'
                        )
                      }
                    >
                      {isSchool
                        ? t('common.voucher_transactions')
                        : t('common.repayments')}
                    </Tab>
                  </Tab.List>
                  <Tab.Panels className='mt-2 focus:outline-none outline-none'>
                    <Tab.Panel className='focus:outline-none outline-none py-2'>
                      {isEmpty(loans) ? (
                        <div className='flex flex-col'>
                          <div className='overflow-x-auto'>
                            <div className='inline-block min-w-full align-middle'>
                              <div className='p-4'>
                                <EmptyContent
                                  title={t('common.no_requests')}
                                  subTitle={
                                    isSchool
                                      ? t('common.no_shopper_requests_help_text')
                                      : t('common.no_fee_requests_help_text')
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className='flex flex-col'>
                          <div className='overflow-x-auto w-full'>
                            <div className='inline-block min-w-full align-middle'>
                              <div className='overflow-hidden p-4'>
                                <section className='mt-2'>
                                  <Table
                                    header={
                                      <tr>
                                        <th
                                          scope='col'
                                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                        >
                                          {t('okoa_fees.name')}
                                        </th>
                                        <th
                                          scope='col'
                                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                        >
                                          {t('okoa_fees.school_name')}
                                        </th>
                                        <th
                                          scope='col'
                                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                        >
                                          {t('okoa_fees.amount')}
                                        </th>
                                        <th
                                          scope='col'
                                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                        >
                                          {t('okoa_shopping.status')}
                                        </th>
                                        <th
                                          scope='col'
                                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                        >
                                          {isSchool
                                            ? t('common.confirmed_by_user')
                                            : t('common.confirmed')}
                                        </th>
                                        <th scope='col' className='p-4'>
                                          <span className='sr-only'>
                                            {t('okoa_shopping.action')}
                                          </span>
                                        </th>
                                      </tr>
                                    }
                                    body={
                                      <>
                                        {loans.map((loan) => (
                                          <tr key={loan.id}>
                                            <td className='pl-2 py-4 whitespace-nowrap'>
                                              <div className='text-medium font-medium text-body'>
                                                {loan.beneficiary?.name}
                                              </div>
                                              <div className='text-xs text-gray-500'>
                                                {loan.beneficiary && (
                                                  <span>
                                                    {t('okoa_fees.nemis')}:{' '}
                                                    {loan.beneficiary.nemis}
                                                  </span>
                                                )}
                                              </div>
                                              <div className='text-xs text-gray-500'>
                                                {formatTimestampToDate(loan.createdAt)}
                                              </div>
                                            </td>
                                            <td className='pl-2 py-4 w-40 text-medium text-body'>
                                              <div className='text-medium font-medium text-body'>
                                                {`${loan.premise.name}`}
                                                <div>
                                                  {loan.premise.location && (
                                                    <div>{loan.premise.location}</div>
                                                  )}
                                                </div>
                                              </div>
                                              <div className='text-xs text-gray-500'>
                                                {loan.paylendNumber}
                                              </div>
                                            </td>
                                            <td className='pl-2 py-4 text-medium text-body'>
                                              <div className='text-medium font-medium text-body'>
                                                Okoa: {currency}{' '}
                                                {formatAmount(loan.amountBorrowed)}
                                              </div>
                                              <div className='text-medium font-medium text-body'>
                                                {t('common.fee')}: {currency}{' '}
                                                {formatAmount(
                                                  loan.amountDue - loan.amountBorrowed
                                                )}
                                              </div>
                                              <div className='text-medium font-medium text-body'>
                                                {t('okoa_shopping.total_due')}: {currency}{' '}
                                                {formatAmount(loan.amountDue)}
                                              </div>
                                            </td>
                                            <td className='pl-2 py-4 text-medium text-body'>
                                              <div
                                                className={
                                                  isSchool
                                                    ? ''
                                                    : 'flex justify-around items-center'
                                                }
                                              >
                                                <span
                                                  className={isSchool ? '' : 'mr-auto'}
                                                >
                                                  {(loan.status === 'Approved' ||
                                                    loan.status === 'Accepted') && (
                                                    <Badge
                                                      type={'purple'}
                                                      label={t('common.approved')}
                                                    />
                                                  )}
                                                  {loan.status === 'Fully Paid' && (
                                                    <Badge
                                                      type={'success'}
                                                      label={t('common.fully_paid')}
                                                    />
                                                  )}
                                                  {loan.status === 'On Payment' && (
                                                    <Badge
                                                      type={'warning'}
                                                      label={t('common.on_payment')}
                                                    />
                                                  )}
                                                  {loan.status === 'Pending' && (
                                                    <Badge
                                                      type={'warning'}
                                                      label={t('common.pending')}
                                                    />
                                                  )}
                                                  {loan.status === 'Confirmed' && (
                                                    <Badge
                                                      type={'success'}
                                                      label={t('common.confirmed')}
                                                    />
                                                  )}
                                                  {(loan.status === 'Ignored' ||
                                                    loan.status === 'Declined') && (
                                                    <Badge
                                                      type={'danger'}
                                                      label={t('common.declined')}
                                                    />
                                                  )}
                                                </span>
                                              </div>
                                            </td>
                                            <td className='pl-2 py-4 text-medium text-body'>
                                              {loan.status === 'Confirmed'
                                                ? t('common.yes')
                                                : t('common.no')}
                                            </td>
                                            {isSchool && loan.status === 'Pending' && (
                                              <td className='px-2 py-4 text-medium text-body'>
                                                <div className='flex items-center gap-3'>
                                                  <button
                                                    className='cursor-pointer font-gotham-rounded-medium text-green-600 mr-2'
                                                    onClick={() => onAcceptRequest(loan)}
                                                  >
                                                    {t('common.approve')}
                                                  </button>
                                                  <button
                                                    className='cursor-pointer font-gotham-rounded-medium text-red-600'
                                                    onClick={() => onDeclineRequest(loan)}
                                                  >
                                                    {t('okoa_fees.decline')}
                                                  </button>
                                                </div>
                                              </td>
                                            )}
                                            {!isSchool && loan.status === 'Approved' && (
                                              <td className='px-2 py-4 text-medium text-body'>
                                                <div className='flex items-center gap-3'>
                                                  <button
                                                    className='cursor-pointer font-gotham-rounded-medium text-green-600 mr-2'
                                                    onClick={() => onConfirmRequest(loan)}
                                                  >
                                                    {t('common.confirm')}
                                                  </button>
                                                  <button
                                                    className='cursor-pointer font-gotham-rounded-medium text-red-600'
                                                    onClick={() => onIgnoreRequest(loan)}
                                                  >
                                                    {t('okoa_fees.decline')}
                                                  </button>
                                                </div>
                                              </td>
                                            )}
                                          </tr>
                                        ))}
                                      </>
                                    }
                                    pagination={
                                      <div className='flex justify-between pl-2 pr-6 py-4 w-full border border-gray-200 border-t-0'>
                                        <div className='text-medium text-muted'>
                                          {loansPagination.count} {t('common.of')}{' '}
                                          {loansPagination.total}{' '}
                                          {t('okoa_fees.requests')}
                                        </div>
                                        <div className='flex justify-between gap-4'>
                                          <button
                                            className={`text-medium ${
                                              loansPagination.hasPrev
                                                ? 'text-secondary-900'
                                                : 'text-muted pointer-events-none'
                                            }`}
                                            onClick={() =>
                                              dispatch(
                                                loadPrevLoans({
                                                  ...(isSchool && {
                                                    premise_owner_id: user.id
                                                  })
                                                })
                                              )
                                            }
                                          >
                                            {t('common.prev')}
                                          </button>
                                          <button
                                            className={`text-medium ${
                                              loansPagination.hasNext
                                                ? 'text-secondary-900'
                                                : 'text-muted pointer-events-none'
                                            }`}
                                            onClick={() =>
                                              dispatch(
                                                loadNextLoans({
                                                  ...(isSchool && {
                                                    premise_owner_id: user.id
                                                  })
                                                })
                                              )
                                            }
                                          >
                                            {t('common.next')}
                                          </button>
                                        </div>
                                      </div>
                                    }
                                  />
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Tab.Panel>
                    <Tab.Panel className='focus:outline-none outline-none py-2'>
                      {isEmpty(isSchool ? transactions : repayments) ? (
                        <div className='flex flex-col'>
                          <div className='overflow-x-auto'>
                            <div className='inline-block min-w-full align-middle'>
                              <div className='p-4'>
                                <EmptyContent
                                  title={
                                    isSchool
                                      ? t('common.no_voucher_transactions')
                                      : t('common.no_repayments')
                                  }
                                  subTitle={
                                    isSchool
                                      ? t('common.no_vouchers_redeemed_school_help_text')
                                      : t('common.no_repayments_help_text')
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className='flex flex-col'>
                          <div className='overflow-x-auto w-full'>
                            <div className='inline-block min-w-full align-middle'>
                              <div className='overflow-hidden p-4'>
                                <section className='mt-2'>
                                  <Table
                                    header={
                                      <tr>
                                        <th
                                          scope='col'
                                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                        >
                                          {t('okoa_shopping.date')}
                                        </th>
                                        <th
                                          scope='col'
                                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                        >
                                          {t('okoa_shopping.transaction_number')}
                                        </th>
                                        <th
                                          scope='col'
                                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                        >
                                          {t('common.premise')}
                                        </th>
                                        <th
                                          scope='col'
                                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                        >
                                          {t('okoa_shopping.amount')}
                                        </th>
                                        <th
                                          scope='col'
                                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                        >
                                          {t('okoa_shopping.status')}
                                        </th>
                                        <th
                                          scope='col'
                                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                                        >
                                          {t('okoa_shopping.type')}
                                        </th>
                                      </tr>
                                    }
                                    body={
                                      <>
                                        {isSchool ? (
                                          <>
                                            {transactions.map((transaction) => (
                                              <tr key={transaction.id}>
                                                <td className='pl-2 py-4 whitespace-nowrap'>
                                                  <div className='text-medium font-medium text-body'>
                                                    {formatTimestampToDate(
                                                      transaction.createdAt
                                                    )}
                                                  </div>
                                                  <div className='text-xs text-gray-500'>
                                                    {formatDateToTime(
                                                      transaction.createdAt
                                                    )}
                                                  </div>
                                                </td>
                                                <td className='pl-2 py-4 w-40 text-medium uppercase text-body'>
                                                  {transaction.receiptNumber}
                                                </td>
                                                <td className='pl-2 py-4 w-40 text-medium text-body'>
                                                  {transaction.premiseName ? (
                                                    <>
                                                      <div className='text-medium font-medium text-body'>
                                                        {transaction.premiseName}
                                                      </div>
                                                      <div className='text-xs text-gray-500'>
                                                        {transaction.paylendNumber}
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>{t('common.not_applicable')}</>
                                                  )}
                                                </td>
                                                <td className='pl-2 py-4 text-medium text-body'>
                                                  <td className='pl-2 py-4 text-medium text-body'>
                                                    {transaction.actionType ===
                                                      'give_loan_to_user' ||
                                                    transaction.actionType ===
                                                      'redeem_voucher' ? (
                                                      <span className='text-green-500'>
                                                        + {currency}{' '}
                                                        {formatAmount(transaction.amount)}
                                                      </span>
                                                    ) : (
                                                      <span className='text-primary'>
                                                        - {currency}{' '}
                                                        {formatAmount(transaction.amount)}
                                                      </span>
                                                    )}
                                                  </td>
                                                </td>
                                                <td className='pl-2 py-4 text-medium capitalize text-body'>
                                                  {transaction.status}
                                                </td>
                                                <td className='pl-2 py-4 text-medium capitalize text-body'>
                                                  {transaction.actionType ===
                                                    'redeem_voucher' && (
                                                    <span>
                                                      {t('common.voucher_redeemed')}
                                                    </span>
                                                  )}
                                                </td>
                                              </tr>
                                            ))}
                                          </>
                                        ) : (
                                          <>
                                            {repayments.map((transaction) => (
                                              <tr key={transaction.id}>
                                                <td className='pl-2 py-4 whitespace-nowrap'>
                                                  <div className='text-medium font-medium text-body'>
                                                    {formatTimestampToDate(
                                                      transaction.createdAt
                                                    )}
                                                  </div>
                                                  <div className='text-xs text-gray-500'>
                                                    {formatDateToTime(
                                                      transaction.createdAt
                                                    )}
                                                  </div>
                                                </td>
                                                <td className='pl-2 py-4 w-40 text-medium uppercase text-body'>
                                                  {transaction.receiptNumber}
                                                </td>
                                                <td className='pl-2 py-4 w-40 text-medium text-body'>
                                                  {transaction.premiseName ? (
                                                    <>
                                                      <div className='text-medium font-medium text-body'>
                                                        {transaction.premiseName}
                                                      </div>
                                                      <div className='text-xs text-gray-500'>
                                                        {transaction.paylendNumber}
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>{t('common.not_applicable')}</>
                                                  )}
                                                </td>
                                                <td className='pl-2 py-4 text-medium text-body'>
                                                  {transaction.actionType ===
                                                  'give_loan_to_user' ? (
                                                    <span className='text-green-500'>
                                                      + {currency}{' '}
                                                      {formatAmount(transaction.amount)}
                                                    </span>
                                                  ) : (
                                                    <span className='text-primary'>
                                                      - {currency}{' '}
                                                      {formatAmount(transaction.amount)}
                                                    </span>
                                                  )}
                                                </td>
                                                <td className='pl-2 py-4 text-medium capitalize text-body'>
                                                  {transaction.status}
                                                </td>
                                                <td className='pl-2 py-4 text-medium capitalize text-body'>
                                                  {transaction.actionType ===
                                                    'repay_loan_by_user' && (
                                                    <span>{t('common.repayment')}</span>
                                                  )}
                                                </td>
                                              </tr>
                                            ))}
                                          </>
                                        )}
                                      </>
                                    }
                                    pagination={
                                      <div className='flex justify-between pl-2 pr-6 py-4 w-full border border-gray-200 border-t-0'>
                                        <div className='text-medium text-muted'>
                                          {isSchool
                                            ? pagination.count
                                            : repaymentsPagination.count}{' '}
                                          {t('common.of')}{' '}
                                          {isSchool
                                            ? pagination.total
                                            : repaymentsPagination.total}{' '}
                                          {t('okoa_shopping.transactions')}
                                        </div>
                                        <div className='flex justify-between gap-4'>
                                          <button
                                            className={`text-medium ${
                                              (
                                                isSchool
                                                  ? pagination.hasPrev
                                                  : repaymentsPagination.hasPrev
                                              )
                                                ? 'text-secondary-900'
                                                : 'text-muted pointer-events-none'
                                            }`}
                                            onClick={() =>
                                              isSchool
                                                ? dispatch(loadPrevTransactions({}))
                                                : dispatch(loadPrevRepayments({}))
                                            }
                                          >
                                            {t('common.prev')}
                                          </button>
                                          <button
                                            className={`text-medium ${
                                              (
                                                isSchool
                                                  ? pagination.hasNext
                                                  : repaymentsPagination.hasNext
                                              )
                                                ? 'text-secondary-900'
                                                : 'text-muted pointer-events-none'
                                            }`}
                                            onClick={() =>
                                              isSchool
                                                ? dispatch(loadNextTransactions({}))
                                                : dispatch(loadNextRepayments({}))
                                            }
                                          >
                                            {t('common.next')}
                                          </button>
                                        </div>
                                      </div>
                                    }
                                  />
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

Transactions.propTypes = {
  t: PropTypes.func,
  okoaUser: PropTypes.object,
  onDeclineRequest: PropTypes.func,
  onAcceptRequest: PropTypes.func,
  onConfirmRequest: PropTypes.func,
  onIgnoreRequest: PropTypes.func
}

export default Transactions
