import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Dropdown } from '../..'
import { icons } from '../../../assets/img'
import { SETTINGS } from '../../../containers/App/RouteConstants'
import { logoutUser, setUser } from '../../../containers/Auth/store'
import { getItemFromLocalStorage } from '../../../helpers/utils'
import { omit } from 'ramda'

function UserMenu({ translate, user }) {
  const dispatch = useDispatch()

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const canRequestLoan = getItemFromLocalStorage('crl')
  const history = useHistory()

  return (
    <Dropdown
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      width='w-48'
      dropdownTrigger={
        <>
          <img
            className='w-8 h-8 rounded-full hidden sm:inline-block'
            src={user?.avatar || icons.iconElsie}
            width='32'
            height='32'
            alt='User'
            onError={() => dispatch(setUser(omit(['avatar'], user)))}
          />
          <div className='flex items-center truncate'>
            <div>
              <span className='truncate ml-2 text-medium text-body hidden sm:block'>
                {user?.name}
              </span>
              <div className='float-right text-muted text-xs hidden sm:block'>
                {canRequestLoan
                  ? translate('home.standard_level')
                  : translate('home.basic_level')}
              </div>
            </div>
            <i className='bi bi-chevron-down text-body font-gotham-rounded-medium ml-2 hidden sm:inline-block'></i>
            <i className='bi bi-three-dots-vertical text-white font-gotham-rounded-medium ml-2 sm:hidden text-xl'></i>
          </div>
        </>
      }
      content={
        <>
          <div className='pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200'>
            <div className='font-medium text-body capitalize'>{user?.name}</div>
            <div className='text-xs text-muted italic'>{user?.email || user?.phone}</div>
          </div>
          <ul>
            <li>
              <button
                className='text-medium text-body hover:bg-gray-100 flex items-center py-1 px-3 w-full'
                onClick={() => {
                  setDropdownOpen(!dropdownOpen)
                  history.push(SETTINGS)
                }}
              >
                {translate('header.settings')}
              </button>
            </li>
            <li>
              <button
                className='text-medium w-full text-body hover:bg-gray-100 flex items-center py-1 px-3'
                onClick={() => dispatch(logoutUser())}
              >
                {translate('header.sign_out')}
              </button>
            </li>
          </ul>
        </>
      }
    />
  )
}

UserMenu.propTypes = {
  translate: PropTypes.func.isRequired,
  user: PropTypes.object
}

export default UserMenu
