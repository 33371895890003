const countries = [
  {
    name: 'Kenya',
    phone_code: '254',
    language: 'en',
    code: 'ke',
    currency: 'Ksh'
  },
  {
    name: 'Zambia',
    phone_code: '260',
    language: 'en',
    code: 'zm',
    currency: 'Zmk'
  }
]

export default countries
