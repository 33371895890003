import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { useSelector } from 'react-redux'
import { Metric, EmptyContent, Table } from '..'
import { icons } from '../../assets/img'
import {
  formatAmount,
  formatDateToTime,
  formatTimestampToDate
} from '../../helpers/utils'

function ChangishaOverview({ t }) {
  const { metrics, currency } = useSelector((state) => state.auth)
  const { recentTransactions: transactions } = useSelector((state) => state.changisha)

  return (
    <>
      <section className='mb-6'>
        <div className='w-full'>
          <div className='sm:grid sm:grid-cols-2 gap-2 last:border-0'>
            <Metric
              label={t('changisha.total_raised')}
              icon={icons.iconNotes}
              showPercentage={false}
              count={`${currency} ${formatAmount(metrics.changisha.totalRaised)}`}
            />
            <Metric
              label={t('changisha.initiatives')}
              icon={icons.iconInitiative}
              showPercentage={false}
              count={metrics.changisha.totalInitiatives}
            />
          </div>
        </div>
      </section>

      <div className='flex flex-col'>
        <div className='overflow-x-auto shadow-md sm:rounded-lg w-full'>
          <div className='inline-block min-w-full align-middle'>
            <div className='p-4 flex justify between'>
              <h2 className='font-gotham-rounded-medium text-body text-base'>
                {t('okoa_shopping.latest_transactions')}
              </h2>
            </div>
            <div className='overflow-hidden p-4'>
              {isEmpty(transactions) ? (
                <EmptyContent
                  title={t('common.no_recent_transactions')}
                  subTitle={t('common.no_recent_transactions_help_text')}
                />
              ) : (
                <>
                  <Table
                    header={
                      <tr>
                        <th
                          scope='col'
                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                        >
                          {t('okoa_shopping.date')}
                        </th>
                        <th
                          scope='col'
                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                        >
                          {t('okoa_shopping.transaction_number')}
                        </th>
                        <th
                          scope='col'
                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                        >
                          {t('changisha.phone')}
                        </th>
                        <th
                          scope='col'
                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                        >
                          {t('okoa_shopping.amount')}
                        </th>
                        <th
                          scope='col'
                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                        >
                          {t('okoa_shopping.status')}
                        </th>
                        <th
                          scope='col'
                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                        >
                          {t('okoa_shopping.type')}
                        </th>
                      </tr>
                    }
                    body={
                      <>
                        {transactions.map((transaction) => (
                          <tr key={transaction.id}>
                            <td className='pl-2 py-4 whitespace-nowrap'>
                              <div className='text-medium font-medium text-body'>
                                {formatTimestampToDate(transaction.createdAt)}
                              </div>
                              <div className='text-xs text-gray-500'>
                                {formatDateToTime(transaction.createdAt)}
                              </div>
                            </td>
                            <td className='pl-2 py-4 w-40 text-medium uppercase text-body'>
                              {transaction.receiptNumber}
                            </td>
                            <td className='pl-2 py-4 w-40 text-medium uppercase text-body'>
                              {transaction.userPhone}
                            </td>
                            <td className='pl-2 py-4 text-medium text-body'>
                              {transaction.actionType === 'contribute_intitiative'
                                ? '+'
                                : '-'}{' '}
                              {currency} {formatAmount(transaction.amount)}
                            </td>
                            <td className='pl-2 py-4 text-medium capitalize text-body'>
                              {transaction.status}
                            </td>
                            <td className='pl-2 py-4 text-medium capitalize text-body'>
                              {transaction.resultDesc.replace('an initiative', '')}
                            </td>
                          </tr>
                        ))}
                      </>
                    }
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

ChangishaOverview.propTypes = {
  t: PropTypes.func
}

export default ChangishaOverview
