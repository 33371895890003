import PropTypes from 'prop-types'

function LeftPageColumn({ content }) {
  return <div className='w-full block col-span-1'>{content}</div>
}

LeftPageColumn.propTypes = {
  content: PropTypes.element
}

export default LeftPageColumn
