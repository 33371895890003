import * as yup from 'yup'
import i18n from '../../../i18n'

export const validateInitiative = (data) => {
  const schema = yup.object().shape({
    name: yup.string().required(i18n.t('errors.initiative_name_required')),
    target_amount: yup
      .number()
      .typeError(i18n.t('errors.amount_must_be_a_number'))
      .min(1, i18n.t('errors.amount_must_be_greater_than_zero'))
      .required(i18n.t('errors.target_amount_required')),
    initiative_type: yup.string().required(i18n.t('errors.initiative_type_required')),
    end_date: yup.string().required(i18n.t('errors.end_date_required')),
    visibility: yup.string().required(i18n.t('errors.visibility_required')),
    category: yup.string().required(i18n.t('errors.category_required')),
    support_document: yup.string().required(i18n.t('errors.support_document_required')),
    contact: yup
      .string()
      .min(12, i18n.t('errors.phone_must_be_valid'))
      .max(12, i18n.t('errors.phone_must_be_valid'))
      .required(i18n.t('errors.contact_required')),
    short_summary: yup.string().required(i18n.t('errors.summary_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateAmount = (data) => {
  const schema = yup.object().shape({
    amount: yup
      .number()
      .typeError(i18n.t('errors.amount_must_be_a_number'))
      .min(1, i18n.t('errors.amount_must_be_greater_than_zero'))
      .required(i18n.t('errors.amount_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateSearchInitiative = (data) => {
  const schema = yup.object().shape({
    initiative_number: yup
      .number()
      .typeError(i18n.t('errors.initiative_number_must_be_a_number'))
      .required(i18n.t('errors.initiative_number_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validatePayment = (data) => {
  const schema = yup.object().shape({
    transaction_mode: yup.string().required(i18n.t('errors.transaction_mode_required')),
    phone: yup.string().when('transaction_mode', {
      is: 'stk_push',
      then: yup
        .string()
        .min(12, i18n.t('errors.phone_must_be_valid'))
        .max(12, i18n.t('errors.phone_must_be_valid'))
        .required(i18n.t('errors.phone_required'))
    }),
    amount: yup
      .number(i18n.t('errors.amount_must_be_a_number'))
      .min(1, i18n.t('errors.amount_must_be_greater_than_zero'))
      .required(i18n.t('errors.amount_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateWithdrawal = (data, max) => {
  const schema = yup.object().shape({
    premise: yup.string().required(i18n.t('errors.you_must_select_initiative')),
    amount: yup
      .number(i18n.t('errors.amount_must_be_a_number'))
      .typeError(i18n.t('errors.amount_must_be_a_number'))
      .min(50, i18n.t('errors.minimum_withdraw_amount'))
      .max(max, `${i18n.t('errors.max_withdraw_amount')} ${max}`)
      .required(i18n.t('errors.amount_required'))
  })

  return schema.validate(data, { abortEarly: true })
}
