import PropTypes from 'prop-types'

function PageLayoutSideMenu({ menus, activeMenu, onSideMenuClicked }) {
  return (
    <div className='sticky block transition duration-150' style={{ top: '106px' }}>
      <div className='mb-8'>
        <div>
          <ul className=''>
            {menus.map((menu) => (
              <li
                className={`${
                  activeMenu === menu.id ? 'text-primary' : 'text-body'
                } block mb-4 hover:text-primary transition duration-150`}
                key={menu.id}
              >
                <button
                  className={`text-sm font-gotham-rounded-medium capitalize flex justify-between items-center`}
                  onClick={() => onSideMenuClicked(menu)}
                >
                  <span className=''>{menu.name}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

PageLayoutSideMenu.propTypes = {
  menus: PropTypes.array,
  activeMenu: PropTypes.string,
  onSideMenuClicked: PropTypes.func,
  menuHeaderTitle: PropTypes.string
}

export default PageLayoutSideMenu
