import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Button, FormGroup, Input, PaymentMethodSelector, PhoneInput } from '../..'
import { formatAmount, getUserDetails } from '../../../helpers/utils'

function RepaymentModal({
  showModal,
  onSubmit,
  translate,
  onCancel,
  modalTitle,
  amount,
  modalSubTitle,
  paymentType,
  onSelectPaymentMethod,
  loading,
  errors,
  showAmount,
  submitText
}) {
  const user = getUserDetails()

  const [state, setState] = useState({
    phone: user.phone,
    amount: formatAmount(amount),
    errors: {}
  })
  const { currency } = useSelector((state) => state.auth)

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const onClickTags = (percentage) => {
    setState({
      ...state,
      amount: formatAmount(amount * percentage)
    })
  }

  useEffect(() => {
    setState({
      phone: user.phone,
      amount: formatAmount(amount)
    })
  }, [user.phone, amount])

  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={onCancel}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button onClick={onCancel} className='focus:outline-none outline-none'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>
                <div className=''>
                  {showAmount ? (
                    <>
                      <header className='uppercase text-medium text-muted font-gotham-rounded-medium block text-center'>
                        {modalSubTitle}
                      </header>
                      <div className='text-secondary-900 mt-1 text-2xl font-gotham-rounded-medium text-center'>
                        {currency} {formatAmount(amount)}
                      </div>
                      <div className='mt-4 font-gotham-rounded-bold text-primary text-3xl text-center'>
                        {modalTitle}
                      </div>
                      <div className='mt-1 text-body text-sm text-center'>
                        {translate('okoa_shopping.repayment')}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='mt-4 font-gotham-rounded-bold text-primary text-3xl text-center'>
                        {modalTitle}
                      </div>
                      <div className='text-secondary-900 mt-1 text-2xl font-gotham-rounded-medium text-center'>
                        {currency} {formatAmount(amount)}
                      </div>
                    </>
                  )}

                  <div className='sm:px-6 my-4'>
                    <PaymentMethodSelector
                      t={translate}
                      type={paymentType}
                      handleChange={(value) => onSelectPaymentMethod(value)}
                    />
                  </div>

                  <section className='mt-3'>
                    {paymentType === 'mpesa' && (
                      <>
                        <div className='text-body text-sm sm:px-6 text-center'>
                          {showAmount
                            ? translate('okoa_shopping.repayment_help_text')
                            : translate('okoa_shopping.payment_help_text')}
                        </div>

                        <div className='mt-3 sm:px-5'>
                          {showAmount && (
                            <FormGroup>
                              <Input
                                type='number'
                                placeholder={translate(
                                  'okoa_shopping.enter_amount_to_repay'
                                )}
                                name={'amount'}
                                value={state.amount}
                                onChange={handleChange}
                                hasError={Object.keys(errors).includes('amount')}
                                errorMessage={errors.amount}
                              />
                            </FormGroup>
                          )}
                          <FormGroup>
                            <PhoneInput
                              value={state.phone}
                              onChange={(phone) =>
                                setState((state) => ({
                                  ...state,
                                  phone
                                }))
                              }
                              placeholder={translate('okoa_shopping.phone_number')}
                              hasError={Object.keys(errors).includes('phone')}
                              errorMessage={errors.phone}
                              name='phone'
                            />
                          </FormGroup>
                        </div>

                        {showAmount && (
                          <div className='mt-3 sm:px-5 flex justify-center items-center gap-2'>
                            <button
                              className='rounded px-4 py-2 font-gotham-rounded-bold text-sm border border-secondary-300 w-full'
                              onClick={() => onClickTags(0.5)}
                            >
                              50%
                            </button>
                            <button
                              className='rounded px-4 py-2 font-gotham-rounded-bold text-sm border border-secondary-300 w-full'
                              onClick={() => onClickTags(0.75)}
                            >
                              75%
                            </button>
                            <button
                              className='rounded px-4 py-2 font-gotham-rounded-bold text-sm border border-secondary-300 w-full'
                              onClick={() => onClickTags(1)}
                            >
                              100%
                            </button>
                          </div>
                        )}

                        <div className='mt-4 sm:px-5'>
                          <Button
                            label={
                              showAmount ? translate('okoa_shopping.repay') : submitText
                            }
                            onClick={() =>
                              onSubmit({
                                payment_type:
                                  paymentType === 'mpesa' ? 'mobile_money' : 'card',
                                phone: state.phone,
                                amount: state.amount
                              })
                            }
                            classes='uppercase'
                            disabled={loading}
                          />
                        </div>
                      </>
                    )}

                    {paymentType === 'other' && (
                      <div className='text-center'>
                        {translate('common.card_payment_help_text')}{' '}
                      </div>
                    )}
                  </section>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

RepaymentModal.defaultProps = {
  showAmount: true
}

RepaymentModal.propTypes = {
  showModal: PropTypes.bool,
  onSubmit: PropTypes.func,
  translate: PropTypes.func,
  onCancel: PropTypes.func,
  modalTitle: PropTypes.string,
  modalSubTitle: PropTypes.string,
  amount: PropTypes.number,
  paymentType: PropTypes.string,
  onSelectPaymentMethod: PropTypes.func,
  loading: PropTypes.bool,
  errors: PropTypes.object,
  showAmount: PropTypes.bool,
  submitText: PropTypes.string
}

export default RepaymentModal
