import PropTypes from 'prop-types'

function Select({
  options,
  hasPlaceholder,
  placeholderText,
  name,
  placeholderClasses,
  classes,
  onChange,
  hasError,
  errorMessage,
  disabled,
  value
}) {
  return (
    <>
      <select
        name={name}
        className={`form-select appearance-none w-full bg-white border ${
          hasError ? 'border-red-300' : 'border-secondary-300'
        } rounded focus:ring-0 outline-none focus:outline-none ${
          hasError ? 'focus:border-red-500' : 'focus:border-secondary-500'
        } block px-4 py-2 text-body text-medium font-gotham-rounded-book ${classes}`}
        onChange={onChange}
        value={value}
        disabled={disabled}
      >
        {hasPlaceholder && (
          <option className={placeholderClasses} disabled selected hidden>
            {placeholderText}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className='text-xs font-gotham-rounded-book block mt-1 text-red-600'>
        {errorMessage}
      </div>
    </>
  )
}

Select.propTypes = {
  options: PropTypes.array.isRequired,
  hasPlaceholder: PropTypes.bool,
  placeholderText: PropTypes.string,
  name: PropTypes.string,
  placeholderClasses: PropTypes.string,
  classes: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  hasError: PropTypes.any,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool
}

export default Select
