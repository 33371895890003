import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import AutoComplete from 'react-google-autocomplete'
import { Button, FormGroup, Input, PhoneInput } from '../../'
import config from '../../../helpers/config'

function AddSchoolInfoModal({
  t,
  showModal,
  onCancel,
  onSubmit,
  errors,
  handleChange,
  handleFileChange,
  handleLocationChange,
  state,
  handlePhoneChange,
  location
}) {
  return (
    <Transition appear show={showModal} as={Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 z-50 overflow-y-auto'
        onClose={() => null}
      >
        <div className='min-h-screen px-4 text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
          </Transition.Child>

          <span className='inline-block h-screen align-middle' aria-hidden='true'>
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div className='inline-block p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl w-full max-w-2xl'>
              <div className='flex justify-end focus:outline-none outline-none'>
                <button onClick={onCancel}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6 hover:text-secondary-900'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </button>
              </div>

              <div className=''>
                <header className='uppercase text-medium text-muted font-gotham-rounded-medium mx-auto flex justify-center'>
                  <i className='bi bi-building text-7xl text-primary'></i>
                </header>
                <div className='mt-4 font-gotham-rounded-bold text-primary text-3xl text-center'>
                  {t('sidebar.okoa_fees')}
                </div>
                <div className='text-body text-base sm:px-6 text-center'>
                  {t('okoa_fees.school_registration')}
                </div>

                <section className='mt-3'>
                  <div className='mt-1 sm:grid sm:grid-cols-2 gap-3'>
                    <FormGroup>
                      <Input
                        type='text'
                        name='premise_name'
                        placeholder={t('okoa_fees.school_name')}
                        onChange={handleChange}
                        value={state.premise_name}
                        hasError={Object.keys(errors).includes('premise_name')}
                        errorMessage={errors.premise_name}
                      />
                    </FormGroup>
                    <FormGroup>
                      <div className='relative'>
                        <label
                          htmlFor='business_permit'
                          className={`bg-white border ${
                            Object.keys(errors).includes('business_permit')
                              ? 'border-red-300'
                              : 'border-secondary-300'
                          } text-body rounded focus:ring-0 outline-none focus:outline-none focus:border-secondary-500 block w-full pr-4 py-2.5 font-gotham-rounded-book text-medium pl-4`}
                        >
                          {state.business_permit !== '' ? (
                            <>{`${
                              state.business_permit.name.length > 30
                                ? `${state.business_permit.name.substring(0, 30)}...`
                                : state.business_permit.name
                            }`}</>
                          ) : (
                            <span className='opacity-50'>
                              {t('okoa_fees.registration_certificate')}
                            </span>
                          )}
                        </label>
                        <div className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 justify-between'>
                          <i className='bi bi-camera-fill text-secondary-900 text-base'></i>
                        </div>
                      </div>
                      <Input
                        type='file'
                        placeholder={t('okoa_fees.registration_certificate')}
                        id='business_permit'
                        classes='hidden'
                        name='business_permit'
                        onChange={handleFileChange}
                        hasError={Object.keys(errors).includes('business_permit')}
                        errorMessage={errors.business_permit}
                      />
                    </FormGroup>
                  </div>
                  <div className='mt-1 sm:grid sm:grid-cols-2 gap-3'>
                    <FormGroup>
                      <Input
                        type='text'
                        name='postal_address'
                        placeholder={t('okoa_fees.postal_address')}
                        onChange={handleChange}
                        value={state.postal_address}
                        hasError={Object.keys(errors).includes('postal_address')}
                        errorMessage={errors.postal_address}
                      />
                    </FormGroup>
                    <FormGroup>
                      <div className='relative'>
                        <>
                          <AutoComplete
                            apiKey={config('GOOGLE_API_KEY')}
                            onPlaceSelected={(place) => handleLocationChange(place)}
                            placeholder={t('okoa_shopping.location')}
                            className={`${
                              Object.keys(errors).includes('premise_address')
                                ? 'border-red-300'
                                : 'border-secondary-300'
                            } text-body rounded focus:ring-0 outline-none focus:outline-none ${
                              Object.keys(errors).includes('premise_address')
                                ? 'focus:border-red-500'
                                : 'focus:border-secondary-500'
                            } block w-full pr-4 py-2.5 font-gotham-rounded-book text-medium pl-4 bg-white border`}
                            options={{
                              componentRestrictions: { country: location }
                            }}
                          />
                          <div className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 justify-between'>
                            <i className='bi bi-geo-alt-fill text-secondary-900 text-base'></i>
                          </div>
                        </>
                      </div>
                      {Object.keys(errors).includes('premise_address') && (
                        <span className='text-xs font-gotham-rounded-book block mt-1 text-red-600'>
                          {errors.premise_address}
                        </span>
                      )}
                    </FormGroup>
                  </div>
                  <div className='mt-1 sm:grid sm:grid-cols-2 gap-3'>
                    <FormGroup>
                      <PhoneInput
                        value={state.premise_phone}
                        onChange={(phone, country, e) =>
                          handlePhoneChange(phone, country, e)
                        }
                        placeholder={t('okoa_fees.phone_number')}
                        hasError={Object.keys(errors).includes('premise_phone')}
                        errorMessage={errors.premise_phone}
                        name='premise_phone'
                      />
                    </FormGroup>
                    <FormGroup>
                      <div className='relative'>
                        <label
                          htmlFor='premise_front_image'
                          className={`bg-white border ${
                            Object.keys(errors).includes('premise_front_image')
                              ? 'border-red-300'
                              : 'border-secondary-300'
                          } text-body rounded focus:ring-0 outline-none focus:outline-none focus:border-secondary-500 block w-full pr-4 py-2.5 font-gotham-rounded-book text-medium pl-4`}
                        >
                          {state.premise_front_image !== '' ? (
                            <>{`${
                              state.premise_front_image.name.length > 30
                                ? `${state.premise_front_image.name.substring(0, 30)}...`
                                : state.premise_front_image.name
                            }`}</>
                          ) : (
                            <span className='opacity-50'>
                              {t('okoa_fees.school_photo_placeholder')}
                            </span>
                          )}
                        </label>
                        <div className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 justify-between'>
                          <i className='bi bi-camera-fill text-secondary-900 text-base'></i>
                        </div>
                      </div>
                      <Input
                        type='file'
                        placeholder={t('okoa_fees.school_photo_placeholder')}
                        id='premise_front_image'
                        classes='hidden'
                        name='premise_front_image'
                        onChange={handleFileChange}
                        hasError={Object.keys(errors).includes('premise_front_image')}
                        errorMessage={errors.premise_front_image}
                      />
                    </FormGroup>
                  </div>
                </section>

                <div className='mt-4 sm:px-20'>
                  <Button
                    label={t('okoa_fees.next')}
                    classes='uppercase'
                    onClick={onSubmit}
                  />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

AddSchoolInfoModal.propTypes = {
  t: PropTypes.func,
  showModal: PropTypes.bool,
  onCancel: PropTypes.func,
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  state: PropTypes.object,
  handleChange: PropTypes.func,
  handleFileChange: PropTypes.func,
  handleLocationChange: PropTypes.func,
  handlePhoneChange: PropTypes.func,
  location: PropTypes.string
}

export default AddSchoolInfoModal
