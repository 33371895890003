import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, FormGroup, Input } from '../..'
import { formatAmount } from '../../../helpers/utils'

function TopUpModal({
  translate,
  showModal,
  onCancel,
  onSubmit,
  amount,
  initiative,
  errors
}) {
  const [state, setState] = useState({
    amount
  })
  const { currency } = useSelector((state) => state.auth)

  const handleChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const onClickTags = (percentage) => {
    setState({
      ...state,
      amount: amount * percentage
    })
  }

  useEffect(() => {
    setState({ amount })
  }, [amount])

  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={onCancel}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button onClick={onCancel} className='focus:outline-none outline-none'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>
                <div className=''>
                  <header className='uppercase text-medium text-muted font-gotham-rounded-medium block text-center'>
                    {translate('changisha.total_deficit')}
                  </header>
                  <div className='text-secondary-900 mt-1 mb-2 text-xl sm:text-2xl font-gotham-rounded-medium text-center'>
                    {currency} {formatAmount(amount)}
                  </div>
                  <div className='font-gotham-rounded-bold text-primary sm:text-3xl text-2xl text-center'>
                    {initiative.name}
                  </div>
                  <div className='mt-1 uppercase sm:text-xl text-base font-gotham-rounded-medium opacity-70 text-center'>
                    {translate('common.contribute')}
                  </div>

                  <section className='mt-3'>
                    <div className='text-body text-sm sm:px-6 text-center'>
                      {translate('changisha.topup_help_text')}
                    </div>

                    <div className='mt-3 sm:px-5'>
                      <FormGroup>
                        <Input
                          type='number'
                          placeholder={translate('changisha.enter_amount_to_contribute')}
                          classes='placeholder:text-body text-body'
                          hasError={Object.keys(errors).includes('amount')}
                          errorMessage={errors.amount}
                          value={state.amount}
                          onChange={handleChange}
                          name='amount'
                        />
                      </FormGroup>
                    </div>

                    <div className='mt-3 sm:px-5 flex justify-center items-center gap-2'>
                      <button
                        className='rounded px-4 py-2 font-gotham-rounded-bold text-sm border border-secondary-300 w-full'
                        onClick={() => onClickTags(0.5)}
                      >
                        50%
                      </button>
                      <button
                        className='rounded px-4 py-2 font-gotham-rounded-bold text-sm border border-secondary-300 w-full'
                        onClick={() => onClickTags(0.75)}
                      >
                        75%
                      </button>
                      <button
                        className='rounded px-4 py-2 font-gotham-rounded-bold text-sm border border-secondary-300 w-full'
                        onClick={() => onClickTags(1)}
                      >
                        100%
                      </button>
                    </div>

                    <div className='mt-4 sm:px-5'>
                      <Button
                        label={translate('common.next')}
                        onClick={() => onSubmit(state)}
                        classes='uppercase'
                      />
                    </div>
                  </section>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

TopUpModal.propTypes = {
  translate: PropTypes.func,
  showModal: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  amount: PropTypes.string,
  initiative: PropTypes.object,
  errors: PropTypes.object
}

export default TopUpModal
