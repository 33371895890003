import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { Input } from '..'

function FileInput({
  name,
  placeholder,
  onChange,
  accept,
  hasError,
  errorMessage,
  id,
  file,
  icon,
  classes
}) {
  return (
    <>
      <div className='relative'>
        <label
          htmlFor={id}
          className={`bg-white border ${
            hasError ? 'border-red-300' : 'border-secondary-300'
          } text-body rounded focus:ring-0 outline-none focus:outline-none focus:border-secondary-500 block w-full pr-4 py-2.5 font-gotham-rounded-book text-medium pl-4 ${classes}`}
        >
          {!isEmpty(file) ? (
            <>{`${
              file.name.length > 30 ? `${file.name.substring(0, 30)}...` : file.name
            }`}</>
          ) : (
            <span className='opacity-50'>{placeholder}</span>
          )}
        </label>
        {icon && (
          <div className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 justify-between'>
            {icon}
          </div>
        )}
      </div>
      <Input
        type='file'
        placeholder={placeholder}
        id={id}
        classes='hidden'
        name={name}
        onChange={onChange}
        hasError={hasError}
        errorMessage={errorMessage}
        accept={accept}
      />
    </>
  )
}

FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  accept: PropTypes.string,
  file: PropTypes.any,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.node,
  classes: PropTypes.string
}

export default FileInput
