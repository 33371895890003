import { useState } from 'react'
import PropTypes from 'prop-types'

function SidebarLinkGroup({ children, activecondition }) {
  const [open, setOpen] = useState(activecondition)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <li
      className={`px-3 py-1 rounded-sm mb-0.5 last:mb-0 font-gotham-medium hidden sm:inline-block`}
    >
      {children(handleClick, open)}
    </li>
  )
}

SidebarLinkGroup.propTypes = {
  children: PropTypes.any,
  activecondition: PropTypes.bool
}

export default SidebarLinkGroup
