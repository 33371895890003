import config from '../helpers/config'
import api from '.'

const CLIENT_SECRET = config('CLIENT_SECRET')

export const createPremise = (payload) =>
  api.post(`/premises?client_secret=${CLIENT_SECRET}`, payload)

export const getPremises = (payload) =>
  api.get(`/premises?client_secret=${CLIENT_SECRET}`, payload)

export const getPremiseByPaylendNumber = (paylendNumber) =>
  api.get(`/premises?client_secret=${CLIENT_SECRET}&paylend_number=${paylendNumber}`)

export const updatePremise = ({ userId, payload }) =>
  api.put(`/premises/${userId}?client_secret=${CLIENT_SECRET}`, payload)

export const getNearbyPremises = (payload) =>
  api.get(`/premises/nearby-premises?client_secret=${CLIENT_SECRET}`, payload)

export const getPremiseWallets = (payload) =>
  api.get(`/premise-wallets?client_secret=${CLIENT_SECRET}`, payload)

export const premiseVerification = ({ premiseId, payload }) =>
  api.put(
    `/premises/premises-verification/${premiseId}?client_secret=${CLIENT_SECRET}`,
    payload
  )

export const checkWithdrawLimit = ({ premiseId, payload }) =>
  api.post(
    `/premises/withdraw_limit/${premiseId}?client_secret=${CLIENT_SECRET}`,
    payload
  )
