import PropTypes from 'prop-types'
import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button, FormGroup, Input, PhoneInput, Alert } from '../../'

function SchoolManagerModal({
  t,
  showModal,
  onCancel,
  onSubmit,
  errors,
  handleChange,
  handleFileChange,
  savingSchool,
  state,
  handlePhoneChange
}) {
  const [error, setError] = useState('')
  const [hasAgreedTerms, setHasAgreedTerms] = useState(false)
  useEffect(() => {
    setError(errors.apiError)
  }, [errors.apiError])

  return (
    <Transition appear show={showModal} as={Fragment}>
      <Dialog as='div' className='fixed inset-0 z-50 overflow-y-auto' onClose={onCancel}>
        <div className='min-h-screen px-4 text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
          </Transition.Child>

          <span className='inline-block h-screen align-middle' aria-hidden='true'>
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div className='inline-block p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl w-full max-w-2xl'>
              <div className='flex justify-end focus:outline-none outline-none'>
                <button onClick={onCancel}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6 hover:text-secondary-900'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </button>
              </div>

              <div className=''>
                <header className='uppercase text-medium text-muted font-gotham-rounded-medium mx-auto flex justify-center'>
                  <i className='bi bi-building text-7xl text-primary'></i>
                </header>
                <div className='mt-4 font-gotham-rounded-bold text-primary text-3xl text-center'>
                  {t('sidebar.okoa_fees')}
                </div>
                <div className='text-body text-base sm:px-6 text-center'>
                  {t('okoa_fees.manager_registration')}
                </div>

                {error && (
                  <Alert type='error' message={error} onCancel={() => setError('')} />
                )}

                <section className='mt-3'>
                  <div className='mt-1 sm:grid sm:grid-cols-2 gap-3'>
                    <FormGroup>
                      <Input
                        type='text'
                        name='manager_firstname'
                        placeholder={t('okoa_fees.first_name')}
                        onChange={handleChange}
                        value={state.manager_firstname}
                        hasError={Object.keys(errors).includes('manager_firstname')}
                        errorMessage={errors.manager_firstname}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type='text'
                        name='manager_lastname'
                        placeholder={t('okoa_fees.last_name')}
                        onChange={handleChange}
                        value={state.manager_lastname}
                        hasError={Object.keys(errors).includes('manager_lastname')}
                        errorMessage={errors.manager_lastname}
                      />
                    </FormGroup>
                  </div>
                  <div className='mt-1 sm:grid sm:grid-cols-2 gap-3 ring-'>
                    <FormGroup>
                      <Input
                        type='text'
                        name='manager_email'
                        placeholder={t('okoa_fees.email_address')}
                        onChange={handleChange}
                        value={state.manager_email}
                        hasError={Object.keys(errors).includes('manager_email')}
                        errorMessage={errors.manager_email}
                      />
                    </FormGroup>
                    <FormGroup>
                      <PhoneInput
                        value={state.manager_phone_number}
                        onChange={(phone, country, e) =>
                          handlePhoneChange(phone, country, e)
                        }
                        placeholder={t('okoa_fees.phone_number')}
                        hasError={Object.keys(errors).includes('manager_phone_number')}
                        errorMessage={errors.manager_phone_number}
                        name='manager_phone_number'
                      />
                    </FormGroup>
                  </div>
                  <div className='mt-1'>
                    <FormGroup>
                      <div className='relative'>
                        <label
                          htmlFor='manager_identification'
                          className={`bg-white border ${
                            Object.keys(errors).includes('manager_identification')
                              ? 'border-red-300'
                              : 'border-secondary-300'
                          } text-body rounded focus:ring-0 outline-none focus:outline-none focus:border-secondary-500 block w-full pr-4 py-2.5 font-gotham-rounded-book text-medium pl-4`}
                        >
                          {state.manager_identification !== '' ? (
                            <>{state.manager_identification.name}</>
                          ) : (
                            <span className='opacity-50'>
                              {t('okoa_fees.id_photo_placeholder')}
                            </span>
                          )}
                        </label>
                        <div className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 justify-between'>
                          <i className='bi bi-camera-fill text-secondary-900 text-base'></i>
                        </div>
                      </div>
                      <Input
                        type='file'
                        placeholder={t('okoa_fees.id_photo_placeholder')}
                        id='manager_identification'
                        classes='hidden'
                        name='manager_identification'
                        onChange={handleFileChange}
                        hasError={Object.keys(errors).includes('manager_identification')}
                        errorMessage={errors.manager_identification}
                      />
                    </FormGroup>
                  </div>
                </section>

                <div className='mt-4 sm:px-6 text-xs'>
                  <input
                    type='checkbox'
                    className='border-secondary-300 text-secondary-600 focus:ring-0 rounded-sm cursor-pointer'
                    checked={hasAgreedTerms}
                    onChange={() => setHasAgreedTerms(!hasAgreedTerms)}
                  />
                  <span className='pl-1'>{t('common.terms_help_text')}</span>{' '}
                  <a
                    href='https://paylend.africa/terms'
                    target='_blank'
                    rel='noreferrer'
                    className='underline'
                  >
                    {t('okoa_fees.terms')}
                  </a>
                </div>

                <div className='mt-4 sm:px-20'>
                  <Button
                    label={t('okoa_fees.submit')}
                    classes='uppercase'
                    onClick={onSubmit}
                    disabled={savingSchool || !hasAgreedTerms}
                  />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

SchoolManagerModal.propTypes = {
  t: PropTypes.func,
  showModal: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  savingSchool: PropTypes.bool,
  state: PropTypes.object,
  handleChange: PropTypes.func,
  handleFileChange: PropTypes.func,
  errors: PropTypes.object,
  handlePhoneChange: PropTypes.func
}

export default SchoolManagerModal
