import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'ramda'
import { useState, useEffect } from 'react'
import {
  loadNextPremises,
  loadPrevPremises,
  setCurrentState,
  setHasVerifiedPremise,
  setPremiseAlreadyVerified,
  verifyPremise
} from '../../containers/AgentDashboard/store'
import { TableSkeleton, EmptyContent, Table, SuccessModal, VerifyPremiseModal } from '../'
import { formatTimestampToDate } from '../../helpers/utils'

function UnverifiedPremises({ t }) {
  const dispatch = useDispatch()
  const {
    premises,
    isLoading,
    premisesPagination: pagination,
    currentState,
    verifyingPremise,
    searchPremiseByNumber,
    searchingPremise,
    errors,
    hasVerifiedPremise,
    premiseAlreadyVerified
  } = useSelector((state) => state.agent)
  const [verificationState, setVerificationState] = useState({
    business_permit: false,
    manager_id: false,
    mpesa_statement: false
  })
  const [showVerifyPremiseModal, setShowVerifyPremiseModal] = useState(false)
  const [thankYouMessage, setThankYouMessage] = useState('')
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const handleChange = (e) =>
    setVerificationState((prevState) => ({
      ...prevState,
      [e.target.name]: !prevState[e.target.name]
    }))

  useEffect(() => {
    if (hasVerifiedPremise) {
      dispatch(setCurrentState({}))
      setShowVerifyPremiseModal(false)
      setVerificationState({
        business_permit: false,
        manager_id: false,
        mpesa_statement: false
      })
      setShowSuccessModal(true)
      setThankYouMessage(t('agent_dashboard.premise_verified_successfully'))
    }
  }, [hasVerifiedPremise, dispatch, t])

  useEffect(() => {
    if (premiseAlreadyVerified) {
      dispatch(setCurrentState({}))
      setShowVerifyPremiseModal(false)
      setVerificationState({
        business_permit: false,
        manager_id: false,
        mpesa_statement: false
      })
      setShowSuccessModal(true)
      setThankYouMessage(t('agent_dashboard.premise_already_verified'))
    }
  }, [premiseAlreadyVerified, dispatch, t])

  const handleVerifyPremise = (premise) => {
    dispatch(
      setCurrentState({
        ...currentState,
        paylend_number: premise.paylendNumber,
        premise: {
          premiseName: premise.premiseName,
          premiseAddress: premise.premiseAddress,
          premiseId: premise.id
        }
      })
    )
    setShowVerifyPremiseModal(true)
  }

  return (
    <>
      {isLoading ? (
        <>
          <TableSkeleton showSearch={false} showPagination={true} />
        </>
      ) : (
        <>
          {isEmpty(premises) ? (
            <div className='flex flex-col'>
              <div className='overflow-x-auto shadow-md sm:rounded-lg'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='p-4'>
                    <h2 className='font-gotham-rounded-medium text-body text-base'>
                      {t('agent_dashboard.unverified_premises')}
                    </h2>
                    <EmptyContent
                      title={t('common.no_unverified_premises')}
                      subTitle={t('common.no_unverified_premises_help_text')}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='flex flex-col'>
              <div className='overflow-x-auto shadow-md sm:rounded-lg w-full'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='p-4 flex items-center'>
                    <h2 className='font-gotham-rounded-medium text-body text-base mb-2'>
                      {t('agent_dashboard.unverified_premises')}
                    </h2>
                  </div>
                  <div className='overflow-hidden p-4'>
                    <Table
                      header={
                        <tr>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('common.premise_name')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_shopping.location')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('common.premise_type')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('common.premise_number')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('common.created_on')}
                          </th>
                          <th scope='col' className='p-4'>
                            <span className='sr-only'>{t('okoa_shopping.action')}</span>
                          </th>
                        </tr>
                      }
                      body={
                        <>
                          {premises.map((shop) => (
                            <tr key={shop.id}>
                              <td className='pl-2 py-4 whitespace-nowrap'>
                                <div className='text-medium font-medium text-body'>
                                  {shop.premiseName}
                                </div>
                              </td>
                              <td className='pl-2 py-4 w-40 text-medium text-body'>
                                {shop.premiseAddress}
                              </td>
                              <td className='pl-2 py-4 w-40 capitalize text-medium text-body'>
                                {shop.premiseType}
                              </td>
                              <td className='pl-2 py-4 text-medium text-body'>
                                {shop.paylendNumber}
                              </td>
                              <td className='pl-2 py-4 text-medium text-body'>
                                {formatTimestampToDate(shop.createdAt)}
                              </td>
                              <td className='px-2 py-4 text-medium text-body flex gap-2 justify-end'>
                                <button
                                  className={`text-center primary-button text-xs border border-transparent text-white rounded py-1 px-2 w-full transition ease-in-out duration-200 focus:outline-none font-gotham-rounded-medium`}
                                  onClick={() => handleVerifyPremise(shop)}
                                >
                                  {t('agent_dashboard.verify')}
                                </button>
                              </td>
                            </tr>
                          ))}
                        </>
                      }
                      pagination={
                        <div className='flex justify-between pl-2 pr-6 py-4 w-full border border-gray-200 border-t-0'>
                          <div className='text-medium text-muted'>
                            {pagination.count} {t('common.of')} {pagination.total}{' '}
                            {t('common.premises')}
                          </div>
                          <div className='flex justify-between gap-4'>
                            <button
                              className={`text-medium ${
                                pagination.hasPrev
                                  ? 'text-secondary-900'
                                  : 'text-muted pointer-events-none'
                              }`}
                              onClick={() => dispatch(loadPrevPremises({}))}
                            >
                              {t('common.prev')}
                            </button>
                            <button
                              className={`text-medium ${
                                pagination.hasNext
                                  ? 'text-secondary-900'
                                  : 'text-muted pointer-events-none'
                              }`}
                              onClick={() => dispatch(loadNextPremises({}))}
                            >
                              {t('common.next')}
                            </button>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <VerifyPremiseModal
        showModal={showVerifyPremiseModal}
        translate={t}
        onCancel={() => {
          dispatch(setCurrentState({}))
          setShowVerifyPremiseModal(false)
        }}
        errors={errors}
        currentState={currentState}
        searchingPremise={searchingPremise}
        searchPremise={(data) => {
          dispatch(searchPremiseByNumber(data))
        }}
        verificationState={verificationState}
        handleChange={handleChange}
        verifyingPremise={verifyingPremise}
        onSubmit={(data) =>
          dispatch(
            verifyPremise({
              ...verificationState,
              ...data
            })
          )
        }
      />

      <SuccessModal
        showModal={showSuccessModal}
        translate={t}
        onCancel={() => {
          dispatch(setHasVerifiedPremise(false))
          dispatch(setPremiseAlreadyVerified(false))
          setShowSuccessModal(false)
        }}
        message={thankYouMessage}
      />
    </>
  )
}

UnverifiedPremises.propTypes = {
  t: PropTypes.func.isRequired
}

export default UnverifiedPremises
