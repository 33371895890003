import config from '../helpers/config'
import api from '.'

const CLIENT_SECRET = config('CLIENT_SECRET')

export const requestLoan = (payload) =>
  api.post(`/loans?client_secret=${CLIENT_SECRET}`, payload)

export const getUserLoans = (payload) =>
  api.get(`/loans?client_secret=${CLIENT_SECRET}`, payload)

export const getLoanByRequestId = (requestId) =>
  api.get(`/loans/${requestId}?client_secret=${CLIENT_SECRET}`)

export const updateLoanByRequestId = ({ requestId, payload }) =>
  api.put(`/loans/${requestId}?client_secret=${CLIENT_SECRET}`, payload)
