import * as yup from 'yup'
import i18n from '../../../i18n'

export const validatePayment = (data, max) => {
  const schema = yup.object().shape({
    transaction_mode: yup.string().required(i18n.t('errors.transaction_mode_required')),
    phone: yup.string().when('transaction_mode', {
      is: 'stk_push',
      then: yup
        .string()
        .min(12, i18n.t('errors.phone_must_be_valid'))
        .max(12, i18n.t('errors.phone_must_be_valid'))
        .required(i18n.t('errors.phone_required'))
    }),
    amount: yup
      .number(i18n.t('errors.amount_must_be_a_number'))
      .typeError(i18n.t('errors.amount_must_be_a_number'))
      .min(1, i18n.t('errors.amount_must_be_greater_than_zero'))
      .max(max, `${i18n.t('errors.max_amount')} ${max}`)
      .required(i18n.t('errors.amount_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateSearchShop = (data) => {
  const schema = yup.object().shape({
    paylend_number: yup
      .number()
      .typeError(i18n.t('errors.paylend_number_must_be_a_number'))
      .required(i18n.t('errors.paylend_number_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateOkoaStepOne = (data) => {
  const schema = yup.object().shape({
    paylend_number: yup
      .number()
      .typeError(i18n.t('errors.paylend_number_must_be_a_number'))
      .required(i18n.t('errors.paylend_number_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateOkoaStepTwo = (data) => {
  const schema = yup.object().shape({
    repayment_plan: yup.string().required(i18n.t('errors.repayment_plan_is_required')),
    amount_borrowed: yup
      .number()
      .typeError(i18n.t('errors.amount_must_be_a_number'))
      .required(i18n.t('errors.amount_is_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateAddShopDetails = (data) => {
  const schema = yup.object().shape({
    is_manager: yup.string().required(i18n.t('errors.is_manager_is_required')),
    premise_front_image: yup
      .string()
      .required(i18n.t('errors.shop_front_image_is_required')),
    mpesa_statement: yup.string().required(i18n.t('errors.mpesa_statement_required')),
    business_permit: yup.string().required(i18n.t('errors.business_permit_is_required')),
    premise_address: yup.string().required(i18n.t('errors.shop_address_is_required')),
    monthly_transaction_income: yup
      .number()
      .typeError(i18n.t('errors.monthly_transaction_volume_must_be_a_number'))
      .required(i18n.t('errors.okoa_shopping.monthly_transaction_volume_is_required')),
    premise_name: yup.string().required(i18n.t('errors.premise_name_is_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateShopManager = (data) => {
  const schema = yup.object().shape({
    manager_identification: yup.string().required(i18n.t('errors.id_is_required')),
    manager_email: yup.string().email(i18n.t('errors.invalid_email')),
    manager_phone_number: yup
      .string()
      .min(12, i18n.t('errors.phone_must_be_valid'))
      .max(12, i18n.t('errors.phone_must_be_valid'))
      .required(i18n.t('errors.phone_required')),
    manager_lastname: yup.string().required(i18n.t('errors.last_name_is_required')),
    manager_firstname: yup.string().required(i18n.t('errors.first_name_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateWithdrawal = (data, max) => {
  const schema = yup.object().shape({
    premise: yup.string().required(i18n.t('errors.you_must_select_a_premise')),
    amount: yup
      .number(i18n.t('errors.amount_must_be_a_number'))
      .typeError(i18n.t('errors.amount_must_be_a_number'))
      .min(50, i18n.t('errors.minimum_withdraw_amount'))
      .max(max, `${i18n.t('errors.max_withdraw_amount')} ${max}`)
      .required(i18n.t('errors.amount_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateSendOtp = (data) => {
  const schema = yup.object().shape({
    phone: yup
      .string()
      .min(12, i18n.t('errors.phone_must_be_valid'))
      .max(12, i18n.t('errors.phone_must_be_valid'))
      .required(i18n.t('errors.phone_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateVerifyOtp = (data) => {
  const schema = yup.object().shape({
    code: yup.string().required(i18n.t('errors.otp_required')),
    phone: yup
      .string()
      .min(12, i18n.t('errors.phone_must_be_valid'))
      .max(12, i18n.t('errors.phone_must_be_valid'))
      .required(i18n.t('errors.phone_required'))
  })

  return schema.validate(data, { abortEarly: true })
}
