import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { FormGroup, Input, Button, Alert } from '..'

function ProfileSettings({
  t,
  errors,
  updatingPassword,
  onChangePassword,
  hasUpdatedPassword,
  hasUpdatedProfile,
  updatingProfile,
  onUpdateProfile
}) {
  const [state, setState] = useState({
    profile_picture: ''
  })

  const [passwordState, setPasswordState] = useState({
    current_password: '',
    password: '',
    confirm_password: ''
  })

  const [error, setError] = useState('')
  const [pictureError, setPictureError] = useState('')

  const handleFileChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.files[0]
    }))
  }

  const handleChange = (e) => {
    setPasswordState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  useEffect(() => {
    if (hasUpdatedPassword) {
      setPasswordState({
        current_password: '',
        password: '',
        confirm_password: ''
      })
    }
  }, [hasUpdatedPassword])

  useEffect(() => {
    if (hasUpdatedProfile) {
      setState({
        profile_picture: ''
      })
    }
  }, [hasUpdatedProfile])

  useEffect(() => {
    setError(errors.passwordError)
  }, [errors.passwordError])

  useEffect(() => {
    setPictureError(errors.profileError)
  }, [errors.profileError])

  return (
    <>
      <div className='flex mb-4'>
        <div className='overflow-x-auto shadow-md sm:rounded-lg w-full'>
          <div className='inline-block min-w-full align-middle'>
            <div className='p-4'>
              <h2 className='font-gotham-rounded-medium text-body text-sm mb-2'>
                {t('settings.profile_picture')}
              </h2>
              <div>{t('settings.profile_picture_help_text')}</div>

              {pictureError && (
                <div className='mt-2'>
                  <Alert
                    type='error'
                    message={pictureError}
                    onCancel={() => setPictureError('')}
                  />
                </div>
              )}

              <section className='mt-4'>
                <div>
                  <FormGroup>
                    <div className='relative'>
                      <label
                        htmlFor='profile_picture'
                        className={`bg-white border ${
                          Object.keys(errors).includes('profile_picture')
                            ? 'border-red-300'
                            : 'border-secondary-300'
                        } text-body rounded focus:ring-0 outline-none focus:outline-none focus:border-secondary-500 block w-full pr-4 py-2.5 font-gotham-rounded-book text-medium pl-4`}
                      >
                        {state.profile_picture !== '' ? (
                          <>{`${
                            state.profile_picture.name.length > 30
                              ? `${state.profile_picture.name.substring(0, 30)}...`
                              : state.profile_picture.name
                          }`}</>
                        ) : (
                          <span className='opacity-50'>
                            {t('settings.profile_picture')}
                          </span>
                        )}
                      </label>
                      <div className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 justify-between'>
                        <i className='bi bi-camera-fill text-secondary-900 text-base'></i>
                      </div>
                    </div>
                    <Input
                      type='file'
                      placeholder={t('settings.profile_picture')}
                      id='profile_picture'
                      classes='hidden'
                      name='profile_picture'
                      onChange={handleFileChange}
                      hasError={Object.keys(errors).includes('profile_picture')}
                      errorMessage={errors.profile_picture}
                    />
                  </FormGroup>
                </div>
                <div className='sm:w-64'>
                  <Button
                    label={t('settings.update_profile_picture_cta')}
                    disabled={updatingProfile}
                    onClick={() => onUpdateProfile(state)}
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <div className='flex'>
        <div className='overflow-x-auto shadow-md sm:rounded-lg w-full'>
          <div className='inline-block min-w-full align-middle'>
            <div className='p-4'>
              <h2 className='font-gotham-rounded-medium text-body text-sm mb-2'>
                {t('settings.change_password')}
              </h2>
              <div>{t('settings.change_password_help_text')}</div>

              {error && (
                <div className='mt-2'>
                  <Alert type='error' message={error} onCancel={() => setError('')} />
                </div>
              )}

              <section className='mt-4'>
                <FormGroup>
                  <Input
                    type='password'
                    placeholder={t('settings.current_password')}
                    onChange={handleChange}
                    name='current_password'
                    hasError={Object.keys(errors).includes('current_password')}
                    errorMessage={errors.current_password}
                    value={passwordState.current_password}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type='password'
                    placeholder={t('settings.new_password')}
                    onChange={handleChange}
                    name='password'
                    hasError={Object.keys(errors).includes('password')}
                    errorMessage={errors.password}
                    value={passwordState.password}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type='password'
                    placeholder={t('settings.confirm_new_password')}
                    onChange={handleChange}
                    name='confirm_password'
                    hasError={Object.keys(errors).includes('confirm_password')}
                    errorMessage={errors.confirm_password}
                    value={passwordState.confirm_password}
                  />
                </FormGroup>
                <div className='sm:w-64'>
                  <Button
                    label={t('settings.change_password')}
                    disabled={updatingPassword}
                    onClick={() => onChangePassword(passwordState)}
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

ProfileSettings.propTypes = {
  t: PropTypes.func,
  errors: PropTypes.object,
  updatingPassword: PropTypes.bool,
  onChangePassword: PropTypes.func,
  hasUpdatedPassword: PropTypes.bool,
  hasUpdatedProfile: PropTypes.bool,
  updatingProfile: PropTypes.bool,
  onUpdateProfile: PropTypes.func
}

export default ProfileSettings
