import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { useSelector } from 'react-redux'
import { Table, Metric, EmptyContent } from '../..'
import { icons } from '../../../assets/img'
import services from '../../../helpers/services'
import {
  formatTimestampToDate,
  formatDateToTime,
  formatAmount
} from '../../../helpers/utils'

function InuaBizOverview({ translate }) {
  const { recentTransactions: transactions, premiseWallets } = useSelector(
    (state) => state.inuaBiashara
  )
  const { currency } = useSelector((state) => state.auth)

  return (
    <>
      <section className='mb-6'>
        <div className='w-full'>
          <div className={`sm:grid sm:grid-cols-3 gap-2 last:border-0`}>
            <Metric
              label={translate('common.amount_borrowable')}
              icon={icons.moneyActive}
              showPercentage={false}
              count={`${currency} ${formatAmount(
                premiseWallets
                  .map((item) => item.borrowable)
                  .reduce((prev, curr) => prev + curr, 0)
              )}`}
            />
            <Metric
              label={translate('common.credit_limit')}
              icon={icons.iconMoneyInHand}
              showPercentage={false}
              statPercent='3.27%'
              type='increase'
              count={`${currency} ${formatAmount(
                premiseWallets
                  .map((item) => item.creditLimit)
                  .reduce((prev, curr) => prev + curr, 0)
              )}`}
            />
            <Metric
              label={translate('okoa_shopping.amount_payable')}
              icon={icons.iconTotalPayable}
              showPercentage={false}
              statPercent='15%'
              type='decrease'
              count={`${currency} ${formatAmount(
                premiseWallets
                  .map((item) => item.repayableAmount)
                  .reduce((prev, curr) => prev + curr, 0)
              )}`}
            />
          </div>
        </div>
      </section>

      <div className='flex flex-col'>
        <div className='overflow-x-auto shadow-md sm:rounded-lg w-full'>
          <div className='inline-block min-w-full align-middle'>
            <div className='p-4 flex justify between'>
              <h2 className='font-gotham-rounded-medium text-body text-base'>
                {translate('okoa_shopping.latest_transactions')}
              </h2>
            </div>
            <div className='overflow-hidden p-4'>
              {isEmpty(transactions) ? (
                <EmptyContent
                  title={translate('common.no_recent_transactions')}
                  subTitle={translate('common.no_recent_transactions_help_text')}
                />
              ) : (
                <>
                  <Table
                    header={
                      <tr>
                        <th
                          scope='col'
                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                        >
                          {translate('okoa_shopping.date')}
                        </th>
                        <th
                          scope='col'
                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                        >
                          {translate('okoa_shopping.transaction_number')}
                        </th>
                        <th
                          scope='col'
                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                        >
                          {translate('okoa_shopping.amount')}
                        </th>
                        <th
                          scope='col'
                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                        >
                          {translate('okoa_shopping.status')}
                        </th>
                        <th
                          scope='col'
                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                        >
                          {translate('okoa_shopping.type')}
                        </th>
                      </tr>
                    }
                    body={
                      <>
                        {transactions.map((transaction) => (
                          <tr key={transaction.id}>
                            <td className='pl-2 py-4 whitespace-nowrap'>
                              <div className='text-medium font-medium text-body'>
                                {formatTimestampToDate(transaction.createdAt)}
                              </div>
                              <div className='text-xs text-gray-500'>
                                {formatDateToTime(transaction.createdAt)}
                              </div>
                            </td>
                            <td className='pl-2 py-4 w-40 text-medium uppercase text-body'>
                              {transaction.receiptNumber}
                            </td>
                            <td className='pl-2 py-4 text-medium font-bold text-body'>
                              {transaction.actionType === 'give_loan_to_premise' ||
                              transaction.actionType === 'withdraw_premise_wallet' ? (
                                <span className='text-green-500'>
                                  + {currency} {formatAmount(transaction.amount)}
                                </span>
                              ) : (
                                <span className='text-primary'>
                                  - {currency} {formatAmount(transaction.amount)}
                                </span>
                              )}
                            </td>
                            <td className='pl-2 py-4 text-medium capitalize text-body'>
                              {transaction.status}
                            </td>
                            <td className='pl-2 py-4 text-medium capitalize text-body'>
                              {transaction.actionType === 'give_loan_to_premise' && (
                                <span>
                                  {services().INUA_BIZ} {translate('common.request')}
                                </span>
                              )}
                              {transaction.actionType === 'repay_loan_by_premise' && (
                                <span>{translate('common.repayment')}</span>
                              )}
                              {transaction.actionType === 'withdraw_premise_wallet' && (
                                <span>{translate('common.withdrawal')}</span>
                              )}
                              {transaction.actionType === 'charge_fine' && (
                                <span>{translate('common.fine')}</span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    }
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

InuaBizOverview.propTypes = {
  translate: PropTypes.func
}

export default InuaBizOverview
