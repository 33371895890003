import config from '../helpers/config'
import api from '.'
import { objectToQueryString } from '../helpers/utils'

const CLIENT_SECRET = config('CLIENT_SECRET')

export const getUserVouchers = (payload) => {
  let query = {
    client_secret: CLIENT_SECRET,
    ...payload
  }

  query = objectToQueryString(query)

  return api.get(`/vouchers?${query}`)
}

export const buyVoucher = (payload) =>
  api.post(`/vouchers?client_secret=${CLIENT_SECRET}`, payload)

export const getVoucherById = (voucherId) =>
  api.get(`/vouchers/${voucherId}?client_secret=${CLIENT_SECRET}`)

export const updateVoucher = ({ voucherId, payload }) =>
  api.put(`/vouchers/${voucherId}?client_secret=${CLIENT_SECRET}`, payload)
