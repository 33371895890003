import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { initialState, setNotification } from '../../containers/Auth/store'

function Notification({ type, message, show, duration, width }) {
  const dispatch = useDispatch()
  useEffect(() => {
    let timer
    if (duration === 'indefinite') return
    if (duration === 'long') {
      timer = setTimeout(() => {
        dispatch(setNotification(initialState.notification))
      }, 3000)
    } else if (duration === 'very long') {
      timer = setTimeout(() => {
        dispatch(setNotification(initialState.notification))
      }, 5000)
    } else {
      timer = setTimeout(() => {
        dispatch(setNotification(initialState.notification))
      }, 2000)
    }
    return () => {
      clearTimeout(timer)
    }
  })

  return show ? (
    <div className={width}>
      {type ? (
        <>
          {type === 'error' && (
            <div
              className={`p-3 w-full text-sm flex justify-between items-center ${`text-red-700`} ${`bg-red-100`} rounded-sm border border-red-400 dark:${`text-red-800`}`}
              role='alert'
            >
              <div className='flex items-center'>
                <span className='font-gotham-rounded-book text-xs'>{message}</span>
              </div>
            </div>
          )}

          {type === 'success' && (
            <div
              className={`p-3 w-full text-sm flex justify-between items-center ${`text-green-700`} ${`bg-green-100`} rounded-sm border border-green-400 dark:${`text-green-800`}`}
              role='alert'
            >
              <div className='flex items-center'>
                <span className='font-gotham-rounded-book text-xs'>{message}</span>
              </div>
              <div>
                <i className='bi bi-check-circle-fill text-xl mr-3'></i>
              </div>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  ) : null
}

Notification.defaultProps = {
  type: 'default',
  show: false,
  duration: 'indefinite'
}

Notification.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string.isRequired,
  show: PropTypes.bool,
  duration: PropTypes.string,
  updateNotificationState: PropTypes.func,
  width: PropTypes.string
}

export default Notification
