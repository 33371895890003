import { createSlice } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { head, isEmpty, splitEvery, last, flatten, dropLast, omit } from 'ramda'
import { cloneDeep } from 'lodash'
import { getUserTransactions } from '../../../api/transactions'
import { getUserDetails } from '../../../helpers/utils'
import {
  validatePayment,
  validateInuaBizAmount,
  validateSendOtp,
  validateVerifyOtp
} from './validator'
import { makePayment, stkPushStatus, premiseWithdrawal } from '../../../api/payments'
import { getUserLoans, requestLoan } from '../../../api/loans'
import { getPremises, getPremiseWallets } from '../../../api/premises'
import { otpVerification, sendOtp } from '../../../api/auth'
import i18n from '../../../i18n'

export const initialState = {
  isLoading: true,
  recentTransactions: [],
  premises: [],
  loadingPayment: false,
  errors: {},
  stkPushRequestId: null,
  transactions: [],
  transactionsPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  premisesPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  loans: [],
  loansPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  repayments: [],
  repaymentsPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  withdrawals: [],
  withdrawalsPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  okoaRequest: {},
  hasSavedStepDetails: false,
  savingOkoaRequest: false,
  hasSavedOkoaRequest: false,
  totalPremises: 0,
  premiseWallets: [],
  hasRepaidLoan: false,
  withdrawData: {},
  withdrawing: false,
  hasWithdrawn: false,
  hasSentOtp: false,
  sendingOtp: false,
  notification: '',
  activeTransactionsTab: 0
}

const PAGE_LIMIT = 10

export const inuaBiasharaSlice = createSlice({
  name: 'inuabiashara',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setRecentTransactions: (state, action) => {
      state.recentTransactions = action.payload
    },
    setTransactions: (state, action) => {
      state.transactions = action.payload
    },
    setPremises: (state, action) => {
      state.premises = action.payload
    },
    setLoadingPayment: (state, action) => {
      state.loadingPayment = action.payload
    },
    setErrors: (state, action) => {
      state.errors = action.payload
    },
    setStkPushRequestId: (state, action) => {
      state.stkPushRequestId = action.payload
    },
    setTransactionsPagination: (state, action) => {
      state.transactionsPagination = action.payload
    },
    setPremisesPagination: (state, action) => {
      state.premisesPagination = action.payload
    },
    setLoans: (state, action) => {
      state.loans = action.payload
    },
    setLoansPagination: (state, action) => {
      state.loansPagination = action.payload
    },
    setOkoaRequest: (state, action) => {
      state.okoaRequest = action.payload
    },
    setHasSavedStepDetails: (state, action) => {
      state.hasSavedStepDetails = action.payload
    },
    setSavingOkoaRequest: (state, action) => {
      state.savingOkoaRequest = action.payload
    },
    setHasSavedOkoaRequest: (state, action) => {
      state.hasSavedOkoaRequest = action.payload
    },
    setTotalPremises: (state, action) => {
      state.totalPremises = action.payload
    },
    setPremiseWallets: (state, action) => {
      state.premiseWallets = action.payload
    },
    setHasRepaidLoan: (state, action) => {
      state.hasRepaidLoan = action.payload
    },
    setWithdrawing: (state, action) => {
      state.withdrawing = action.payload
    },
    setHasWithdrawn: (state, action) => {
      state.hasWithdrawn = action.payload
    },
    setWithdrawData: (state, action) => {
      state.withdrawData = action.payload
    },
    setHasSentOtp: (state, action) => {
      state.hasSentOtp = action.payload
    },
    setSendingOtp: (state, action) => {
      state.sendingOtp = action.payload
    },
    setNotification: (state, action) => {
      state.notification = action.payload
    },
    setWithdrawals: (state, action) => {
      state.withdrawals = action.payload
    },
    setWithdrawalsPagination: (state, action) => {
      state.withdrawalsPagination = action.payload
    },
    setRepayments: (state, action) => {
      state.repayments = action.payload
    },
    setRepaymentsPagination: (state, action) => {
      state.repaymentsPagination = action.payload
    },
    setActiveTransactionsTab: (state, action) => {
      state.activeTransactionsTab = action.payload
    }
  }
})

export const {
  setIsLoading,
  setRecentTransactions,
  setTransactions,
  setPremises,
  setLoadingPayment,
  setErrors,
  setStkPushRequestId,
  setTransactionsPagination,
  setPremisesPagination,
  setLoans,
  setLoansPagination,
  setOkoaRequest,
  setHasSavedStepDetails,
  setSavingOkoaRequest,
  setHasSavedOkoaRequest,
  setTotalPremises,
  setPremiseWallets,
  setHasRepaidLoan,
  setWithdrawing,
  setHasWithdrawn,
  setWithdrawData,
  setHasSentOtp,
  setSendingOtp,
  setNotification,
  setWithdrawals,
  setWithdrawalsPagination,
  setRepayments,
  setRepaymentsPagination,
  setActiveTransactionsTab
} = inuaBiasharaSlice.actions

const attachPremiseToLoan = async (loan) => {
  try {
    if (loan.premiseName) {
      return loan
    }
    const { data } = await getPremises({ paylend_number: loan.paylendNumber.toString() })
    return Object.assign({}, loan, {
      premiseName: data[0].premiseName
    })
  } catch (err) {
    return loan
  }
}

export const repayLoan = (payload) => async (dispatch, getState) => {
  try {
    const { locationDetails } = getState().auth
    dispatch(setLoadingPayment(true))
    dispatch(setErrors({}))
    dispatch(setHasRepaidLoan(false))

    if (isEmpty(payload.premise)) {
      dispatch(setLoadingPayment(false))
      return dispatch(setErrors({ premise: i18n.t('errors.select_premise_first') }))
    }

    const { premiseWallets } = getState().inuaBiashara
    const wallet = premiseWallets.find((w) => w.id === payload.premise)

    await validatePayment(
      {
        ...payload,
        transaction_mode: 'stk_push'
      },
      wallet.repayableAmount
    )

    const res = await makePayment({
      ...omit(['premise'], payload),
      payment_type: payload.payment_type === 'mpesa' ? 'mobile_money' : 'card',
      country: locationDetails.country_name,
      user_id: getUserDetails().id,
      action_type: 'repay_loan_by_premise',
      reference_occasion: 'inua_biashara',
      transaction_mode: 'stk_push',
      premise_id: wallet.premiseId
    })

    dispatch(setLoadingPayment(false))
    dispatch(setHasRepaidLoan(true))
    dispatch(setStkPushRequestId(res.checkoutRequestId))
  } catch (error) {
    Sentry.captureException(error)
    dispatch(setLoadingPayment(false))
    const isValidatorError = Array.isArray(error.errors)

    if (isValidatorError) {
      const message = { [error.path]: head(error.errors) }
      return dispatch(setErrors(message))
    }
    dispatch(setErrors({ apiError: error.message }))
  }
}

export const getStkPushStatus = (requestId) => async (dispatch) => {
  try {
    dispatch(setLoadingPayment(true))

    await stkPushStatus({ checkout_request_id: requestId })

    dispatch(setLoadingPayment(false))
    dispatch(setStkPushRequestId(null))
  } catch (error) {
    dispatch(setLoadingPayment(false))
  }
}

export const fetchRecentTransactions = (params) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true))
    const { id: userId } = getUserDetails()
    const { data } = await getUserTransactions({
      user_id: userId,
      ...(params.premise_owner_id && {
        premise_owner_id: params.premise_owner_id
      }),
      reference_occasion: 'inua_biashara',
      limit: PAGE_LIMIT,
      sort: '-created_at',
      ...(!isEmpty(params) && { ...params })
    })

    dispatch(setRecentTransactions(data))

    dispatch(setIsLoading(false))
  } catch (error) {
    dispatch(setIsLoading(false))
    Sentry.captureException(error)
  }
}

export const fetchTransactions =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { id: userId } = getUserDetails()
      const { data, count } = await getUserTransactions({
        user_id: userId,
        ...(params.premise_owner_id && {
          premise_owner_id: params.premise_owner_id
        }),
        reference_occasion: 'inua_biashara',
        limit: PAGE_LIMIT,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setTransactions(data))

      dispatch(
        setTransactionsPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }

export function loadNextTransactions(params = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))
      const { transactions, transactionsPagination: pagination } = cloneDeep(
        getState().inuaBiashara
      )
      const transaction = transactions[transactions.length - 1]
      const cursor = transaction.id
      const { data, count } = await getUserTransactions({
        user_id: getUserDetails().id,
        ...(params.premise_owner_id && {
          premise_owner_id: params.premise_owner_id
        }),
        reference_occasion: 'inua_biashara',
        limit: PAGE_LIMIT,
        after: cursor,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setTransactions(data))

      dispatch(
        setTransactionsPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...transactions],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }
}

export function loadPrevTransactions(params = {}) {
  return async (dispatch, getState) => {
    const { transactionsPagination: pagination, transactions: stateTransactions } =
      getState().inuaBiashara
    const transactions = pagination.paginated
    const transactionChunks = splitEvery(PAGE_LIMIT, transactions)

    if (isEmpty(transactionChunks) || transactionChunks.length === 1) {
      try {
        dispatch(setIsLoading(true))

        const { data, count } = await getUserTransactions({
          user_id: getUserDetails().id,
          ...(params.premise_owner_id && {
            premise_owner_id: params.premise_owner_id
          }),
          reference_occasion: 'inua_biashara',
          limit: PAGE_LIMIT,
          sort: '-created_at',
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setTransactions(data))

        dispatch(
          setTransactionsPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
      }
    } else {
      const previousTransactions = last(transactionChunks)
      dispatch(setTransactions(previousTransactions))

      dispatch(
        setTransactionsPagination({
          hasNext: transactions.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (stateTransactions.length < PAGE_LIMIT
              ? stateTransactions.length
              : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, transactionChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export const fetchRepayments =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { id: userId } = getUserDetails()
      const { data, count } = await getUserTransactions({
        user_id: userId,
        reference_occasion: 'inua_biashara',
        action_type: 'repay_loan_by_premise',
        limit: PAGE_LIMIT,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setRepayments(data))

      dispatch(
        setRepaymentsPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }

export function loadNextRepayments(params = {}) {
  return async (dispatch, getState) => {
    try {
      // dispatch(setIsLoading(true))
      const { repayments: transactions, repaymentsPagination: pagination } = cloneDeep(
        getState().inuaBiashara
      )
      const transaction = transactions[transactions.length - 1]
      const cursor = transaction.id
      const { data, count } = await getUserTransactions({
        user_id: getUserDetails().id,
        reference_occasion: 'inua_biashara',
        action_type: 'repay_loan_by_premise',
        limit: PAGE_LIMIT,
        after: cursor,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setRepayments(data))

      dispatch(
        setRepaymentsPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...transactions],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }
}

export function loadPrevRepayments(params = {}) {
  return async (dispatch, getState) => {
    const { repaymentsPagination: pagination, repayments: stateTransactions } =
      getState().inuaBiashara
    const transactions = pagination.paginated
    const transactionChunks = splitEvery(PAGE_LIMIT, transactions)

    if (isEmpty(transactionChunks) || transactionChunks.length === 1) {
      try {
        const { data, count } = await getUserTransactions({
          user_id: getUserDetails().id,
          reference_occasion: 'inua_biashara',
          action_type: 'repay_loan_by_premise',
          limit: PAGE_LIMIT,
          sort: '-created_at',
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setRepayments(data))

        dispatch(
          setRepaymentsPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
      }
    } else {
      const previousTransactions = last(transactionChunks)
      dispatch(setRepayments(previousTransactions))

      dispatch(
        setRepaymentsPagination({
          hasNext: transactions.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (stateTransactions.length < PAGE_LIMIT
              ? stateTransactions.length
              : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, transactionChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export const fetchWithdrawals =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { id: userId } = getUserDetails()
      const { data, count } = await getUserTransactions({
        user_id: userId,
        action_type: 'withdraw_premise_wallet',
        limit: PAGE_LIMIT,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setWithdrawals(data))

      dispatch(
        setWithdrawalsPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }

export function loadNextWithdrawals(params = {}) {
  return async (dispatch, getState) => {
    try {
      const { withdrawals: transactions, withdrawalsPagination: pagination } = cloneDeep(
        getState().inuaBiashara
      )
      const transaction = transactions[transactions.length - 1]
      const cursor = transaction.id
      const { data, count } = await getUserTransactions({
        user_id: getUserDetails().id,
        action_type: 'withdraw_premise_wallet',
        limit: PAGE_LIMIT,
        after: cursor,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setWithdrawals(data))

      dispatch(
        setWithdrawalsPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...transactions],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }
}

export function loadPrevWithdrawals(params = {}) {
  return async (dispatch, getState) => {
    const { withdrawalsPagination: pagination, wtihdrawals: stateTransactions } =
      getState().inuaBiashara
    const transactions = pagination.paginated
    const transactionChunks = splitEvery(PAGE_LIMIT, transactions)

    if (isEmpty(transactionChunks) || transactionChunks.length === 1) {
      try {
        const { data, count } = await getUserTransactions({
          user_id: getUserDetails().id,
          action_type: 'withdraw_premise_wallet',
          limit: PAGE_LIMIT,
          sort: '-created_at',
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setWithdrawals(data))

        dispatch(
          setWithdrawalsPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
      }
    } else {
      const previousTransactions = last(transactionChunks)
      dispatch(setWithdrawals(previousTransactions))

      dispatch(
        setWithdrawalsPagination({
          hasNext: transactions.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (stateTransactions.length < PAGE_LIMIT
              ? stateTransactions.length
              : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, transactionChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export const fetchLoans =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { id: userId } = getUserDetails()
      const { data, count } = await getUserLoans({
        user_id: userId,
        loan_type: 'inua_biashara',
        limit: PAGE_LIMIT,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      const formattedLoans = await Promise.all(
        data.map((loan) => attachPremiseToLoan(loan))
      )

      dispatch(setLoans(formattedLoans))

      dispatch(
        setLoansPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }

export function loadNextLoans(params = {}) {
  return async (dispatch, getState) => {
    try {
      const { loans, loansPagination: pagination } = cloneDeep(getState().inuaBiashara)
      const loan = loans[loans.length - 1]
      const cursor = loan.id
      const { data, count } = await getUserLoans({
        ...(params.premise_owner_id
          ? { premise_owner_id: params.premise_owner_id }
          : { user_id: getUserDetails().id }),
        loan_type: 'inua_biashara',
        limit: PAGE_LIMIT,
        after: cursor,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setLoans(data))

      dispatch(
        setLoansPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...loans],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }
}

export function loadPrevLoans(params = {}) {
  return async (dispatch, getState) => {
    const { loansPagination: pagination, loans: stateLoans } = getState().inuaBiashara
    const loans = pagination.paginated
    const loanChunks = splitEvery(PAGE_LIMIT, loans)

    if (isEmpty(loanChunks) || loanChunks.length === 1) {
      try {
        const { data, count } = await getUserLoans({
          ...(params.premise_owner_id
            ? { premise_owner_id: params.premise_owner_id }
            : { user_id: getUserDetails().id }),
          loan_type: 'inua_biashara',
          limit: PAGE_LIMIT,
          sort: '-created_at',
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setLoans(data))

        dispatch(
          setLoansPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
      }
    } else {
      const previousLoans = last(loanChunks)
      dispatch(setLoans(previousLoans))

      dispatch(
        setLoansPagination({
          hasNext: loans.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (stateLoans.length < PAGE_LIMIT ? stateLoans.length : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, loanChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export const fetchPremises =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { id: userId } = getUserDetails()
      const [{ data, count }] = await Promise.all([
        getPremises({
          user_id: userId,
          limit: PAGE_LIMIT,
          ...(!isEmpty(params) && { ...params })
        })
      ])

      dispatch(setPremises(data))

      dispatch(
        setPremisesPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }

export function loadNextPremises(params = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))
      const { premises, premisesPagination: pagination } = cloneDeep(
        getState().inuaBiashara
      )
      const premise = [premises.length - 1]
      const cursor = premise.id
      const { data, count } = await getPremises({
        user_id: getUserDetails().id,
        limit: PAGE_LIMIT,
        after: cursor,
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setPremises(data))

      dispatch(
        setPremisesPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...premises],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }
}

export function loadPrevPremises(params = {}) {
  return async (dispatch, getState) => {
    const { premisesPagination: pagination, premises: statePremises } =
      getState().inuaBiashara
    const premises = pagination.paginated
    const premisesChunks = splitEvery(PAGE_LIMIT, premises)

    if (isEmpty(premisesChunks) || premisesChunks.length === 1) {
      try {
        dispatch(setIsLoading(true))

        const { data, count } = await getPremises({
          user_id: getUserDetails().id,
          limit: PAGE_LIMIT,
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setPremises(data))

        dispatch(
          setPremisesPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
      }
    } else {
      const previousPremises = last(premisesChunks)
      dispatch(setPremises(previousPremises))

      dispatch(
        setPremisesPagination({
          hasNext: premises.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (statePremises.length < PAGE_LIMIT ? statePremises.length : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, premisesChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export function submitInuaBizAmount(data) {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))
      dispatch(setHasSavedStepDetails(false))
      await validateInuaBizAmount(data)
      dispatch(setErrors({}))
      const { okoaRequest } = getState().inuaBiashara
      dispatch(setOkoaRequest({ ...okoaRequest, ...data }))
      dispatch(setIsLoading(false))
      dispatch(setHasSavedStepDetails(true))
    } catch (error) {
      dispatch(setHasSavedStepDetails(false))
      dispatch(setIsLoading(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
    }
  }
}

export function submitInuaBizRequest(data) {
  return async (dispatch, getState) => {
    try {
      dispatch(setHasSavedOkoaRequest(false))
      dispatch(setSavingOkoaRequest(true))
      dispatch(setErrors({}))

      const user = getUserDetails()
      const { okoaRequest } = getState().inuaBiashara

      await validateVerifyOtp({
        type: 'verify',
        code: data.otp,
        phone: user.phone
      })

      await requestLoan({
        user_id: user.id,
        loan_type: 'inua_biashara',
        paylend_number: okoaRequest.paylend_number,
        repayment_plan: okoaRequest.repayment_plan,
        amount_borrowed: okoaRequest.amount_borrowed,
        otp: data.otp
      })

      dispatch(fetchRecentTransactions({}))
      dispatch(fetchPremiseWallets())

      dispatch(setSavingOkoaRequest(false))
      dispatch(setHasSavedOkoaRequest(true))
      dispatch(setOkoaRequest({}))
    } catch (error) {
      dispatch(setSavingOkoaRequest(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function fetchPremiseWallets() {
  return async (dispatch) => {
    try {
      const user = getUserDetails()
      const [{ data }] = await Promise.all([
        getPremiseWallets({
          user_id: user.id
        })
      ])

      dispatch(setIsLoading(true))
      dispatch(setPremiseWallets(data))
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }
}

export function withdraw(data) {
  return async (dispatch, getState) => {
    try {
      dispatch(setErrors({}))
      dispatch(setWithdrawing(true))
      dispatch(setHasWithdrawn(false))

      const user = getUserDetails()
      const { withdrawData, premiseWallets } = getState().inuaBiashara

      await validateVerifyOtp({
        type: 'verify',
        code: data.code,
        phone: user.phone
      })

      await otpVerification({
        phone: user.phone,
        ...data
      })

      const wallet = premiseWallets.find((w) => w.id === withdrawData.premise)

      await premiseWithdrawal({
        amount: withdrawData.amount,
        phone: user.phone,
        premise_id: wallet.premiseId,
        premise_wallet_id: wallet.id,
        reference_occasion: 'inua_biashara',
        user_id: user.id
      })

      dispatch(setWithdrawing(false))
      dispatch(setHasWithdrawn(true))
    } catch (error) {
      dispatch(setWithdrawing(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function onSendOtp(data) {
  return async (dispatch) => {
    try {
      dispatch(setErrors({}))
      dispatch(setSendingOtp(true))
      dispatch(setNotification(''))

      await validateSendOtp({
        phone: getUserDetails().phone
      })
      await sendOtp(data)

      dispatch(setErrors({}))
      dispatch(setSendingOtp(false))
      dispatch(setHasSentOtp(true))
      dispatch(setNotification(i18n.t('common.otp_sent')))
    } catch (error) {
      dispatch(setSendingOtp(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export default inuaBiasharaSlice.reducer
