import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { formatAmount } from '../../helpers/utils'

function RedeemVoucher({ t, onRedeemVoucher, onViewVoucher }) {
  const { wallet, currency } = useSelector((state) => state.auth)

  return (
    <>
      <div className='flex flex-col'>
        <div className='overflow-x-auto shadow-md sm:rounded-lg'>
          <div className='inline-block min-w-full align-middle'>
            <div className='p-4'>
              <h2 className='font-gotham-rounded-medium text-body text-base'>
                {t('pata_voucher.vouchers')}
              </h2>

              <section className='mt-4'>
                <div className='grid grid-cols-2 gap-10 mb-10'>
                  <div className='w-full bg-voucher-shopping rounded-3xl'>
                    <div className='flex flex-row'>
                      <div
                        className={`bg-primary font-gotham-rounded-bold uppercase py-16 text-white text-2xl flex justify-end h-auto rounded-l-3xl`}
                      >
                        <div className='-rotate-90 py-2 flex justify-center float-right relative w-20'>
                          {t('pata_voucher.shopping')}
                        </div>
                      </div>
                      <section>
                        <div className='pt-4 pb-3 px-4 relative w-full'>
                          <div
                            className={`text-primary text-2xl font-gotham-rounded-medium`}
                          >
                            {currency} {formatAmount(wallet.pataVoucher.shopping)}
                          </div>
                          <div className='mt-1 text-black'>
                            {t('pata_voucher.voucher_help_text')}
                          </div>
                        </div>

                        <div className='flex items-end px-4 pb-2'>
                          <div className='p-1 bg-white'>
                            <i className='bi bi-qr-code text-5xl'></i>
                          </div>
                          <div className='flex justify-between items-center ml-auto gap-6'>
                            <button
                              className={`ml-auto uppercase font-gotham-rounded-bold text-sm text-primary`}
                              onClick={() => onViewVoucher('shopping')}
                            >
                              {t('pata_voucher.view')}
                            </button>
                            <button
                              className={`ml-auto uppercase font-gotham-rounded-bold text-sm text-primary`}
                              onClick={() => onRedeemVoucher('shopping')}
                            >
                              {t('pata_voucher.redeem')}
                            </button>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                  <div className='w-full bg-voucher-fees rounded-3xl'>
                    <div className='flex flex-row'>
                      <div
                        className={`bg-secondary-900 font-gotham-rounded-bold uppercase py-16 text-white text-2xl flex justify-end h-auto rounded-l-3xl`}
                      >
                        <div className='-rotate-90 py-2 flex justify-center float-right relative w-20'>
                          {t('pata_voucher.sch_fees')}
                        </div>
                      </div>
                      <section>
                        <div className='pt-4 pb-3 px-4 relative w-full'>
                          <div
                            className={`text-secondary-900 text-2xl font-gotham-rounded-medium`}
                          >
                            {currency} {formatAmount(wallet.pataVoucher.schoolFees)}
                          </div>
                          <div className='mt-1 text-black'>
                            {t('pata_voucher.voucher_help_text')}
                          </div>
                        </div>

                        <div className='flex items-end px-4 pb-2'>
                          <div className='p-1 bg-white'>
                            <i className='bi bi-qr-code text-5xl'></i>
                          </div>
                          <div className='flex justify-between items-center ml-auto gap-6'>
                            <button
                              className={`ml-auto uppercase font-gotham-rounded-bold text-sm text-secondary-900`}
                              onClick={() => onViewVoucher('fees')}
                            >
                              {t('pata_voucher.view')}
                            </button>
                            <button
                              className={`ml-auto uppercase font-gotham-rounded-bold text-sm text-secondary-900`}
                              onClick={() => onRedeemVoucher('fees')}
                            >
                              {t('pata_voucher.redeem')}
                            </button>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

RedeemVoucher.propTypes = {
  t: PropTypes.func,
  onRedeemVoucher: PropTypes.func,
  onViewVoucher: PropTypes.func
}

export default RedeemVoucher
