import PropTypes from 'prop-types'
import { take, isEmpty } from 'ramda'
import { useSelector } from 'react-redux'
import { Metric, EmptyContent, Table } from '../'
import { formatTimestampToDate } from '../../helpers/utils'

function AgentOverview({ translate }) {
  const { metrics } = useSelector((state) => state.auth)
  const {
    verifiedPremisesPagination: verified,
    registeredPremisesPagination: registered,
    verifiedPremises
  } = useSelector((state) => state.agent)

  return (
    <>
      <section className='mb-6'>
        <div className='w-full'>
          <div className={`sm:grid sm:grid-cols-3 gap-2 last:border-0`}>
            <Metric
              label={translate('agent_dashboard.unverified_premises')}
              iconNode={
                <>
                  <i className='m-auto flex justify-center bi bi-patch-question text-primary text-5xl'></i>
                </>
              }
              showPercentage={false}
              showIcon={false}
              count={metrics?.agentDashboard.unverifiedPremises}
            />
            <Metric
              label={translate('agent_dashboard.verified_by_me')}
              iconNode={
                <>
                  <i className='m-auto flex justify-center bi bi-clipboard-check text-primary text-5xl'></i>
                </>
              }
              showPercentage={false}
              showIcon={false}
              count={verified?.total}
            />
            <Metric
              label={translate('agent_dashboard.registered_by_me')}
              iconNode={
                <>
                  <i className='m-auto flex justify-center bi bi-journal-text text-primary text-5xl'></i>
                </>
              }
              showPercentage={false}
              showIcon={false}
              count={registered?.total}
            />
          </div>
        </div>
      </section>

      <div className='flex flex-col'>
        <div className='overflow-x-auto shadow-md sm:rounded-lg w-full'>
          <div className='inline-block min-w-full align-middle'>
            <div className='p-4 flex justify between'>
              <h2 className='font-gotham-rounded-medium text-body text-base'>
                {translate('agent_dashboard.latest_verified_premises')}
              </h2>
            </div>
            <div className='overflow-hidden p-4'>
              {isEmpty(verifiedPremises) ? (
                <EmptyContent
                  title={translate('common.no_verified_premises')}
                  subTitle={translate('common.no_verified_premises_help_text')}
                />
              ) : (
                <>
                  <Table
                    header={
                      <tr>
                        <th
                          scope='col'
                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                        >
                          {translate('common.premise_name')}
                        </th>
                        <th
                          scope='col'
                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                        >
                          {translate('okoa_shopping.location')}
                        </th>
                        <th
                          scope='col'
                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                        >
                          {translate('common.premise_type')}
                        </th>
                        <th
                          scope='col'
                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                        >
                          {translate('common.premise_number')}
                        </th>
                        <th
                          scope='col'
                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                        >
                          {translate('common.created_on')}
                        </th>
                        <th scope='col' className='p-4'>
                          <span className='sr-only'>
                            {translate('okoa_shopping.action')}
                          </span>
                        </th>
                      </tr>
                    }
                    body={
                      <>
                        {take(5, verifiedPremises).map((shop) => (
                          <tr key={shop.id}>
                            <td className='pl-2 py-4 whitespace-nowrap'>
                              <div className='text-medium font-medium text-body'>
                                {shop.premiseName}
                              </div>
                            </td>
                            <td className='pl-2 py-4 w-40 text-medium text-body'>
                              {shop.premiseAddress}
                            </td>
                            <td className='pl-2 py-4 w-40 capitalize text-medium text-body'>
                              {shop.premiseType}
                            </td>
                            <td className='pl-2 py-4 text-medium text-body'>
                              {shop.paylendNumber}
                            </td>
                            <td className='pl-2 py-4 text-medium text-body'>
                              {formatTimestampToDate(shop.createdAt)}
                            </td>
                          </tr>
                        ))}
                      </>
                    }
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

AgentOverview.propTypes = {
  translate: PropTypes.func
}

export default AgentOverview
