import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Input, Select } from '..'

function ChangishaChats({ t }) {
  const { userInitiatives } = useSelector((state) => state.changisha)
  const handleChange = (e) => {}
  return (
    <>
      <div className='flex flex-col'>
        <div className='overflow-x-auto shadow-md sm:rounded-lg w-full'>
          <div className='inline-block min-w-full align-middle'>
            <div className='p-4'>
              <h2 className='font-gotham-rounded-medium text-body text-base mb-2'>
                {t('changisha.chats')}
              </h2>
              <div className='flex justify-between items-center'>
                <div className='w-64 mt-1'>
                  <Select
                    onChange={handleChange}
                    name={'initiative'}
                    options={userInitiatives.map((initiative) => ({
                      label: initiative.name,
                      value: initiative.name
                    }))}
                  />
                </div>
                <div className='ml-auto'>
                  <label htmlFor='table-search' className='sr-only'>
                    Search
                  </label>
                  <div className=''>
                    <Input
                      type='search'
                      placeholder={t('changisha.search_initiative')}
                      onChange={() => null}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

ChangishaChats.propTypes = {
  t: PropTypes.func
}

export default ChangishaChats
