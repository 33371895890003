import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Button, Alert } from '../..'
import { icons } from '../../../assets/img'
import { amountDue, addDaysToDate, formatAmount } from '../../../helpers/utils'

function ConfirmOkoaRequestModal({
  t,
  showModal,
  onCancel,
  onSubmit,
  request,
  updatingRequest,
  errors
}) {
  const [error, setError] = useState('')
  const { currency } = useSelector((state) => state.auth)

  useEffect(() => {
    setError(errors?.apiError)
  }, [errors?.apiError])
  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={onCancel}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button onClick={onCancel} className='outline-none focus:outline-none'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>

                <div className='text-center'>
                  <div className='flex justify-center'>
                    <img src={icons.iconSchoolThin} alt='School' className='w-24' />
                  </div>
                  {error && (
                    <Alert type='error' message={error} onCancel={() => setError('')} />
                  )}
                  <div className='mt-4 font-gotham-rounded-bold text-primary text-3xl'>
                    {t('okoa_fees.okoa_request')}
                  </div>
                  <div className='mt-4 text-body opacity-80 sm:px-10 text-base'>
                    <p className='text-sm'>
                      {t('okoa_shopping.okoa_amount_is', {
                        amount: `${currency} ${formatAmount(request?.amountBorrowed)}`,
                        kiasi: `${currency} ${formatAmount(request?.amountBorrowed)}`
                      })}
                    </p>
                    <p className='text-sm'>
                      {t('okoa_shopping.fee_charged', {
                        amount: `${currency} ${formatAmount(
                          request?.amountDue - request?.amountBorrowed
                        )}`,
                        kiasi: `${currency} ${formatAmount(
                          request?.amountDue - request?.amountBorrowed
                        )}`
                      })}{' '}
                      {t('okoa_shopping.total_okoa_outstanding_amount_will_be', {
                        amount: `${currency} ${formatAmount(
                          amountDue(request?.amountBorrowed, request?.repaymentPlan)
                        )}`,
                        kiasi: `${currency} ${formatAmount(
                          amountDue(request?.amountBorrowed, request?.repaymentPlan)
                        )}`
                      })}{' '}
                      {'to be paid on'}{' '}
                      {addDaysToDate(new Date(), request?.repaymentPlan)}
                    </p>
                  </div>

                  <div className='mt-4 sm:px-10'>
                    <Button
                      label={t('common.confirm')}
                      onClick={onSubmit}
                      classes='uppercase'
                      disabled={updatingRequest}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

ConfirmOkoaRequestModal.propTypes = {
  t: PropTypes.func,
  showModal: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  request: PropTypes.object,
  updatingRequest: PropTypes.bool,
  errors: PropTypes.object
}

export default ConfirmOkoaRequestModal
