/* eslint-disable promise/always-return */
import PropTypes from 'prop-types'
import { useState, useEffect, useCallback } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { onMessageListener } from '../../helpers/firebase'

function AppNotification({ translate }) {
  const [notification, setNotification] = useState({
    title: '',
    body: ''
  })

  const notify = useCallback(
    () =>
      toast.custom(
        (t) => (
          <div className='w-full max-w-sm p-4 text-gray-900 bg-white rounded-lg shadow dark:bg-gray-800 dark:text-gray-300'>
            <div className='flex items-center mb-3'>
              <span className='mb-1 text-medium font-gotham-rounded-bold text-gray-900 dark:text-white'>
                {translate('common.new_notification')}
              </span>
              <button
                type='button'
                className='ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700'
                aria-label='Close'
                onClick={() => {
                  setNotification({ title: '', message: '' })
                  toast.remove(t.id)
                }}
              >
                <span className='sr-only'>Close</span>
                <svg
                  className='w-5 h-5'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                    clipRule='evenodd'
                  ></path>
                </svg>
              </button>
            </div>
            <div className='flex items-center'>
              <div className='relative inline-block shrink-0'>
                <i className='bi bi-bell-fill text-3xl text-secondary-900'></i>
              </div>
              <div className='ml-3 text-medium font-normal'>
                <h4 className='text-medium font-semibold text-gray-900 dark:text-white'>
                  {notification.title}
                </h4>
                <div className='text-medium font-normal'>{notification.body}</div>
                <span className='text-xs font-medium text-muted'>a few seconds ago</span>
              </div>
            </div>
          </div>
        ),
        {
          position: 'top-right',
          duration: 5000,
          id: 'app-notification'
        }
      ),
    [notification, translate]
  )

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body
      })
    })
    .catch((err) => console.log('failed: ', err))

  useEffect(() => {
    if (notification?.title) {
      notify()
    }
  })

  return <Toaster />
}

AppNotification.propTypes = {
  message: PropTypes.string,
  translate: PropTypes.func
}

export default AppNotification
