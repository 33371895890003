import PropTypes from 'prop-types'
import { Table, Metric, EmptyContent } from '../..'
import { icons } from '../../../assets/img'
import { isEmpty } from 'ramda'
import { useSelector } from 'react-redux'
import {
  formatTimestampToDate,
  formatDateToTime,
  formatAmount,
  textCase
} from '../../../helpers/utils'

function OkoaShoppingOverview({ translate, okoaUser }) {
  const { wallet, currency } = useSelector((state) => state.auth)
  const {
    recentTransactions: transactions,
    shops,
    shopWallets
  } = useSelector((state) => state.shopping)

  const isShopper = okoaUser.value === 'shopper'

  return (
    <>
      <section className='mb-6'>
        <div className='w-full'>
          <div
            className={`sm:grid ${
              isShopper ? 'sm:grid-cols-3' : 'sm:grid-cols-2'
            } gap-2 last:border-0`}
          >
            <Metric
              label={
                isShopper
                  ? translate('okoa_shopping.okoa_balance')
                  : translate('okoa_shopping.wallet_balance')
              }
              icon={icons.moneyActive}
              showPercentage={false}
              count={
                isShopper
                  ? `${currency} ${formatAmount(wallet.okoaShopping?.available)}`
                  : isEmpty(shops)
                  ? `${currency} 0`
                  : `${currency} ${formatAmount(
                      shopWallets
                        .map((item) => item.availableBalance)
                        .reduce((prev, curr) => prev + curr, 0)
                    )}`
              }
            />
            <Metric
              label={
                isShopper
                  ? translate('okoa_shopping.okoa_limit')
                  : translate('okoa_shopping.shops')
              }
              icon={icons.iconMoneyInHand}
              showPercentage={false}
              showIcon={isShopper}
              statPercent='3.27%'
              type='increase'
              count={
                isShopper
                  ? `${currency} ${formatAmount(wallet.okoaShopping?.limit)}`
                  : shops.length
              }
              iconNode={
                <>
                  <i className='bi bi-building text-6xl text-primary'></i>
                </>
              }
            />
            {isShopper && (
              <Metric
                label={translate('okoa_shopping.okoa_payable')}
                icon={icons.iconTotalPayable}
                showPercentage={false}
                statPercent='15%'
                type='decrease'
                count={`${currency} ${formatAmount(wallet.okoaShopping?.payable)}`}
              />
            )}
          </div>
        </div>
      </section>

      <div className='flex flex-col'>
        <div className='overflow-x-auto shadow-md sm:rounded-lg w-full'>
          <div className='inline-block min-w-full align-middle'>
            <div className='p-4 flex justify between'>
              <h2 className='font-gotham-rounded-medium text-body text-base'>
                {translate('okoa_shopping.latest_transactions')}
              </h2>
            </div>
            <div className='overflow-hidden p-4'>
              {isEmpty(transactions) ? (
                <EmptyContent
                  title={translate('common.no_recent_transactions')}
                  subTitle={translate('common.no_recent_transactions_help_text')}
                />
              ) : (
                <>
                  <Table
                    header={
                      <tr>
                        <th
                          scope='col'
                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                        >
                          {translate('okoa_shopping.date')}
                        </th>
                        <th
                          scope='col'
                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                        >
                          {translate('okoa_shopping.transaction_number')}
                        </th>
                        <th
                          scope='col'
                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                        >
                          {translate('okoa_shopping.amount')}
                        </th>
                        <th
                          scope='col'
                          className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                        >
                          {translate('okoa_shopping.type')}
                        </th>
                      </tr>
                    }
                    body={
                      <>
                        {transactions.map((transaction) => (
                          <tr key={transaction.id}>
                            <td className='pl-2 py-4 whitespace-nowrap'>
                              <div className='text-medium font-medium text-body'>
                                {formatTimestampToDate(transaction.createdAt)}
                              </div>
                              <div className='text-xs text-gray-500'>
                                {formatDateToTime(transaction.createdAt)}
                              </div>
                            </td>
                            <td className='pl-2 py-4 w-40 text-medium uppercase text-body'>
                              {transaction.receiptNumber}
                            </td>
                            <td className='pl-2 py-4 text-medium font-bold text-body'>
                              {transaction.actionType === 'give_loan_to_user' ? (
                                <span className='text-green-500'>
                                  + {currency} {formatAmount(transaction.amount)}
                                </span>
                              ) : (
                                <span className='text-primary'>
                                  - {currency} {formatAmount(transaction.amount)}
                                </span>
                              )}
                            </td>

                            <td className='pl-2 py-4 text-medium capitalize text-body'>
                              {transaction.actionType === 'give_loan_to_user' && (
                                <span>{translate('common.okoa_request')}</span>
                              )}
                              {transaction.actionType === 'repay_loan_by_user' && (
                                <span>{translate('common.repayment')}</span>
                              )}
                              {transaction.actionType === 'charge_fine' && (
                                <span>{translate('common.fine')}</span>
                              )}
                              {transaction.actionType !== 'charge_fine' &&
                                transaction.actionType !== 'repay_loan_by_user' &&
                                transaction.actionType !== 'give_loan_to_user' && (
                                  <span>{textCase(transaction.actionType)}</span>
                                )}
                            </td>
                          </tr>
                        ))}
                      </>
                    }
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

OkoaShoppingOverview.propTypes = {
  translate: PropTypes.func,
  okoaUser: PropTypes.object
}

export default OkoaShoppingOverview
