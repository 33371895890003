import * as yup from 'yup'
import i18n from '../../../i18n'

export const validatePayment = (data, max) => {
  const schema = yup.object().shape({
    transaction_mode: yup.string().required(i18n.t('errors.transaction_mode_required')),
    phone: yup.string().when('transaction_mode', {
      is: 'stk_push',
      then: yup
        .string()
        .min(12, i18n.t('errors.phone_must_be_valid'))
        .max(12, i18n.t('errors.phone_must_be_valid'))
        .required(i18n.t('errors.phone_required'))
    }),
    amount: yup
      .number(i18n.t('errors.amount_must_be_a_number'))
      .typeError(i18n.t('errors.amount_must_be_a_number'))
      .min(1, i18n.t('errors.amount_must_be_greater_than_zero'))
      .max(max, `${i18n.t('errors.max_repayable')} ${max}`)
      .required(i18n.t('errors.amount_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateInuaBizAmount = (data, max) => {
  const schema = yup.object().shape({
    repayment_plan: yup.string().required(i18n.t('errors.repayment_plan_is_required')),
    amount_borrowed: yup
      .number()
      .typeError(i18n.t('errors.amount_must_be_a_number'))
      .max(max, `${i18n.t('errors.max_inua_amount')} ${max}`)
      .required(i18n.t('errors.amount_is_required')),
    paylend_number: yup.string().required(i18n.t('errors.premise_is_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateSendOtp = (data) => {
  const schema = yup.object().shape({
    phone: yup
      .string()
      .min(12, i18n.t('errors.phone_must_be_valid'))
      .max(12, i18n.t('errors.phone_must_be_valid'))
      .required(i18n.t('errors.phone_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateVerifyOtp = (data) => {
  const schema = yup.object().shape({
    code: yup.string().required(i18n.t('errors.otp_required')),
    phone: yup
      .string()
      .min(12, i18n.t('errors.phone_must_be_valid'))
      .max(12, i18n.t('errors.phone_must_be_valid'))
      .required(i18n.t('errors.phone_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateWithdrawal = (data, max) => {
  const schema = yup.object().shape({
    premise: yup.string().required(i18n.t('errors.you_must_select_a_premise')),
    amount: yup
      .number(i18n.t('errors.amount_must_be_a_number'))
      .typeError(i18n.t('errors.amount_must_be_a_number'))
      .min(50, i18n.t('errors.minimum_withdraw_amount'))
      .max(max, `${i18n.t('errors.max_withdraw_amount')} ${max}`)
      .required(i18n.t('errors.amount_required'))
  })

  return schema.validate(data, { abortEarly: true })
}
