import PropTypes from 'prop-types'
import toast, { Toaster } from 'react-hot-toast'
import { useEffect } from 'react'

function DashboardAlert({ type, message, show, duration, updateNotificationState }) {
  const getAlertColor = () => {
    let notificationColor = ''
    switch (type) {
      case 'error':
        notificationColor = 'text-red-700 bg-red-100'
        break
      case 'success':
        notificationColor = 'text-green-700 bg-green-100'
        break
      case 'info':
        notificationColor = 'text-blue-700 bg-blue-100'
        break
      default:
        notificationColor = 'text-gray-700 bg-gray-100'
    }

    return notificationColor
  }

  const getIcon = () => {
    if (type === 'success') {
      return (
        <div className='inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-700 bg-green-200 rounded-full dark:bg-green-800 dark:text-green-200'>
          <svg
            className='w-5 h-5'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
              clipRule='evenodd'
            ></path>
          </svg>
        </div>
      )
    } else if (type === 'error') {
      return (
        <div className='inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-700 bg-red-200 rounded-full dark:bg-red-800 dark:text-red-200'>
          <svg
            className='w-5 h-5'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
              clipRule='evenodd'
            ></path>
          </svg>
        </div>
      )
    } else if (type === 'info') {
      return (
        <div className='inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-700 bg-blue-200 rounded-full dark:bg-blue-800 dark:text-blue-200'>
          <svg
            className='w-5 h-5'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z'
              clipRule='evenodd'
            ></path>
          </svg>
        </div>
      )
    }
  }

  const getButtonClass = () => {
    let color = ''
    switch (type) {
      case 'error':
        color = 'hover:bg-red-200'
        break
      case 'success':
        color = 'hover:bg-green-200'
        break
      case 'info':
        color = 'hover:bg-blue-200'
        break
      default:
        break
    }
    return color
  }

  const notify = () =>
    toast.custom(
      (t) => (
        <div
          className={`${
            t.visible ? 'animate-enter' : 'animate-leave'
          } ${getAlertColor()} shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 items-center w-full max-w-sm p-4`}
        >
          {getIcon()}
          <div className='ml-3 text-sm font-normal'>{message}</div>
          <button
            onClick={() => {
              updateNotificationState({ show: false })
              toast.remove(t.id)
            }}
            className={`ml-auto -mx-1.5 -my-1.5 ${getButtonClass()} inline-flex h-8 w-8 rounded-lg p-1.5`}
          >
            <span className='sr-only'>Close</span>
            <svg
              className='w-5 h-5'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                clipRule='evenodd'
              ></path>
            </svg>
          </button>
        </div>
      ),
      {
        duration,
        position: 'bottom-center',
        id: 'dashboard-alert'
      }
    )

  useEffect(() => {
    show && notify()
  })

  return <Toaster />
}

DashboardAlert.defaultProps = {
  type: 'default',
  show: false,
  duration: 'indefinite'
}

DashboardAlert.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string.isRequired,
  show: PropTypes.bool,
  duration: PropTypes.string,
  updateNotificationState: PropTypes.func
}

export default DashboardAlert
