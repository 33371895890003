import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button, FormGroup, Input, Select } from '../../'

function SchoolFinancialDetailsModal({
  t,
  showModal,
  onCancel,
  onSubmit,
  handleChange,
  state,
  errors,
  loading,
  handleFileChange,
  showManagerInput
}) {
  return (
    <Transition appear show={showModal} as={Fragment}>
      <Dialog as='div' className='fixed inset-0 z-50 overflow-y-auto' onClose={onCancel}>
        <div className='min-h-screen px-4 text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
          </Transition.Child>

          <span className='inline-block h-screen align-middle' aria-hidden='true'>
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div className='inline-block p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl w-full max-w-2xl'>
              <div className='flex justify-end focus:outline-none outline-none'>
                <button onClick={onCancel}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6 hover:text-secondary-900'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </button>
              </div>

              <div className=''>
                <header className='uppercase text-medium text-muted font-gotham-rounded-medium mx-auto flex justify-center'>
                  <i className='bi bi-building text-7xl text-primary'></i>
                </header>
                <div className='mt-4 font-gotham-rounded-bold text-primary text-3xl text-center'>
                  {t('sidebar.okoa_fees')}
                </div>
                <div className='text-body text-base sm:px-6 text-center'>
                  {t('okoa_fees.financial_information')}
                </div>

                <section className='mt-3'>
                  <div className='mt-1 sm:grid sm:grid-cols-2 gap-3'>
                    <FormGroup>
                      <Select
                        name='institutional_level'
                        onChange={handleChange}
                        options={[
                          { label: t('okoa_fees.nursery'), value: 'Nursery' },
                          { label: t('okoa_fees.primary'), value: 'Primary' },
                          { label: t('okoa_fees.secondary'), value: 'Secondary' },
                          { label: t('okoa_fees.university'), value: 'University' }
                        ]}
                        hasPlaceholder={true}
                        placeholderText={t('okoa_fees.select_institution_level')}
                        hasError={Object.keys(errors).includes('institutional_level')}
                        errorMessage={errors.institutional_level}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type='text'
                        name='bank_account'
                        placeholder={t('okoa_fees.bank_account')}
                        onChange={handleChange}
                        value={state.bank_account}
                        hasError={Object.keys(errors).includes('bank_account')}
                        errorMessage={errors.bank_account}
                      />
                    </FormGroup>
                  </div>
                  <div className='mt-1 sm:grid sm:grid-cols-2 gap-3'>
                    <FormGroup>
                      <Input
                        type='number'
                        name='account_number'
                        placeholder={t('okoa_fees.account_number')}
                        onChange={handleChange}
                        value={state.account_number}
                        hasError={Object.keys(errors).includes('account_number')}
                        errorMessage={errors.account_number}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type='number'
                        name='paybill_number'
                        placeholder={t('okoa_fees.paybill_number')}
                        onChange={handleChange}
                        value={state.paybill_number}
                        hasError={Object.keys(errors).includes('paybill_number')}
                        errorMessage={errors.paybill_number}
                      />
                    </FormGroup>
                  </div>
                  <div className='mt-1 sm:grid sm:grid-cols-2 gap-3'>
                    <FormGroup>
                      <Input
                        type='number'
                        name='monthly_transaction_income'
                        placeholder={t('okoa_fees.monthly_income')}
                        onChange={handleChange}
                        value={state.monthly_transaction_income}
                        hasError={Object.keys(errors).includes(
                          'monthly_transaction_income'
                        )}
                        errorMessage={errors.monthly_transaction_income}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type='number'
                        name='till_number'
                        placeholder={t('okoa_fees.till_number')}
                        onChange={handleChange}
                        value={state.till_number}
                        hasError={Object.keys(errors).includes('till_number')}
                        errorMessage={errors.till_number}
                      />
                    </FormGroup>
                  </div>
                  <div
                    className={`mt-1 ${
                      showManagerInput ? 'sm:grid' : ''
                    } sm:grid-cols-2 gap-3`}
                  >
                    <FormGroup>
                      <div className='relative'>
                        <label
                          htmlFor='mpesa_statement'
                          className={`bg-white border ${
                            Object.keys(errors).includes('mpesa_statement')
                              ? 'border-red-300'
                              : 'border-secondary-300'
                          } text-body rounded focus:ring-0 outline-none focus:outline-none focus:border-secondary-500 block w-full pr-4 py-2.5 font-gotham-rounded-book text-medium pl-4`}
                        >
                          {state.mpesa_statement !== '' ? (
                            <>{`${
                              state.mpesa_statement.name.length > 30
                                ? `${state.mpesa_statement.name.substring(0, 30)}...`
                                : state.mpesa_statement.name
                            }`}</>
                          ) : (
                            <span className='opacity-50'>
                              {t('okoa_shopping.statement_placeholder')}
                            </span>
                          )}
                        </label>
                        <div className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 justify-between'>
                          <div className='mr-3 -rotate-45'>
                            <i className='bi bi-paperclip text-secondary-900 text-base'></i>
                          </div>
                          <div>
                            <i className='bi bi-camera-fill text-secondary-900 text-base'></i>
                          </div>
                        </div>
                      </div>
                      <Input
                        type='file'
                        name='mpesa_statement'
                        placeholder={t('okoa_shopping.statement_placeholder')}
                        hasError={Object.keys(errors).includes('mpesa_statement')}
                        errorMessage={errors.mpesa_statement}
                        onChange={handleFileChange}
                        classes='hidden'
                        id='mpesa_statement'
                      />
                    </FormGroup>
                    {showManagerInput && (
                      <FormGroup>
                        <Select
                          name='is_manager'
                          onChange={handleChange}
                          options={[
                            { label: t('common.yes'), value: true },
                            { label: t('common.no'), value: false }
                          ]}
                          hasPlaceholder={true}
                          placeholderText={t('okoa_shopping.are_you_a_shop_manager')}
                          hasError={Object.keys(errors).includes('is_manager')}
                          errorMessage={errors.is_manager}
                        />
                      </FormGroup>
                    )}
                  </div>
                </section>

                {state.is_manager !== 'true' && showManagerInput && (
                  <div className='mt-4 sm:px-28 text-center text-sm'>
                    {t('okoa_fees.account_info_help_text')}
                  </div>
                )}

                <div className='mt-4 sm:px-20'>
                  <Button
                    label={
                      state.is_manager === 'true' || state.is_manager === true
                        ? t('okoa_fees.submit')
                        : t('okoa_fees.add_school_manager')
                    }
                    classes='uppercase'
                    onClick={onSubmit}
                    disabled={loading}
                  />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

SchoolFinancialDetailsModal.defaultProps = {
  showManagerInput: true
}

SchoolFinancialDetailsModal.propTypes = {
  t: PropTypes.func,
  showModal: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  state: PropTypes.object,
  errors: PropTypes.object,
  loading: PropTypes.bool,
  handleFileChange: PropTypes.func,
  showManagerInput: PropTypes.bool
}

export default SchoolFinancialDetailsModal
