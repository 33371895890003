import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import { Button } from '../..'

function SuccessModal({ showModal, onCancel, translate, message }) {
  return (
    <Transition appear show={showModal} as={Fragment}>
      <Dialog as='div' className='fixed inset-0 z-50 overflow-y-auto' onClose={onCancel}>
        <div className='min-h-screen px-4 text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
          </Transition.Child>

          <span className='inline-block h-screen align-middle' aria-hidden='true'>
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
              <div className='flex justify-end'>
                <button onClick={onCancel}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6 hover:text-secondary-900'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </button>
              </div>
              <div>
                <div className='rounded-full relative h-14 w-14 py-1 text-center bg-green-600 mx-auto'>
                  <i className='bi bi-check-lg text-4xl text-white mt-1 inline-block'></i>
                </div>
                <div className='sm:px-6 mt-12 text-center text-sm text-body opacity-80'>
                  {message}
                </div>
                <div className='sm:px-16 mt-12'>
                  <Button
                    onClick={onCancel}
                    classes='uppercase'
                    label={translate('okoa_shopping.okay')}
                  />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

SuccessModal.propTypes = {
  onCancel: PropTypes.func,
  showModal: PropTypes.bool,
  translate: PropTypes.func,
  message: PropTypes.string
}

export default SuccessModal
