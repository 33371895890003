import { createSlice } from '@reduxjs/toolkit'
import { head, isEmpty, omit, pipe, prop, allPass, isNil, propEq } from 'ramda'
import * as Sentry from '@sentry/react'
import toSnakeCase from 'snakecase-keys'
import {
  forgotPassword,
  login,
  logout,
  register,
  resetPassword,
  otpVerification,
  sendOtp,
  verifyEmail,
  updateProfile,
  fetchUsers
} from '../../../api/auth'
import { getUserLocation } from '../../../api/geolocation'
import {
  validateLogin,
  validateSignUp,
  validateForgotPassword,
  validateResetPassword,
  validateVerifyOtp,
  validateCompleteRegistration,
  validatePayment
} from './validator'
import {
  decodeToken,
  getUserDetails,
  removeCookie,
  setCookie,
  setItemToLocalStorage,
  getItemFromLocalStorage,
  identifyUser
} from '../../../helpers/utils'
import { EMAIL_SENT, HOME, LOGIN, OTP_VERIFICATION } from '../../App/RouteConstants'
import i18n from '../../../i18n'
import { getPremises } from '../../../api/premises'
import { getSignedUrl, uploadFileToS3, storeMedia, getMedia } from '../../../api/media'
import { getProductMetrics, migrate } from '../../../api/user'
import { makePayment, stkPushStatus } from '../../../api/payments'
import countries from '../../../helpers/countries'

export const initialState = {
  user: {},
  isAuthenticating: false,
  isLoading: false,
  errors: {},
  locationDetails: {},
  shouldResetForm: false,
  notification: {
    type: 'default',
    message: '',
    show: false,
    duration: 'indefinite'
  },
  metrics: {
    loading: true,
    inuaBiashara: {
      borrowable: 0,
      creditLimit: 0,
      repayableAmount: 0
    },
    okoaServices: {
      totalAvailable: 0,
      totalLimit: 0,
      totalPayable: 0
    },
    pataVoucher: {
      totalRedeemed: 0,
      totalVouchers: 0,
      totalWorth: 0
    },
    changisha: {
      totalDeficit: 0,
      totalInitiatives: 0,
      totalRaised: 0
    },
    agentDashboard: {
      unverifiedPremises: 0,
      surveys: 0,
      registered_premises: 0
    }
  },
  wallet: {
    loading: true,
    okoaShopping: {
      available: 0,
      payable: 0,
      limit: 0
    },
    pataVoucher: {
      shopping: 0,
      kilimo: 0,
      schoolFees: 0,
      logistics: 0,
      afya: 0
    },
    okoaFees: {
      available: 0,
      payable: 0,
      limit: 0
    }
  },
  savingUser: false,
  hasSavedUser: false,
  makingPayment: false,
  hasMadePayment: false,
  checkingPaymentStatus: false,
  hasCheckedPaymentStatus: false,
  hasCancelledRequest: false,
  hasTimedOutRequest: false,
  currency: 'Ksh',
  shouldCreatePassword: false,
  resetToken: '',
  hasCreatedPassword: false,
  shouldResetPassword: false,
  hasResetPassword: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setIsAuthenticating: (state, action) => {
      state.isAuthenticating = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setErrors: (state, action) => {
      state.errors = action.payload
    },
    setLocationDetails: (state, action) => {
      state.locationDetails = action.payload
    },
    setShouldResetForm: (state, action) => {
      state.shouldResetForm = action.payload
    },
    setNotification: (state, action) => {
      state.notification = action.payload
    },
    setLoadingMetrics: (state, action) => {
      state.metrics.loading = action.payload
    },
    setMetrics: (state, action) => {
      state.metrics = action.payload
    },
    setSavingUser: (state, action) => {
      state.savingUser = action.payload
    },
    setHasSavedUser: (state, action) => {
      state.hasSavedUser = action.payload
    },
    setLoadingWallet: (state, action) => {
      state.wallet.loading = action.payload
    },
    setWallet: (state, action) => {
      state.wallet = action.payload
    },
    setMakingPayment: (state, action) => {
      state.makingPayment = action.payload
    },
    setHasMadePayment: (state, action) => {
      state.hasMadePayment = action.payload
    },
    setCheckingPaymentStatus: (state, action) => {
      state.checkingPaymentStatus = action.payload
    },
    setHasCheckedPaymentStatus: (state, action) => {
      state.hasCheckedPaymentStatus = action.payload
    },
    setHasCancelledRequest: (state, action) => {
      state.hasCancelledRequest = action.payload
    },
    setHasTimedOutRequest: (state, action) => {
      state.hasTimedOutRequest = action.payload
    },
    setCurrency: (state, action) => {
      state.currency = action.payload
    },
    setShouldCreatePassword: (state, action) => {
      state.shouldCreatePassword = action.payload
    },
    setResetToken: (state, action) => {
      state.resetToken = action.payload
    },
    setHasCreatedPassword: (state, action) => {
      state.hasCreatedPassword = action.payload
    },
    setShouldResetPassword: (state, action) => {
      state.shouldResetPassword = action.payload
    },
    setHasResetPassword: (state, action) => {
      state.hasResetPassword = action.payload
    }
  }
})

export const {
  setUser,
  setIsAuthenticating,
  setIsLoading,
  setErrors,
  setLocationDetails,
  setShouldResetForm,
  setNotification,
  setLoadingMetrics,
  setMetrics,
  setSavingUser,
  setHasSavedUser,
  setLoadingWallet,
  setWallet,
  setMakingPayment,
  setHasMadePayment,
  setCheckingPaymentStatus,
  setHasCheckedPaymentStatus,
  setHasCancelledRequest,
  setHasTimedOutRequest,
  setCurrency,
  setShouldCreatePassword,
  setResetToken,
  setHasCreatedPassword,
  setShouldResetPassword,
  setHasResetPassword
} = authSlice.actions

export const getLocationDetails = () => async (dispatch) => {
  try {
    const locationDetails = await getUserLocation()
    window.navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords
      dispatch(setLocationDetails({ ...locationDetails, latitude, longitude }))
    })
    const country = countries.find(
      (country) => country.name === locationDetails.country_name
    )
    dispatch(setCurrency(country.currency))
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const loginUser = (payload) => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true))
    dispatch(setShouldResetForm(false))
    const { locationDetails } = getState().auth
    await validateLogin(payload)
    dispatch(setErrors({}))

    const fcm = getItemFromLocalStorage('fcm')

    const res = await login({
      ...payload,
      login_from: 'website',
      device_id: locationDetails.IPv4,
      ...(fcm && { fcm_token: fcm })
    })

    const user = decodeToken(res.token)

    setCookie({
      key: 'user',
      value: { ...user, token: res.token },
      options: { expires: new Date(user.exp * 1000) }
    })
    dispatch(setShouldResetForm(true))
    dispatch(setIsLoading(false))

    window.location.href = HOME
  } catch (error) {
    const regex = /Email not verified/
    dispatch(setIsLoading(false))
    const isValidatorError = Array.isArray(error.errors)
    const isEmailUnverifiedError = regex.test(error.message)

    if (isValidatorError) {
      const message = { [error.path]: head(error.errors) }
      return dispatch(setErrors(message))
    }

    if (isEmailUnverifiedError) {
      dispatch(
        setNotification({
          type: 'info',
          message: i18n.t('common.email_unverified_help_text'),
          show: true,
          duration: 'very long'
        })
      )
    } else {
      dispatch(
        setNotification({
          type: 'error',
          message: error.message,
          show: true,
          duration: 'very long'
        })
      )
    }
  }
}

export const registerUser = (payload) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true))
    dispatch(setShouldResetForm(false))
    await validateSignUp(payload)
    dispatch(setErrors({}))

    await register(payload)

    dispatch(setShouldResetForm(true))
    dispatch(setIsLoading(false))

    window.location.replace(OTP_VERIFICATION)
  } catch (error) {
    dispatch(setIsLoading(false))
    const isValidatorError = Array.isArray(error.errors)
    const isDuplicateEmail = error.message.includes(
      'E11000 duplicate key error collection: paylend.users index: email_1 dup key'
    )

    if (isValidatorError) {
      const message = { [error.path]: head(error.errors) }
      return dispatch(setErrors(message))
    } else if (isDuplicateEmail) {
      return dispatch(
        setNotification({
          type: 'error',
          message: i18n.t('errors.signup.email_already_exists'),
          show: true,
          duration: 'indefinite'
        })
      )
    }

    dispatch(
      setNotification({
        type: 'error',
        message: error.message,
        show: true,
        duration: 'very long'
      })
    )
  }
}

export const fetchCurrentUserDetails = () => async (dispatch) => {
  try {
    const user = getUserDetails()

    dispatch(setUser(user))
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const logoutUser = () => async () => {
  try {
    const { token } = getUserDetails()
    await logout(token)
    removeCookie('user')
    window.location.href = LOGIN
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const onForgotPassword = (payload) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true))
    dispatch(setShouldResetForm(false))
    await validateForgotPassword(payload)
    dispatch(setErrors({}))

    await forgotPassword(payload)

    dispatch(setShouldResetForm(true))
    dispatch(setIsLoading(false))

    if (payload.type === 'email') {
      window.location.replace(EMAIL_SENT)
    } else if (payload.phone === 'phone') {
      dispatch(
        setNotification({
          type: 'info',
          message: i18n.t('otp_sent.help_text'),
          show: true,
          duration: 'very long'
        })
      )
    }
  } catch (error) {
    dispatch(setIsLoading(false))
    const isValidatorError = Array.isArray(error.errors)

    if (isValidatorError) {
      const message = { [error.path]: head(error.errors) }
      return dispatch(setErrors(message))
    }

    dispatch(setErrors({}))

    dispatch(
      setNotification({
        type: 'error',
        message: error.message,
        show: true,
        duration: 'very long'
      })
    )
  }
}

export const onResetPassword = (payload) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true))
    dispatch(setShouldResetForm(false))
    dispatch(setHasCreatedPassword(false))

    await validateResetPassword(payload)
    dispatch(setErrors({}))

    await resetPassword(payload)

    if (payload.oldUser) {
      dispatch(setHasCreatedPassword(true))
      dispatch(setIsLoading(false))
      dispatch(
        setNotification({
          type: 'success',
          message: i18n.t('common.create_password_success'),
          show: true,
          duration: 'long'
        })
      )
      setTimeout(() => {
        window.location.href = LOGIN
      }, 3000)
    } else {
      dispatch(setHasResetPassword(true))
      dispatch(setIsLoading(false))
      dispatch(
        setNotification({
          type: 'success',
          message: i18n.t('common.reset_password_success'),
          show: true,
          duration: 'long'
        })
      )
      setTimeout(() => {
        window.location.href = LOGIN
      }, 3000)
    }
  } catch (error) {
    dispatch(setIsLoading(false))
    const isValidatorError = Array.isArray(error.errors)

    if (isValidatorError) {
      const message = { [error.path]: head(error.errors) }
      return dispatch(setErrors(message))
    }

    dispatch(setErrors({}))

    dispatch(
      setNotification({
        type: 'error',
        message: error.message,
        show: true,
        duration: 'very long'
      })
    )
  }
}

export const verifyOtp = (payload) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true))
    dispatch(setShouldResetForm(false))
    dispatch(setShouldCreatePassword(false))
    dispatch(setShouldResetPassword(false))
    dispatch(setErrors({}))

    if (payload.type === 'verify') {
      await validateVerifyOtp(payload)
      const res = await otpVerification(payload)

      if (payload.oldUser) {
        dispatch(setResetToken(res.token))
        dispatch(setShouldCreatePassword(true))
        dispatch(setShouldResetForm(true))
        return dispatch(setIsLoading(false))
      } else if (payload.actionType === 'reset password') {
        dispatch(setResetToken(res.token))
        dispatch(setShouldResetPassword(true))
        dispatch(setShouldResetForm(true))
        return dispatch(setIsLoading(false))
      }

      const user = decodeToken(res.token)

      setCookie({
        key: 'user',
        value: { ...user, token: res.token },
        options: { expires: new Date(user.exp * 1000) }
      })
      dispatch(setShouldResetForm(true))
      dispatch(setIsLoading(false))

      window.location.href = HOME
    } else {
      dispatch(setErrors({}))
      await sendOtp({
        phone: payload.oldUser
          ? payload.user.phone
          : isNil(payload.user)
          ? payload.phone
          : payload.user.phone
      })
      dispatch(setShouldResetForm(true))
      dispatch(setIsLoading(false))
      dispatch(
        setNotification({
          message: i18n.t('common.otp_sent'),
          type: 'info',
          show: false,
          duration: 'long'
        })
      )
    }
  } catch (error) {
    dispatch(setIsLoading(false))
    const isValidatorError = Array.isArray(error.errors)

    if (isValidatorError) {
      const message = { [error.path]: head(error.errors) }
      return dispatch(setErrors(message))
    }

    dispatch(setErrors({}))

    const regex = /reset password for old user/

    if (regex.test(error.message)) {
      return dispatch(
        setNotification({
          message: i18n.t('common.otp_sent'),
          type: 'info',
          show: false,
          duration: 'long'
        })
      )
    }

    dispatch(
      setNotification({
        type: 'error',
        message: error.message,
        show: true,
        duration: 'very long'
      })
    )
  }
}

export const onVerifyEmail = (payload) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true))
    dispatch(setErrors({}))

    const res = await verifyEmail(payload)

    dispatch(setShouldResetForm(true))
    dispatch(setIsLoading(false))
    dispatch(
      setNotification({
        type: 'success',
        message: res.message,
        show: true,
        duration: 'very long'
      })
    )

    window.location.replace(LOGIN)
  } catch (error) {
    dispatch(setIsLoading(false))
    const isValidatorError = Array.isArray(error.errors)

    if (isValidatorError) {
      const message = { [error.path]: head(error.errors) }
      return dispatch(setErrors(message))
    }

    dispatch(
      setNotification({
        type: 'error',
        message: error.message,
        show: true,
        duration: 'very long'
      })
    )
  }
}

export const fetchMetrics = () => async (dispatch) => {
  try {
    const user = getUserDetails()
    identifyUser(user.id, {
      name: `${user.firstname} ${user.lastname}`,
      phone: user.phone,
      ...(!isNil(user.email) && {
        email: user.email
      }),
      gender: user.gender,
      sign_up_date: user.created_at
    })
    const metrics = await getProductMetrics(user.id, 'inua_biashara')

    dispatch(
      setUser(omit(['user_wallet'], toSnakeCase(metrics.userDetails, { deep: true })))
    )

    dispatch(
      setMetrics({
        ...metrics.calculations,
        agentDashboard: {
          unverifiedPremises: metrics.unverifiedPremises,
          surveys: 0,
          registeredPremises: 0
        }
      })
    )
    dispatch(
      setWallet({
        ...metrics.userDetails.userWallet
      })
    )
    dispatch(setLoadingMetrics(false))
  } catch (error) {
    const regex = /User does not exist/
    if (regex.test(error.message) || error.status === 401) {
      removeCookie('user')
      window.location.href = LOGIN
      return dispatch(setUser({}))
    }

    setLoadingMetrics(false)
    Sentry.captureException(error)
  }
}

export const setLoanQualification = () => async (dispatch) => {
  try {
    const user = getUserDetails()
    const { data } = await fetchUsers(user.id)
    const { data: userDocument } = await getMedia({
      user_id: user.id,
      category: 'user_identification_document'
    })
    if (isEmpty(userDocument)) {
      setItemToLocalStorage('crl', false)
    } else {
      setItemToLocalStorage('crl', canRequestLoan(head(data)))
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const setPremiseRules = () => async (dispatch) => {
  try {
    const user = getUserDetails()
    const [{ data: shops }, { data: schools }] = await Promise.all([
      getPremises({
        user_id: user.id,
        premise_type: 'shop'
      }),
      getPremises({
        user_id: user.id,
        premise_type: 'school'
      })
    ])
    setItemToLocalStorage('sp', !isEmpty(shops))
    setItemToLocalStorage('sl', !isEmpty(schools))
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const completeRegistration = (payload) => async (dispatch) => {
  try {
    const user = getUserDetails()
    dispatch(setErrors({}))
    dispatch(setSavingUser(true))
    dispatch(setHasSavedUser(false))

    await validateCompleteRegistration(payload)
    const signedUrl = await getSignedUrl({
      filename: payload.identification.name,
      content_type: payload.identification.type
    })

    await Promise.all([
      uploadFileToS3({ url: signedUrl.url, body: payload.identification }),
      storeMedia({
        user_id: user.id,
        filename: `${signedUrl.filename}`,
        content_type: payload.identification.type,
        category: 'user_identification_document'
      }),
      updateProfile({
        userId: user.id,
        payload: omit(['identification'], payload)
      })
    ])

    dispatch(fetchMetrics())
    dispatch(setSavingUser(false))
    dispatch(setHasSavedUser(true))
  } catch (error) {
    dispatch(setSavingUser(false))
    const isValidatorError = Array.isArray(error.errors)

    if (isValidatorError) {
      const message = { [error.path]: head(error.errors) }
      return dispatch(setErrors(message))
    }
  }
}

export const pay = (payload) => async (dispatch) => {
  try {
    dispatch(setMakingPayment(true))
    dispatch(setErrors({}))

    await validatePayment(payload)

    await makePayment(payload)
    dispatch(setMakingPayment(false))
    dispatch(setHasMadePayment(true))
  } catch (error) {
    dispatch(setMakingPayment(false))
    const isValidatorError = Array.isArray(error.errors)

    if (isValidatorError) {
      const message = { [error.path]: head(error.errors) }
      return dispatch(setErrors(message))
    }
    Sentry.captureException(error)
  }
}

export const checkStkStatus = (requestId) => async (dispatch) => {
  try {
    dispatch(setCheckingPaymentStatus(true))
    dispatch(setHasCancelledRequest(false))
    dispatch(setHasTimedOutRequest(false))
    dispatch(setErrors({}))
    dispatch(setHasCheckedPaymentStatus(false))
    const { resultCode } = await stkPushStatus({ checkout_request_id: requestId })

    dispatch(setCheckingPaymentStatus(false))

    if (Number(resultCode) === 1032) {
      return dispatch(setErrors({ apiError: i18n.t('errors.stk_cancelled') }))
    } else if (Number(resultCode) === 1037) {
      return dispatch(setErrors({ apiError: i18n.t('errors.stk_timeout') }))
    } else if (Number(resultCode) === 2001) {
      return dispatch(setErrors({ apiError: i18n.t('errors.wrong_pin') }))
    }
    dispatch(setHasCheckedPaymentStatus(true))
  } catch (error) {
    dispatch(setCheckingPaymentStatus(false))
    dispatch(
      setErrors({
        apiError: error.status === 500 ? i18n.t('errors.stk_timeout') : error.message
      })
    )
  }
}

export const migrateOldData = () => async () => {
  try {
    const user = getUserDetails()
    const isOldUser = propEq('old_user', true)

    if (isOldUser) {
      await migrate(user.id)
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}

const hasDateOfBirth = pipe(prop('dateOfBirth'), (dob) => dob.length > 0)
const hasGender = pipe(prop('gender'), (gender) => gender.length > 0)
const hasIdentification = pipe(prop('identification'), (id) => id.length > 0)
const hasIncome = pipe(prop('income'), (income) => income !== null || income.length > 0)
const hasOccupation = pipe(prop('occupation'), (occupation) => occupation.length > 0)

const canRequestLoan = allPass([
  hasDateOfBirth,
  hasGender,
  hasIdentification,
  hasIncome,
  hasOccupation
])

export default authSlice.reducer
