import { createSlice } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import {
  head,
  isEmpty,
  splitEvery,
  last,
  flatten,
  dropLast,
  reject,
  equals,
  omit,
  isNil
} from 'ramda'
import { cloneDeep } from 'lodash'
import { getUserTransactions } from '../../../api/transactions'
import {
  getUserDetails,
  setItemToLocalStorage,
  getItemFromLocalStorage
} from '../../../helpers/utils'
import i18n from '../../../i18n'
import {
  validatePayment,
  validateSearchSchool,
  validateOkoaStepTwo,
  validateAddSchoolDetails,
  validateSchoolManager,
  validateUpdateLoan,
  validateAddBeneficiary,
  validateSendOtp,
  validateVerifyOtp
} from './validator'
import { makePayment, stkPushStatus, premiseWithdrawal } from '../../../api/payments'
import { getUserLoans, requestLoan, updateLoanByRequestId } from '../../../api/loans'
import { createPremise, getPremises, getPremiseWallets } from '../../../api/premises'
import { getSignedUrl, uploadFileToS3, storeMedia } from '../../../api/media'
import config from '../../../helpers/config'
import { fetchMetrics } from '../../Auth/store'
import {
  getBeneficiaryById,
  createBeneficiary,
  getBeneficiaries
} from '../../../api/beneficiaries'
import { otpVerification, sendOtp } from '../../../api/auth'
import { fetchRegisteredPremises } from '../../AgentDashboard/store'

export const initialState = {
  isLoading: true,
  recentTransactions: [],
  loadingPayment: false,
  errors: {},
  stkPushRequestId: null,
  transactions: [],
  transactionsPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  loans: [],
  loansPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  repayments: [],
  repaymentsPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  voucherTransactions: [],
  voucherTransactionsPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  okoaRequest: {},
  searchingSchool: false,
  hasSavedStepDetails: false,
  savingOkoaRequest: false,
  hasSavedOkoaRequest: false,
  schoolWallets: [],
  schoolDetails: {},
  savingSchool: false,
  hasSavedSchool: false,
  totalPremises: 0,
  schools: [],
  schoolsPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  beneficiaries: [],
  beneficiariesPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  pendingLoans: [],
  pendingLoansPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  isUpdatingLoan: false,
  hasUpdatedLoan: false,
  isSavingBeneficiary: false,
  hasSavedBeneficiary: false,
  repayingLoan: false,
  hasRepaidLoan: false,
  withdrawing: false,
  hasWithdrawn: false,
  withdrawData: {},
  hasSentOtp: false,
  sendingOtp: false,
  notification: '',
  activeTransactionsTab: 0
}

const PAGE_LIMIT = 10

export const okoaFeesSlice = createSlice({
  name: 'okoafees',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setRecentTransactions: (state, action) => {
      state.recentTransactions = action.payload
    },
    setTransactions: (state, action) => {
      state.transactions = action.payload
    },
    setLoadingPayment: (state, action) => {
      state.loadingPayment = action.payload
    },
    setErrors: (state, action) => {
      state.errors = action.payload
    },
    setStkPushRequestId: (state, action) => {
      state.stkPushRequestId = action.payload
    },
    setTransactionsPagination: (state, action) => {
      state.transactionsPagination = action.payload
    },
    setLoans: (state, action) => {
      state.loans = action.payload
    },
    setLoansPagination: (state, action) => {
      state.loansPagination = action.payload
    },
    setOkoaRequest: (state, action) => {
      state.okoaRequest = action.payload
    },
    setSearchingSchool: (state, action) => {
      state.searchingSchool = action.payload
    },
    setHasSavedStepDetails: (state, action) => {
      state.hasSavedStepDetails = action.payload
    },
    setSavingOkoaRequest: (state, action) => {
      state.savingOkoaRequest = action.payload
    },
    setHasSavedOkoaRequest: (state, action) => {
      state.hasSavedOkoaRequest = action.payload
    },
    setSchoolWallets: (state, action) => {
      state.schoolWallets = action.payload
    },
    setSchoolDetails: (state, action) => {
      state.schoolDetails = action.payload
    },
    setSavingSchool: (state, action) => {
      state.savingSchool = action.payload
    },
    setHasSavedSchool: (state, action) => {
      state.hasSavedSchool = action.payload
    },
    setTotalPremises: (state, action) => {
      state.totalPremises = action.payload
    },
    setSchools: (state, action) => {
      state.schools = action.payload
    },
    setSchoolsPagination: (state, action) => {
      state.schoolsPagination = action.payload
    },
    setBeneficiaries: (state, action) => {
      state.beneficiaries = action.payload
    },
    setBeneficiairesPagination: (state, action) => {
      state.beneficiariesPagination = action.payload
    },
    setPendingLoans: (state, action) => {
      state.pendingLoans = action.payload
    },
    setPendingLoansPagination: (state, action) => {
      state.pendingLoansPagination = action.payload
    },
    setIsUpdatingLoan: (state, action) => {
      state.isUpdatingLoan = action.payload
    },
    setHasUpdatedLoan: (state, action) => {
      state.hasUpdatedLoan = action.payload
    },
    setIsSavingBeneficiary: (state, action) => {
      state.isSavingBeneficiary = action.payload
    },
    setHasSavedBeneficiary: (state, action) => {
      state.hasSavedBeneficiary = action.payload
    },
    setRepayingLoan: (state, action) => {
      state.repayingLoan = action.payload
    },
    setHasRepaidLoan: (state, action) => {
      state.hasRepaidLoan = action.payload
    },
    setWithdrawing: (state, action) => {
      state.withdrawing = action.payload
    },
    setHasWithdrawn: (state, action) => {
      state.hasWithdrawn = action.payload
    },
    setWithdrawData: (state, action) => {
      state.withdrawData = action.payload
    },
    setHasSentOtp: (state, action) => {
      state.hasSentOtp = action.payload
    },
    setSendingOtp: (state, action) => {
      state.sendingOtp = action.payload
    },
    setNotification: (state, action) => {
      state.notification = action.payload
    },
    setRepayments: (state, action) => {
      state.repayments = action.payload
    },
    setRepaymentsPagination: (state, action) => {
      state.repaymentsPagination = action.payload
    },
    setVoucherTransactions: (state, action) => {
      state.voucherTransactions = action.payload
    },
    setVoucherTransactionsPagination: (state, action) => {
      state.voucherTransactionsPagination = action.payload
    },
    setActiveTransactionsTab: (state, action) => {
      state.activeTransactionsTab = action.payload
    }
  }
})

export const {
  setIsLoading,
  setRecentTransactions,
  setTransactions,
  setLoadingPayment,
  setErrors,
  setStkPushRequestId,
  setTransactionsPagination,
  setLoans,
  setLoansPagination,
  setOkoaRequest,
  setSearchingSchool,
  setHasSavedStepDetails,
  setSavingOkoaRequest,
  setHasSavedOkoaRequest,
  setSchoolWallets,
  setSchoolDetails,
  setSavingSchool,
  setHasSavedSchool,
  setTotalPremises,
  setSchools,
  setSchoolsPagination,
  setBeneficiaries,
  setBeneficiairesPagination,
  setPendingLoans,
  setPendingLoansPagination,
  setIsUpdatingLoan,
  setHasUpdatedLoan,
  setIsSavingBeneficiary,
  setHasSavedBeneficiary,
  setRepayingLoan,
  setHasRepaidLoan,
  setWithdrawing,
  setHasWithdrawn,
  setWithdrawData,
  setHasSentOtp,
  setSendingOtp,
  setNotification,
  setRepayments,
  setRepaymentsPagination,
  setVoucherTransactions,
  setVoucherTransactionsPagination,
  setActiveTransactionsTab
} = okoaFeesSlice.actions

const attachBeneficiaryToLoan = async (loan) => {
  try {
    const beneficiary = await getBeneficiaryById(loan.beneficiaryId)
    return Object.assign({}, loan, {
      beneficiary: {
        name: `${beneficiary.firstName} ${beneficiary.surname}`,
        nemis: beneficiary.nemisNumber
      }
    })
  } catch (err) {
    return loan
  }
}

const attachPremiseToLoan = async (loan) => {
  try {
    const { data } = await getPremises({ paylend_number: loan.paylendNumber.toString() })
    return Object.assign({}, loan, {
      premise: {
        name: data[0].premiseName,
        location: data[0].premiseAddress
      }
    })
  } catch (err) {
    return loan
  }
}

export const repayLoan = (payload) => async (dispatch, getState) => {
  try {
    const { wallet, locationDetails } = getState().auth
    dispatch(setLoadingPayment(true))
    dispatch(setErrors({}))
    dispatch(setHasRepaidLoan(false))

    await validatePayment(
      {
        ...payload,
        transaction_mode: 'stk_push'
      },
      wallet.okoaFees.payable
    )

    const res = await makePayment({
      ...payload,
      country: locationDetails.country_name,
      user_id: getUserDetails().id,
      action_type: 'repay_loan_by_user',
      reference_occasion: 'okoa_fees',
      transaction_mode: 'stk_push'
    })

    dispatch(setLoadingPayment(false))
    dispatch(setHasRepaidLoan(true))
    dispatch(setStkPushRequestId(res.checkoutRequestId))
  } catch (error) {
    Sentry.captureException(error)
    dispatch(setLoadingPayment(false))
    const isValidatorError = Array.isArray(error.errors)

    if (isValidatorError) {
      const message = { [error.path]: head(error.errors) }
      return dispatch(setErrors(message))
    }
    dispatch(setErrors({ apiError: error.message }))
  }
}

export const getStkPushStatus = (requestId) => async (dispatch) => {
  try {
    dispatch(setLoadingPayment(true))

    await stkPushStatus({ checkout_request_id: requestId })

    dispatch(setLoadingPayment(false))
    dispatch(setStkPushRequestId(null))
  } catch (error) {
    dispatch(setLoadingPayment(false))
  }
}

export const fetchRecentTransactions = (params) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true))
    const { id: userId } = getUserDetails()
    const { data } = await getUserTransactions({
      user_id: userId,
      ...(params.premise_owner_id && {
        premise_owner_id: params.premise_owner_id
      }),
      reference_occasion: 'okoa_fees',
      limit: PAGE_LIMIT,
      sort: '-created_at',
      ...(!isEmpty(params) && { ...params })
    })

    dispatch(setRecentTransactions(data))

    dispatch(setIsLoading(false))
  } catch (error) {
    dispatch(setIsLoading(false))
    Sentry.captureException(error)
  }
}

export const fetchTransactions =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { id: userId } = getUserDetails()
      const { data, count } = await getUserTransactions({
        user_id: userId,
        ...(params.premise_owner_id && {
          premise_owner_id: params.premise_owner_id
        }),
        reference_occasion: 'okoa_fees',
        limit: PAGE_LIMIT,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setTransactions(data))

      dispatch(
        setTransactionsPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }

export function loadNextTransactions(params = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))
      const { transactions, transactionsPagination: pagination } = cloneDeep(
        getState().okoaFees
      )
      const transaction = transactions[transactions.length - 1]
      const cursor = transaction.id
      const { data, count } = await getUserTransactions({
        user_id: getUserDetails().id,
        ...(params.premise_owner_id && {
          premise_owner_id: params.premise_owner_id
        }),
        reference_occasion: 'okoa_fees',
        limit: PAGE_LIMIT,
        after: cursor,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setTransactions(data))

      dispatch(
        setTransactionsPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...transactions],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }
}

export function loadPrevTransactions(params = {}) {
  return async (dispatch, getState) => {
    const { transactionsPagination: pagination, transactions: stateTransactions } =
      getState().okoaFees
    const transactions = pagination.paginated
    const transactionChunks = splitEvery(PAGE_LIMIT, transactions)

    if (isEmpty(transactionChunks) || transactionChunks.length === 1) {
      try {
        dispatch(setIsLoading(true))

        const { data, count } = await getUserTransactions({
          user_id: getUserDetails().id,
          ...(params.premise_owner_id && {
            premise_owner_id: params.premise_owner_id
          }),
          reference_occasion: 'okoa_fees',
          limit: PAGE_LIMIT,
          sort: '-created_at',
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setTransactions(data))

        dispatch(
          setTransactionsPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
      }
    } else {
      const previousTransactions = last(transactionChunks)
      dispatch(setTransactions(previousTransactions))

      dispatch(
        setTransactionsPagination({
          hasNext: transactions.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (stateTransactions.length < PAGE_LIMIT
              ? stateTransactions.length
              : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, transactionChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export const fetchLoans =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { id: userId } = getUserDetails()
      const okoaUser = getItemFromLocalStorage('okoa_fees_user') || 'parent'
      const isSchool = okoaUser === 'school'

      const { data, count } = await getUserLoans({
        user_id: userId,
        ...(isSchool && { premise_owner_id: userId }),
        loan_type: 'okoa_fees',
        limit: PAGE_LIMIT,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      const loansWithBeneficiaries = await Promise.all(
        data.map((loan) => attachBeneficiaryToLoan(loan))
      )

      const formattedLoans = await Promise.all(
        loansWithBeneficiaries.map((loan) => attachPremiseToLoan(loan))
      )

      dispatch(setLoans(formattedLoans))

      dispatch(
        setLoansPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }

export function loadNextLoans(params = {}) {
  return async (dispatch, getState) => {
    try {
      const okoaUser = getItemFromLocalStorage('okoa_fees_user') || 'parent'
      const isSchool = okoaUser === 'school'
      const { loans, loansPagination: pagination } = cloneDeep(getState().okoaFees)
      const loan = loans[loans.length - 1]
      const cursor = loan.id

      const { data, count } = await getUserLoans({
        user_id: getUserDetails().id,
        ...(isSchool && { premise_owner_id: getUserDetails().id }),
        loan_type: 'okoa_fees',
        limit: PAGE_LIMIT,
        after: cursor,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      const loansWithBeneficiaries = await Promise.all(
        data.map((loan) => attachBeneficiaryToLoan(loan))
      )

      const formattedLoans = await Promise.all(
        loansWithBeneficiaries.map((loan) => attachPremiseToLoan(loan))
      )

      dispatch(setLoans(formattedLoans))

      dispatch(
        setLoansPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...loans],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }
}

export function loadPrevLoans(params = {}) {
  return async (dispatch, getState) => {
    const { loansPagination: pagination, loans: stateLoans } = getState().okoaFees
    const loans = pagination.paginated
    const loanChunks = splitEvery(PAGE_LIMIT, loans)

    const okoaUser = getItemFromLocalStorage('okoa_fees_user') || 'parent'
    const isSchool = okoaUser === 'school'

    if (isEmpty(loanChunks) || loanChunks.length === 1) {
      try {
        const { data, count } = await getUserLoans({
          user_id: getUserDetails().id,
          ...(isSchool && { premise_owner_id: getUserDetails().id }),
          loan_type: 'okoa_fees',
          limit: PAGE_LIMIT,
          sort: '-created_at',
          ...(!isEmpty(params) && { ...params })
        })

        const loansWithBeneficiaries = await Promise.all(
          data.map((loan) => attachBeneficiaryToLoan(loan))
        )

        const formattedLoans = await Promise.all(
          loansWithBeneficiaries.map((loan) => attachPremiseToLoan(loan))
        )

        dispatch(setLoans(formattedLoans))

        dispatch(
          setLoansPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
      }
    } else {
      const previousLoans = last(loanChunks)
      dispatch(setLoans(previousLoans))

      dispatch(
        setLoansPagination({
          hasNext: loans.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (stateLoans.length < PAGE_LIMIT ? stateLoans.length : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, loanChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export const fetchRepayments =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { id: userId } = getUserDetails()
      const { data, count } = await getUserTransactions({
        user_id: userId,
        reference_occasion: 'okoa_fees',
        action_type: 'repay_loan_by_user',
        limit: PAGE_LIMIT,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setRepayments(data))

      dispatch(
        setRepaymentsPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }

export function loadNextRepayments(params = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))
      const { repayments: transactions, repaymentsPagination: pagination } = cloneDeep(
        getState().okoaFees
      )
      const transaction = transactions[transactions.length - 1]
      const cursor = transaction.id
      const { data, count } = await getUserTransactions({
        user_id: getUserDetails().id,
        reference_occasion: 'okoa_fees',
        action_type: 'repay_loan_by_user',
        limit: PAGE_LIMIT,
        after: cursor,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setRepayments(data))

      dispatch(
        setRepaymentsPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...transactions],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function loadPrevRepayments(params = {}) {
  return async (dispatch, getState) => {
    const { repaymentsPagination: pagination, repayments: stateTransactions } =
      getState().okoaFees
    const transactions = pagination.paginated
    const transactionChunks = splitEvery(PAGE_LIMIT, transactions)

    if (isEmpty(transactionChunks) || transactionChunks.length === 1) {
      try {
        dispatch(setIsLoading(true))

        const { data, count } = await getUserTransactions({
          user_id: getUserDetails().id,
          reference_occasion: 'okoa_fees',
          action_type: 'repay_loan_by_user',
          limit: PAGE_LIMIT,
          sort: '-created_at',
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setRepayments(data))

        dispatch(
          setRepaymentsPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
        dispatch(setErrors({ apiError: error.message }))
      }
    } else {
      const previousTransactions = last(transactionChunks)
      dispatch(setRepayments(previousTransactions))

      dispatch(
        setRepaymentsPagination({
          hasNext: transactions.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (stateTransactions.length < PAGE_LIMIT
              ? stateTransactions.length
              : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, transactionChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export const fetchVoucherTransactions =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { id: userId } = getUserDetails()
      const { data, count } = await getUserTransactions({
        user_id: userId,
        reference_occasion: 'pata_voucher',
        action_type: 'redeem_voucher',
        sub_wallet: 'school_fees',
        premises_owner_id: userId,
        limit: PAGE_LIMIT,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setVoucherTransactions(data))

      dispatch(
        setVoucherTransactionsPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }

export function loadNextVoucherTransactions(params = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))
      const {
        voucherTransactions: transactions,
        voucherTransactionsPagination: pagination
      } = cloneDeep(getState().okoaFees)
      const transaction = transactions[transactions.length - 1]
      const cursor = transaction.id
      const { data, count } = await getUserTransactions({
        user_id: getUserDetails().id,
        reference_occasion: 'pata_voucher',
        action_type: 'redeem_voucher',
        sub_wallet: 'school_fees',
        premises_owner_id: getUserDetails().id,
        limit: PAGE_LIMIT,
        after: cursor,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setVoucherTransactions(data))

      dispatch(
        setVoucherTransactionsPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...transactions],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function loadPrevVoucherTransactions(params = {}) {
  return async (dispatch, getState) => {
    const {
      voucherTransactionsPagination: pagination,
      voucherTransactions: stateTransactions
    } = getState().okoaFees
    const transactions = pagination.paginated
    const transactionChunks = splitEvery(PAGE_LIMIT, transactions)

    if (isEmpty(transactionChunks) || transactionChunks.length === 1) {
      try {
        dispatch(setIsLoading(true))

        const { data, count } = await getUserTransactions({
          user_id: getUserDetails().id,
          reference_occasion: 'pata_voucher',
          action_type: 'redeem_voucher',
          sub_wallet: 'school_fees',
          premises_owner_id: getUserDetails().id,
          limit: PAGE_LIMIT,
          sort: '-created_at',
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setVoucherTransactions(data))

        dispatch(
          setVoucherTransactionsPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
        dispatch(setErrors({ apiError: error.message }))
      }
    } else {
      const previousTransactions = last(transactionChunks)
      dispatch(setVoucherTransactions(previousTransactions))

      dispatch(
        setVoucherTransactionsPagination({
          hasNext: transactions.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (stateTransactions.length < PAGE_LIMIT
              ? stateTransactions.length
              : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, transactionChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export const fetchSchools =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { id: userId } = getUserDetails()
      const [{ data, count }, { data: allPremises }] = await Promise.all([
        getPremises({
          user_id: userId,
          premise_type: 'school',
          limit: PAGE_LIMIT,
          ...(!isEmpty(params) && { ...params })
        }),
        getPremises({
          user_id: userId,
          limit: PAGE_LIMIT,
          ...(!isEmpty(params) && { ...params })
        })
      ])

      dispatch(setSchools(data))
      dispatch(setTotalPremises(allPremises.length))

      dispatch(
        setSchoolsPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }

export function loadNextSchools(params = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))
      const { schools, schoolsPagination: pagination } = cloneDeep(getState().okoaFees)
      const school = [schools.length - 1]
      const cursor = school.id
      const { data, count } = await getPremises({
        user_id: getUserDetails().id,
        premise_type: 'school',
        limit: PAGE_LIMIT,
        after: cursor,
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setSchools(data))

      dispatch(
        setSchoolsPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...schools],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }
}

export function loadPrevSchools(params = {}) {
  return async (dispatch, getState) => {
    const { schoolsPagination: pagination, schools: stateSchools } = getState().okoaFees
    const schools = pagination.paginated
    const schoolsChunks = splitEvery(PAGE_LIMIT, schools)

    if (isEmpty(schoolsChunks) || schoolsChunks.length === 1) {
      try {
        dispatch(setIsLoading(true))

        const { data, count } = await getPremises({
          user_id: getUserDetails().id,
          premise_type: 'school',
          limit: PAGE_LIMIT,
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setSchools(data))

        dispatch(
          setSchoolsPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
      }
    } else {
      const previousSchools = last(schoolsChunks)
      dispatch(setSchools(previousSchools))

      dispatch(
        setSchoolsPagination({
          hasNext: schools.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (stateSchools.length < PAGE_LIMIT ? stateSchools.length : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, schoolsChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export function searchSchoolByNumber(payload) {
  return async (dispatch, getState) => {
    try {
      dispatch(setSearchingSchool(true))
      dispatch(setErrors({}))
      await validateSearchSchool(payload)
      const prevOkoaRequestState = getState().okoaFees.okoaRequest
      const { data } = await getPremises({
        premise_type: 'school',
        ...payload,
        limit: 500000
      })

      dispatch(
        setOkoaRequest({
          ...prevOkoaRequestState,
          chool: {
            premiseName: data[0].premiseName,
            premiseAddress: data[0].premiseAddress
          },
          paylend_number: payload.paylend_number
        })
      )

      dispatch(setSearchingSchool(false))
    } catch (error) {
      dispatch(setSearchingSchool(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      Sentry.captureException(error)
    }
  }
}

export function submitOkoaAmount(data) {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))
      dispatch(setHasSavedStepDetails(false))
      await validateOkoaStepTwo(data)
      dispatch(setErrors({}))
      const { okoaRequest } = getState().okoaFees
      dispatch(setOkoaRequest({ ...okoaRequest, ...data }))
      dispatch(setIsLoading(false))
      dispatch(setHasSavedStepDetails(true))
    } catch (error) {
      dispatch(setHasSavedStepDetails(false))
      dispatch(setIsLoading(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
    }
  }
}

export function submitOkoaRequest(data) {
  return async (dispatch, getState) => {
    try {
      dispatch(setHasSavedOkoaRequest(false))
      dispatch(setSavingOkoaRequest(true))

      const user = getUserDetails()
      const { okoaRequest } = getState().okoaFees

      await validateVerifyOtp({
        code: data.otp,
        phone: user.phone,
        type: 'verify'
      })

      await requestLoan({
        user_id: user.id,
        loan_type: 'okoa_fees',
        paylend_number: okoaRequest.paylend_number,
        repayment_plan: okoaRequest.repayment_plan,
        amount_borrowed: okoaRequest.amount_borrowed,
        beneficiary_id: okoaRequest.beneficiary_id,
        otp: data.otp
      })

      dispatch(fetchRecentTransactions({}))
      dispatch(fetchMetrics())

      dispatch(setSavingOkoaRequest(false))
      dispatch(setHasSavedOkoaRequest(true))
      dispatch(setOkoaRequest({}))
    } catch (error) {
      dispatch(setSavingOkoaRequest(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function fetchSchoolWallets() {
  return async (dispatch) => {
    try {
      const user = getUserDetails()
      const [{ data: schools }, { data }] = await Promise.all([
        getPremises({
          user_id: user.id,
          premise_type: 'school',
          limit: PAGE_LIMIT
        }),
        getPremiseWallets({
          user_id: user.id
        })
      ])

      let ids = []
      if (!isEmpty(schools)) {
        ids = schools.map((school) => school.id)
      }

      dispatch(setIsLoading(true))
      const formattedData = data.filter((wallet) => ids.includes(wallet.premiseId))
      dispatch(setSchoolWallets(formattedData))
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }
}

export function addSchoolDetails(data) {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true))
      dispatch(setHasSavedStepDetails(false))
      await validateAddSchoolDetails(data)
      dispatch(setErrors({}))
      dispatch(setIsLoading(false))
      dispatch(setHasSavedStepDetails(true))
    } catch (error) {
      dispatch(setHasSavedStepDetails(false))
      dispatch(setIsLoading(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
    }
  }
}

export function saveSchool(data) {
  return async (dispatch, getState) => {
    try {
      dispatch(setHasSavedSchool(false))
      dispatch(setSavingSchool(true))

      const agentState = getState().agent.currentPremiseState

      const user = agentState.user || getUserDetails()

      if (isNil(data.registering_agent)) {
        await validateSchoolManager(data)
      }

      const filteredData = reject(equals(''))(data)

      let identificationSignedUrl

      const [permitSignedUrl, schoolImageSignedUrl, statementSignedUrl] =
        await Promise.all([
          getSignedUrl({
            filename: data.business_permit.name,
            content_type: data.business_permit.type
          }),
          getSignedUrl({
            filename: data.premise_front_image.name,
            content_type: data.premise_front_image.type
          }),
          getSignedUrl({
            filename: data.mpesa_statement.name,
            content_type: data.mpesa_statement.type
          })
        ])

      if (typeof data.manager_identification === 'object') {
        identificationSignedUrl = await getSignedUrl({
          filename: data.manager_identification.name,
          content_type: data.manager_identification.type
        })

        await uploadFileToS3({
          url: identificationSignedUrl.url,
          body: data.manager_identification
        })

        await storeMedia({
          user_id: user.id,
          filename: `${identificationSignedUrl.filename}`,
          content_type: data.manager_identification.type,
          category: 'school_manager_identification_document'
        })
      }

      await Promise.all([
        uploadFileToS3({ url: permitSignedUrl.url, body: data.business_permit }),
        uploadFileToS3({ url: schoolImageSignedUrl.url, body: data.premise_front_image }),
        uploadFileToS3({
          url: statementSignedUrl.url,
          body: data.mpesa_statement
        }),
        storeMedia({
          user_id: user.id,
          filename: `${permitSignedUrl.filename}`,
          content_type: data.business_permit.type,
          category: 'school_registration_certificate'
        }),
        storeMedia({
          user_id: user.id,
          filename: `${schoolImageSignedUrl.filename}`,
          content_type: data.premise_front_image.type,
          category: 'school_administration_block_photo'
        }),
        storeMedia({
          user_id: user.id,
          filename: `${statementSignedUrl.filename}`,
          content_type: data.mpesa_statement.type,
          category: 'school_mpesa_statement'
        }),
        createPremise({
          ...filteredData,
          business_permit: `${config('S3_BUCKET_URL')}/${permitSignedUrl.filename}`,
          premise_front_image: `${config('S3_BUCKET_URL')}/${
            schoolImageSignedUrl.filename
          }`,
          ...(typeof data.manager_identification === 'object'
            ? {
                manager_identification: `${config('S3_BUCKET_URL')}/${
                  identificationSignedUrl.filename
                }`
              }
            : {
                manager_identification: data.manager_identification
              }),
          mpesa_statement: `${config('S3_BUCKET_URL')}/${statementSignedUrl.filename}`,
          user_id: user.id,
          premise_type: 'school'
        })
      ])

      setItemToLocalStorage('sl', true)

      dispatch(fetchSchools())
      dispatch(fetchSchoolWallets())

      dispatch(setSavingSchool(false))
      dispatch(setHasSavedSchool(true))
      dispatch(setSchoolDetails({}))
      if (!isNil(data.registering_agent)) {
        dispatch(fetchRegisteredPremises())
      }
    } catch (error) {
      dispatch(setSavingSchool(false))
      Sentry.captureException(error)
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function updateLoan(data) {
  return async (dispatch) => {
    try {
      dispatch(setHasUpdatedLoan(false))
      dispatch(setIsUpdatingLoan(true))

      await validateUpdateLoan(data, data.max_amount)
      await updateLoanByRequestId({
        requestId: data.request_id,
        payload: omit(['request_id'], data)
      })

      dispatch(fetchLoans())
      dispatch(setHasUpdatedLoan(true))
      dispatch(setIsUpdatingLoan(false))
    } catch (error) {
      dispatch(setIsUpdatingLoan(false))
      Sentry.captureException(error)
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export const fetchBeneficiaries =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { id: userId } = getUserDetails()
      const [{ data, count }] = await Promise.all([
        getBeneficiaries({
          user_id: userId,
          limit: PAGE_LIMIT,
          ...(!isEmpty(params) && { ...params })
        })
      ])

      const formattedData = await Promise.all(
        data.map((beneficiary) => attachPremiseToLoan(beneficiary))
      )

      dispatch(setBeneficiaries(formattedData))

      dispatch(
        setBeneficiairesPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }

export function loadNextBeneficiaries(params = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))
      const { beneficiaries, beneficiariesPagination: pagination } = cloneDeep(
        getState().okoaFees
      )
      const beneficiary = [beneficiaries.length - 1]
      const cursor = beneficiary.id
      const { data, count } = await getBeneficiaries({
        user_id: getUserDetails().id,
        limit: PAGE_LIMIT,
        after: cursor,
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setBeneficiaries(data))

      dispatch(
        setBeneficiairesPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...beneficiaries],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }
}

export function loadPrevBeneficiaries(params = {}) {
  return async (dispatch, getState) => {
    const { beneficiariesPagination: pagination, beneficiaries: stateBeneficiaries } =
      getState().okoaFees
    const beneficiaries = pagination.paginated
    const beneficiariesChunks = splitEvery(PAGE_LIMIT, beneficiaries)

    if (isEmpty(beneficiariesChunks) || beneficiariesChunks.length === 1) {
      try {
        dispatch(setIsLoading(true))

        const { data, count } = await getBeneficiaries({
          user_id: getUserDetails().id,
          limit: PAGE_LIMIT,
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setBeneficiaries(data))

        dispatch(
          setBeneficiairesPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
      }
    } else {
      const previousBeneficiaries = last(beneficiariesChunks)
      dispatch(setBeneficiaries(previousBeneficiaries))

      dispatch(
        setBeneficiairesPagination({
          hasNext: beneficiaries.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (stateBeneficiaries.length < PAGE_LIMIT
              ? stateBeneficiaries.length
              : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, beneficiariesChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export function saveBeneficiary(data) {
  return async (dispatch) => {
    try {
      const user = getUserDetails()
      dispatch(setHasSavedBeneficiary(false))
      dispatch(setIsSavingBeneficiary(true))

      await validateAddBeneficiary(data)

      const filteredData = reject(equals(''))(data)

      if (filteredData.student_photo) {
        const [certificateSignedUrl, studentPhotoSignedUrl] = await Promise.all([
          getSignedUrl({
            filename: data.birth_certificate_photo.name,
            content_type: data.birth_certificate_photo.type
          }),
          getSignedUrl({
            filename: data.student_photo.name,
            content_type: data.student_photo.type
          })
        ])

        await Promise.all([
          uploadFileToS3({
            url: certificateSignedUrl.url,
            body: data.birth_certificate_photo
          }),
          uploadFileToS3({ url: studentPhotoSignedUrl.url, body: data.student_photo }),
          storeMedia({
            user_id: user.id,
            filename: `${certificateSignedUrl.filename}`,
            content_type: data.birth_certificate_photo.type,
            category: 'birth_certificate_photo'
          }),
          storeMedia({
            user_id: user.id,
            filename: `${studentPhotoSignedUrl.filename}`,
            content_type: data.student_photo.type,
            category: 'student_photo'
          }),
          createBeneficiary({
            ...filteredData,
            student_photo: `${config('S3_BUCKET_URL')}/${studentPhotoSignedUrl.filename}`,
            birth_certificate_photo: `${config('S3_BUCKET_URL')}/${
              certificateSignedUrl.filename
            }`,
            user_id: user.id
          })
        ])
      } else {
        const [certificateSignedUrl] = await Promise.all([
          getSignedUrl({
            filename: data.birth_certificate_photo.name,
            content_type: data.birth_certificate_photo.type
          })
        ])

        await Promise.all([
          uploadFileToS3({
            url: certificateSignedUrl.url,
            body: data.birth_certificate_photo
          }),
          storeMedia({
            user_id: user.id,
            filename: `${certificateSignedUrl.filename}`,
            content_type: data.birth_certificate_photo.type,
            category: 'birth_certificate_photo'
          }),
          createBeneficiary({
            ...filteredData,
            birth_certificate_photo: `${config('S3_BUCKET_URL')}/${
              certificateSignedUrl.filename
            }`,
            user_id: user.id,
            premise_type: 'school'
          })
        ])
      }

      dispatch(fetchBeneficiaries({}))

      dispatch(setIsSavingBeneficiary(false))
      dispatch(setHasSavedBeneficiary(true))
    } catch (error) {
      dispatch(setIsSavingBeneficiary(false))
      Sentry.captureException(error)
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function withdraw(data) {
  return async (dispatch, getState) => {
    try {
      dispatch(setErrors({}))
      dispatch(setWithdrawing(true))
      dispatch(setHasWithdrawn(false))

      const user = getUserDetails()
      const { withdrawData, schoolWallets } = getState().okoaFees

      await validateVerifyOtp({
        type: 'verify',
        code: data.code,
        phone: user.phone
      })

      await otpVerification({
        phone: user.phone,
        ...data
      })

      const wallet = schoolWallets.find((w) => w.id === withdrawData.premise)

      await premiseWithdrawal({
        amount: withdrawData.amount,
        phone: user.phone,
        premise_id: wallet.premiseId,
        premise_wallet_id: wallet.id,
        reference_occasion: 'inua_biashara',
        user_id: user.id
      })

      dispatch(setWithdrawing(false))
      dispatch(setHasWithdrawn(true))
    } catch (error) {
      dispatch(setWithdrawing(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function onSendOtp(data) {
  return async (dispatch) => {
    try {
      dispatch(setErrors({}))
      dispatch(setSendingOtp(true))
      dispatch(setNotification(''))

      await validateSendOtp({
        phone: getUserDetails().phone
      })
      await sendOtp(data)

      dispatch(setErrors({}))
      dispatch(setSendingOtp(false))
      dispatch(setHasSentOtp(true))
      dispatch(setNotification(i18n.t('common.otp_sent')))
    } catch (error) {
      dispatch(setSendingOtp(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export default okoaFeesSlice.reducer
