import { createSlice } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { isEmpty, splitEvery, last, flatten, dropLast, head, reverse } from 'ramda'
import { cloneDeep } from 'lodash'
import Pusher from 'pusher-js'
import { getUserTransactions } from '../../../api/transactions'
import {
  getItemFromLocalStorage,
  getUserDetails,
  snakeCase
} from '../../../helpers/utils'
import {
  addMember,
  createInitiative,
  getInitiativeById,
  getInitiativeWallet,
  getUserInitiatives
} from '../../../api/initiatives'
import { fetchMetrics } from '../../Auth/store'
import {
  validateInitiative,
  validateSearchInitiative,
  validatePayment,
  validateWithdrawal
} from './validator'
import { getSignedUrl, uploadFileToS3, storeMedia } from '../../../api/media'
import { authChat, getMessages, joinChat, postMessage } from '../../../api/chat'
import config from '../../../helpers/config'
import { createContribution } from '../../../api/contributions'
import { makePayment, premiseWithdrawal } from '../../../api/payments'
import i18n from '../../../i18n'

export const initialState = {
  isLoading: true,
  recentTransactions: [],
  errors: {},
  transactions: [],
  transactionsPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  initiatives: [],
  initiativesPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  userInitiatives: [],
  userInitiativesPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  creatingInitiative: false,
  hasCreatedInitiative: false,
  contributionState: {},
  isContributing: false,
  hasContributed: false,
  stkPushRequestId: '',
  addingMember: false,
  hasAddedMember: false,
  initiativeState: {},
  searchingInitiative: false,
  chats: [],
  chatsPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  chatInitiative: {},
  joiningChat: false,
  sendingMessage: false,
  hasSentMessage: false,
  withdrawing: false,
  hasWithdrawn: false,
  loadingChats: false
}

const PAGE_LIMIT = 10

export const changishaSlice = createSlice({
  name: 'changisha',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setRecentTransactions: (state, action) => {
      state.recentTransactions = action.payload
    },
    setErrors: (state, action) => {
      state.errors = action.payload
    },
    setTransactionsPagination: (state, action) => {
      state.transactionsPagination = action.payload
    },
    setTransactions: (state, action) => {
      state.transactions = action.payload
    },
    setInitiativesPagination: (state, action) => {
      state.initiativesPagination = action.payload
    },
    setInitiatives: (state, action) => {
      state.initiatives = action.payload
    },
    setCreatingInitiative: (state, action) => {
      state.creatingInitiative = action.payload
    },
    setHasCreatedInitiative: (state, action) => {
      state.hasCreatedInitiative = action.payload
    },
    setUserInitiatives: (state, action) => {
      state.userInitiatives = action.payload
    },
    setUserInitiativesPagination: (state, action) => {
      state.userInitiativesPagination = action.payload
    },
    setContributionState: (state, action) => {
      state.contributionState = action.payload
    },
    setIsContributing: (state, action) => {
      state.isContributing = action.payload
    },
    setHasContributed: (state, action) => {
      state.hasContributed = action.payload
    },
    setStkPushRequestId: (state, action) => {
      state.stkPushRequestId = action.payload
    },
    setAddingMember: (state, action) => {
      state.addingMember = action.payload
    },
    setHasAddedMember: (state, action) => {
      state.hasAddedMember = action.payload
    },
    setInitiativeState: (state, action) => {
      state.initiativeState = action.payload
    },
    setSearchingInitiative: (state, action) => {
      state.searchingInitiative = action.payload
    },
    setChats: (state, action) => {
      state.chats = action.payload
    },
    setChatInitiative: (state, action) => {
      state.chatInitiative = action.payload
    },
    setJoiningChat: (state, action) => {
      state.joiningChat = action.payload
    },
    setChatsPagination: (state, action) => {
      state.chatsPagination = action.payload
    },
    setSendingMessage: (state, action) => {
      state.sendingMessage = action.payload
    },
    setHasSentMessage: (state, action) => {
      state.hasSentMessage = action.payload
    },
    setWithdrawing: (state, action) => {
      state.withdrawing = action.payload
    },
    setHasWithdrawn: (state, action) => {
      state.hasWithdrawn = action.payload
    },
    setLoadingChats: (state, action) => {
      state.loadingChats = action.payload
    }
  }
})

export const {
  setIsLoading,
  setRecentTransactions,
  setErrors,
  setTransactionsPagination,
  setTransactions,
  setInitiatives,
  setInitiativesPagination,
  setUserInitiatives,
  setUserInitiativesPagination,
  setCreatingInitiative,
  setHasCreatedInitiative,
  setContributionState,
  setIsContributing,
  setHasContributed,
  setStkPushRequestId,
  setAddingMember,
  setHasAddedMember,
  setInitiativeState,
  setSearchingInitiative,
  setChats,
  setChatInitiative,
  setJoiningChat,
  setChatsPagination,
  setSendingMessage,
  setHasSentMessage,
  setWithdrawing,
  setHasWithdrawn,
  setLoadingChats
} = changishaSlice.actions

export const fetchRecentTransactions = (params) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true))
    const { id: userId } = getUserDetails()
    const { data } = await getUserTransactions({
      user_id: userId,
      reference_occasion: 'changisha',
      limit: PAGE_LIMIT,
      sort: '-created_at',
      ...(!isEmpty(params) && { ...params })
    })

    dispatch(setRecentTransactions(data))

    dispatch(setIsLoading(false))
  } catch (error) {
    dispatch(setIsLoading(false))
    Sentry.captureException(error)
  }
}

export const fetchTransactions =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { id: userId } = getUserDetails()
      const { data, count } = await getUserTransactions({
        user_id: userId,
        reference_occasion: 'changisha',
        limit: PAGE_LIMIT,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setTransactions(data))

      dispatch(
        setTransactionsPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }

export function loadNextTransactions(params = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))
      const { transactions, transactionsPagination: pagination } = cloneDeep(
        getState().changisha
      )
      const transaction = transactions[transactions.length - 1]
      const cursor = transaction.id
      const { data, count } = await getUserTransactions({
        user_id: getUserDetails().id,
        reference_occasion: 'changisha',
        limit: PAGE_LIMIT,
        after: cursor,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setTransactions(data))

      dispatch(
        setTransactionsPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...transactions],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }
}

export function loadPrevTransactions(params = {}) {
  return async (dispatch, getState) => {
    const { transactionsPagination: pagination, transactions: stateTransactions } =
      getState().changisha
    const transactions = pagination.paginated
    const transactionChunks = splitEvery(PAGE_LIMIT, transactions)

    if (isEmpty(transactionChunks) || transactionChunks.length === 1) {
      try {
        dispatch(setIsLoading(true))

        const { data, count } = await getUserTransactions({
          user_id: getUserDetails().id,
          reference_occasion: 'changisha',
          limit: PAGE_LIMIT,
          sort: '-created_at',
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setTransactions(data))

        dispatch(
          setTransactionsPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
      }
    } else {
      const previousTransactions = last(transactionChunks)
      dispatch(setTransactions(previousTransactions))

      dispatch(
        setTransactionsPagination({
          hasNext: transactions.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (stateTransactions.length < PAGE_LIMIT
              ? stateTransactions.length
              : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, transactionChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export const fetchUserInitiatives =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { id: userId } = getUserDetails()
      const { data, count } = await getUserInitiatives({
        members_id: userId,
        ...(params.limit ? { limit: params.limit } : { limit: PAGE_LIMIT }),
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setUserInitiatives(data))

      dispatch(
        setUserInitiativesPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }

export function loadNextUserInitiatives(params = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))
      const { userInitiatives, userInitiativesPagination: pagination } = cloneDeep(
        getState().changisha
      )
      const initiative = userInitiatives[userInitiatives.length - 1]
      const cursor = initiative.id
      const { data, count } = await getUserInitiatives({
        user_id: getUserDetails().id,
        limit: PAGE_LIMIT,
        after: cursor,
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setUserInitiatives(data))

      dispatch(
        setUserInitiativesPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...userInitiatives],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }
}

export function loadPrevUserInitiatives(params = {}) {
  return async (dispatch, getState) => {
    const { userInitiativesPagination: pagination, userInitiatives: stateInitiatives } =
      getState().changisha
    const initiatives = pagination.paginated
    const initiativeChunks = splitEvery(PAGE_LIMIT, initiatives)

    if (isEmpty(initiativeChunks) || initiativeChunks.length === 1) {
      try {
        dispatch(setIsLoading(true))

        const { data, count } = await getUserInitiatives({
          user_id: getUserDetails().id,
          limit: PAGE_LIMIT,
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setUserInitiatives(data))

        dispatch(
          setUserInitiativesPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
      }
    } else {
      const previousInitiatives = last(initiativeChunks)
      dispatch(setUserInitiatives(previousInitiatives))

      dispatch(
        setUserInitiativesPagination({
          hasNext: initiatives.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (stateInitiatives.length < PAGE_LIMIT ? stateInitiatives.length : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, initiativeChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export const fetchInitiatives =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { data, count } = await getUserInitiatives({
        ...(params.limit ? { limit: params.limit } : { limit: PAGE_LIMIT }),
        visibility: 'public',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setInitiatives(data))

      dispatch(
        setInitiativesPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }

export function loadNextInitiatives(params = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))
      const { initiatives, initiativesPagination: pagination } = cloneDeep(
        getState().changisha
      )
      const initiative = initiatives[initiatives.length - 1]
      const cursor = initiative.id
      const { data, count } = await getUserInitiatives({
        limit: PAGE_LIMIT,
        after: cursor,
        visibility: 'public',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setInitiatives(data))

      dispatch(
        setInitiativesPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...initiatives],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }
}

export function loadPrevInitiatives(params = {}) {
  return async (dispatch, getState) => {
    const { initiativesPagination: pagination, initiatives: stateInitiatives } =
      getState().changisha
    const initiatives = pagination.paginated
    const initiativeChunks = splitEvery(PAGE_LIMIT, initiatives)

    if (isEmpty(initiativeChunks) || initiativeChunks.length === 1) {
      try {
        dispatch(setIsLoading(true))

        const { data, count } = await getUserInitiatives({
          limit: PAGE_LIMIT,
          visibility: 'public',
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setInitiatives(data))

        dispatch(
          setInitiativesPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
      }
    } else {
      const previousInitiatives = last(initiativeChunks)
      dispatch(setInitiatives(previousInitiatives))

      dispatch(
        setInitiativesPagination({
          hasNext: initiatives.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (stateInitiatives.length < PAGE_LIMIT ? stateInitiatives.length : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, initiativeChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export function addInitiative(payload) {
  return async (dispatch) => {
    try {
      dispatch(setCreatingInitiative(true))
      dispatch(setHasCreatedInitiative(false))
      dispatch(setErrors({}))

      await validateInitiative(payload)

      const signedUrl = await getSignedUrl({
        filename: payload.support_document.name,
        content_type: payload.support_document.type
      })

      await Promise.all([
        uploadFileToS3({ url: signedUrl.url, body: payload.support_document }),
        storeMedia({
          user_id: getUserDetails().id,
          filename: `${signedUrl.filename}`,
          content_type: payload.support_document.type,
          category: 'initiative_support_document'
        }),
        createInitiative({
          ...payload,
          user_id: getUserDetails().id,
          support_document: `${config('S3_BUCKET_URL')}/${signedUrl.filename}`
        })
      ])

      dispatch(setCreatingInitiative(false))
      dispatch(fetchMetrics())
      dispatch(fetchTransactions())
      dispatch(fetchRecentTransactions())
      dispatch(fetchUserInitiatives())
      dispatch(fetchInitiatives())
      dispatch(setHasCreatedInitiative(true))
    } catch (error) {
      dispatch(setCreatingInitiative(false))
      const isValidatorError = Array.isArray(error.errors)
      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }

      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function addContribution(payload) {
  return async (dispatch, getState) => {
    try {
      const user = getUserDetails()
      const { contributionState } = getState().changisha
      dispatch(setErrors({}))
      dispatch(setHasContributed(false))
      dispatch(setIsContributing(true))

      await validatePayment({
        ...payload,
        transaction_mode: 'stk_push'
      })

      await createContribution({
        contributed_amount: contributionState.amount,
        contributors_id: user.id,
        initiative_id: contributionState.initiative_id
      })

      const payment = await makePayment({
        user_id: user.id,
        phone: payload.phone,
        reference_occasion: 'changisha',
        transaction_mode: 'stk_push',
        action_type: 'contribute_intitiative',
        amount: contributionState.amount,
        reference_occasion_id: contributionState.initiative_id
      })
      dispatch(setStkPushRequestId(payment.checkoutRequestId))
      dispatch(setIsContributing(false))
      dispatch(setHasContributed(true))
      dispatch(setErrors({}))
      dispatch(setContributionState({}))
    } catch (error) {
      dispatch(setIsContributing(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function addMemberToInitiative(payload) {
  return async (dispatch) => {
    try {
      dispatch(setAddingMember(true))
      dispatch(setHasAddedMember(false))
      dispatch(setErrors({}))

      const user = getUserDetails()

      await addMember(payload.initiative_id, {
        members_id: user.id
      })

      dispatch(fetchInitiatives())
      dispatch(fetchUserInitiatives())
      dispatch(fetchMetrics())

      dispatch(setAddingMember(false))
      dispatch(setHasAddedMember(true))
    } catch (error) {
      dispatch(setAddingMember(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function searchInitiativeByNumber(payload) {
  return async (dispatch, getState) => {
    try {
      dispatch(setSearchingInitiative(true))
      dispatch(setErrors({}))
      await validateSearchInitiative(payload)
      const prevState = getState().changisha.initiativeState
      const { data } = await getUserInitiatives({
        ...payload,
        limit: PAGE_LIMIT
      })

      dispatch(
        setInitiativeState({
          ...prevState,
          initiative: {
            name: data[0].name,
            targetAmount: data[0].targetAmount,
            totalRaised: data[0].totalRaised,
            endDate: data[0].endDate,
            id: data[0].id
          },
          initiative_number: payload.initiative_number
        })
      )

      dispatch(setSearchingInitiative(false))
    } catch (error) {
      dispatch(setSearchingInitiative(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      Sentry.captureException(error)
    }
  }
}

export const fetchInitiativeById =
  (id, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const data = await getInitiativeById(id)

      dispatch(setChatInitiative(data))

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }

export const authenticateAndJoinChat = () => {
  return async (dispatch) => {
    try {
      dispatch(setJoiningChat(true))
      dispatch(setLoadingChats(true))
      const user = getUserDetails()
      const initiative = getItemFromLocalStorage('initiative')
      const pusher = new Pusher(config('PUSHER_KEY'))
      pusher.connection.bind('connected', async () => {
        await Promise.all([
          authChat({
            channel: snakeCase(initiative.name),
            socket_id: pusher.connection.socket_id || pusher.connection.id,
            user
          }),
          joinChat({
            channel: snakeCase(initiative.name),
            first_name: user.firstname,
            last_name: user.lastname
          })
        ])
      })

      const { data: messages, count } = await getMessages({
        limit: 20,
        channel: initiative.id
      })

      dispatch(setChats(reverse(messages)))

      dispatch(
        setChatsPagination({
          hasNext: messages.length < count,
          hasPrev: false,
          count: messages.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setJoiningChat(false))
      dispatch(setLoadingChats(false))
    } catch (error) {
      dispatch(setLoadingChats(false))
      dispatch(setJoiningChat(false))
      Sentry.captureException(error)
    }
  }
}

export const sendMessage = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setSendingMessage(true))
      dispatch(setHasSentMessage(false))

      await postMessage(payload)

      const { data: messages, count } = await getMessages({
        limit: 20,
        channel: payload.id
      })

      dispatch(setChats(reverse(messages)))

      dispatch(
        setChatsPagination({
          hasNext: messages.length < count,
          hasPrev: false,
          count: messages.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setSendingMessage(false))
      dispatch(setHasSentMessage(true))
    } catch (error) {
      dispatch(setSendingMessage(false))
      Sentry.captureException(error)
    }
  }
}

export function withdraw(data) {
  return async (dispatch, getState) => {
    try {
      dispatch(setErrors({}))
      dispatch(setWithdrawing(true))
      dispatch(setHasWithdrawn(false))

      const user = getUserDetails()

      if (isEmpty(data.premise)) {
        dispatch(setWithdrawing(false))
        return dispatch(
          setErrors({ premise: i18n.t('errors.you_must_select_initiative') })
        )
      }

      const { userInitiatives } = getState().changisha
      const initiative = userInitiatives.find((w) => w.id === data.premise)

      await validateWithdrawal(data, initiative.totalRaised)
      const wallet = await getInitiativeWallet({
        user_id: user.id,
        initiative_id: initiative.id
      })

      await premiseWithdrawal({
        amount: data.amount,
        phone: user.phone,
        user_id: user.id,
        initiative_id: initiative.id,
        initiative_wallet_id: wallet.data[0].id,
        initiative_owner_id: user.id,
        reference_occasion: 'changisha'
      })

      dispatch(setWithdrawing(false))
      dispatch(setHasWithdrawn(true))
    } catch (error) {
      dispatch(setWithdrawing(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export default changishaSlice.reducer
