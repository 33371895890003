import PropTypes from 'prop-types'
import LeftPageColumn from './LeftPageColumn'
import RightPageColumn from './RightPageColumn'

function PageColumns({ leftPageColumnContent, rightPageColumnContent }) {
  return (
    <div className='grid grid-cols-6 gap-2 mt-6'>
      {leftPageColumnContent && <LeftPageColumn content={leftPageColumnContent} />}

      {rightPageColumnContent && <RightPageColumn content={rightPageColumnContent} />}
    </div>
  )
}

PageColumns.propTypes = {
  leftPageColumnContent: PropTypes.element,
  rightPageColumnContent: PropTypes.element
}

export default PageColumns
