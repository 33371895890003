export function HomeWebSkeleton() {
  return (
    <>
      <div className='w-full mb-6'>
        <div className='grid sm:grid-cols-2 lg:grid-cols-4 gap-2'>
          {Array.from({ length: 4 }).map((item, index) => (
            <div
              key={index}
              className='border border-gray-200 shadow-md rounded-md p-4 max-w-sm w-full mx-auto'
            >
              <div className='animate-pulse flex space-x-4'>
                <div className='flex-1 space-y-6 py-1'>
                  <div className='h-2 bg-slate-200 rounded'></div>
                  <div className='space-y-3'>
                    <div className='grid grid-cols-3 gap-4'>
                      <div className='h-2 bg-slate-200 rounded col-span-2'></div>
                      <div className='h-2 bg-slate-200 rounded col-span-1'></div>
                    </div>
                    <div className='h-2 bg-slate-200 rounded'></div>
                  </div>
                </div>
                <div className='rounded-full bg-slate-200 h-10 w-10'></div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <section className='flex flex-col col-span-full bg-white shadow-md rounded-sm animate-pulse'>
        <header className='px-4 py-4 border-b border-slate-100 w-60'>
          <div className='h-2 bg-slate-200 rounded'></div>
        </header>

        <div>
          <div className='px-4 py-3'>
            <div className='grid grid-cols-4 gap-6'>
              <div className='grid grid-cols-3 items-center gap-2'>
                <div className='rounded-full h-3 w-3 bg-slate-200 col-span-1'></div>
                <div className='h-2 bg-slate-200 rounded col-span-2 -ml-10'></div>
              </div>
              <div className='grid grid-cols-3 items-center gap-2'>
                <div className='rounded-full h-3 w-3 bg-slate-200 col-span-1'></div>
                <div className='h-2 bg-slate-200 rounded col-span-2 -ml-10'></div>
              </div>
            </div>
          </div>

          <div className='grid grid-cols-12 px-4 py-3 gap-4 items-end'>
            <div className='col-span-1'>
              <div className='flex flex-col space-y-8'>
                <div className='h-2 bg-slate-200 rounded'></div>
                <div className='h-2 bg-slate-200 rounded'></div>
                <div className='h-2 bg-slate-200 rounded'></div>
                <div className='h-2 bg-slate-200 rounded'></div>
                <div className='h-2 bg-slate-200 rounded'></div>
                <div className='h-2 bg-slate-200 rounded'></div>
              </div>
            </div>
            <div className='col-span-2 flex justify-between gap-20'>
              <div className='grid grid-cols-2 gap-16 items-end'>
                <div className='bg-slate-200 h-48 w-10'></div>
                <div className='bg-slate-200 h-12 w-10'></div>
              </div>
              <div className='grid grid-cols-2 gap-16 items-end'>
                <div className='bg-slate-200 h-32 w-10'></div>
                <div className='bg-slate-200 h-16 w-10'></div>
              </div>
              <div className='grid grid-cols-2 gap-16 items-end'>
                <div className='bg-slate-200 h-12 w-10'></div>
                <div className='bg-slate-200 h-40 w-10'></div>
              </div>
              <div className='grid grid-cols-2 gap-16 items-end'>
                <div className='bg-slate-200 h-48 w-10'></div>
                <div className='bg-slate-200 h-12 w-10'></div>
              </div>
              <div className='grid grid-cols-2 gap-16 items-end'>
                <div className='bg-slate-200 h-32 w-10'></div>
                <div className='bg-slate-200 h-16 w-10'></div>
              </div>
              <div className='grid grid-cols-2 gap-16 items-end'>
                <div className='bg-slate-200 h-12 w-10'></div>
                <div className='bg-slate-200 h-40 w-10'></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export function HomeMobileSkeleton() {
  return (
    <>
      <div className='w-full grid grid-cols-2 py-6 gap-6 animate-pulse'>
        <div className='flex items-center justify-between'>
          <div className='w-10 h-10 rounded-full mr-3 bg-slate-200'></div>
          <div className='flex-1 space-y-6 py-1'>
            <div className='space-y-3'>
              <div className='grid grid-cols-3 gap-4'>
                <div className='h-2 bg-slate-200 rounded col-span-2'></div>
                <div className='h-2 bg-slate-200 rounded col-span-1'></div>
              </div>
              <div className='h-2 bg-slate-200 rounded'></div>
            </div>
          </div>
        </div>
        <div className='flex items-center justify-between'>
          <div className='flex-1 space-y-6 py-1'>
            <div className='space-y-3 mr-3'>
              <div className='grid grid-cols-3 gap-4'>
                <div className='h-2 bg-slate-200 rounded col-span-2'></div>
                <div className='h-2 bg-slate-200 rounded col-span-1'></div>
              </div>
              <div className='h-2 bg-slate-200 rounded'></div>
            </div>
          </div>
          <div className='w-10 h-10 rounded-full bg-slate-200'></div>
        </div>
      </div>

      <section className='mt-4 animate-pulse'>
        {Array.from({ length: 5 }).map((_, i) => (
          <div
            key={i}
            className='mb-4 border border-gray-200 shadow-md rounded-md p-4 max-w-sm w-full mx-auto'
          >
            <div className='flex-1 space-y-6 py-1'>
              <div className='h-2 bg-slate-200 rounded'></div>
              <div className='space-y-3'>
                <div className='grid grid-cols-3 gap-4'>
                  <div className='h-2 bg-slate-200 rounded col-span-2'></div>
                  <div className='h-2 bg-slate-200 rounded col-span-1'></div>
                </div>
                <div className='h-2 bg-slate-200 rounded'></div>
              </div>
            </div>
          </div>
        ))}
      </section>
    </>
  )
}
