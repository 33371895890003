/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types'

function MobileMetric({ icon, label, onClick, disabled }) {
  return (
    <>
      <div
        className={`bg-white ${
          disabled ? 'bg-opacity-20 opacity-50 pointer-events-none' : 'bg-opacity-20'
        } rounded-2xl h-28 p-2 relative`}
        style={{
          border: 'solid 0.3px #c6c6c6;',
          boxShadow: '3px 3px 3px 0 rgba(0, 0, 0, 0.15);',
          padding: '30.8px 52.8px 10px 10px;',
          height: '315px;'
        }}
        onClick={onClick}
      >
        <div className='absolute top-2 left-4'>{icon}</div>
        <div
          className='uppercase font-gotham-rounded-bold absolute bottom-2 right-4 w-1/2 flex justify-end'
          style={{ fontSize: '9px' }}
        >
          {label}
        </div>
      </div>
    </>
  )
}

MobileMetric.propTypes = {
  t: PropTypes.func,
  icon: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

export default MobileMetric
