import { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Transition } from '@headlessui/react'
import { useSelector } from 'react-redux'
import services from '../../../helpers/services'
import { formatAmount } from '../../../helpers/utils'

function ViewVoucherModal({ t, showModal, onCancel, onRedeem, type, amount }) {
  const [service, setService] = useState('')
  const { currency } = useSelector((state) => state.auth)

  const getVoucherType = () => {
    let bgClass = 'bg-voucher-shopping'
    switch (type) {
      case 'kilimo':
        bgClass = 'bg-voucher-kilimo'
        break
      case 'afya':
        bgClass = 'bg-voucher-afya'
        break
      case 'fees':
        bgClass = 'bg-voucher-fees'
        break
      case 'salon':
        bgClass = 'bg-voucher-shopping'
        break
      case 'other':
        bgClass = 'bg-voucher-shopping'
        break
      default:
        break
    }

    return bgClass
  }

  const getVoucherBackground = () => {
    let voucherBg = 'bg-primary'

    switch (type) {
      case 'shopping':
        voucherBg = 'bg-primary'
        break
      case 'fees':
        voucherBg = 'bg-secondary-900'
        break
      case 'afya':
        voucherBg = 'bg-cyan-700'
        break
      case 'kilimo':
        voucherBg = 'bg-teal-700'
        break
      default:
        break
    }

    return voucherBg
  }

  const getTextColor = () => {
    let textColor = 'text-primary'

    switch (type) {
      case 'shopping':
        textColor = 'text-primary'
        break
      case 'fees':
        textColor = 'text-secondary-900'
        break
      case 'afya':
        textColor = 'text-cyan-700'
        break
      case 'kilimo':
        textColor = 'text-teal-700'
        break
      default:
        break
    }

    return textColor
  }

  useEffect(() => {
    if (type === 'shopping') {
      setService(services().OKOA_SHOPPING)
    } else if (type === 'fees') {
      setService(services().OKOA_FEES)
    } else if (type === 'afya') {
      setService(services().OKOA_AFYA)
    } else if (type === 'kilimo') {
      setService(services().OKOA_KILIMO)
    }
  }, [type])

  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={onCancel}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div
                className={`inline-block w-full max-w-md overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-3xl ${getVoucherType()}`}
              >
                <div className='flex flex-row'>
                  <div
                    className={`${getVoucherBackground()} font-gotham-rounded-bold text-2xl uppercase py-6 text-white flex justify-end h-auto`}
                  >
                    <div className='-rotate-90 py-2 flex justify-center float-right relative w-20'>
                      {type}
                    </div>
                  </div>
                  <section>
                    <div
                      className={`p-6 relative w-full border-dashed border-b border-black`}
                    >
                      <div className='flex justify-end'>
                        <button onClick={onCancel}>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-6 w-6 text-muted'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth={2}
                              d='M6 18L18 6M6 6l12 12'
                            />
                          </svg>
                        </button>
                      </div>
                      <div
                        className={`${getTextColor()} text-3xl font-gotham-rounded-medium`}
                      >
                        {currency} {formatAmount(amount)}
                      </div>
                      <div className='mt-2 text-black text-base'>
                        {t('pata_voucher.voucher_help_text')}
                      </div>
                      <div className='mt-6 pl-3'>
                        <ul className='list-disc text-black text-sm space-y-1'>
                          <li>{t('pata_voucher.view_voucher_not_valid_text')}</li>
                          <li>{t('pata_voucher.valid_for_text', { service })}</li>
                          <li>{t('pata_voucher.no_cash_value')}</li>
                        </ul>
                      </div>
                    </div>

                    <div className='flex space-between items-center p-6'>
                      <div className='p-1 bg-white'>
                        <i className='bi bi-qr-code text-5xl'></i>
                      </div>
                      <button
                        className={`ml-auto uppercase font-gotham-rounded-bold text-sm ${getTextColor()}`}
                        onClick={() => onRedeem(type)}
                      >
                        {t('pata_voucher.redeem_now')}
                      </button>
                    </div>
                  </section>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

ViewVoucherModal.propTypes = {
  showModal: PropTypes.bool,
  t: PropTypes.func,
  onCancel: PropTypes.func,
  onRedeem: PropTypes.func,
  type: PropTypes.string,
  amount: PropTypes.any
}

export default ViewVoucherModal
