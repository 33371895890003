import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import { Button } from '../..'
import { icons } from '../../../assets/img'

function OkoaConfirmationModal({ translate, showModal, onCancel, onSubmit, modalTitle }) {
  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={onCancel}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block p-6 w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button onClick={onCancel}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>

                <div className='text-center'>
                  <header className='uppercase text-medium text-muted font-gotham-rounded-medium mx-auto flex justify-center'>
                    <img src={icons.iconShopPrimary} alt='Shop' className='w-20 h-20' />
                  </header>
                  <div className='mt-4 text-body text-2xl font-gotham-rounded-bold opacity-80'>
                    {modalTitle}
                  </div>
                  <div className='mt-1 text-primary text-2xl font-gotham-rounded-bold opacity-80'>
                    Wakanai&apos;s Shop
                  </div>
                  <div className='mt-6 font-gotham-rounded-bold text-body opacity-70 text-base'>
                    <p>Monday, 09-12-2021 09:09</p>
                    <p className='mt-2'>{translate('okoa_shopping.amount')}: 999</p>
                  </div>

                  <div className='mt-12'>
                    <Button
                      label={translate('okoa_shopping.confirm_transaction')}
                      classes='uppercase'
                      onClick={onSubmit}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

OkoaConfirmationModal.propTypes = {
  showModal: PropTypes.bool,
  translate: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  modalTitle: PropTypes.string
}

export default OkoaConfirmationModal
