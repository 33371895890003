import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  Button,
  FormGroup,
  Input,
  PaymentMethodSelector,
  Select,
  PhoneInput
} from '../..'
import { formatAmount, getUserDetails } from '../../../helpers/utils'

function InuaBizRepaymentModal({
  showModal,
  onSubmit,
  translate,
  onCancel,
  modalTitle,
  amount,
  paymentType,
  onSelectPaymentMethod,
  loading,
  errors,
  premises
}) {
  const user = getUserDetails()

  const [state, setState] = useState({
    phone: user.phone,
    amount: '',
    premise: ''
  })

  const { premiseWallets } = useSelector((state) => state.inuaBiashara)
  const { currency } = useSelector((state) => state.auth)

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      phone: user.phone
    }))
  }, [user.phone])

  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={onCancel}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button onClick={onCancel} className='focus:outline-none outline-none'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>
                <div className=''>
                  <div className='mt-4 font-gotham-rounded-bold text-primary text-3xl text-center'>
                    {modalTitle}
                  </div>
                  <div className='mt-1 text-body text-sm text-center'>
                    {translate('okoa_shopping.repayment')}
                  </div>

                  <div className='sm:px-6 my-4'>
                    <PaymentMethodSelector
                      t={translate}
                      type={paymentType}
                      handleChange={(value) => onSelectPaymentMethod(value)}
                    />
                  </div>

                  <section className='mt-3'>
                    {paymentType === 'mpesa' && (
                      <>
                        <div className='text-body text-sm sm:px-6 text-center'>
                          {translate('okoa_shopping.repayment_help_text')}
                        </div>

                        <div className='mt-3 sm:px-5'>
                          <FormGroup>
                            <Select
                              name='premise'
                              options={premises.map((premise) => ({
                                label: `${premise.premiseName} - ${translate(
                                  'common.amount_due'
                                )}: ${`${currency} ${formatAmount(
                                  premiseWallets.find(
                                    (wallet) => wallet.premiseId === premise.id
                                  )?.repayableAmount
                                )}`}`,
                                value: premiseWallets.find(
                                  (wallet) => wallet.premiseId === premise.id
                                )?.id
                              }))}
                              hasPlaceholder={true}
                              placeholderText={translate('okoa_shopping.select_premise')}
                              onChange={handleChange}
                              hasError={Object.keys(errors).includes('premise')}
                              errorMessage={errors.premise}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Input
                              type='number'
                              placeholder={translate(
                                'okoa_shopping.enter_amount_to_repay'
                              )}
                              name={'amount'}
                              value={state.amount}
                              onChange={handleChange}
                              hasError={Object.keys(errors).includes('amount')}
                              errorMessage={errors.amount}
                            />
                          </FormGroup>
                          <FormGroup>
                            <PhoneInput
                              value={state.phone}
                              onChange={(phone) =>
                                setState((state) => ({
                                  ...state,
                                  phone
                                }))
                              }
                              placeholder={translate('okoa_shopping.phone_number')}
                              hasError={Object.keys(errors).includes('phone')}
                              errorMessage={errors.phone}
                              name='phone'
                            />
                          </FormGroup>
                        </div>

                        <div className='mt-4 sm:px-5'>
                          <Button
                            label={translate('okoa_shopping.repay')}
                            onClick={() =>
                              onSubmit({
                                payment_type: paymentType,
                                phone: state.phone,
                                amount: state.amount,
                                premise: state.premise
                              })
                            }
                            classes='uppercase'
                            disabled={loading}
                          />
                        </div>
                      </>
                    )}

                    {paymentType === 'other' && (
                      <div className='text-center'>
                        {translate('common.card_payment_help_text')}{' '}
                      </div>
                    )}
                  </section>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

InuaBizRepaymentModal.propTypes = {
  showModal: PropTypes.bool,
  onSubmit: PropTypes.func,
  translate: PropTypes.func,
  onCancel: PropTypes.func,
  modalTitle: PropTypes.string,
  modalSubTitle: PropTypes.string,
  amount: PropTypes.number,
  paymentType: PropTypes.string,
  onSelectPaymentMethod: PropTypes.func,
  loading: PropTypes.bool,
  errors: PropTypes.object,
  premises: PropTypes.array
}

export default InuaBizRepaymentModal
