import * as yup from 'yup'
import i18n from '../../../i18n'

export const validateBuyVoucher = (data) => {
  const schema = yup.object().shape({
    action_type: yup.string().required(),
    purchase_amount: yup
      .number()
      .typeError(i18n.t('errors.amount_must_be_a_number'))
      .min(50, i18n.t('errors.amount_must_be_greater_than_fifty'))
      .required(i18n.t('errors.amount_required')),
    voucher_type: yup.string().required(i18n.t('errors.voucher_type_is_required')),
    beneficiary_phone: yup.string().when('action_type', {
      is: 'gifting',
      then: yup
        .string()
        .min(12, i18n.t('errors.phone_must_be_valid'))
        .max(12, i18n.t('errors.phone_must_be_valid'))
        .required(i18n.t('errors.phone_required'))
    })
  })

  return schema.validate(data, { abortEarly: true })
}

export const validatePayment = (data) => {
  const schema = yup.object().shape({
    phone: yup
      .string()
      .min(12, i18n.t('errors.phone_must_be_valid'))
      .max(12, i18n.t('errors.phone_must_be_valid'))
      .required(i18n.t('errors.phone_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateSearchPremise = (data) => {
  const schema = yup.object().shape({
    paylend_number: yup
      .number()
      .typeError(i18n.t('errors.paylend_number_must_be_a_number'))
      .required(i18n.t('errors.paylend_number_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateRedeemVoucher = (data, max) => {
  const schema = yup.object().shape({
    purchase_amount: yup
      .number()
      .typeError(i18n.t('errors.amount_must_be_a_number'))
      .min(50, i18n.t('errors.amount_must_be_greater_than_fifty'))
      .max(max, `${i18n.t('errors.max_amount')} ${max}`)
      .required(i18n.t('errors.amount_required')),
    voucher_type: yup.string().required(i18n.t('errors.voucher_type_is_required'))
  })

  return schema.validate(data, { abortEarly: true })
}
