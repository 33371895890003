import PropTypes from 'prop-types'

function TextArea({
  onChange,
  placeholder,
  classes,
  name,
  rows,
  cols,
  value,
  hasError,
  errorMessage
}) {
  return (
    <>
      <textarea
        name={name}
        cols={cols}
        rows={rows}
        placeholder={placeholder}
        onChange={onChange}
        className={`bg-white border ${
          hasError ? 'border-red-300' : 'border-secondary-300'
        } rounded-md focus:ring-0 outline-none focus:outline-none ${
          hasError ? 'focus:border-red-500' : 'focus:border-secondary-500'
        } block w-full pr-4 py-2 pl-4 text-medium text-body ${classes}`}
      >
        {value}
      </textarea>
      <span className='text-xs font-gotham-rounded-book block mt-1 text-red-600'>
        {errorMessage}
      </span>
    </>
  )
}

TextArea.defaultProps = {
  rows: '10',
  cols: '30'
}

TextArea.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  classes: PropTypes.string,
  name: PropTypes.string,
  rows: PropTypes.string,
  cols: PropTypes.string,
  value: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string
}

export default TextArea
