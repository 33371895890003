/* eslint-disable */
import { useState, useMemo } from 'react'
import { icons } from '../../../assets/img'
import { Dropdown } from '../..'
import { languages } from '../../../containers/App/constants'
import { getItemFromLocalStorage, setItemToLocalStorage } from '../../../helpers/utils'
import i18n from '../../../i18n'

function LanguageSelector() {
  const userLang =
    getItemFromLocalStorage('language') ||
    languages.filter((lang) => lang.value === 'english')[0].value

  const selectedLanguage = useMemo(
    () => languages.find((language) => language.value === userLang),
    [userLang]
  )

  const [activeLanguage, setActiveLanguage] = useState({
    label: selectedLanguage.label,
    value: selectedLanguage.value,
    icon: selectedLanguage.icon
  })

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const handleLanguageChange = (lang) => {
    setActiveLanguage(lang)
    setItemToLocalStorage('language', lang.value)
    const userLanguage = languages.filter((l) => l.value === lang.value)[0]
    if (userLanguage) {
      i18n.changeLanguage(userLanguage.code)
    }
  }

  return (
    <Dropdown
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      dropdownTrigger={
        <>
          <div className='border-gray-300 border ml-3 lg:ml-0 flex items-center rounded-md px-3 py-1.5'>
            <img src={icons[activeLanguage.icon]} className='w-6 mr-3' alt='flag' />
            <span className='lg:text-body' style={{ paddingTop: '2px' }}>
              {activeLanguage.label}
            </span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-4 w-4 text-muted text-right block ml-4'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              style={{ paddingTop: '-2px' }}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M19 9l-7 7-7-7'
              />
            </svg>
          </div>
        </>
      }
      content={
        <>
          <ul>
            {languages
              .filter((language) => language.value !== activeLanguage.value)
              .map((l) => (
                <li
                  key={l.label}
                  className='cursor-pointer'
                  onClick={() => handleLanguageChange(l)}
                >
                  <span className='text-medium text-body hover:bg-gray-100 flex items-center py-1.5 px-3'>
                    <img src={icons[l.icon]} className='w-5 mr-3' alt='flag' />
                    {l.label}
                  </span>
                </li>
              ))}
          </ul>
        </>
      }
    />
  )
}

export default LanguageSelector
