import config from '../helpers/config'
import api from '.'

const CLIENT_SECRET = config('CLIENT_SECRET')

export const makePayment = (payload) =>
  api.post(`/payments/pay?client_secret=${CLIENT_SECRET}`, payload)

export const stkPushStatus = (payload) =>
  api.post(`/payments/stk-push-status?client_secret=${CLIENT_SECRET}`, payload)

export const premiseWithdrawal = (payload) =>
  api.post(`/payments/b2c-payment?client_secret=${CLIENT_SECRET}`, payload)
