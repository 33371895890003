import config from '../helpers/config'
import api from '.'
import { objectToQueryString } from '../helpers/utils'

const CLIENT_SECRET = config('CLIENT_SECRET')

export const createInitiative = (payload) =>
  api.post(`/initiatives?client_secret=${CLIENT_SECRET}`, payload)

export const getUserInitiatives = (payload) => {
  let query = {
    client_secret: CLIENT_SECRET,
    ...payload
  }

  query = objectToQueryString(query)

  return api.get(`/initiatives?${query}`)
}

export const getInitiativeById = (initativeId) =>
  api.get(`/initiatives/${initativeId}?client_secret=${CLIENT_SECRET}`)

export const updateInitiative = ({ initativeId, payload }) =>
  api.put(`/initiatives/${initativeId}?client_secret=${CLIENT_SECRET}`, payload)

export const addMember = (initiativeId, payload) =>
  api.put(`/initiatives/members/${initiativeId}?client_secret=${CLIENT_SECRET}`, payload)

export const getInitiativeWallet = (payload) =>
  api.get(`/initiative-wallets?client_secret=${CLIENT_SECRET}`, payload)
