import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import { icons } from '../../../assets/img'

function Notifications({ translate }) {
  return (
    <div className='w-32 sm:w-56 text-right mr-3'>
      <Menu as='div' className='relative inline-block text-left'>
        <div>
          <Menu.Button className='inline-flex justify-center w-full'>
            <i className='bi bi-bell-fill text-xl text-white sm:text-muted'></i>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-200 transform'
          enterFrom='opacity-0 -translate-y-2'
          enterTo='opacity-100 translate-y-0'
          leave='transition ease-out duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Menu.Items className='origin-top-right z-10 absolute top-full right-0 min-w-44 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1'>
            <div className='text-sm font-gotham-rounded-bold pt-1.5 pb-2 px-4 text-body'>
              {translate('header.notifications')}
            </div>
            <ul className='w-60 sm:w-72'>
              <div className='px-4 text-center py-2 flex justify-center mx-auto'>
                <div>
                  <img
                    src={icons.iconNotificationsPrimary}
                    alt='notifications'
                    className='w-8 block mx-auto mb-4'
                  />
                  <div className='text-body'>{translate('common.no_notifications')}</div>
                </div>
              </div>
            </ul>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

Notifications.propTypes = {
  translate: PropTypes.func.isRequired
}

export default Notifications
