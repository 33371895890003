import api from '.'
import config from '../helpers/config'

const CLIENT_SECRET = config('CLIENT_SECRET')

export const authChat = (payload) =>
  api.post(`/chat/auth?client_secret=${CLIENT_SECRET}`, payload)

export const getMessages = (payload) =>
  api.get(`/chat?client_secret=${CLIENT_SECRET}`, payload)

export const joinChat = (payload) =>
  api.put(`/chat/join?client_secret=${CLIENT_SECRET}`, payload)

export const postMessage = (payload) =>
  api.post(`/chat/message?client_secret=${CLIENT_SECRET}`, payload)

export const leaveChat = (payload) =>
  api.delete(`/chat/delete?client_secret=${CLIENT_SECRET}`, payload)
