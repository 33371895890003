import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import config from './config'
import { setItemToLocalStorage } from './utils'

const firebaseConfig = {
  apiKey: config('FIREBASE_API_KEY'),
  authDomain: config('FIREBASE_AUTH_DOMAIN'),
  projectId: config('FIREBASE_PROJECT_ID'),
  storageBucket: config('FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: config('FIREBASE_MESSAGING_SENDER_ID'),
  appId: config('FIREBASE_APP_ID')
}

export const firebase = initializeApp(firebaseConfig)

export const messaging = getMessaging(firebase)

export const getFcmToken = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey: config('FIREBASE_CLOUD_MESSAGING_KEY')
    })

    setItemToLocalStorage('fcm', token)
  } catch (error) {
    console.error(error)
  }
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })
