function ChatSkeletonMobile() {
  return (
    <>
      <div className='w-full animate-pulse'>
        {Array.from({ length: 2 }).map((_, i) => (
          <div className='flex flex-col' key={i}>
            <section className='float-right ml-auto'>
              <div className='message me mb-4 flex text-right'>
                <div className='px-2'>
                  <div className='h-6 bg-slate-400 rounded w-40'></div>
                  <div className='pr-4 h-2 bg-slate-400 rounded mt-2 w-24'></div>
                </div>
              </div>
            </section>
            <section>
              <div className='message me mb-4 flex'>
                <div className='px-2'>
                  <div className='h-6 bg-slate-400 rounded w-40'></div>
                  <div className='pr-4 h-2 bg-slate-400 rounded mt-2 w-24'></div>
                </div>
              </div>
            </section>
            <section>
              <div className='message me mb-4 flex'>
                <div className='px-2'>
                  <div className='h-6 bg-slate-400 rounded w-40'></div>
                  <div className='pr-4 h-2 bg-slate-400 rounded mt-2 w-24'></div>
                </div>
              </div>
            </section>
            <section>
              <div className='message me mb-4 flex'>
                <div className='px-2'>
                  <div className='h-6 bg-slate-400 rounded w-40'></div>
                  <div className='pr-4 h-2 bg-slate-400 rounded mt-2 w-24'></div>
                </div>
              </div>
            </section>
            <section className='float-right ml-auto'>
              <div className='message me mb-4 flex text-right'>
                <div className='px-2'>
                  <div className='h-6 bg-slate-400 rounded w-40'></div>
                  <div className='pr-4 h-2 bg-slate-400 rounded mt-2 w-24'></div>
                </div>
              </div>
            </section>
            <section className='float-right ml-auto'>
              <div className='message me mb-4 flex text-right'>
                <div className='px-2'>
                  <div className='h-6 bg-slate-400 rounded w-40'></div>
                  <div className='pr-4 h-2 bg-slate-400 rounded mt-2 w-24'></div>
                </div>
              </div>
            </section>
          </div>
        ))}
      </div>
    </>
  )
}

export default ChatSkeletonMobile
