import { useHistory } from 'react-router'
import { icons } from '../../assets/img'
import { Button } from '../../components'
import { HOME } from '../../containers/App/RouteConstants'

function ErrorPage() {
  const history = useHistory()

  return (
    <div className='flex items-center h-screen justify-center flex-col'>
      <div className='text-center'>
        <img src={icons.iconWarning} alt='404' className='w-32 mx-auto' />
        <h4 className='font-title font-bold text-xl mb-3'>An error occurred</h4>
        <p className='mb-3'>We encountered an error while completing your request.</p>

        <Button
          type='primary'
          label='Back to Dashboard'
          styles={{ fontWeight: 'bold', textTransform: 'uppercase' }}
          onClick={() => history.push(HOME)}
        />
      </div>
      <p className='text-muted absolute bottom-0 py-4'>
        {new Date().getFullYear()} &copy; Paylend - All rights reserved
      </p>
    </div>
  )
}

export default ErrorPage
