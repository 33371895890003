import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../..'

function UpcomingFeatureModal({ translate, showModal, onSubmit, onCancel, featureName }) {
  return (
    <Transition appear show={showModal} as={Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 z-50 overflow-y-auto'
        onClose={() => null}
      >
        <div className='min-h-screen px-4 text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
          </Transition.Child>

          <span className='inline-block h-screen align-middle' aria-hidden='true'>
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
              <div className='flex justify-end'>
                <button onClick={onCancel} className='focus:outline-none outline-none'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6 hover:text-secondary-900'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </button>
              </div>

              <div className='text-center'>
                <div className='mt-4 font-gotham-rounded-bold text-primary text-3xl text-center'>
                  {featureName}
                </div>
                <div className='mb-8 mt-3 text-sm'>
                  {translate('common.are_you_interested_in_feature')}
                </div>
                <div className='my-4 sm:px-6'>
                  <Button
                    label={translate('common.show_interest')}
                    onClick={onSubmit}
                    classes='uppercase'
                  />
                </div>
                <div className='my-4 sm:px-6'>
                  <Button
                    label={translate('common.cancel')}
                    onClick={onCancel}
                    classes='uppercase'
                    type='white'
                  />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

UpcomingFeatureModal.propTypes = {
  translate: PropTypes.func,
  onCancel: PropTypes.func,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  onSubmit: PropTypes.func,
  featureName: PropTypes.string
}

export default UpcomingFeatureModal
