import PropTypes from 'prop-types'

function PaymentMethodSelector({ type, handleChange, t }) {
  return (
    <section>
      <div className='text-center text-sm'>{t('common.select_payment_method')}</div>
      <div className='flex justify-between gap-4 mt-3'>
        <div className='flex items-center'>
          <input
            type='radio'
            className='text-primary outline-none focus:outline-none focus:ring-0 cursor-pointer'
            name='type'
            value='mpesa'
            checked={type === 'mpesa'}
            onChange={(e) => handleChange(e.target.value)}
          />
          <label htmlFor='type' className='pl-2 pt-1'>
            M-PESA
          </label>
        </div>

        <div className='flex items-center'>
          <input
            type='radio'
            className='text-primary outline-none focus:outline-none focus:ring-0 cursor-pointer'
            name='type'
            value='other'
            checked={type === 'other'}
            onChange={(e) => handleChange(e.target.value)}
          />
          <label htmlFor='type' className='pl-2 pt-1'>
            {t('common.other')}
          </label>
        </div>
      </div>
    </section>
  )
}

PaymentMethodSelector.propTypes = {
  type: PropTypes.string,
  handleChange: PropTypes.func,
  t: PropTypes.func
}

export default PaymentMethodSelector
