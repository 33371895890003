import { createSlice } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { head } from 'ramda'
import { changePassword } from '../../../api/user'
import { getSignedUrl, storeMedia, uploadFileToS3 } from '../../../api/media'
import { getUserDetails } from '../../../helpers/utils'
import { validateChangePassword, validateProfilePicture } from './validator'
import { fetchCurrentUserDetails } from '../../Auth/store'

export const initialState = {
  errors: {},
  updatingPassword: false,
  hasUpdatedPassword: false,
  updatingProfile: false,
  hasUpdatedProfile: false,
  activeSettingsTab: 0
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setErrors: (state, action) => {
      state.errors = action.payload
    },
    setUpdatingPassword: (state, action) => {
      state.updatingPassword = action.payload
    },
    setHasUpdatedPassword: (state, action) => {
      state.hasUpdatedPassword = action.payload
    },
    setUpdatingProfile: (state, action) => {
      state.updatingProfile = action.payload
    },
    setHasUpdatedProfile: (state, action) => {
      state.hasUpdatedProfile = action.payload
    },
    setActiveSettingsTab: (state, action) => {
      state.activeSettingsTab = action.payload
    }
  }
})

export const {
  setErrors,
  setUpdatingPassword,
  setHasUpdatedPassword,
  setUpdatingProfile,
  setHasUpdatedProfile,
  setActiveSettingsTab
} = settingsSlice.actions

export function updateProfile(data) {
  return async (dispatch) => {
    try {
      const user = getUserDetails()
      dispatch(setErrors({}))
      dispatch(setUpdatingProfile(true))
      dispatch(setHasUpdatedProfile(false))

      await validateProfilePicture(data)

      const signedUrl = await getSignedUrl({
        filename: data.profile_picture.name,
        content_type: data.profile_picture.type
      })

      await Promise.all([
        uploadFileToS3({ url: signedUrl.url, body: data.profile_picture }),
        storeMedia({
          user_id: user.id,
          filename: `${signedUrl.filename}`,
          content_type: data.profile_picture.type,
          category: 'profile_picture'
        })
      ])

      dispatch(fetchCurrentUserDetails())

      dispatch(setUpdatingProfile(false))
      dispatch(setHasUpdatedProfile(true))
    } catch (error) {
      dispatch(setUpdatingProfile(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      Sentry.captureException(error)
      dispatch(setErrors({ profileError: error.message }))
    }
  }
}

export function updatePassword(data) {
  return async (dispatch) => {
    try {
      const user = getUserDetails()
      dispatch(setErrors({}))
      dispatch(setUpdatingPassword(true))
      dispatch(setHasUpdatedPassword(false))

      await validateChangePassword(data)

      await changePassword(user.id, data)

      dispatch(setUpdatingPassword(false))
      dispatch(setHasUpdatedPassword(true))
    } catch (error) {
      dispatch(setUpdatingPassword(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      Sentry.captureException(error)
      dispatch(setErrors({ passwordError: error.message }))
    }
  }
}

export default settingsSlice.reducer
