import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, FileInput, FormGroup, Input, Select, Alert } from '../..'

function AddBeneficiaryModal({
  t,
  showModal,
  onCancel,
  onSubmit,
  errors,
  savingBeneficiary
}) {
  const [state, setState] = useState({
    first_name: '',
    surname: '',
    student_photo: '',
    nemis_number: '',
    birth_certificate_photo: '',
    student_level: '',
    paylend_number: ''
  })

  const [error, setError] = useState('')

  const { hasSavedBeneficiary } = useSelector((state) => state.okoaFees)

  const handleChange = (event) =>
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }))

  const handleFileChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.files[0]
    }))
  }

  useEffect(() => {
    if (hasSavedBeneficiary) {
      setState({
        first_name: '',
        surname: '',
        student_photo: '',
        nemis_number: '',
        birth_certificate_photo: '',
        student_level: '',
        paylend_number: ''
      })
    }
  }, [hasSavedBeneficiary])

  useEffect(() => {
    setError(errors.apiError)
  }, [errors.apiError])

  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={onCancel}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-full max-w-md sm:max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button onClick={onCancel}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>

                <div className=''>
                  <div className='mt-4 font-gotham-rounded-bold text-primary text-3xl text-center'>
                    {t('sidebar.okoa_fees')}
                  </div>
                  <div className='mt-4 font-gotham-rounded-medium text-body opacity-80 text-xl px-10 text-center'>
                    {t('okoa_fees.beneficiary_details')}
                  </div>

                  {error && (
                    <Alert type='error' message={error} onCancel={() => setError('')} />
                  )}

                  <section className='mt-6'>
                    <div className='sm:grid sm:grid-cols-2 sm:gap-3'>
                      <FormGroup>
                        <Input
                          type='text'
                          placeholder={t('okoa_fees.first_name')}
                          onChange={handleChange}
                          name='first_name'
                          hasError={Object.keys(errors).includes('first_name')}
                          errorMessage={errors.first_name}
                          value={state.first_name}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input
                          type='text'
                          placeholder={t('okoa_fees.surname')}
                          onChange={handleChange}
                          name='surname'
                          hasError={Object.keys(errors).includes('surname')}
                          errorMessage={errors.surname}
                          value={state.surname}
                        />
                      </FormGroup>
                    </div>
                    <div className='sm:grid sm:grid-cols-2 sm:gap-3 sm:mt-1'>
                      <FormGroup>
                        <FileInput
                          icon={
                            <>
                              <i className='bi bi-camera-fill text-secondary-900 text-base'></i>
                            </>
                          }
                          id='student_photo'
                          onChange={handleFileChange}
                          name='student_photo'
                          file={state.student_photo}
                          hasError={Object.keys(errors).includes('student_photo')}
                          errorMessage={errors.student_photo}
                          accept='image/*'
                          placeholder={t('okoa_fees.student_photo')}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input
                          type='number'
                          placeholder={t('okoa_fees.nemis_number_placeholder')}
                          onChange={handleChange}
                          name='nemis_number'
                          hasError={Object.keys(errors).includes('nemis_number')}
                          errorMessage={errors.nemis_number}
                          value={state.nemis_number}
                        />
                      </FormGroup>
                    </div>
                    <div className='sm:grid sm:grid-cols-2 sm:gap-3 sm:mt-1'>
                      <FormGroup>
                        <FileInput
                          icon={
                            <>
                              <i className='bi bi-camera-fill text-secondary-900 text-base'></i>
                            </>
                          }
                          id='birth_certificate_photo'
                          onChange={handleFileChange}
                          name='birth_certificate_photo'
                          file={state.birth_certificate_photo}
                          hasError={Object.keys(errors).includes(
                            'birth_certificate_photo'
                          )}
                          errorMessage={errors.birth_certificate_photo}
                          accept='image/*'
                          placeholder={t('okoa_fees.birth_certificate_photo')}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Select
                          name='student_level'
                          onChange={handleChange}
                          options={[
                            { label: t('okoa_fees.nursery'), value: 'Nursery' },
                            { label: t('okoa_fees.primary'), value: 'Primary' },
                            { label: t('okoa_fees.secondary'), value: 'Secondary' },
                            { label: t('okoa_fees.university'), value: 'University' }
                          ]}
                          hasPlaceholder={true}
                          placeholderText={t('okoa_fees.select_student_level')}
                          hasError={Object.keys(errors).includes('student_level')}
                          errorMessage={errors.student_level}
                        />
                      </FormGroup>
                    </div>
                    <div className='w-full mt-1'>
                      <FormGroup>
                        <Input
                          type='number'
                          placeholder={t('okoa_fees.school_paylend_number')}
                          onChange={handleChange}
                          name='paylend_number'
                          hasError={Object.keys(errors).includes('paylend_number')}
                          errorMessage={errors.paylend_number}
                          value={state.paylend_number}
                        />
                      </FormGroup>
                    </div>

                    <div className='mt-4 sm:px-20'>
                      <Button
                        label={t('okoa_fees.submit')}
                        onClick={() => onSubmit(state)}
                        classes='uppercase'
                        disabled={savingBeneficiary}
                      />
                    </div>
                  </section>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

AddBeneficiaryModal.propTypes = {
  t: PropTypes.func,
  showModal: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  errors: PropTypes.object,
  savingBeneficiary: PropTypes.bool
}

export default AddBeneficiaryModal
