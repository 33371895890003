export function OkoaMobileSkeleton() {
  return (
    <section className='text-center'>
      <section className='animate-pulse mt-10 flex justify-center flex-col items-center'>
        <div className='h-2 bg-white bg-opacity-20 rounded w-28 text-center'></div>
        <div className='h-2 bg-white bg-opacity-20 rounded w-20 text-center mt-2'></div>
      </section>

      <div className='flex justify-between gap-6 mt-8'>
        <div className='flex justify-center flex-col items-center'>
          <div className='h-2 bg-white bg-opacity-20 rounded w-28 text-center'></div>
          <div className='h-2 bg-white bg-opacity-20 rounded w-20 text-center mt-2'></div>
        </div>
        <div className='flex justify-center flex-col items-center'>
          <div className='h-2 bg-white bg-opacity-20 rounded w-28 text-center'></div>
          <div className='h-2 bg-white bg-opacity-20 rounded w-20 text-center mt-2'></div>
        </div>
      </div>

      <div className='flex justify-center'>
        <div className='h-2 bg-white bg-opacity-20 rounded w-4/5 text-center mt-10'></div>
      </div>

      <section>
        <section className='mt-4 grid grid-cols-3 gap-4'>
          <div className='bg-white bg-opacity-20 rounded-2xl h-28 p-2 relative'></div>
          <div className='bg-white bg-opacity-20 rounded-2xl h-28 p-2 relative'></div>
          <div className='bg-white bg-opacity-20 rounded-2xl h-28 p-2 relative'></div>
        </section>
        <section className='mt-4 grid grid-cols-3 gap-4'>
          <div className='bg-white bg-opacity-20 rounded-2xl h-28 p-2 relative'></div>
          <div className='bg-white bg-opacity-20 rounded-2xl h-28 p-2 relative'></div>
          <div className='bg-white bg-opacity-20 rounded-2xl h-28 p-2 relative'></div>
        </section>
      </section>

      <div className='flex justify-center'>
        <div className='h-2 bg-white bg-opacity-20 rounded w-4/5 text-center mt-10'></div>
      </div>

      <div className='my-4 bg-white bg-opacity-20 rounded-2xl w-full h-28 p-4 relative'></div>
    </section>
  )
}

export function OkoaListSkeleton() {
  return (
    <div className='flex justify-between items-center pb-2 mb-4 animate-pulse'>
      <div className='text-sm flex items-center justify-between gap-2'>
        <div className='h-2 bg-slate-200 rounded w-10 text-center -rotate-90'></div>
        <div>
          <div className='h-2 bg-slate-200 rounded w-16'></div>
          <div className='h-2 bg-slate-200 rounded w-32 mt-2'></div>
          <div className='h-2 bg-slate-200 rounded w-32 mt-2'></div>
        </div>
      </div>
      <div className='h-2 bg-slate-200 rounded w-12 ml-auto float-right'></div>
    </div>
  )
}
