import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Button, FormGroup, Input, Select } from '../..'
import { formatAmount, getUserDetails } from '../../../helpers/utils'

function WithdrawModal({
  translate,
  showModal,
  onCancel,
  onSubmit,
  amount,
  showAmount,
  premiseType,
  premiseName,
  walletType,
  errors,
  disabled
}) {
  const { currency } = useSelector((state) => state.auth)
  const user = getUserDetails()
  const state = useSelector((state) =>
    premiseType === 'shop'
      ? state.shopping
      : premiseType === 'school'
      ? state.okoaFees
      : premiseType === 'changisha'
      ? state.changisha
      : state.inuaBiashara
  )

  const [form, setForm] = useState({
    premise: '',
    amount: ''
  })

  const handleChange = (e) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={onCancel}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button onClick={onCancel}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>
                <div className=''>
                  {showAmount && (
                    <>
                      <header className='uppercase text-medium text-muted font-gotham-rounded-medium block'>
                        {translate('okoa_shopping.wallet_balance')}
                      </header>
                      <div className='text-secondary-900 mt-1 text-2xl font-gotham-rounded-medium'>
                        {currency} {formatAmount(amount)}
                      </div>
                    </>
                  )}
                  <div className='mt-4 font-gotham-rounded-bold text-primary text-3xl text-center'>
                    {translate('okoa_shopping.withdrawal')}
                  </div>

                  <section className='mt-3'>
                    <div className='text-body text-sm sm:px-6 text-center'>
                      {translate('okoa_shopping.withdraw_help_text')}
                    </div>

                    <div className='mt-3 sm:px-5'>
                      <FormGroup>
                        <Select
                          name='premise'
                          options={
                            premiseType !== 'changisha'
                              ? state[premiseName].map((premise) => ({
                                  label: `${premise.premiseName} - ${translate(
                                    'common.available'
                                  )}: ${`${currency} ${
                                    state[walletType].find(
                                      (wallet) => wallet.premiseId === premise.id
                                    )?.availableBalance
                                  }`}`,
                                  value: state[walletType].find(
                                    (wallet) => wallet.premiseId === premise.id
                                  )?.id
                                }))
                              : state[premiseName]
                                  .filter(
                                    (initiative) =>
                                      initiative.members[0].membersId === user.id
                                  )
                                  .map((initiative) => ({
                                    label: `${initiative.name} - ${translate(
                                      'common.available'
                                    )}: ${currency} ${initiative.totalRaised}`,
                                    value: initiative.id
                                  }))
                          }
                          hasPlaceholder={true}
                          placeholderText={
                            premiseType === 'changisha'
                              ? translate('common.select_initiative')
                              : translate('okoa_shopping.select_premise')
                          }
                          onChange={handleChange}
                          hasError={Object.keys(errors).includes('premise')}
                          errorMessage={errors.premise}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input
                          type='number'
                          placeholder={translate(
                            'okoa_shopping.enter_amount_to_withdraw'
                          )}
                          onChange={handleChange}
                          hasError={Object.keys(errors).includes('amount')}
                          errorMessage={errors.amount}
                          value={form.amount}
                          name='amount'
                        />
                      </FormGroup>
                    </div>

                    <div className='mt-4 sm:px-5'>
                      <Button
                        label={translate('common.next')}
                        onClick={() => onSubmit(form)}
                        classes='uppercase'
                        disabled={disabled}
                      />
                    </div>
                  </section>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

WithdrawModal.defaultProps = {
  showAmount: false
}

WithdrawModal.propTypes = {
  translate: PropTypes.func,
  showModal: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  amount: PropTypes.string,
  showAmount: PropTypes.bool,
  premiseType: PropTypes.string,
  premiseName: PropTypes.string,
  walletType: PropTypes.string,
  errors: PropTypes.object,
  disabled: PropTypes.bool
}

export default WithdrawModal
