const languages = [
  {
    label: 'English',
    value: 'english',
    code: 'en',
    icon: 'iconUsaFlag'
  },
  {
    label: 'Swahili',
    value: 'swahili',
    code: 'sw',
    icon: 'kenya'
  },
  {
    label: 'French',
    value: 'french',
    code: 'fr',
    icon: 'iconFranceFlag'
  }
]

const MAX_NUMBER_OF_PREMISES = 1

module.exports = {
  languages,
  MAX_NUMBER_OF_PREMISES
}
