import { createSlice } from '@reduxjs/toolkit'
import { dropLast, flatten, head, isEmpty, last, splitEvery } from 'ramda'
import * as Sentry from '@sentry/react'
import { getPremises, premiseVerification } from '../../../api/premises'
import i18n from '../../../i18n'
import {
  validateSearchPremise,
  validateVerifyPremise,
  validateAddPremiseType
} from './validator'
import { fetchMetrics } from '../../Auth/store'
import { getUserDetails } from '../../../helpers/utils'
import { cloneDeep } from 'lodash'
import { getUserByPhone } from '../../../api/auth'

const PAGE_LIMIT = 10

export const initialState = {
  isLoading: true,
  currentState: {},
  searchingPremise: false,
  errors: {},
  verifyingPremise: false,
  hasVerifiedPremise: false,
  premiseAlreadyVerified: false,
  premises: [],
  premisesPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  registeredPremises: [],
  registeredPremisesPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  verifiedPremises: [],
  verifiedPremisesPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  searchingUser: false,
  currentPremiseState: {}
}

export const agentDashboardSlice = createSlice({
  name: 'agentDashboard',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setCurrentState: (state, action) => {
      state.currentState = action.payload
    },
    setSearchingPremise: (state, action) => {
      state.searchingPremise = action.payload
    },
    setErrors: (state, action) => {
      state.errors = action.payload
    },
    setVerifyingPremise: (state, action) => {
      state.verifyingPremise = action.payload
    },
    setHasVerifiedPremise: (state, action) => {
      state.hasVerifiedPremise = action.payload
    },
    setPremiseAlreadyVerified: (state, action) => {
      state.premiseAlreadyVerified = action.payload
    },
    setPremises: (state, action) => {
      state.premises = action.payload
    },
    setPremisesPagination: (state, action) => {
      state.premisesPagination = action.payload
    },
    setRegisteredPremises: (state, action) => {
      state.registeredPremises = action.payload
    },
    setRegisteredPremisesPagination: (state, action) => {
      state.registeredPremisesPagination = action.payload
    },
    setVerifiedPremises: (state, action) => {
      state.verifiedPremises = action.payload
    },
    setVerifiedPremisesPagination: (state, action) => {
      state.verifiedPremisesPagination = action.payload
    },
    setSearchingUser: (state, action) => {
      state.searchingUser = action.payload
    },
    setCurrentPremiseState: (state, action) => {
      state.currentPremiseState = action.payload
    }
  }
})

export const {
  setIsLoading,
  setCurrentState,
  setSearchingPremise,
  setErrors,
  setVerifyingPremise,
  setHasVerifiedPremise,
  setPremiseAlreadyVerified,
  setPremises,
  setPremisesPagination,
  setSearchingUser,
  setCurrentPremiseState,
  setRegisteredPremises,
  setRegisteredPremisesPagination,
  setVerifiedPremises,
  setVerifiedPremisesPagination
} = agentDashboardSlice.actions

export function searchPremiseByNumber(payload) {
  return async (dispatch, getState) => {
    try {
      dispatch(setSearchingPremise(true))
      dispatch(setPremiseAlreadyVerified(false))
      dispatch(setErrors({}))
      await validateSearchPremise(payload)
      const prevState = getState().agent.currentState
      const { data } = await getPremises({
        ...payload,
        limit: 500000
      })

      if (isEmpty(data)) {
        dispatch(
          setCurrentState({
            paylend_number: payload.paylend_number
          })
        )
        dispatch(setErrors({ apiError: i18n.t('agent_dashboard.no_premise_found') }))
      } else {
        if (data[0].isPremiseVerified) {
          dispatch(setPremiseAlreadyVerified(true))
          return
        }
        dispatch(
          setCurrentState({
            ...prevState,
            premise: {
              premiseName: data[0].premiseName,
              premiseAddress: data[0].premiseAddress,
              premiseId: data[0].id
            },
            paylend_number: payload.paylend_number
          })
        )
      }

      dispatch(setSearchingPremise(false))
    } catch (error) {
      dispatch(setSearchingPremise(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export const fetchPremises =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const [{ data, count }] = await Promise.all([
        getPremises({
          is_premise_verified: false,
          limit: PAGE_LIMIT
        })
      ])

      dispatch(setPremises(data))

      dispatch(
        setPremisesPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }

export function verifyPremise(payload) {
  return async (dispatch) => {
    try {
      dispatch(setVerifyingPremise(true))
      dispatch(setHasVerifiedPremise(false))
      dispatch(setErrors({}))

      await validateVerifyPremise(payload)

      await premiseVerification({
        premiseId: payload.premise_id,
        payload: {
          is_premise_verified: true,
          agent_id: getUserDetails().id
        }
      })

      dispatch(setVerifyingPremise(false))
      dispatch(setHasVerifiedPremise(true))
      dispatch(fetchPremises())
      dispatch(fetchMetrics())
      dispatch(fetchVerifiedPremises())
    } catch (error) {
      dispatch(setVerifyingPremise(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        return dispatch(
          setErrors({ apiError: i18n.t('errors.ensure_all_documents_are_present') })
        )
      }
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function loadNextPremises(params = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))
      const { premises, premisesPagination: pagination } = cloneDeep(getState().agent)
      const premise = [premises.length - 1]
      const cursor = premise.id
      const { data, count } = await getPremises({
        is_premise_verified: false,
        limit: PAGE_LIMIT,
        after: cursor,
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setPremises(data))

      dispatch(
        setPremisesPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...premises],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function loadPrevPremises(params = {}) {
  return async (dispatch, getState) => {
    const { premisesPagination: pagination, premises: statePremises } = getState().agent
    const premises = pagination.paginated
    const premiseChunks = splitEvery(PAGE_LIMIT, premises)

    if (isEmpty(premiseChunks) || premiseChunks.length === 1) {
      try {
        dispatch(setIsLoading(true))

        const { data, count } = await getPremises({
          is_premise_verified: false,
          limit: PAGE_LIMIT,
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setPremises(data))

        dispatch(
          setPremisesPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
        dispatch(setErrors({ apiError: error.message }))
      }
    } else {
      const previousPremises = last(premiseChunks)
      dispatch(setPremises(previousPremises))

      dispatch(
        setPremisesPagination({
          hasNext: premises.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (statePremises.length < PAGE_LIMIT ? statePremises.length : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, premiseChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export const fetchRegisteredPremises =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const [{ data, count }] = await Promise.all([
        getPremises({
          registering_agent: getUserDetails().id,
          limit: PAGE_LIMIT
        })
      ])

      dispatch(setRegisteredPremises(data))

      dispatch(
        setRegisteredPremisesPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }

export function loadNextRegisteredPremises(params = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))
      const { registeredPremises: premises, registeredPremisesPagination: pagination } =
        cloneDeep(getState().agent)
      const premise = [premises.length - 1]
      const cursor = premise.id
      const { data, count } = await getPremises({
        registering_agent: getUserDetails().id,
        limit: PAGE_LIMIT,
        after: cursor,
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setRegisteredPremises(data))

      dispatch(
        setRegisteredPremisesPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...premises],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function loadPrevRegisteredPremises(params = {}) {
  return async (dispatch, getState) => {
    const {
      registeredPremisesPagination: pagination,
      registeredPremises: statePremises
    } = getState().agent
    const premises = pagination.paginated
    const premiseChunks = splitEvery(PAGE_LIMIT, premises)

    if (isEmpty(premiseChunks) || premiseChunks.length === 1) {
      try {
        dispatch(setIsLoading(true))

        const { data, count } = await getPremises({
          registering_agent: getUserDetails().id,
          limit: PAGE_LIMIT,
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setRegisteredPremises(data))

        dispatch(
          setRegisteredPremisesPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
        dispatch(setErrors({ apiError: error.message }))
      }
    } else {
      const previousPremises = last(premiseChunks)
      dispatch(setRegisteredPremises(previousPremises))

      dispatch(
        setRegisteredPremisesPagination({
          hasNext: premises.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (statePremises.length < PAGE_LIMIT ? statePremises.length : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, premiseChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export const fetchVerifiedPremises =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const [{ data, count }] = await Promise.all([
        getPremises({
          agent_id: getUserDetails().id,
          limit: PAGE_LIMIT
        })
      ])

      dispatch(setVerifiedPremises(data))

      dispatch(
        setVerifiedPremisesPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }

export function loadNextVerifiedPremises(params = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))
      const { verifiedPremises: premises, verifiedPremisesPagination: pagination } =
        cloneDeep(getState().agent)
      const premise = [premises.length - 1]
      const cursor = premise.id
      const { data, count } = await getPremises({
        registering_agent: getUserDetails().id,
        limit: PAGE_LIMIT,
        after: cursor,
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setVerifiedPremises(data))

      dispatch(
        setVerifiedPremisesPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...premises],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function loadPrevVerifiedPremises(params = {}) {
  return async (dispatch, getState) => {
    const { verifiedPremisesPagination: pagination, verifiedPremises: statePremises } =
      getState().agent
    const premises = pagination.paginated
    const premiseChunks = splitEvery(PAGE_LIMIT, premises)

    if (isEmpty(premiseChunks) || premiseChunks.length === 1) {
      try {
        dispatch(setIsLoading(true))

        const { data, count } = await getPremises({
          registering_agent: getUserDetails().id,
          limit: PAGE_LIMIT,
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setVerifiedPremises(data))

        dispatch(
          setVerifiedPremisesPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
        dispatch(setErrors({ apiError: error.message }))
      }
    } else {
      const previousPremises = last(premiseChunks)
      dispatch(setVerifiedPremises(previousPremises))

      dispatch(
        setVerifiedPremisesPagination({
          hasNext: premises.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (statePremises.length < PAGE_LIMIT ? statePremises.length : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, premiseChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export function addPremiseType(payload) {
  return async (dispatch) => {
    try {
      dispatch(setErrors({}))
      await validateAddPremiseType(payload)
      dispatch(setSearchingUser(true))
      const { data } = await getUserByPhone(payload.phone)
      dispatch(
        setCurrentPremiseState({
          premise_type: payload.premise_type,
          user: {
            id: data[0].id,
            name: `${data[0].firstname} ${data[0].lastname}`
          }
        })
      )
      dispatch(setSearchingUser(false))
    } catch (error) {
      dispatch(setSearchingUser(false))
      dispatch(setCurrentPremiseState({}))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export default agentDashboardSlice.reducer
