import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import './assets/css/index.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import App from './containers/App'
import { store } from './store'
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals'
import config from './helpers/config'
import './i18n'
import { OnlineStatus } from './components'

Sentry.init({
  dsn: process.env.NODE_ENV === 'production' ? config('SENTRY_DSN') : '',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
})

ReactDOM.render(
  <>
    <Provider store={store}>
      <OnlineStatus>
        <App store={store} />
      </OnlineStatus>
    </Provider>
  </>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
