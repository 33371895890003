import { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Transition } from '@headlessui/react'
import { Button, Alert } from '../..'

function PaymentConfirmationModal({
  t,
  showModal,
  onCancel,
  onSubmit,
  errors,
  loading,
  title,
  subTitle,
  description
}) {
  const [error, setError] = useState('')
  useEffect(() => {
    setError(errors.apiError)
  }, [errors.apiError])
  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={onCancel}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button onClick={onCancel}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>

                <div className=''>
                  <div className='font-gotham-rounded-bold text-primary text-3xl text-center'>
                    {title}
                  </div>
                  <div className='mt-1 uppercase text-xl font-gotham-rounded-medium opacity-70 text-center'>
                    {subTitle}
                  </div>

                  <section className='mt-3'>
                    {error && (
                      <Alert type='error' message={error} onCancel={() => setError('')} />
                    )}
                    <div className='text-body text-sm sm:px-6 text-center'>
                      {description}
                    </div>
                  </section>
                  <div className='mt-4 sm:px-5'>
                    <Button
                      label={t('common.completed')}
                      onClick={onSubmit}
                      classes='uppercase'
                      disabled={loading}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

PaymentConfirmationModal.propTypes = {
  t: PropTypes.func,
  showModal: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  errors: PropTypes.object,
  loading: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string
}

export default PaymentConfirmationModal
