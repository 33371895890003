import { createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'ramda'
import * as Sentry from '@sentry/react'
import { getUserTransactions } from '../../../api/transactions'
import { getUserDetails } from '../../../helpers/utils'

export const initialState = {
  isLoading: true,
  errors: {},
  metrics: {
    totalOkoaLimit: 0,
    totalVouchers: 0,
    totalInitiatives: 0,
    okoaPayable: 0,
    activeInitiatives: 0,
    closedInitiatives: 0
  },
  transactions: []
}

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setMetrics: (state, action) => {
      state.metrics = action.payload
    },
    setTransactions: (state, action) => {
      state.transactions = action.payload
    }
  }
})

export const { setIsLoading, setMetrics, setTransactions } = homeSlice.actions

export const fetchTransactions =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { id: userId } = getUserDetails()
      const { data } = await getUserTransactions({
        user_id: userId,
        limit: 10,
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setTransactions(data))
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }

export default homeSlice.reducer
