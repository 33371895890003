import { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Transition } from '@headlessui/react'
import { Button, FormGroup, Input, Select, Alert } from '../..'

function BuyVoucherModal({ t, showModal, onCancel, onSubmit, errors, buyingVoucher }) {
  const [state, setState] = useState({
    voucher_type: '',
    purchase_amount: ''
  })

  const handleChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const [error, setError] = useState('')
  useEffect(() => {
    setError(errors.apiError)
  }, [errors.apiError])
  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={onCancel}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button onClick={onCancel}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>

                <div className=''>
                  <div className='font-gotham-rounded-bold text-primary text-3xl text-center'>
                    {t('sidebar.pata_voucher')}
                  </div>
                  <div className='mt-1 uppercase text-2xl font-gotham-rounded-medium opacity-70 text-center'>
                    {t('pata_voucher.buying')}
                  </div>

                  <section className='mt-3'>
                    {error && (
                      <Alert type='error' message={error} onCancel={() => setError('')} />
                    )}
                    <div className='text-body text-sm sm:px-6 text-center'>
                      {t('pata_voucher.buy_help_text')}
                    </div>
                    <div className='mt-5 sm:px-5'>
                      <FormGroup>
                        <Select
                          hasPlaceholder={true}
                          placeholderText={t('pata_voucher.voucher_type')}
                          options={[
                            {
                              label: 'Shopping',
                              value: 'shopping'
                            },
                            {
                              label: 'School Fees',
                              value: 'school_fees'
                            }
                          ]}
                          hasError={Object.keys(errors).includes('voucher_type')}
                          errorMessage={errors.voucher_type}
                          onChange={handleChange}
                          name='voucher_type'
                        />
                      </FormGroup>
                    </div>
                    <div className='mt-3 sm:px-5'>
                      <FormGroup>
                        <Input
                          type='number'
                          placeholder={t('pata_voucher.enter_amount_to_buy')}
                          classes='placeholder:text-body text-body'
                          hasError={Object.keys(errors).includes('purchase_amount')}
                          errorMessage={errors.purchase_amount}
                          value={state.purchase_amount}
                          onChange={handleChange}
                          name='purchase_amount'
                        />
                      </FormGroup>
                    </div>
                  </section>
                  <div className='mt-4 sm:px-5'>
                    <Button
                      label={t('common.next')}
                      onClick={() =>
                        onSubmit({
                          ...state,
                          action_type: 'buying'
                        })
                      }
                      classes='uppercase'
                      disabled={buyingVoucher}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

BuyVoucherModal.propTypes = {
  t: PropTypes.func,
  showModal: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  errors: PropTypes.object,
  buyingVoucher: PropTypes.bool
}

export default BuyVoucherModal
