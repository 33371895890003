export const icons = {
    logoWhite: require('./logo-white.svg').default,
    pataVoucher: require('./pata-voucher.svg').default,
    okoaServices: require('./okoa-services.svg').default,
    changisha: require('./changisha.svg').default,
    logo: require('./logo.svg').default,
    eyeIcon: require('./eye-icon.svg').default,
    eyeIconAlternate: require('./eye-icon-alternate.svg').default,
    kenya: require('./kenya.svg').default,
    dashboard: require('./dashboard.png'),
    dashboardActive: require('./dashboard_active.png'),
    voucher: require('./voucher.png'),
    voucherActive: require('./voucher_active.png'),
    okoaServices: require('./okoa_services.png'),
    okoaServicesActive: require('./okoa_services_active.png'),
    money: require('./money.png'),
    moneyActive: require('./moneyActive.png'),
    icon404: require('./404.png'),
    iconUsaFlag: require('./us.svg').default,
    iconGermanyFlag: require('./de.svg').default,
    iconFranceFlag: require('./fr.svg').default,
    iconPortugalFlag: require('./pt.svg').default,
    iconSpainFlag: require('./es.svg').default,
    iconAvatar: require('./avatar.png'),
    iconMoneyInHand: require('./money-in-hand.svg').default,
    iconTotalRepayment: require('./total-repayment.svg').default,
    iconTotalPayable: require('./total-payable.svg').default,
    iconInitiative: require('./initiatives.svg').default,
    iconVoucher: require('./voucher.svg').default,
    iconLady: require('./woman.png'),
    iconManager: require('./manager.svg').default,
    iconSchool: require('./school.png'),
    iconPerson: require('./person.png'),
    iconElsie: require('./iconElsie.png'),
    iconBeneficiary: require('./beneficiary.svg').default,
    iconSchoolThin: require('./school_thin.svg').default,
    iconHeadphones: require('./headphones.svg').default,
    iconOkoa: require('./okoa.svg').default,
    iconFees: require('./okoa_fees.svg').default,
    iconOkoaBills: require('./okoa_bills.svg').default,
    iconOkoaKilimo: require('./okoa_kilimo.svg').default,
    iconOkoaAfya: require('./okoa_afya.svg').default,
    iconOkoaSalon: require('./okoa_salon.svg').default,
    iconShopNearby: require('./shop_nearby.svg').default,
    iconSwapVoucher: require('./swap_voucher.svg').default,
    iconVoucherHistory: require('./voucher_history.svg').default,
    iconTransactions: require('./transactions.svg').default,
    iconOkoaWhite: require('./okoa_white.svg').default,
    iconRepayWhite: require('./repay.svg').default,
    iconShopOwned: require('./shop_owned.svg').default,
    iconWarning: require('./warning.png'),
    iconBuyVoucher: require('./buy_voucher.svg').default,
    iconGiftVoucher: require('./gift_voucher.svg').default,
    iconRedeemVoucher: require('./redeem_voucher.svg').default,
    iconCheckBalance: require('./check_balance.svg').default,
    iconShopPrimary: require('./shop_primary.svg').default,
    iconShopPurple: require('./shop.svg').default,
    iconHomeGray: require('./home_gray.svg').default,
    iconProfileGray: require('./profile_gray.svg').default,
    iconShopGray: require('./shop_gray.svg').default,
    iconAccountsGray: require('./accounts_gray.svg').default,
    iconPlayStore: require('./playstore.svg').default,
    iconRedeemedVouchers: require('./icon_redeemed_vouchers.svg').default,
    iconNotesInHand: require('./icon_notes_in_hand.svg').default,
    iconNotes: require('./redeemed.svg').default,
    iconMoneyArrow: require('./icon_money_arrow.svg').default,
    iconProfile: require('./profile.svg').default,
    iconBasket: require('./basket.svg').default,
    iconSpinner: require('./spinner.svg').default,
    iconEmptyChat: require('./empty-chat.svg').default,
    iconMpesa: require('./mpesa.png'),
    iconNotificationsPrimary: require('./notifications_primary.svg').default,
    iconPigiaMe: require('./pigiame.svg').default,
    iconAirDuka: require('./airduka.png'),
    iconHava: require('./hava.png')
}