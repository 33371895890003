import { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { XIcon, ExclamationIcon } from '@heroicons/react/outline'

function OnlineStatus({ children }) {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(true)
  const [online, setOnline] = useState(navigator.onLine)

  useEffect(() => {
    window.addEventListener('online', setOnline)
    window.addEventListener('offline', () => setOnline(false))

    return () => {
      window.removeEventListener('online', setOnline)
      window.removeEventListener('offline', () => setOnline(false))
    }
  }, [])

  return (
    <>
      {!online && (
        <Transition appear show={showModal} as={Fragment}>
          <Dialog
            as='div'
            className='fixed inset-0 z-40 overflow-y-auto'
            onClose={() => setShowModal(false)}
          >
            <div className='min-h-screen px-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-60' />
              </Transition.Child>
              <span className='inline-block h-screen align-middle' aria-hidden='true'>
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <div className='inline-block p-6 w-full max-w-md my-8 overflow-hidden text-left align-top transition-all transform bg-white shadow-xl rounded-lg'>
                  <div className='absolute top-0 right-0 pt-4 pr-4'>
                    <button
                      type='button'
                      className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-0'
                      onClick={() => setShowModal(false)}
                    >
                      <span className='sr-only'>Close</span>
                      <XIcon className='h-6 w-6' aria-hidden='true' />
                    </button>
                  </div>
                  <div className='flex items-start'>
                    {!online && (
                      <>
                        <div className='flex flex-shrink-0 items-center justify-center rounded-full bg-red-100 mx-0 h-10 w-10'>
                          <ExclamationIcon
                            className='h-6 w-6 text-red-600'
                            aria-hidden='true'
                          />
                        </div>
                        <div className='mt-0 ml-4 text-left'>
                          <Dialog.Title
                            as='h3'
                            className='sm:text-base text-sm font-gotham-rounded-medium text-body'
                          >
                            {t('common.you_are_offline')}
                          </Dialog.Title>
                          <div className='mt-2'>
                            <p className='sm:text-sm text-medium'>
                              {t('common.offline_help_text')}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      )}
      {children}
    </>
  )
}

OnlineStatus.propTypes = {
  children: PropTypes.node.isRequired
}

export default OnlineStatus
