import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import { TextArea, Button, Alert } from '../..'

function CancellationReasonModal({
  showModal,
  onCancel,
  onSubmit,
  translate,
  onBackClicked,
  modalTitle,
  description,
  errors,
  isSubmitting
}) {
  const [state, setState] = useState({
    declining_reason: ''
  })
  const [error, setError] = useState('')

  useEffect(() => {
    setError(errors?.apiError)
  }, [errors?.apiError])
  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={onCancel}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button onClick={onCancel}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>
                <div>
                  <div className=''>
                    <div className='mt-1 text-primary text-2xl font-gotham-rounded-bold opacity-80 text-center'>
                      {modalTitle || translate('okoa_shopping.cancel_request')}
                    </div>

                    {error && (
                      <Alert type='error' message={error} onCancel={() => setError('')} />
                    )}

                    <div
                      className='mt-4 text-body opacity-80 px-12 text-center'
                      style={{ fontSize: '15px' }}
                    >
                      {description || translate('okoa_shopping.cancel_help_text')}
                    </div>

                    <div className='mt-2'>
                      <TextArea
                        placeholder={translate('okoa_shopping.cancel_placeholder')}
                        onChange={(e) =>
                          setState((prevState) => ({
                            ...prevState,
                            declining_reason: e.target.value
                          }))
                        }
                        value={state.declining_reason}
                        hasError={Object.keys(errors).includes('declining_reason')}
                        errorMessage={errors.declining_reason}
                      />
                    </div>

                    <div className='flex justify-between mt-4'>
                      <div className='w-28'>
                        <Button
                          label={translate('okoa_shopping.back')}
                          onClick={onBackClicked}
                          classes='uppercase'
                          type='white'
                        />
                      </div>
                      <div className='w-28'>
                        <Button
                          onClick={() => onSubmit(state)}
                          classes='uppercase'
                          label={translate('okoa_shopping.submit')}
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

CancellationReasonModal.propTypes = {
  showModal: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  translate: PropTypes.func,
  onBackClicked: PropTypes.func,
  modalTitle: PropTypes.func,
  description: PropTypes.string,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool
}

export default CancellationReasonModal
