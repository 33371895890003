import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'ramda'
import { Table, TableSkeleton, EmptyContent } from '../..'
import { loadNextSchools, loadPrevSchools } from '../../../containers/OkoaFees/store'

function Schools({ t }) {
  const dispatch = useDispatch()
  const {
    schools,
    isLoading,
    schoolsPagination: pagination
  } = useSelector((state) => state.okoaFees)

  return (
    <>
      {isLoading ? (
        <>
          <TableSkeleton showSearch={false} showPagination={true} />
        </>
      ) : (
        <>
          {isEmpty(schools) ? (
            <div className='flex flex-col'>
              <div className='overflow-x-auto shadow-md sm:rounded-lg'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='p-4'>
                    <h2 className='font-gotham-rounded-medium text-body text-base'>
                      {t('okoa_fees.schools')}
                    </h2>
                    <EmptyContent
                      title={t('common.no_schools')}
                      subTitle={t('common.no_schools_help_text')}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='flex flex-col'>
              <div className='overflow-x-auto shadow-md sm:rounded-lg w-full'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='p-4 flex'>
                    <h2 className='font-gotham-rounded-medium text-body text-base mb-2'>
                      {t('okoa_fees.schools')}
                    </h2>
                  </div>
                  <div className='overflow-hidden p-4'>
                    <Table
                      header={
                        <tr>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_fees.school_name')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_fees.location')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_shopping.paylend_number')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('common.monthly_income')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_fees.institution_level')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('common.verified')}
                          </th>
                        </tr>
                      }
                      body={
                        <>
                          {schools.map((shop) => (
                            <tr key={shop.id}>
                              <td className='pl-2 py-4 whitespace-nowrap'>
                                <div className='text-medium font-medium text-body'>
                                  {shop.premiseName}
                                </div>
                                <div className='text-xs text-gray-500'>
                                  {shop.managerEmail
                                    ? t('common.email')
                                    : t('common.phone')}
                                  : {shop.managerEmail || shop.managerPhoneNumber}
                                </div>
                              </td>
                              <td className='pl-2 py-4 w-40 text-medium text-body'>
                                {shop.premiseAddress}
                              </td>
                              <td className='pl-2 py-4 text-medium text-body'>
                                {shop.paylendNumber}
                              </td>
                              <td className='pl-2 py-4 text-medium text-body'>
                                {shop.monthlyTransactionIncome}
                              </td>
                              <td className='pl-2 py-4 text-medium text-body'>
                                {shop.institutionalLevel}
                              </td>
                              <td className='pl-2 py-4 text-medium text-body'>
                                {shop.isPremiseVerified
                                  ? t('common.yes')
                                  : t('common.no')}
                              </td>
                            </tr>
                          ))}
                        </>
                      }
                      pagination={
                        <div className='flex justify-between pl-2 pr-6 py-4 w-full border border-gray-200 border-t-0'>
                          <div className='text-medium text-muted'>
                            {pagination.count} {t('common.of')} {pagination.total}{' '}
                            {t('okoa_fees.schools')}
                          </div>
                          <div className='flex justify-between gap-4'>
                            <button
                              className={`text-medium ${
                                pagination.hasPrev
                                  ? 'text-secondary-900'
                                  : 'text-muted pointer-events-none'
                              }`}
                              onClick={() => dispatch(loadPrevSchools())}
                            >
                              {t('common.prev')}
                            </button>
                            <button
                              className={`text-medium ${
                                pagination.hasNext
                                  ? 'text-secondary-900'
                                  : 'text-muted pointer-events-none'
                              }`}
                              onClick={() => dispatch(loadNextSchools())}
                            >
                              {t('common.next')}
                            </button>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

Schools.propTypes = {
  t: PropTypes.func
}

export default Schools
