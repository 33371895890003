import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Button,
  FormGroup,
  Input,
  TextArea,
  Select,
  PhoneInput,
  FileInput,
  DatePicker,
  Alert
} from '../..'

function InitiativeModal({ t, showModal, onCancel, onSubmit, errors, savingInitiative }) {
  const [state, setState] = useState({
    name: '',
    target_amount: '',
    end_date: '',
    visibility: '',
    category: '',
    initiative_type: '',
    support_document: '',
    contact: '',
    short_summary: ''
  })
  const [error, setError] = useState('')

  const disabledDate = (current) => current && current < moment().startOf('day')

  const handleChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const handleFileChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.files[0]
    }))
  }

  const handleDateChange = (date, dateString) => {
    setState((prevState) => ({
      ...prevState,
      end_date: dateString
    }))
  }

  const resetState = () =>
    setState({
      name: '',
      target_amount: '',
      end_date: '',
      visibility: '',
      category: '',
      initiative_type: '',
      support_document: '',
      contact: '',
      short_summary: ''
    })

  useEffect(() => {
    setError(errors.apiError)
  }, [errors.apiError])

  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={() => null}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button
                    onClick={() => {
                      onCancel()
                      resetState()
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>

                <div className=''>
                  <div className='font-gotham-rounded-bold text-primary text-3xl text-center'>
                    {t('changisha.add_initiative')}
                  </div>
                  {error && (
                    <Alert type='error' message={error} onCancel={() => setError('')} />
                  )}
                  <section className='mt-6'>
                    <div className='sm:grid sm:grid-cols-2 sm:gap-3'>
                      <FormGroup>
                        <Input
                          type='text'
                          placeholder={t('changisha.name')}
                          classes='placeholder:text-muted'
                          hasError={Object.keys(errors).includes('name')}
                          errorMessage={errors.name}
                          value={state.name}
                          onChange={handleChange}
                          name='name'
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input
                          type='number'
                          placeholder={t('changisha.target_amount')}
                          classes='placeholder:text-muted'
                          hasError={Object.keys(errors).includes('target_amount')}
                          errorMessage={errors.target_amount}
                          value={state.target_amount}
                          onChange={handleChange}
                          name='target_amount'
                        />
                      </FormGroup>
                    </div>
                    <div className='sm:grid sm:grid-cols-2 sm:gap-3'>
                      <FormGroup>
                        <Select
                          options={[
                            {
                              label: t('changisha.individual'),
                              value: 'individual'
                            },
                            {
                              label: t('changisha.organization'),
                              value: 'organization'
                            }
                          ]}
                          placeholderText={t('changisha.initiative_type')}
                          hasPlaceholder={true}
                          hasError={Object.keys(errors).includes('initiative_type')}
                          errorMessage={errors.initiative_type}
                          onChange={handleChange}
                          name='initiative_type'
                        />
                      </FormGroup>
                      <FormGroup>
                        <DatePicker
                          placeholder={t('changisha.end_date')}
                          onChange={handleDateChange}
                          name='dob'
                          showToday={false}
                          {...(state.end_date !== '' && { value: state.end_date })}
                          hasError={Object.keys(errors).includes('end_date')}
                          errorMessage={errors.end_date}
                          disabledDate={disabledDate}
                        />
                      </FormGroup>
                    </div>
                    <div className='sm:grid sm:grid-cols-2 sm:gap-3 mt-1'>
                      <FormGroup>
                        <Select
                          options={[
                            {
                              label: t('changisha.public'),
                              value: 'public'
                            },
                            {
                              label: t('changisha.private'),
                              value: 'private'
                            }
                          ]}
                          placeholderText={t('changisha.visibility')}
                          hasPlaceholder={true}
                          hasError={Object.keys(errors).includes('visibility')}
                          errorMessage={errors.visibility}
                          onChange={handleChange}
                          name='visibility'
                        />
                      </FormGroup>
                      <FormGroup>
                        <Select
                          options={[
                            {
                              label: t('changisha.medical'),
                              value: 'Medical'
                            },
                            {
                              label: t('changisha.agriculture'),
                              value: 'Agriculture'
                            },
                            {
                              label: t('changisha.education'),
                              value: 'Education'
                            },
                            {
                              label: t('changisha.wedding'),
                              value: 'Wedding'
                            },
                            {
                              label: t('changisha.funeral'),
                              value: 'Funeral'
                            },
                            {
                              label: t('changisha.other'),
                              value: 'Other'
                            }
                          ]}
                          placeholderText={t('changisha.category')}
                          hasPlaceholder={true}
                          hasError={Object.keys(errors).includes('category')}
                          errorMessage={errors.category}
                          onChange={handleChange}
                          name='category'
                        />
                      </FormGroup>
                    </div>
                    <div className='sm:grid sm:grid-cols-2 sm:gap-3'>
                      <FormGroup>
                        <FileInput
                          icon={
                            <>
                              <div className='mr-3 -rotate-45'>
                                <i className='bi bi-paperclip text-secondary-900 text-base'></i>
                              </div>
                              <div>
                                <i className='bi bi-camera-fill text-secondary-900 text-base'></i>
                              </div>
                            </>
                          }
                          id='support_document'
                          onChange={handleFileChange}
                          name='support_document'
                          file={state.support_document}
                          hasError={Object.keys(errors).includes('support_document')}
                          errorMessage={errors.support_document}
                          accept='image/*'
                          placeholder={t('changisha.support_document')}
                          classes='text-body'
                        />
                      </FormGroup>
                      <FormGroup>
                        <PhoneInput
                          value={state.contact}
                          onChange={(phone) =>
                            setState((state) => ({ ...state, contact: phone }))
                          }
                          placeholder={t('changisha.initiator_contact')}
                          hasError={Object.keys(errors).includes('contact')}
                          errorMessage={errors.contact}
                          name='contact'
                          classes='placeholder:text-muted'
                        />
                      </FormGroup>
                    </div>
                    <TextArea
                      placeholder={t('changisha.short_summary')}
                      classes='placeholder:text-muted'
                      rows={3}
                      hasError={Object.keys(errors).includes('short_summary')}
                      errorMessage={errors.short_summary}
                      name='short_summary'
                      onChange={handleChange}
                    />

                    <div className='mt-4 sm:px-28'>
                      <Button
                        label={t('changisha.add_initiative')}
                        onClick={() => {
                          onSubmit(state)
                        }}
                        disabled={savingInitiative}
                        classes='uppercase'
                      />
                    </div>
                  </section>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

InitiativeModal.propTypes = {
  t: PropTypes.func,
  showModal: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  errors: PropTypes.object,
  savingInitiative: PropTypes.bool
}

export default InitiativeModal
