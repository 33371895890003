const envVars = process.env

const config = (env) => {
  const key = `REACT_APP_${env}`
  if (!envVars[key]) throw new Error('no config found for ' + key)

  return envVars[key]
}

export default config
