const services = () => ({
  CHANGISHA: 'Changisha',
  JAZA_SERVICES: 'Jaza Services',
  PATA_VOUCHER: 'Pata Voucher',
  OKOA_SHOPPING: 'Okoa Shopping',
  OKOA_FEES: 'Okoa School Fees',
  OKOA_KILIMO: 'Okoa Kilimo',
  OKOA_AFYA: 'Okoa Afya',
  OKOA_LOGISTICS: 'Okoa Logistics',
  OKOA_BILLS: 'Okoa Bills',
  INUA_BIZ: 'Inua Biashara'
})

module.exports = services
