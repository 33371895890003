import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  notification: {
    type: 'default',
    message: '',
    show: false,
    duration: 'long'
  },
  alert: {
    type: 'default',
    message: '',
    show: false,
    duration: 'long'
  }
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateNotificationState: (state, action) => {
      state.notification = {
        type: action.payload?.type,
        message: action.payload?.message,
        show: action.payload?.show,
        duration: action.payload?.duration
      }
    },
    updateAlert: (state, action) => {
      state.alert = {
        type: action.payload?.type,
        message: action.payload?.message,
        show: action.payload?.show,
        duration: action.payload?.duration
      }
    }
  }
})

export const { updateNotificationState, updateAlert } = dashboardSlice.actions

export default dashboardSlice.reducer
