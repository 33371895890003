import PropTypes from 'prop-types'

function ListSkeleton({ length }) {
  return (
    <div key={''} className='max-w-sm w-4/5'>
      <div className='animate-pulse flex space-x-4'>
        <div className='flex-1 space-y-6 py-1'>
          <div className='space-y-3'>
            <div className='h-2 bg-slate-200 rounded'></div>
            <div className='h-2 bg-slate-200 rounded'></div>
            <div className='h-2 bg-slate-200 rounded'></div>
            <div className='h-2 bg-slate-200 rounded'></div>
          </div>
        </div>
      </div>
    </div>
  )
}

ListSkeleton.defaulProps = {
  length: 3
}

ListSkeleton.propTypes = {
  length: PropTypes.number
}

export default ListSkeleton
