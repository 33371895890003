import axios from 'axios'
import toSnakeCase from 'snakecase-keys'
import toCamelCase from 'camelcase-keys'
import { is } from 'ramda'
import { getUserDetails } from '../helpers/utils'
import config from '../helpers/config'
import i18n from '../i18n'

const authDetails = getUserDetails()
const AUTH_TOKEN = authDetails && authDetails.token

const transformRequest = [
  (data) => data && toSnakeCase(data, { deep: true }),
  ...axios.defaults.transformRequest
]
const transformResponse = [
  ...axios.defaults.transformResponse,
  (data) => data && toCamelCase(data, { deep: true })
]

const requestDefaults = {
  baseURL: config('PAYLEND_API_BASE_URL'),
  headers: () => ({
    'Content-Type': 'application/json',
    ...(AUTH_TOKEN ? { Authorization: `Bearer ${AUTH_TOKEN}` } : {}),
    'utm-source': 'web'
  }),
  transformResponse,
  transformRequest
}

const formatErrors = ({ status, data: { errors, error } }) => {
  return is(Array, errors)
    ? errors.map((error) => ({
        status: status,
        code: error.code,
        message: error.description || error.message,
        details: error.details || []
      }))
    : {
        ...(error?.message ? { message: error.message } : {}),
        ...(error?.code ? { code: error.code } : {}),
        status: status || error.status,
        ...(error?.details
          ? {
              details: error.details.map((details) => ({
                message: details.message,
                param: details.param,
                value: details.value
              }))
            }
          : {})
      }
}

export default function request(configDefaults) {
  const defaults = { ...requestDefaults, ...configDefaults }
  const api = (method, url, data) => {
    return new Promise((resolve, reject) => {
      return axios({
        url: `${defaults.baseURL}${url}`,
        method,
        headers: defaults.headers(),
        params: method === 'get' ? data : undefined,
        data: method !== 'get' ? data : undefined,
        transformRequest: defaults.transformRequest,
        transformResponse: defaults.transformResponse
      }).then(
        (response) => {
          const data = response?.data?.data || response?.data
          const metadata = response?.data?.metadata
          return resolve(metadata ? { data, ...metadata } : data)
        },
        (error) => {
          const status = error?.response?.status || 503
          const data = error?.response?.data
          const hasErrorsOrError = data?.errors || data?.error
          reject(
            (hasErrorsOrError && formatErrors(error.response)) || {
              message: i18n.t('common.default_error_message'),
              status
            }
          )
        }
      )
    })
  }

  return {
    get: (...args) => api('get', ...args),
    post: (...args) => api('post', ...args),
    patch: (...args) => api('patch', ...args),
    put: (...args) => api('put', ...args),
    delete: (...args) => api('delete', ...args)
  }
}

export { formatErrors }
