import { createSlice } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { head, isEmpty, splitEvery, last, flatten, dropLast, isNil } from 'ramda'
import { cloneDeep } from 'lodash'
import { getUserTransactions } from '../../../api/transactions'
import { getUserDetails, omitEmptyValues } from '../../../helpers/utils'
import i18n from '../../../i18n'
import {
  validatePayment,
  validateSearchShop,
  validateOkoaStepTwo,
  validateAddShopDetails,
  validateShopManager,
  validateSendOtp,
  validateVerifyOtp
} from './validator'
import { makePayment, premiseWithdrawal, stkPushStatus } from '../../../api/payments'
import { getUserLoans, requestLoan } from '../../../api/loans'
import {
  createPremise,
  getNearbyPremises,
  getPremises,
  getPremiseWallets
} from '../../../api/premises'
import { getSignedUrl, uploadFileToS3, storeMedia } from '../../../api/media'
import config from '../../../helpers/config'
import { fetchMetrics } from '../../Auth/store'
import { otpVerification, sendOtp } from '../../../api/auth'
import { fetchRegisteredPremises } from '../../AgentDashboard/store'

export const initialState = {
  isLoading: true,
  recentTransactions: [],
  shopsNearby: [],
  shops: [],
  loadingPayment: false,
  errors: {},
  stkPushRequestId: null,
  transactions: [],
  transactionsPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  repayments: [],
  repaymentsPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  voucherTransactions: [],
  voucherTransactionsPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  shopsPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  nearbyShopsPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  loans: [],
  loansPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  okoaRequest: {},
  searchingShop: false,
  hasSavedStepDetails: false,
  savingOkoaRequest: false,
  hasSavedOkoaRequest: false,
  shopWallets: [],
  shopDetails: {},
  savingShop: false,
  hasSavedShop: false,
  totalPremises: 0,
  repayingLoan: false,
  withdrawData: {},
  hasRepaidLoan: false,
  withdrawing: false,
  hasWithdrawn: false,
  hasSentOtp: false,
  sendingOtp: false,
  notification: '',
  activeTransactionsTab: 0
}

const PAGE_LIMIT = 10

export const okoaShoppingSlice = createSlice({
  name: 'okoashopping',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setRecentTransactions: (state, action) => {
      state.recentTransactions = action.payload
    },
    setShopsNearby: (state, action) => {
      state.shopsNearby = action.payload
    },
    setTransactions: (state, action) => {
      state.transactions = action.payload
    },
    setShops: (state, action) => {
      state.shops = action.payload
    },
    setLoadingPayment: (state, action) => {
      state.loadingPayment = action.payload
    },
    setErrors: (state, action) => {
      state.errors = action.payload
    },
    setStkPushRequestId: (state, action) => {
      state.stkPushRequestId = action.payload
    },
    setTransactionsPagination: (state, action) => {
      state.transactionsPagination = action.payload
    },
    setShopsPagination: (state, action) => {
      state.shopsPagination = action.payload
    },
    setNearbyShopsPagination: (state, action) => {
      state.nearbyShopsPagination = action.payload
    },
    setLoans: (state, action) => {
      state.loans = action.payload
    },
    setLoansPagination: (state, action) => {
      state.loansPagination = action.payload
    },
    setRepayments: (state, action) => {
      state.repayments = action.payload
    },
    setRepaymentsPagination: (state, action) => {
      state.repaymentsPagination = action.payload
    },
    setVoucherTransactions: (state, action) => {
      state.voucherTransactions = action.payload
    },
    setVoucherTransactionsPagination: (state, action) => {
      state.voucherTransactionsPagination = action.payload
    },
    setOkoaRequest: (state, action) => {
      state.okoaRequest = action.payload
    },
    setSearchingShop: (state, action) => {
      state.searchingShop = action.payload
    },
    setHasSavedStepDetails: (state, action) => {
      state.hasSavedStepDetails = action.payload
    },
    setSavingOkoaRequest: (state, action) => {
      state.savingOkoaRequest = action.payload
    },
    setHasSavedOkoaRequest: (state, action) => {
      state.hasSavedOkoaRequest = action.payload
    },
    setShopWallets: (state, action) => {
      state.shopWallets = action.payload
    },
    setShopDetails: (state, action) => {
      state.shopDetails = action.payload
    },
    setSavingShop: (state, action) => {
      state.savingShop = action.payload
    },
    setHasSavedShop: (state, action) => {
      state.hasSavedShop = action.payload
    },
    setTotalPremises: (state, action) => {
      state.totalPremises = action.payload
    },
    setRepayingLoan: (state, action) => {
      state.repayingLoan = action.payload
    },
    setHasRepaidLoan: (state, action) => {
      state.hasRepaidLoan = action.payload
    },
    setWithdrawing: (state, action) => {
      state.withdrawing = action.payload
    },
    setHasWithdrawn: (state, action) => {
      state.hasWithdrawn = action.payload
    },
    setWithdrawData: (state, action) => {
      state.withdrawData = action.payload
    },
    setHasSentOtp: (state, action) => {
      state.hasSentOtp = action.payload
    },
    setSendingOtp: (state, action) => {
      state.sendingOtp = action.payload
    },
    setNotification: (state, action) => {
      state.notification = action.payload
    },
    setActiveTransactionsTab: (state, action) => {
      state.activeTransactionsTab = action.payload
    }
  }
})

export const {
  setIsLoading,
  setRecentTransactions,
  setShopsNearby,
  setTransactions,
  setShops,
  setLoadingPayment,
  setErrors,
  setStkPushRequestId,
  setTransactionsPagination,
  setShopsPagination,
  setNearbyShopsPagination,
  setLoans,
  setLoansPagination,
  setOkoaRequest,
  setSearchingShop,
  setHasSavedStepDetails,
  setSavingOkoaRequest,
  setHasSavedOkoaRequest,
  setShopWallets,
  setShopDetails,
  setSavingShop,
  setHasSavedShop,
  setTotalPremises,
  setRepayingLoan,
  setHasRepaidLoan,
  setWithdrawing,
  setHasWithdrawn,
  setWithdrawData,
  setHasSentOtp,
  setSendingOtp,
  setNotification,
  setRepayments,
  setRepaymentsPagination,
  setVoucherTransactions,
  setVoucherTransactionsPagination,
  setActiveTransactionsTab
} = okoaShoppingSlice.actions

export const repayLoan = (payload) => async (dispatch, getState) => {
  try {
    const { wallet } = getState().auth
    dispatch(setLoadingPayment(true))
    dispatch(setErrors({}))
    dispatch(setHasRepaidLoan(false))

    await validatePayment(
      {
        ...payload,
        transaction_mode: 'stk_push'
      },
      wallet.okoaShopping.payable
    )

    const res = await makePayment({
      ...payload,
      user_id: getUserDetails().id,
      action_type: 'repay_loan_by_user',
      reference_occasion: 'okoa_shopping',
      transaction_mode: 'stk_push'
    })

    dispatch(setLoadingPayment(false))
    dispatch(setHasRepaidLoan(true))
    dispatch(setStkPushRequestId(res.checkoutRequestId))
  } catch (error) {
    Sentry.captureException(error)
    dispatch(setLoadingPayment(false))
    const isValidatorError = Array.isArray(error.errors)

    if (isValidatorError) {
      const message = { [error.path]: head(error.errors) }
      return dispatch(setErrors(message))
    }
    dispatch(setErrors({ apiError: error.message }))
  }
}

export const getStkPushStatus = (requestId) => async (dispatch) => {
  try {
    dispatch(setLoadingPayment(true))

    await stkPushStatus({ checkout_request_id: requestId })

    dispatch(setLoadingPayment(false))
    dispatch(setStkPushRequestId(null))
  } catch (error) {
    dispatch(setLoadingPayment(false))
  }
}

export const fetchRecentTransactions = (params) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true))
    const { id: userId } = getUserDetails()
    const { data } = await getUserTransactions({
      user_id: userId,
      ...(params.premise_owner_id && {
        premise_owner_id: params.premise_owner_id
      }),
      reference_occasion: 'okoa_shopping',
      limit: PAGE_LIMIT,
      sort: '-created_at',
      ...(!isEmpty(params) && { ...params })
    })

    dispatch(setRecentTransactions(data))

    dispatch(setIsLoading(false))
  } catch (error) {
    dispatch(setIsLoading(false))
    Sentry.captureException(error)
    dispatch(setErrors({ apiError: error.message }))
  }
}

export const fetchRepayments =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { id: userId } = getUserDetails()
      const { data, count } = await getUserTransactions({
        user_id: userId,
        reference_occasion: 'okoa_shopping',
        action_type: 'repay_loan_by_user',
        limit: PAGE_LIMIT,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setRepayments(data))

      dispatch(
        setRepaymentsPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }

export function loadNextRepayments(params = {}) {
  return async (dispatch, getState) => {
    try {
      const { repayments: transactions, repaymentsPagination: pagination } = cloneDeep(
        getState().shopping
      )
      const transaction = transactions[transactions.length - 1]
      const cursor = transaction.id
      const { data, count } = await getUserTransactions({
        user_id: getUserDetails().id,
        reference_occasion: 'okoa_shopping',
        action_type: 'repay_loan_by_user',
        limit: PAGE_LIMIT,
        after: cursor,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setRepayments(data))

      dispatch(
        setRepaymentsPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...transactions],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function loadPrevRepayments(params = {}) {
  return async (dispatch, getState) => {
    const { repaymentsPagination: pagination, repayments: stateTransactions } =
      getState().shopping
    const transactions = pagination.paginated
    const transactionChunks = splitEvery(PAGE_LIMIT, transactions)

    if (isEmpty(transactionChunks) || transactionChunks.length === 1) {
      try {
        const { data, count } = await getUserTransactions({
          user_id: getUserDetails().id,
          reference_occasion: 'okoa_shopping',
          action_type: 'repay_loan_by_user',
          limit: PAGE_LIMIT,
          sort: '-created_at',
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setRepayments(data))

        dispatch(
          setRepaymentsPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
        dispatch(setErrors({ apiError: error.message }))
      }
    } else {
      const previousTransactions = last(transactionChunks)
      dispatch(setRepayments(previousTransactions))

      dispatch(
        setRepaymentsPagination({
          hasNext: transactions.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (stateTransactions.length < PAGE_LIMIT
              ? stateTransactions.length
              : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, transactionChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export const fetchVoucherTransactions =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { id: userId } = getUserDetails()
      const { data, count } = await getUserTransactions({
        user_id: userId,
        reference_occasion: 'pata_voucher',
        action_type: 'redeem_voucher',
        sub_wallet: 'shopping',
        premises_owner_id: userId,
        limit: PAGE_LIMIT,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setVoucherTransactions(data))

      dispatch(
        setVoucherTransactionsPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }

export function loadNextVoucherTransactions(params = {}) {
  return async (dispatch, getState) => {
    try {
      const {
        voucherTransactions: transactions,
        voucherTransactionsPagination: pagination
      } = cloneDeep(getState().shopping)
      const transaction = transactions[transactions.length - 1]
      const cursor = transaction.id
      const { data, count } = await getUserTransactions({
        user_id: getUserDetails().id,
        reference_occasion: 'pata_voucher',
        action_type: 'redeem_voucher',
        sub_wallet: 'shopping',
        premises_owner_id: getUserDetails().id,
        limit: PAGE_LIMIT,
        after: cursor,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setVoucherTransactions(data))

      dispatch(
        setVoucherTransactionsPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...transactions],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function loadPrevVoucherTransactions(params = {}) {
  return async (dispatch, getState) => {
    const {
      voucherTransactionsPagination: pagination,
      voucherTransactions: stateTransactions
    } = getState().shopping
    const transactions = pagination.paginated
    const transactionChunks = splitEvery(PAGE_LIMIT, transactions)

    if (isEmpty(transactionChunks) || transactionChunks.length === 1) {
      try {
        const { data, count } = await getUserTransactions({
          user_id: getUserDetails().id,
          reference_occasion: 'pata_voucher',
          action_type: 'redeem_voucher',
          sub_wallet: 'shopping',
          premises_owner_id: getUserDetails().id,
          limit: PAGE_LIMIT,
          sort: '-created_at',
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setVoucherTransactions(data))

        dispatch(
          setVoucherTransactionsPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
        dispatch(setErrors({ apiError: error.message }))
      }
    } else {
      const previousTransactions = last(transactionChunks)
      dispatch(setVoucherTransactions(previousTransactions))

      dispatch(
        setVoucherTransactionsPagination({
          hasNext: transactions.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (stateTransactions.length < PAGE_LIMIT
              ? stateTransactions.length
              : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, transactionChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export const fetchTransactions =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { id: userId } = getUserDetails()
      const { data, count } = await getUserTransactions({
        user_id: userId,
        ...(params.premise_owner_id && {
          premise_owner_id: params.premise_owner_id
        }),
        reference_occasion: 'okoa_shopping',
        limit: PAGE_LIMIT,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setTransactions(data))

      dispatch(
        setTransactionsPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }

export function loadNextTransactions(params = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))
      const { transactions, transactionsPagination: pagination } = cloneDeep(
        getState().shopping
      )
      const transaction = transactions[transactions.length - 1]
      const cursor = transaction.id
      const { data, count } = await getUserTransactions({
        user_id: getUserDetails().id,
        ...(params.premise_owner_id && {
          premise_owner_id: params.premise_owner_id
        }),
        reference_occasion: 'okoa_shopping',
        limit: PAGE_LIMIT,
        after: cursor,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setTransactions(data))

      dispatch(
        setTransactionsPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...transactions],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function loadPrevTransactions(params = {}) {
  return async (dispatch, getState) => {
    const { transactionsPagination: pagination, transactions: stateTransactions } =
      getState().shopping
    const transactions = pagination.paginated
    const transactionChunks = splitEvery(PAGE_LIMIT, transactions)

    if (isEmpty(transactionChunks) || transactionChunks.length === 1) {
      try {
        dispatch(setIsLoading(true))

        const { data, count } = await getUserTransactions({
          user_id: getUserDetails().id,
          ...(params.premise_owner_id && {
            premise_owner_id: params.premise_owner_id
          }),
          reference_occasion: 'okoa_shopping',
          limit: PAGE_LIMIT,
          sort: '-created_at',
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setTransactions(data))

        dispatch(
          setTransactionsPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
        dispatch(setErrors({ apiError: error.message }))
      }
    } else {
      const previousTransactions = last(transactionChunks)
      dispatch(setTransactions(previousTransactions))

      dispatch(
        setTransactionsPagination({
          hasNext: transactions.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (stateTransactions.length < PAGE_LIMIT
              ? stateTransactions.length
              : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, transactionChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export const fetchLoans =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { id: userId } = getUserDetails()
      const { data, count } = await getUserLoans({
        ...(params.premise_owner_id
          ? { premise_owner_id: params.premise_owner_id }
          : { user_id: userId }),
        loan_type: 'okoa_shopping',
        limit: PAGE_LIMIT,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setLoans(data))

      dispatch(
        setLoansPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }

export function loadNextLoans(params = {}) {
  return async (dispatch, getState) => {
    try {
      const { loans, loansPagination: pagination } = cloneDeep(getState().shopping)
      const loan = loans[loans.length - 1]
      const cursor = loan.id
      const { data, count } = await getUserLoans({
        ...(params.premise_owner_id
          ? { premise_owner_id: params.premise_owner_id }
          : { user_id: getUserDetails().id }),
        loan_type: 'okoa_shopping',
        limit: PAGE_LIMIT,
        after: cursor,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setLoans(data))

      dispatch(
        setLoansPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...loans],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function loadPrevLoans(params = {}) {
  return async (dispatch, getState) => {
    const { loansPagination: pagination, loans: stateLoans } = getState().shopping
    const loans = pagination.paginated
    const loanChunks = splitEvery(PAGE_LIMIT, loans)

    if (isEmpty(loanChunks) || loanChunks.length === 1) {
      try {
        const { data, count } = await getUserLoans({
          ...(params.premise_owner_id
            ? { premise_owner_id: params.premise_owner_id }
            : { user_id: getUserDetails().id }),
          loan_type: 'okoa_shopping',
          limit: PAGE_LIMIT,
          sort: '-created_at',
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setLoans(data))

        dispatch(
          setLoansPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
        dispatch(setErrors({ apiError: error.message }))
      }
    } else {
      const previousLoans = last(loanChunks)
      dispatch(setLoans(previousLoans))

      dispatch(
        setLoansPagination({
          hasNext: loans.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (stateLoans.length < PAGE_LIMIT ? stateLoans.length : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, loanChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export const fetchNearbyShops =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { data, count } = await getNearbyPremises({
        premise_type: 'shop',
        limit: PAGE_LIMIT,
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setShopsNearby(data))

      dispatch(
        setNearbyShopsPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }

export function loadNextNearbyShops(params = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))
      const { shopsNearby, nearbyShopsPagination: pagination } = cloneDeep(
        getState().shopping
      )
      const shop = [shopsNearby.length - 1]
      const cursor = shop.id
      const { data, count } = await getNearbyPremises({
        premise_type: 'shop',
        limit: PAGE_LIMIT,
        after: cursor,
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setShopsNearby(data))

      dispatch(
        setNearbyShopsPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...shopsNearby],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function loadPrevNearbyShops(params = {}) {
  return async (dispatch, getState) => {
    const { nearbyShopsPagination: pagination, shopsNearby: stateShopsNearby } =
      getState().shopping
    const shops = pagination.paginated
    const shopsChunks = splitEvery(PAGE_LIMIT, shops)

    if (isEmpty(shopsChunks) || shopsChunks.length === 1) {
      try {
        dispatch(setIsLoading(true))

        const { data, count } = await getNearbyPremises({
          premise_type: 'shop',
          limit: PAGE_LIMIT,
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setShopsNearby(data))

        dispatch(
          setNearbyShopsPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
        dispatch(setErrors({ apiError: error.message }))
      }
    } else {
      const previousShops = last(shopsChunks)
      dispatch(setShopsNearby(previousShops))

      dispatch(
        setNearbyShopsPagination({
          hasNext: shops.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (stateShopsNearby.length < PAGE_LIMIT ? stateShopsNearby.length : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, shopsChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export const fetchShops =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { id: userId } = getUserDetails()
      const [{ data, count }, { data: userPremises }] = await Promise.all([
        getPremises({
          user_id: userId,
          premise_type: 'shop',
          limit: PAGE_LIMIT,
          ...(!isEmpty(params) && { ...params })
        }),
        getPremises({
          user_id: userId,
          limit: PAGE_LIMIT,
          ...(!isEmpty(params) && { ...params })
        })
      ])

      dispatch(setShops(data))
      dispatch(setTotalPremises(userPremises.length))

      dispatch(
        setShopsPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }

export function loadNextShops(params = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))
      const { shops, shopsPagination: pagination } = cloneDeep(getState().shopping)
      const shop = [shops.length - 1]
      const cursor = shop.id
      const { data, count } = await getPremises({
        user_id: getUserDetails().id,
        premise_type: 'shop',
        limit: PAGE_LIMIT,
        after: cursor,
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setShops(data))

      dispatch(
        setShopsPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...shops],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function loadPrevShops(params = {}) {
  return async (dispatch, getState) => {
    const { shopsPagination: pagination, shops: stateShops } = getState().shopping
    const shops = pagination.paginated
    const shopsChunks = splitEvery(PAGE_LIMIT, shops)

    if (isEmpty(shopsChunks) || shopsChunks.length === 1) {
      try {
        dispatch(setIsLoading(true))

        const { data, count } = await getPremises({
          user_id: getUserDetails().id,
          premise_type: 'shop',
          limit: PAGE_LIMIT,
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setShops(data))

        dispatch(
          setShopsPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
        dispatch(setErrors({ apiError: error.message }))
      }
    } else {
      const previousShops = last(shopsChunks)
      dispatch(setShops(previousShops))

      dispatch(
        setShopsPagination({
          hasNext: shops.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (stateShops.length < PAGE_LIMIT ? stateShops.length : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, shopsChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export function searchShopByNumber(payload) {
  return async (dispatch, getState) => {
    try {
      dispatch(setSearchingShop(true))
      dispatch(setErrors({}))
      await validateSearchShop(payload)
      const prevOkoaRequestState = getState().shopping.okoaRequest
      const { data } = await getPremises({
        premise_type: 'shop',
        ...payload,
        limit: 500000
      })

      if (isEmpty(data)) {
        dispatch(
          setOkoaRequest({
            paylend_number: payload.paylend_number
          })
        )
        dispatch(setErrors({ apiError: i18n.t('okoa_shopping.no_shop_found') }))
      } else {
        dispatch(
          setOkoaRequest({
            ...prevOkoaRequestState,
            shop: {
              premiseName: data[0].premiseName,
              premiseAddress: data[0].premiseAddress
            },
            paylend_number: payload.paylend_number
          })
        )
      }

      dispatch(setSearchingShop(false))
    } catch (error) {
      dispatch(setSearchingShop(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function submitOkoaAmount(data) {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))
      dispatch(setHasSavedStepDetails(false))
      await validateOkoaStepTwo(data)
      dispatch(setErrors({}))
      const { okoaRequest } = getState().shopping
      dispatch(setOkoaRequest({ ...okoaRequest, ...data }))
      dispatch(setIsLoading(false))
      dispatch(setHasSavedStepDetails(true))
    } catch (error) {
      dispatch(setHasSavedStepDetails(false))
      dispatch(setIsLoading(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }

      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function submitOkoaRequest(data) {
  return async (dispatch, getState) => {
    try {
      dispatch(setHasSavedOkoaRequest(false))
      dispatch(setSavingOkoaRequest(true))
      dispatch(setErrors({}))

      const user = getUserDetails()
      const { okoaRequest } = getState().shopping

      await validateVerifyOtp({
        type: 'verify',
        code: data.otp,
        phone: user.phone
      })

      await requestLoan({
        user_id: user.id,
        loan_type: 'okoa_shopping',
        paylend_number: okoaRequest.paylend_number,
        repayment_plan: okoaRequest.repayment_plan,
        amount_borrowed: okoaRequest.amount_borrowed,
        otp: data.otp
      })

      dispatch(fetchRecentTransactions({}))
      dispatch(fetchMetrics())

      dispatch(setSavingOkoaRequest(false))
      dispatch(setHasSavedOkoaRequest(true))
      dispatch(setOkoaRequest({}))
    } catch (error) {
      dispatch(setSavingOkoaRequest(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function fetchShopWallets() {
  return async (dispatch) => {
    try {
      const user = getUserDetails()
      const [{ data: shops }, { data }] = await Promise.all([
        getPremises({
          user_id: user.id,
          premise_type: 'shop',
          limit: PAGE_LIMIT
        }),
        getPremiseWallets({
          user_id: user.id
        })
      ])

      let ids = []
      if (!isEmpty(shops)) {
        ids = shops.map((shop) => shop.id)
      }

      dispatch(setIsLoading(true))
      const formattedData = data.filter((wallet) => ids.includes(wallet.premiseId))
      dispatch(setShopWallets(formattedData))
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function addShopDetails(data) {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true))
      dispatch(setHasSavedStepDetails(false))
      await validateAddShopDetails(data)
      dispatch(setErrors({}))
      dispatch(setIsLoading(false))
      dispatch(setHasSavedStepDetails(true))
    } catch (error) {
      dispatch(setHasSavedStepDetails(false))
      dispatch(setIsLoading(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }

      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function saveShop(data) {
  return async (dispatch, getState) => {
    try {
      dispatch(setHasSavedShop(false))
      dispatch(setSavingShop(true))
      const agentState = getState().agent.currentPremiseState

      const user = agentState.user || getUserDetails()

      let identificationSignedUrl

      await validateShopManager(data)

      const [permitSignedUrl, shopImageSignedUrl, statementSignedUrl] = await Promise.all(
        [
          getSignedUrl({
            filename: data.business_permit.name,
            content_type: data.business_permit.type
          }),
          getSignedUrl({
            filename: data.premise_front_image.name,
            content_type: data.premise_front_image.type
          }),
          getSignedUrl({
            filename: data.mpesa_statement.name,
            content_type: data.mpesa_statement.type
          })
        ]
      )

      if (typeof data.manager_identification === 'object') {
        identificationSignedUrl = await getSignedUrl({
          filename: data.manager_identification.name,
          content_type: data.manager_identification.type
        })
        await uploadFileToS3({
          url: identificationSignedUrl.url,
          body: data.manager_identification
        })
        await storeMedia({
          user_id: user.id,
          filename: `${identificationSignedUrl.filename}`,
          content_type: data.manager_identification.type,
          category: 'identification_document'
        })
      }

      const formattedData = omitEmptyValues(data)

      await Promise.all([
        uploadFileToS3({ url: permitSignedUrl.url, body: data.business_permit }),
        uploadFileToS3({ url: shopImageSignedUrl.url, body: data.premise_front_image }),
        uploadFileToS3({ url: statementSignedUrl.url, body: data.mpesa_statement }),
        storeMedia({
          user_id: user.id,
          filename: `${permitSignedUrl.filename}`,
          content_type: data.business_permit.type,
          category: 'business_permit'
        }),
        storeMedia({
          user_id: user.id,
          filename: `${shopImageSignedUrl.filename}`,
          content_type: data.premise_front_image.type,
          category: 'shop_front_image'
        }),
        storeMedia({
          user_id: user.id,
          filename: `${statementSignedUrl.filename}`,
          content_type: data.mpesa_statement.type,
          category: 'mpesa_statement'
        }),
        createPremise({
          ...formattedData,
          business_permit: `${config('S3_BUCKET_URL')}/${permitSignedUrl.filename}`,
          premise_front_image: `${config('S3_BUCKET_URL')}/${
            shopImageSignedUrl.filename
          }`,
          ...(typeof data.manager_identification === 'object'
            ? {
                manager_identification: `${config('S3_BUCKET_URL')}/${
                  identificationSignedUrl.filename
                }`
              }
            : {
                manager_identification: data.manager_identification
              }),
          mpesa_statement: `${config('S3_BUCKET_URL')}/${statementSignedUrl.filename}`,
          user_id: user.id,
          premise_type: 'shop'
        })
      ])

      dispatch(fetchShops())
      dispatch(fetchShopWallets())

      dispatch(setSavingShop(false))
      dispatch(setHasSavedShop(true))
      dispatch(setShopDetails({}))
      if (!isNil(data.registering_agent)) {
        dispatch(fetchRegisteredPremises())
      }
    } catch (error) {
      dispatch(setSavingShop(false))
      Sentry.captureException(error)
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function withdraw(data) {
  return async (dispatch, getState) => {
    try {
      dispatch(setErrors({}))
      dispatch(setWithdrawing(true))
      dispatch(setHasWithdrawn(false))

      const user = getUserDetails()
      const { withdrawData, shopWallets } = getState().shopping

      await validateVerifyOtp({
        type: 'verify',
        code: data.code,
        phone: user.phone
      })

      await otpVerification({
        phone: user.phone,
        ...data
      })

      const wallet = shopWallets.find((w) => w.id === withdrawData.premise)

      await premiseWithdrawal({
        amount: withdrawData.amount,
        phone: user.phone,
        premise_id: wallet.premiseId,
        premise_wallet_id: wallet.id,
        reference_occasion: 'inua_biashara',
        user_id: user.id
      })

      dispatch(setWithdrawing(false))
      dispatch(setHasWithdrawn(true))
    } catch (error) {
      dispatch(setWithdrawing(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function onSendOtp(data) {
  return async (dispatch) => {
    try {
      dispatch(setErrors({}))
      dispatch(setSendingOtp(true))
      dispatch(setNotification(''))

      await validateSendOtp({
        phone: getUserDetails().phone
      })
      await sendOtp(data)

      dispatch(setErrors({}))
      dispatch(setSendingOtp(false))
      dispatch(setHasSentOtp(true))
      dispatch(setNotification(i18n.t('common.otp_sent')))
    } catch (error) {
      dispatch(setSendingOtp(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export default okoaShoppingSlice.reducer
