import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, FormGroup, Input, Select, DatePicker } from '../..'
import { getUserDetails } from '../../../helpers/utils'

function StandardAccessModal({
  translate,
  showModal,
  onCancel,
  onSubmit,
  errors,
  savingUser
}) {
  const user = getUserDetails()
  const [state, setState] = useState({
    dob: '',
    occupation: '',
    income: '',
    gender: '',
    identification: ''
  })

  const handleDateChange = (date, dateString) => {
    setState((prevState) => ({
      ...prevState,
      dob: dateString
    }))
  }

  const handleChange = (event) =>
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }))

  const handleFileChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.files[0]
    }))
  }

  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={() => null}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block p-6 my-8 w-full max-w-md overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button className='focus:outline-none outline-none' onClick={onCancel}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>
                <div className=''>
                  <header className='font-gotham-rounded-medium text-secondary-900 text-2xl mb-2 text-center'>
                    {translate('okoa_fees.hello')} {user.firstname}
                  </header>
                  <div className='text-slate text-sm font-normal text-center mb-4'>
                    {translate('signup.full_access_help_text')}
                  </div>

                  <section className='mt-3'>
                    <div className=''>
                      <FormGroup>
                        <DatePicker
                          placeholder={translate('signup.date_of_birth')}
                          onChange={handleDateChange}
                          name='dob'
                          showToday={false}
                          {...(state.dob !== '' && { value: state.dob })}
                          hasError={Object.keys(errors).includes('dob')}
                          errorMessage={errors.dob}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Select
                          options={[
                            {
                              label: translate('signup.male'),
                              value: 'male'
                            },
                            {
                              label: translate('signup.female'),
                              value: 'female'
                            }
                          ]}
                          hasPlaceholder={true}
                          placeholderText={translate('signup.gender')}
                          onChange={handleChange}
                          name='gender'
                          hasError={Object.keys(errors).includes('gender')}
                          errorMessage={errors.gender}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Input
                          type='text'
                          placeholder={translate('signup.occupation')}
                          onChange={handleChange}
                          name='occupation'
                          hasError={Object.keys(errors).includes('occupation')}
                          errorMessage={errors.occupation}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Input
                          type='number'
                          placeholder={translate('signup.monthly_income')}
                          onChange={handleChange}
                          name='income'
                          hasError={Object.keys(errors).includes('income')}
                          errorMessage={errors.income}
                        />
                      </FormGroup>

                      <FormGroup>
                        <div className='relative'>
                          <label
                            htmlFor='id_images'
                            className={`bg-white border ${
                              Object.keys(errors).includes('identification')
                                ? 'border-red-300'
                                : 'border-secondary-300'
                            } text-body rounded focus:ring-0 outline-none focus:outline-none focus:border-secondary-500 block w-full pr-4 py-2.5 font-gotham-rounded-book text-medium pl-4`}
                          >
                            {state.identification !== '' ? (
                              <>{`${
                                state.identification.name.length > 30
                                  ? `${state.identification.name.substring(0, 30)}...`
                                  : state.identification.name
                              }`}</>
                            ) : (
                              <span className='opacity-50'>
                                {translate('signup.id_images')}
                              </span>
                            )}
                          </label>
                          <div className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 justify-between'>
                            <i className='bi bi-camera-fill text-secondary-900 text-base'></i>
                          </div>
                        </div>
                        <Input
                          type='file'
                          placeholder={translate('signup.id_images')}
                          id='id_images'
                          classes='hidden'
                          name='identification'
                          onChange={handleFileChange}
                          hasError={Object.keys(errors).includes('identification')}
                          errorMessage={errors.identification}
                        />
                      </FormGroup>
                    </div>
                    <div className='mt-4 sm:px-10'>
                      <Button
                        label={translate('okoa_fees.get_started')}
                        onClick={() => onSubmit(state)}
                        classes='uppercase'
                        disabled={savingUser}
                      />
                    </div>

                    <div className='sm:px-16 mt-4 opacity-80 text-medium text-center'>
                      {translate('okoa_fees.details_assurance')}
                    </div>
                  </section>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

StandardAccessModal.propTypes = {
  translate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showModal: PropTypes.func,
  errors: PropTypes.object,
  savingUser: PropTypes.bool
}

export default StandardAccessModal
