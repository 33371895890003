import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import PropTypes from 'prop-types'
import AutoComplete from 'react-google-autocomplete'
import { Button, FormGroup, Input } from '../..'
import config from '../../../helpers/config'
import Select from '../../Select'

function AddShopModal({
  translate,
  showModal,
  onCancel,
  errors,
  onSubmit,
  loading,
  handleChange,
  state,
  handleFileChange,
  handleLocationChange,
  location,
  showManagerInput
}) {
  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={() => null}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl w-full max-w-2xl'>
                <div className='flex justify-end'>
                  <button onClick={onCancel}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>
                <div className=''>
                  <header className='uppercase text-medium text-muted font-gotham-rounded-medium block text-center'>
                    <i className='bi bi-shop text-primary text-6xl'></i>
                  </header>
                  <div className='text-body mt-3 text-2xl font-gotham-rounded-medium text-center'>
                    {translate('okoa_shopping.shop_detail')}
                  </div>
                  <div className='mt-4 font-gotham-rounded-medium text-body text-2xl sm:px-6 text-center'>
                    {translate('okoa_shopping.shop_detail_title_text')}
                  </div>
                  <div className='mt-1 text-body text-sm sm:px-6 text-center'>
                    {translate('okoa_shopping.shop_detail_help_text')}
                  </div>

                  <section className='mt-3'>
                    <div className='mt-1 sm:grid sm:grid-cols-2 gap-3'>
                      <FormGroup>
                        <Input
                          type='text'
                          placeholder={translate('okoa_shopping.shop_name')}
                          name='premise_name'
                          onChange={handleChange}
                          value={state.premise_name}
                          hasError={Object.keys(errors).includes('premise_name')}
                          errorMessage={errors.premise_name}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input
                          type='number'
                          placeholder={translate(
                            'okoa_shopping.monthly_transaction_volume'
                          )}
                          name='monthly_transaction_income'
                          onChange={handleChange}
                          value={state.monthly_transaction_income}
                          hasError={Object.keys(errors).includes(
                            'monthly_transaction_income'
                          )}
                          errorMessage={errors.monthly_transaction_income}
                        />
                      </FormGroup>
                    </div>

                    <div className='mt-1 sm:grid sm:grid-cols-2 gap-3'>
                      <FormGroup>
                        <div className='relative'>
                          <>
                            <AutoComplete
                              apiKey={config('GOOGLE_API_KEY')}
                              onPlaceSelected={(place) => handleLocationChange(place)}
                              placeholder={translate('okoa_shopping.location')}
                              className={`${
                                Object.keys(errors).includes('premise_address')
                                  ? 'border-red-300'
                                  : 'border-secondary-300'
                              } text-body rounded focus:ring-0 outline-none focus:outline-none ${
                                Object.keys(errors).includes('premise_address')
                                  ? 'focus:border-red-500'
                                  : 'focus:border-secondary-500'
                              } block w-full pr-4 py-2.5 font-gotham-rounded-book text-medium pl-4 bg-white border`}
                              options={{
                                componentRestrictions: { country: location }
                              }}
                            />
                            <div className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 justify-between'>
                              <i className='bi bi-geo-alt-fill text-secondary-900 text-base'></i>
                            </div>
                          </>
                        </div>
                        {Object.keys(errors).includes('premise_address') && (
                          <span className='text-xs font-gotham-rounded-book block mt-1 text-red-600'>
                            {errors.premise_address}
                          </span>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <div className='relative'>
                          <label
                            htmlFor='business_permit'
                            className={`bg-white border ${
                              Object.keys(errors).includes('business_permit')
                                ? 'border-red-300'
                                : 'border-secondary-300'
                            } text-body rounded focus:ring-0 outline-none focus:outline-none focus:border-secondary-500 block w-full pr-4 py-2.5 font-gotham-rounded-book text-medium pl-4`}
                          >
                            {state.business_permit !== '' ? (
                              <>{`${
                                state.business_permit.name.length > 30
                                  ? `${state.business_permit.name.substring(0, 30)}...`
                                  : state.business_permit.name
                              }`}</>
                            ) : (
                              <span className='opacity-50'>
                                {translate('okoa_shopping.business_permit')}
                              </span>
                            )}
                          </label>
                          <div className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 justify-between'>
                            <div className='mr-3 -rotate-45'>
                              <i className='bi bi-paperclip text-secondary-900 text-base'></i>
                            </div>
                            <div>
                              <i className='bi bi-camera-fill text-secondary-900 text-base'></i>
                            </div>
                          </div>
                        </div>
                        <Input
                          type='file'
                          placeholder={translate('okoa_shopping.business_permit')}
                          name='business_permit'
                          onChange={handleFileChange}
                          hasError={Object.keys(errors).includes('business_permit')}
                          errorMessage={errors.business_permit}
                          classes='hidden'
                          id='business_permit'
                        />
                      </FormGroup>
                    </div>

                    <div className='mt-1'>
                      <FormGroup>
                        <div className='relative'>
                          <label
                            htmlFor='mpesa_statement'
                            className={`bg-white border ${
                              Object.keys(errors).includes('mpesa_statement')
                                ? 'border-red-300'
                                : 'border-secondary-300'
                            } text-body rounded focus:ring-0 outline-none focus:outline-none focus:border-secondary-500 block w-full pr-4 py-2.5 font-gotham-rounded-book text-medium pl-4`}
                          >
                            {state.mpesa_statement !== '' ? (
                              <>{`${
                                state.mpesa_statement.name.length > 30
                                  ? `${state.mpesa_statement.name.substring(0, 30)}...`
                                  : state.mpesa_statement.name
                              }`}</>
                            ) : (
                              <span className='opacity-50'>
                                {translate('okoa_shopping.statement_placeholder')}
                              </span>
                            )}
                          </label>
                          <div className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 justify-between'>
                            <div className='mr-3 -rotate-45'>
                              <i className='bi bi-paperclip text-secondary-900 text-base'></i>
                            </div>
                            <div>
                              <i className='bi bi-camera-fill text-secondary-900 text-base'></i>
                            </div>
                          </div>
                        </div>
                        <Input
                          type='file'
                          name='mpesa_statement'
                          placeholder={translate('okoa_shopping.statement_placeholder')}
                          hasError={Object.keys(errors).includes('mpesa_statement')}
                          errorMessage={errors.mpesa_statement}
                          onChange={handleFileChange}
                          classes='hidden'
                          id='mpesa_statement'
                        />
                      </FormGroup>
                    </div>

                    <div
                      className={`mt-1 ${
                        showManagerInput ? 'sm:grid' : ''
                      } sm:grid-cols-2 gap-3`}
                    >
                      <FormGroup>
                        <div className='relative'>
                          <label
                            htmlFor='premise_front_image'
                            className={`bg-white border ${
                              Object.keys(errors).includes('premise_front_image')
                                ? 'border-red-300'
                                : 'border-secondary-300'
                            } text-body rounded focus:ring-0 outline-none focus:outline-none focus:border-secondary-500 block w-full pr-4 py-2.5 font-gotham-rounded-book text-medium pl-4`}
                          >
                            {state.premise_front_image !== '' ? (
                              <>{`${
                                state.premise_front_image.name.length > 30
                                  ? `${state.premise_front_image.name.substring(
                                      0,
                                      30
                                    )}...`
                                  : state.premise_front_image.name
                              }`}</>
                            ) : (
                              <span className='opacity-50'>
                                {translate('okoa_shopping.shop_front_image')}
                              </span>
                            )}
                          </label>
                          <div className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 justify-between'>
                            <div>
                              <i className='bi bi-camera-fill text-secondary-900 text-base'></i>
                            </div>
                          </div>
                        </div>
                        <Input
                          type='file'
                          placeholder={translate('okoa_shopping.shop_front_image')}
                          name='premise_front_image'
                          onChange={handleFileChange}
                          hasError={Object.keys(errors).includes('premise_front_image')}
                          errorMessage={errors.premise_front_image}
                          classes='hidden'
                          id='premise_front_image'
                        />
                      </FormGroup>
                      {showManagerInput && (
                        <FormGroup>
                          <Select
                            name='is_manager'
                            onChange={handleChange}
                            options={[
                              { label: translate('common.yes'), value: true },
                              { label: translate('common.no'), value: false }
                            ]}
                            hasPlaceholder={true}
                            placeholderText={translate(
                              'okoa_shopping.are_you_a_shop_manager'
                            )}
                            hasError={Object.keys(errors).includes('is_manager')}
                            errorMessage={errors.is_manager}
                          />
                        </FormGroup>
                      )}
                    </div>

                    <div className='mt-4 sm:px-5 sm:w-72 flex justify-center mx-auto'>
                      <Button
                        label={
                          state.is_manager === 'true' || state.is_manager === true
                            ? translate('okoa_shopping.add_shop')
                            : translate('common.next')
                        }
                        onClick={onSubmit}
                        classes='uppercase'
                        disabled={loading}
                      />
                    </div>
                  </section>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

AddShopModal.defaultProps = {
  showManagerInput: true
}

AddShopModal.propTypes = {
  translate: PropTypes.func,
  showModal: PropTypes.bool,
  onCancel: PropTypes.func,
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  state: PropTypes.object,
  handleChange: PropTypes.func,
  handleFileChange: PropTypes.func,
  handleLocationChange: PropTypes.func,
  location: PropTypes.string,
  showManagerInput: PropTypes.bool
}

export default AddShopModal
