import PropTypes from 'prop-types'

function Button({ label, type, classes, onClick, disabled }) {
  const getButtonType = (buttonType) => {
    let btnClasses = ''
    switch (buttonType) {
      case 'danger':
        btnClasses =
          'bg-red-600 hover:bg-red-700 text-white border border-transparent text-white'
        break
      case 'primary':
        btnClasses = 'primary-button text-white border border-transparent text-white'
        break
      case 'white':
        btnClasses =
          'bg-white border text-body border-secondary-900 w-full transition ease-in-out duration-200 focus:outline-none'
        break
      default:
        break
    }

    return btnClasses
  }

  return (
    <button
      className={`${getButtonType(
        type
      )} text-center rounded py-2.5 px-4 w-full transition ease-in-out duration-200 focus:outline-none ${' '} ${classes} font-gotham-rounded-medium ${
        disabled ? 'opacity-50 pointer-events-none' : ''
      }`}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  classes: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

Button.defaultProps = {
  type: 'primary'
}

export default Button
