import { configureStore } from '@reduxjs/toolkit'
import auth from './containers/Auth/store'
import dashboard from './containers/DashboardLayout/store'
import home from './containers/Home/store'
import shopping from './containers/OkoaShopping/store'
import inuaBiashara from './containers/InuaBiashara/store'
import okoaFees from './containers/OkoaFees/store'
import pataVoucher from './containers/PataVoucher/store'
import changisha from './containers/Changisha/store'
import settings from './containers/Settings/store'
import agent from './containers/AgentDashboard/store'

export const store = configureStore({
  reducer: {
    auth,
    dashboard,
    home,
    shopping,
    inuaBiashara,
    okoaFees,
    pataVoucher,
    changisha,
    settings,
    agent
  }
})
