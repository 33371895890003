import * as yup from 'yup'
import i18n from '../../../i18n'

export const validateChangePassword = (data) => {
  const schema = yup.object().shape({
    confirm_password: yup
      .string()
      .min(8, i18n.t('errors.password_length_error'))
      .oneOf([yup.ref('password')], i18n.t('errors.password_match'))
      .required(i18n.t('errors.confirm_password_required')),
    password: yup
      .string()
      .min(8, i18n.t('errors.password_length_error'))
      .required(i18n.t('errors.password_required')),
    current_password: yup.string().required(i18n.t('errors.current_password_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateProfilePicture = (data) => {
  const schema = yup.object().shape({
    profile_picture: yup.string().required(i18n.t('errors.profile_picture_required'))
  })

  return schema.validate(data, { abortEarly: true })
}
