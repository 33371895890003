import PropTypes from 'prop-types'

function FormGroup({ children }) {
  return <div className='mb-5'>{children}</div>
}

FormGroup.propTypes = {
  children: PropTypes.node.isRequired
}

export default FormGroup
