import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, FormGroup, PhoneInput, Input, Alert } from '../..'
import { formatAmount } from '../../../helpers/utils'

function OtpModal({
  translate,
  showModal,
  onCancel,
  onSubmit,
  modalTitle,
  description,
  errors,
  withdrawAmount,
  handlePhoneChange,
  state,
  handleChange,
  showOtpInput,
  disabled,
  notification,
  onResendOtp
}) {
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const { currency } = useSelector((state) => state.auth)

  useEffect(() => {
    setError(errors.apiError)
  }, [errors.apiError])

  useEffect(() => {
    setMessage(notification)
  }, [notification])
  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={onCancel}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block p-6 w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button onClick={onCancel}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>

                <div className='text-center'>
                  <header className='uppercase text-medium text-muted font-gotham-rounded-medium block text-center'>
                    {translate('okoa_shopping.withdraw')}
                  </header>
                  <div className='text-secondary-900 mt-1 text-2xl font-gotham-rounded-medium text-center'>
                    {currency} {formatAmount(withdrawAmount)}
                  </div>
                  <div className='mt-4 font-gotham-rounded-bold text-primary text-3xl'>
                    {modalTitle}
                  </div>
                  <div className='mt-4 text-body text-sm sm:px-10'>{description}</div>
                </div>
                {error && (
                  <Alert type='error' message={error} onCancel={() => setError('')} />
                )}
                {message && (
                  <Alert
                    type='success'
                    message={message}
                    onCancel={() => setMessage('')}
                  />
                )}
                <section className='mt-3'>
                  <FormGroup>
                    <PhoneInput
                      value={state.phone}
                      onChange={(phone) => handlePhoneChange(phone)}
                      placeholder={translate('forgot_password.phone_placeholder')}
                      hasError={Object.keys(errors).includes('phone')}
                      errorMessage={errors.phone}
                      name='phone'
                      disabled={true}
                      inputStyle={{ backgroundColor: '#f5f5f5' }}
                    />
                  </FormGroup>
                  {showOtpInput && (
                    <>
                      <FormGroup>
                        <Input
                          type='text'
                          placeholder={translate('otp_verification.otp_code')}
                          onChange={handleChange}
                          name='code'
                          hasError={Object.keys(errors).includes('code')}
                          errorMessage={errors.code}
                          value={state.code}
                        />
                      </FormGroup>
                      <div className='flex justify-between items-center -mt-2'>
                        <FormGroup>
                          <button
                            className='text-secondary-900 font-gotham-rounded-medium text-xs'
                            onClick={() => onResendOtp(state)}
                          >
                            {translate('otp_verification.resend_otp')}
                          </button>
                        </FormGroup>
                      </div>
                    </>
                  )}

                  <div className='w-full'>
                    <Button
                      label={
                        showOtpInput
                          ? translate('okoa_shopping.withdraw')
                          : translate('common.send_otp')
                      }
                      classes='uppercase'
                      onClick={() => onSubmit(state)}
                      disabled={disabled}
                    />
                  </div>
                </section>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

OtpModal.propTypes = {
  showModal: PropTypes.bool,
  translate: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  modalTitle: PropTypes.string,
  description: PropTypes.string,
  errors: PropTypes.object,
  withdrawAmount: PropTypes.number,
  handlePhoneChange: PropTypes.func,
  state: PropTypes.object,
  handleChange: PropTypes.func,
  showOtpInput: PropTypes.bool,
  disabled: PropTypes.bool,
  notification: PropTypes.string,
  onResendOtp: PropTypes.func
}

export default OtpModal
