import config from '../helpers/config'
import api from '.'

const CLIENT_SECRET = config('CLIENT_SECRET')

export const getSignedUrl = (payload) =>
  api.post(`/media/get-signed-url?client_secret=${CLIENT_SECRET}`, payload)

export const storeMedia = (payload) =>
  api.post(`/media?client_secret=${CLIENT_SECRET}`, payload)

export const uploadFileToS3 = ({ url, body }) =>
  fetch(url, {
    method: 'PUT',
    body,
    headers: {
      'x-amz-acl': 'public-read'
    }
  })

export const getMedia = (payload) =>
  api.get(`/media?client_secret=${CLIENT_SECRET}`, payload)
