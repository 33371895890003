import { useContext, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import Notifications from './Notifications'
import UserMenu from './UserMenu'
import LanguageSelector from './LanguageSelector'
import { ThemeContext } from '..'
import {
  OKOA_SHOPPING_SHOPPER,
  PATA_VOUCHER,
  SELECT_CHANGISHA_USER,
  SELECT_OKOA_FEES_USER,
  SELECT_OKOA_USER,
  SHOPPING_TRANSACTIONS,
  SHOPPING_RECENT_TRANSACTIONS,
  SHOPS_NEARBY,
  SHOPS,
  SETTINGS,
  REDEEM_VOUCHER,
  VOUCHER_HISTORY,
  CHANGISHA_INDIVIDUAL,
  CHANGISHA_ORGANIZATION,
  CHANGISHA_TRANSACTIONS,
  CHANGISHA_INITIATIVES,
  VIEW_INITIATIVE,
  OKOA_FEES_SCHOOL,
  OKOA_FEES_PARENT,
  OKOA_FEES_BENEFICIARIES,
  OKOA_FEES_REQUESTS,
  OKOA_FEES_SCHOOLS,
  OKOA_FEES_TRANSACTIONS,
  OKOA_FEES_RECENT_TRANSACTIONS,
  OKOA_SHOPPING_REQUESTS,
  INUA_BIASHARA_TRANSACTIONS,
  INUA_BIASHARA_PREMISES,
  INUA_BIASHARA_REQUESTS,
  SEARCH_INITIATIVES,
  AGENT_DASHBOARD,
  UNVERIFIED_PREMISES,
  PARTNERS
} from '../../containers/App/RouteConstants'
import services from '../../helpers/services'

function Header({ toggleSidebar, translate, sidebarOpen, user }) {
  const location = useLocation()
  const history = useHistory()

  const { pathname } = location
  const { theme, setTheme } = useContext(ThemeContext)
  const [pageName, setPageName] = useState('Paylend')

  const pagesWithoutHeader = [
    SELECT_OKOA_USER,
    SELECT_OKOA_FEES_USER,
    SELECT_CHANGISHA_USER
  ]

  const serviceNames = services()

  useEffect(() => {
    switch (pathname) {
      case OKOA_SHOPPING_SHOPPER:
        setPageName(serviceNames.OKOA_SHOPPING)
        break
      case PATA_VOUCHER:
        setPageName(serviceNames.PATA_VOUCHER)
        break
      case SHOPPING_TRANSACTIONS:
        setPageName(serviceNames.OKOA_SHOPPING)
        break
      case SHOPPING_RECENT_TRANSACTIONS:
        setPageName(serviceNames.OKOA_SHOPPING)
        break
      case SHOPS_NEARBY:
        setPageName(serviceNames.OKOA_SHOPPING)
        break
      case SHOPS:
        setPageName(serviceNames.OKOA_SHOPPING)
        break
      case OKOA_SHOPPING_REQUESTS:
        setPageName(serviceNames.OKOA_SHOPPING)
        break
      case SETTINGS:
        setPageName(translate('header.settings'))
        break
      case REDEEM_VOUCHER:
        setPageName(serviceNames.PATA_VOUCHER)
        break
      case VOUCHER_HISTORY:
        setPageName(serviceNames.PATA_VOUCHER)
        break
      case CHANGISHA_INDIVIDUAL:
        setPageName(serviceNames.CHANGISHA)
        break
      case SEARCH_INITIATIVES:
        setPageName(serviceNames.CHANGISHA)
        break
      case CHANGISHA_ORGANIZATION:
        setPageName(serviceNames.CHANGISHA)
        break
      case CHANGISHA_TRANSACTIONS:
        setPageName(serviceNames.CHANGISHA)
        break
      case CHANGISHA_INITIATIVES:
        setPageName(serviceNames.CHANGISHA)
        break
      case VIEW_INITIATIVE:
        setPageName(serviceNames.CHANGISHA)
        break
      case OKOA_FEES_PARENT:
        setPageName(serviceNames.OKOA_FEES)
        break
      case OKOA_FEES_SCHOOL:
        setPageName(serviceNames.OKOA_FEES)
        break
      case OKOA_FEES_TRANSACTIONS:
        setPageName(serviceNames.OKOA_FEES)
        break
      case OKOA_FEES_BENEFICIARIES:
        setPageName(serviceNames.OKOA_FEES)
        break
      case OKOA_FEES_SCHOOLS:
        setPageName(serviceNames.OKOA_FEES)
        break
      case OKOA_FEES_REQUESTS:
        setPageName(serviceNames.OKOA_FEES)
        break
      case OKOA_FEES_RECENT_TRANSACTIONS:
        setPageName(serviceNames.OKOA_FEES)
        break
      case INUA_BIASHARA_TRANSACTIONS:
        setPageName(serviceNames.INUA_BIZ)
        break
      case INUA_BIASHARA_PREMISES:
        setPageName(serviceNames.INUA_BIZ)
        break
      case INUA_BIASHARA_REQUESTS:
        setPageName(serviceNames.INUA_BIZ)
        break
      case AGENT_DASHBOARD:
        setPageName(translate('agent_dashboard.agent_dashboard'))
        break
      case UNVERIFIED_PREMISES:
        setPageName(translate('agent_dashboard.unverified_premises'))
        break
      case PARTNERS:
        setPageName(translate('common.partners'))
        break
      default:
        setPageName('Paylend')
        break
    }
  }, [pathname, translate, serviceNames])

  return (
    <>
      {pagesWithoutHeader.includes(pathname) || pathname.includes('messages') ? (
        <></>
      ) : (
        <>
          <header className='sticky top-0 bg-white drop-shadow z-30 hidden sm:block'>
            <div className='px-4 sm:px-6 lg:px-8'>
              <div className='flex items-center justify-between h-16 -mb-px'>
                <div className='flex'>
                  <button
                    className='lg:hidden text-muted hover:text-muted'
                    aria-controls='sidebar'
                    aria-expanded={sidebarOpen}
                    onClick={toggleSidebar}
                  >
                    <span className='sr-only'>Open sidebar</span>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M4 6h16M4 12h16M4 18h16'
                      />
                    </svg>
                  </button>

                  <LanguageSelector />
                </div>

                <div className='flex items-center justify-between relative'>
                  <div className='px-4 absolute left-8 sm:left-36'>
                    {theme === 'dark' ? (
                      <button
                        className='bi bi-brightness-high text-xl text-muted'
                        onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                      ></button>
                    ) : (
                      <button
                        className='bi bi-moon-fill text-xl text-muted'
                        onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                      ></button>
                    )}
                  </div>

                  <Notifications translate={translate} />

                  <hr className='w-px h-6 bg-slate-200 mr-3 hidden lg:inline-block' />
                  <UserMenu translate={translate} user={user} />
                </div>
              </div>
            </div>
          </header>

          <header className='sm:hidden bg-gradient-primary py-1 px-3 sticky top-0 z-30'>
            <div className='flex items-center justify-between h-16 -mb-px'>
              <div className='flex items-center justify-between gap-2'>
                {history.action !== 'POP' && (
                  <button className='sm:hidden' onClick={() => history.goBack()}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-6 h-6 text-white'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18'
                      />
                    </svg>
                  </button>
                )}
                <button
                  className='lg:hidden text-white hover:text-white'
                  aria-controls='sidebar'
                  aria-expanded={sidebarOpen}
                  onClick={toggleSidebar}
                >
                  <span className='sr-only'>Open sidebar</span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6 text-white'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M4 6h16M4 12h16M4 18h16'
                    />
                  </svg>
                </button>

                <h3 className='font-gotham-rounded-bold text-sm text-white'>
                  {pageName}
                </h3>
              </div>
              <div className='flex items-center justify-between relative'>
                <Notifications translate={translate} />

                <UserMenu translate={translate} user={user} />
              </div>
            </div>
          </header>
        </>
      )}
    </>
  )
}

Header.propTypes = {
  sidebarOpen: PropTypes.bool,
  toggleSidebar: PropTypes.func.isRequired,
  translate: PropTypes.func,
  user: PropTypes.object
}

export default Header
