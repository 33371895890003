import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'ramda'
import { Table, TableSkeleton, EmptyContent, Badge } from '../..'
import {
  loadNextPremises,
  loadPrevPremises
} from '../../../containers/InuaBiashara/store'
import { formatAmount } from '../../../helpers/utils'

function Premises({ t }) {
  const dispatch = useDispatch()
  const {
    premises,
    isLoading,
    premisesPagination: pagination,
    premiseWallets
  } = useSelector((state) => state.inuaBiashara)
  const { currency } = useSelector((state) => state.auth)

  return (
    <>
      {isLoading ? (
        <>
          <TableSkeleton showSearch={false} showPagination={true} />
        </>
      ) : (
        <>
          {isEmpty(premises) ? (
            <div className='flex flex-col'>
              <div className='overflow-x-auto shadow-md sm:rounded-lg'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='p-4'>
                    <h2 className='font-gotham-rounded-medium text-body text-base'>
                      {t('okoa_shopping.premises')}
                    </h2>
                    <EmptyContent
                      title={t('common.no_premises')}
                      subTitle={t('common.no_premises_help_text')}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='flex flex-col'>
              <div className='overflow-x-auto shadow-md sm:rounded-lg w-full'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='p-4 flex'>
                    <h2 className='font-gotham-rounded-medium text-body text-base mb-2'>
                      {t('okoa_shopping.premises')}
                    </h2>
                  </div>
                  <div className='overflow-hidden p-4'>
                    <Table
                      header={
                        <tr>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_shopping.premise_name')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_shopping.premise_type')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('common.premise_verification')}
                          </th>
                          <th scope='col' className='p-4'>
                            <span className='sr-only'>{t('okoa_shopping.action')}</span>
                          </th>
                        </tr>
                      }
                      body={
                        <>
                          {premises.map((premise) => (
                            <tr key={premise.id}>
                              <td className='pl-2 py-4 whitespace-nowrap'>
                                <div className='text-medium font-medium text-body'>
                                  {premise.premiseName}
                                </div>
                                <div className='text-xs text-gray-500'>
                                  {premise.paylendNumber}
                                </div>
                                <div className='text-xs text-gray-500'>
                                  {(premise.managerEmail || premise.managerPhone) && (
                                    <>
                                      {premise.managerEmail
                                        ? t('common.email')
                                        : t('common.phone')}
                                      :{' '}
                                      {premise.managerEmail || premise.managerPhoneNumber}
                                    </>
                                  )}
                                </div>
                              </td>
                              <td className='pl-2 py-4 w-40 text-medium capitalize text-body'>
                                {premise.premiseType}
                              </td>
                              <td className='pl-2 py-4 text-medium text-body'>
                                {premise.isPremiseVerified ? (
                                  <Badge type={'success'} label={t('common.verified')} />
                                ) : (
                                  <Badge
                                    type={'warning'}
                                    label={t('common.unverified')}
                                  />
                                )}
                              </td>
                              <td className='pl-2 py-4 text-medium text-body'>
                                <div>
                                  {t('common.wallet_balance')}:{' '}
                                  {`${currency} ${formatAmount(
                                    premiseWallets.find(
                                      (wallet) => wallet.premiseId === premise.id
                                    )?.availableBalance
                                  )}`}
                                </div>
                                <div>
                                  {t('common.credit_limit')}:{' '}
                                  {`${currency} ${formatAmount(
                                    premiseWallets.find(
                                      (wallet) => wallet.premiseId === premise.id
                                    )?.creditLimit
                                  )}`}
                                </div>
                                <div>
                                  {t('common.borrowable_amount')}:{' '}
                                  {`${currency} ${formatAmount(
                                    premiseWallets.find(
                                      (wallet) => wallet.premiseId === premise.id
                                    )?.borrowable
                                  )}`}
                                </div>
                                <div>
                                  {t('common.payable_amount')}:{' '}
                                  {`${currency} ${formatAmount(
                                    premiseWallets.find(
                                      (wallet) => wallet.premiseId === premise.id
                                    )?.repayableAmount
                                  )}`}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </>
                      }
                      pagination={
                        <div className='flex justify-between pl-2 pr-6 py-4 w-full border border-gray-200 border-t-0'>
                          <div className='text-medium text-muted'>
                            {pagination.count} {t('common.of')} {pagination.total}{' '}
                            {t('okoa_shopping.premises')}
                          </div>
                          <div className='flex justify-between gap-4'>
                            <button
                              className={`text-medium ${
                                pagination.hasPrev
                                  ? 'text-secondary-900'
                                  : 'text-muted pointer-events-none'
                              }`}
                              onClick={() => dispatch(loadPrevPremises())}
                            >
                              {t('common.prev')}
                            </button>
                            <button
                              className={`text-medium ${
                                pagination.hasNext
                                  ? 'text-secondary-900'
                                  : 'text-muted pointer-events-none'
                              }`}
                              onClick={() => dispatch(loadNextPremises())}
                            >
                              {t('common.next')}
                            </button>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

Premises.propTypes = {
  t: PropTypes.func
}

export default Premises
