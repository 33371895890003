/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect, useRef, Fragment } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { omit } from 'ramda'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { icons } from '../../assets/img'
import { SidebarLinkGroup } from '..'
import {
  HOME,
  OKOA_FEES,
  OKOA_SHOPPING,
  PATA_VOUCHER,
  PRIVACY_POLICY,
  CHANGISHA,
  INUA_BIZNA,
  OKOA_SERVICES,
  SELECT_CHANGISHA_USER,
  AGENT_DASHBOARD
} from '../../containers/App/RouteConstants'
import { device } from '../../helpers/mediaQueries'
import services from '../../helpers/services'
import { logoutUser, setUser } from '../../containers/Auth/store'

function Sidebar({
  sidebarOpen,
  setSidebarOpen,
  translate,
  user,
  metrics,
  onLinkClicked
}) {
  const location = useLocation()
  const dispatch = useDispatch()
  const { pathname } = location

  const trigger = useRef(null)
  const sidebar = useRef(null)

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded')
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null
      ? false
      : window.matchMedia(device.mobile).matches
      ? false
      : storedSidebarExpanded
  )

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return
      setSidebarOpen(false)
    }
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return
      setSidebarOpen(false)
    }
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded)
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded')
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded')
    }
  }, [sidebarExpanded])

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-dashboard-backdrop bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        aria-hidden='true'
      ></div>

      {/* Sidebar */}
      <div
        id='sidebar'
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-dashboard-sidebar-background py-4 pl-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-64'
        }`}
      >
        {/* Header */}
        <div className='flex lg:justify-center justify-end mb-5 pr-3 sm:px-2'>
          {/* Close button */}
          <NavLink end to='/' className='hidden lg:block'>
            <img src={icons.logo} alt='Logo' className='w-12 h-12' />
          </NavLink>

          <button
            ref={trigger}
            className='lg:hidden text-secondary-900'
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls='sidebar'
            aria-expanded={sidebarOpen}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6 text-secondary-900'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M6 18L18 6M6 6l12 12'
              />
            </svg>
          </button>
        </div>

        <div className='lg:hidden mx-auto w-full text-center'>
          <div>
            <div className='flex justify-center w-full'>
              <img
                src={user?.profile_picture || icons.iconElsie}
                alt='Avatar'
                className='w-20 h-20 mb-4 rounded-full'
                onError={() => dispatch(setUser(omit(['avatar'], user)))}
              />
            </div>
            <div className='text-secondary-900 text-2xl font-gotham-rounded-medium'>
              {user?.old_user ? (
                <>{user?.firstname}</>
              ) : (
                <>
                  {user?.firstname} {`${user?.lastname?.charAt(0)}.`}
                </>
              )}
            </div>
            <div className='text-muted text-xs font-gotham-rounded-medium'>
              {user.phone && user.phone}
            </div>
            <div className='text-muted text-xs font-gotham-rounded-medium mt-2'>
              {user.email && user.email}
            </div>
          </div>
        </div>

        <section className='pr-2 lg:hidden'>
          <div className='border-b border-t border-gray-300 mt-4 py-6 flex items-start pl-3'>
            <img src={icons.iconBasket} alt='icon' className='w-5' />
            <section className='ml-3 -mt-1'>
              <div className='text-primary font-gotham-rounded-bold text-xl'>
                {translate('okoa_shopping.okoa')}
              </div>
              <div className='pt-4'>
                <div className='grid grid-cols-2'>
                  <div className='border-r border-gray-300 w-full pr-8'>
                    <h3 className='uppercase text-xs text-slate-700 font-gotham-rounded-medium'>
                      {translate('common.limit')}
                    </h3>
                    <div className='text-secondary-900 text-base font-gotham-rounded-bold'>
                      {metrics.okoaServices.totalLimit}
                    </div>
                  </div>
                  <div className='w-full pl-8'>
                    <h3 className='uppercase text-xs text-slate-700 font-gotham-rounded-medium'>
                      {translate('common.payable')}
                    </h3>
                    <div className='text-secondary-900 text-base font-gotham-rounded-bold'>
                      {metrics.okoaServices.totalPayable}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>

        {/* Sidebar Links */}
        <div className='space-y-8'>
          <div>
            <ul className='mt-3'>
              {/* Dashboard */}
              <li
                className={`py-1 pl-3 rounded-sm mb-0.5 font-gotham-medium last:mb-0 hidden sm:block`}
              >
                <NavLink
                  to={HOME}
                  className={`block truncate hover:text-primary font-normal transition duration-150 ${
                    pathname === HOME ? 'text-primary' : 'text-body'
                  }`}
                >
                  <div className='flex items-center'>
                    <i
                      className={`bi bi-house text-xl mr-1 ${
                        pathname === HOME ? 'text-primary' : 'text-secondary-900'
                      } hover:text-primary`}
                    ></i>
                    <span className='font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 text-sm'>
                      {translate('sidebar.home')}
                    </span>
                  </div>
                </NavLink>
              </li>

              {user.roles === 'agent' && (
                <li
                  className={`py-1 pl-3 rounded-sm mb-0.5 font-gotham-medium last:mb-0 hidden sm:block`}
                >
                  <NavLink
                    to={AGENT_DASHBOARD}
                    className={`block truncate hover:text-primary font-normal transition duration-150 ${
                      pathname === AGENT_DASHBOARD ? 'text-primary' : 'text-body'
                    }`}
                  >
                    <div className='flex items-center'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className={`w-6 h-6 ${
                          pathname === AGENT_DASHBOARD
                            ? 'text-primary'
                            : 'text-secondary-900'
                        } hover:text-primary`}
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z'
                        />
                      </svg>

                      <span className='font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 text-sm'>
                        {translate('agent_dashboard.agent_dashboard')}
                      </span>
                    </div>
                  </NavLink>
                </li>
              )}

              {/* Okoa Services */}
              <SidebarLinkGroup activecondition={pathname.includes('okoa')}>
                {(handleClick, open) => {
                  return (
                    <Fragment>
                      <a
                        href='#0'
                        className={`block hover:text-primary focus:text-body truncate transition duration-150 ${
                          pathname.includes('okoa') ? 'text-primary' : 'text-body'
                        }`}
                        onClick={(e) => {
                          e.preventDefault()
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true)
                        }}
                      >
                        <div className='flex items-center justify-between'>
                          <div className='flex items-center'>
                            <i
                              className={`bi bi-bag text-xl mr-1 ${
                                pathname.includes('okoa')
                                  ? 'text-primary'
                                  : 'text-secondary-900'
                              }`}
                            ></i>

                            <span className='font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 text-sm'>
                              {translate('sidebar.okoa_services')}
                            </span>
                          </div>
                          {/* Icon */}
                          <div className='flex shrink-0 ml-2'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              className={`h-4 w-4 ${open && 'transform rotate-180'}`}
                              fill='none'
                              viewBox='0 0 24 24'
                              stroke='currentColor'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth={2}
                                d='M19 9l-7 7-7-7'
                              />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <div className='lg:hidden lg:sidebar-expanded:block 2xl:block'>
                        <ul className={`pl-9 mt-1 ${!open && 'hidden'}`}>
                          <li className='mb-1 last:mb-0'>
                            <NavLink
                              to={OKOA_SHOPPING}
                              className={`${
                                pathname.includes('okoa-shopping')
                                  ? 'text-primary'
                                  : 'text-body'
                              } hover:text-primary block transition duration-150 truncate`}
                            >
                              <span className='font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 text-medium'>
                                {services().OKOA_SHOPPING}
                              </span>
                            </NavLink>
                          </li>
                          <li className='mb-1 last:mb-0'>
                            <NavLink
                              to={OKOA_FEES}
                              className={`${
                                pathname.includes('okoa-school-fees')
                                  ? 'text-primary'
                                  : 'text-body'
                              } hover:text-primary block transition duration-150 truncate`}
                            >
                              <span className='font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 text-medium'>
                                {services().OKOA_FEES}
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </Fragment>
                  )
                }}
              </SidebarLinkGroup>

              {/* Inua Biashara */}
              <li
                className={`py-1 pl-3 rounded-sm mb-0.5 font-gotham-medium last:mb-0 hidden sm:block`}
              >
                <NavLink
                  to={INUA_BIZNA}
                  className={`block truncate hover:text-primary font-normal transition duration-150 ${
                    pathname === INUA_BIZNA ? 'text-primary' : 'text-body'
                  }`}
                >
                  <div className='flex items-center'>
                    <i
                      className={`bi bi-briefcase text-xl mr-1 ${
                        pathname === INUA_BIZNA ? 'text-primary' : 'text-secondary-900'
                      }`}
                    ></i>
                    <span className='font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 text-sm'>
                      {services().INUA_BIZ}
                    </span>
                  </div>
                </NavLink>
              </li>

              {/* Inua Biashara */}
              <li
                className={`py-1 pl-3 rounded-sm mb-0.5 font-gotham-medium last:mb-0 hidden sm:block`}
              >
                <NavLink
                  to={CHANGISHA}
                  className={`block truncate hover:text-primary font-normal transition duration-150 ${
                    pathname === CHANGISHA ? 'text-primary' : 'text-body'
                  }`}
                >
                  <div className='flex items-center'>
                    <i
                      className={`bi bi-basket text-xl mr-1 ${
                        pathname.includes('changisha')
                          ? 'text-primary'
                          : 'text-secondary-900'
                      }`}
                    ></i>
                    <span className='font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 text-sm'>
                      {services().CHANGISHA}
                    </span>
                  </div>
                </NavLink>
              </li>

              {/* Pata Voucher */}
              <li
                className={`py-1 pl-3 rounded-sm mb-0.5 font-gotham-medium last:mb-0 hidden sm:block`}
              >
                <NavLink
                  to={PATA_VOUCHER}
                  className={`block truncate hover:text-primary font-normal transition duration-150 ${
                    pathname === PATA_VOUCHER ? 'text-primary' : 'text-body'
                  }`}
                >
                  <div className='flex items-center'>
                    <i
                      className={`bi bi-ticket-detailed text-xl mr-1 ${
                        pathname === PATA_VOUCHER ? 'text-primary' : 'text-secondary-900'
                      }`}
                    ></i>
                    <span className='font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 text-sm'>
                      {services().PATA_VOUCHER}
                    </span>
                  </div>
                </NavLink>
              </li>

              <li
                className={`py-1 pl-3 rounded-sm mb-0.5 font-gotham-medium last:mb-0 sm:hidden`}
              >
                <NavLink
                  to={HOME}
                  className={`block truncate hover:text-primary font-normal transition duration-150 ${
                    pathname === HOME ? 'text-primary' : 'text-body'
                  }`}
                  onClick={onLinkClicked}
                >
                  <div className='flex items-center'>
                    <i
                      className={`bi bi-house text-xl mr-1 ${
                        pathname === HOME ? 'text-primary' : 'text-secondary-900'
                      } hover:text-primary`}
                    ></i>
                    <span
                      className={`font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 text-xs ${
                        pathname === HOME ? 'text-primary' : 'text-body'
                      }`}
                    >
                      {translate('sidebar.home')}
                    </span>
                  </div>
                </NavLink>
              </li>

              <li
                className={`py-1 pl-3 rounded-sm mb-0.5 font-gotham-medium last:mb-0 sm:hidden`}
              >
                <NavLink
                  to={OKOA_SERVICES}
                  className={`block truncate hover:text-primary font-normal transition duration-150 ${
                    pathname.includes('okoa') ? 'text-primary' : 'text-body'
                  }`}
                  onClick={onLinkClicked}
                >
                  <div className='flex items-center'>
                    <i
                      className={`bi bi-bag text-xl mr-1 ${
                        pathname.includes('okoa') ? 'text-primary' : 'text-secondary-900'
                      }`}
                    ></i>
                    <span
                      className={`font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 text-xs ${
                        pathname.includes('okoa') ? 'text-primary' : 'text-body'
                      }`}
                    >
                      {translate('sidebar.okoa_services')}
                    </span>
                  </div>
                </NavLink>
              </li>

              <li
                className={`py-1 pl-3 rounded-sm mb-0.5 font-gotham-medium last:mb-0 sm:hidden`}
              >
                <NavLink
                  to={SELECT_CHANGISHA_USER}
                  className={`block truncate hover:text-primary font-normal transition duration-150 ${
                    pathname.includes('changisha') ? 'text-primary' : 'text-secondary-900'
                  }`}
                  onClick={onLinkClicked}
                >
                  <div className='flex items-center'>
                    <i
                      className={`bi bi-basket text-xl mr-1 ${
                        pathname.includes('changisha')
                          ? 'text-primary'
                          : 'text-secondary-900'
                      } hover:text-primary`}
                    ></i>
                    <span
                      className={`font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 text-xs ${
                        pathname.includes('changisha') ? 'text-primary' : 'text-body'
                      }`}
                    >
                      {services().CHANGISHA}
                    </span>
                  </div>
                </NavLink>
              </li>

              <li
                className={`py-1 pl-3 rounded-sm mb-0.5 font-gotham-medium last:mb-0 sm:hidden`}
              >
                <NavLink
                  to={PATA_VOUCHER}
                  className={`block truncate hover:text-primary font-normal transition duration-150 ${
                    pathname === PATA_VOUCHER ? 'text-primary' : 'text-body'
                  }`}
                  onClick={onLinkClicked}
                >
                  <div className='flex items-center'>
                    <i
                      className={`bi bi-ticket-detailed text-xl mr-1 ${
                        pathname === PATA_VOUCHER ? 'text-primary' : 'text-secondary-900'
                      } hover:text-primary`}
                    ></i>
                    <span
                      className={`font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 text-xs ${
                        pathname === PATA_VOUCHER ? 'text-primary' : 'text-body'
                      }`}
                    >
                      {services().PATA_VOUCHER}
                    </span>
                  </div>
                </NavLink>
              </li>

              <li
                className={`py-1 pl-3 rounded-sm mb-0.5 font-gotham-medium last:mb-0 sm:hidden`}
              >
                <NavLink
                  to={INUA_BIZNA}
                  className={`block truncate hover:text-primary font-normal transition duration-150 ${
                    pathname === INUA_BIZNA ? 'text-primary' : 'text-body'
                  }`}
                  onClick={onLinkClicked}
                >
                  <div className='flex items-center'>
                    <i
                      className={`bi bi-briefcase text-xl mr-1 ${
                        pathname === INUA_BIZNA ? 'text-primary' : 'text-secondary-900'
                      } hover:text-primary`}
                    ></i>
                    <span
                      className={`font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 text-xs ${
                        pathname === INUA_BIZNA ? 'text-primary' : 'text-body'
                      }`}
                    >
                      {services().INUA_BIZ}
                    </span>
                  </div>
                </NavLink>
              </li>

              <li className={`py-1 pl-3 rounded-sm mb-0.5 font-gotham-medium last:mb-0`}>
                <a
                  href={'https://paylend.africa/privacy'}
                  target='_blank'
                  rel='noreferrer'
                  className={`block truncate hover:text-primary font-normal transition duration-150 ${
                    pathname === PRIVACY_POLICY ? 'text-primary' : 'text-body'
                  }`}
                >
                  <div className='flex items-center'>
                    <i
                      className={`bi bi-eye-slash text-xl mr-1 ${
                        pathname === PRIVACY_POLICY
                          ? 'text-primary'
                          : 'text-secondary-900'
                      }`}
                    ></i>
                    <span className='font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 text-xs sm:text-sm sm:capitalize text-body sm:text-body font-gotham-rounded-medium sm:font-gotham-medium'>
                      {translate('sidebar.privacy_policy')}
                    </span>
                  </div>
                </a>
              </li>

              {/* Playstore */}
              <li
                className={`py-1 pl-3 pr-2 rounded-sm mb-0.5 font-gotham-medium last:mb-0`}
              >
                <a onClick={() => dispatch(logoutUser())}>
                  <div className='flex items-center'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-6 h-6 text-secondary-900'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75'
                      />
                    </svg>

                    <span className='font-medium ml-4 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 text-xs sm:text-sm sm:capitalize text-body sm:text-body font-gotham-rounded-medium sm:font-gotham-medium'>
                      {translate('header.sign_out')}
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

Sidebar.propTypes = {
  sidebarOpen: PropTypes.bool,
  setSidebarOpen: PropTypes.func,
  translate: PropTypes.func.isRequired,
  user: PropTypes.object,
  metrics: PropTypes.object,
  onLinkClicked: PropTypes.func
}

export default Sidebar
