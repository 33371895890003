import config from '../helpers/config'
import api from '.'
import { objectToQueryString } from '../helpers/utils'

const CLIENT_SECRET = config('CLIENT_SECRET')

export const getUserTransactions = (payload) => {
  let query = {
    client_secret: CLIENT_SECRET,
    ...payload
  }

  query = objectToQueryString(query)

  return api.get(`/transactions?${query}`)
}
