import { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty, pickBy, values } from 'ramda'
import { Table, TableSkeleton, EmptyContent, FiltersModal, DatePicker, Button } from '../'
import {
  formatDateToTime,
  formatTimestampToDate,
  transformTransactionType,
  formatAmount
} from '../../helpers/utils'
import {
  fetchTransactions,
  loadNextTransactions,
  loadPrevTransactions
} from '../../containers/PataVoucher/store'

function VoucherHistory({ t }) {
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const dispatch = useDispatch()
  const {
    transactions,
    isLoading,
    transactionsPagination: pagination
  } = useSelector((state) => state.pataVoucher)
  const { currency } = useSelector((state) => state.auth)
  const [filters, setFilters] = useState({
    action_type: '',
    status: '',
    from: '',
    to: '',
    sort: ''
  })

  const hasEmptyFilter = values(filters).every((value) => isEmpty(value))
  const selectedFilters = values(filters).filter((value) => !isEmpty(value)).length

  const handleStatusChange = (e) => {
    setFilters((prevState) => ({
      ...prevState,
      status: e.target.value
    }))
  }

  const handleActionTypeChange = (e) => {
    setFilters((prevState) => ({
      ...prevState,
      action_type: e.target.value
    }))
  }

  const handleSortChange = (e) => {
    setFilters((prevState) => ({
      ...prevState,
      sort: e.target.value
    }))
  }

  const handleFromDateChange = (date, dateString) => {
    setFilters((prevState) => ({
      ...prevState,
      from: dateString
    }))
  }

  const handleToDateChange = (date, dateString) => {
    setFilters((prevState) => ({
      ...prevState,
      to: dateString
    }))
  }

  const handleReset = () =>
    setFilters({
      from: '',
      to: '',
      action_type: '',
      status: ''
    })

  const onApplyFilters = () => {
    const formattedFilters = pickBy((val, key) => val !== '' && val !== 'all', filters)
    dispatch(fetchTransactions(formattedFilters, false))
    setShowFiltersModal(false)
  }

  return (
    <>
      {isLoading ? (
        <>
          <TableSkeleton showSearch={true} showPagination={true} />
        </>
      ) : (
        <>
          {isEmpty(transactions) ? (
            <div className='flex flex-col'>
              <div className='overflow-x-auto shadow-md sm:rounded-lg'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='p-4'>
                    <h2 className='font-gotham-rounded-medium text-body text-base'>
                      {t('okoa_shopping.transactions')}
                    </h2>
                    <EmptyContent
                      title={t('common.no_transactions')}
                      subTitle={
                        hasEmptyFilter
                          ? t('common.no_transactions_help_text')
                          : t('common.no_transactions_help_text_with_filter')
                      }
                      actionButton={
                        <>
                          {!hasEmptyFilter && (
                            <Button
                              label={t('common.reset')}
                              classes='uppercase'
                              onClick={() => {
                                handleReset()
                                dispatch(fetchTransactions())
                              }}
                            />
                          )}
                        </>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='flex flex-col'>
              <div className='overflow-x-auto shadow-md sm:rounded-lg w-full'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='p-4'>
                    <div className='flex justify-between items-center'>
                      <h2 className='font-gotham-rounded-medium text-body text-base mb-2'>
                        {t('okoa_shopping.transactions')}
                      </h2>
                      <div className='ml-auto'>
                        <button
                          className='primary-button flex items-center justify-between rounded py-2 w-40 px-4 transition ease-in-out duration-200 focus:outline-none'
                          onClick={() => setShowFiltersModal(true)}
                        >
                          <div>
                            <i className='bi bi-funnel text-white text-xl'></i>
                          </div>
                          <span className='text-white text-sm font-gotham-rounded-medium'>
                            {t('common.filters')}
                          </span>
                          <div className='bg-white rounded-full p-3 w-6 h-6 text-secondary-900 font-gotham-rounded-bold flex justify-center items-center'>
                            <div className='mt-1'>
                              {hasEmptyFilter ? 0 : selectedFilters}
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>

                    <section className='mt-4'>
                      <Table
                        header={
                          <tr>
                            <th
                              scope='col'
                              className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                            >
                              {t('okoa_shopping.date')}
                            </th>
                            <th
                              scope='col'
                              className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                            >
                              {t('okoa_shopping.transaction_number')}
                            </th>
                            <th
                              scope='col'
                              className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                            >
                              {t('okoa_shopping.amount')}
                            </th>
                            <th
                              scope='col'
                              className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                            >
                              {t('okoa_shopping.status')}
                            </th>
                            <th
                              scope='col'
                              className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                            >
                              {t('okoa_shopping.type')}
                            </th>
                          </tr>
                        }
                        body={
                          <>
                            {transactions.map((transaction) => (
                              <tr key={transaction.id}>
                                <td className='pl-2 py-4 whitespace-nowrap'>
                                  <div className='text-medium font-medium text-body'>
                                    {formatTimestampToDate(transaction.createdAt)}
                                  </div>
                                  <div className='text-xs text-gray-500'>
                                    {formatDateToTime(transaction.createdAt)}
                                  </div>
                                </td>
                                <td className='pl-2 py-4 w-40 text-medium uppercase text-body'>
                                  {transaction.receiptNumber}
                                </td>
                                <td className='pl-2 py-4 text-medium text-body'>
                                  <td className='pl-2 py-4 text-medium text-body'>
                                    {transaction.actionType === 'buy_voucher' ? (
                                      <span className='text-green-500'>
                                        + {currency} {formatAmount(transaction.amount)}
                                      </span>
                                    ) : (
                                      <span className='text-primary'>
                                        - {currency} {formatAmount(transaction.amount)}
                                      </span>
                                    )}
                                  </td>
                                </td>
                                <td className='pl-2 py-4 text-medium capitalize text-body'>
                                  {transaction.status}
                                </td>
                                <td className='pl-2 py-4 text-medium capitalize text-body'>
                                  {transformTransactionType(transaction.actionType)}
                                </td>
                              </tr>
                            ))}
                          </>
                        }
                        pagination={
                          <div className='flex justify-between pl-2 pr-6 py-4 w-full border border-gray-200 border-t-0'>
                            <div className='text-medium text-muted'>
                              {pagination.count} {t('common.of')} {pagination.total}{' '}
                              {t('okoa_shopping.transactions')}
                            </div>
                            <div className='flex justify-between gap-4'>
                              <button
                                className={`text-medium ${
                                  pagination.hasPrev
                                    ? 'text-secondary-900'
                                    : 'text-muted pointer-events-none'
                                }`}
                                onClick={() => dispatch(loadPrevTransactions({}))}
                              >
                                {t('common.prev')}
                              </button>
                              <button
                                className={`text-medium ${
                                  pagination.hasNext
                                    ? 'text-secondary-900'
                                    : 'text-muted pointer-events-none'
                                }`}
                                onClick={() => dispatch(loadNextTransactions({}))}
                              >
                                {t('common.next')}
                              </button>
                            </div>
                          </div>
                        }
                      />
                    </section>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <FiltersModal
        t={t}
        showModal={showFiltersModal}
        onCancel={() => setShowFiltersModal(false)}
        content={
          <>
            <div className='w-full'>
              <div className='space-y-3'>
                <section>
                  <h3 className='lex justify-between w-full text-left focus:outline-none font-gotham-rounded-medium py-2'>
                    {t('common.transaction_status')}
                  </h3>

                  <div className='py-2 rounded-md'>
                    <div className='grid grid-cols-3'>
                      <div className=''>
                        <div className='inline-block mr-3'>
                          <input
                            id='successful'
                            name='successful'
                            type='radio'
                            value='successful'
                            className='h-4 w-4 text-secondary-900 outline-none focus:outline-none focus:ring-0 border-gray-300 cursor-pointer'
                            checked={filters.status === 'successful'}
                            onChange={handleStatusChange}
                          />
                        </div>
                        <label className='font-gotham-rounded-book text-medium'>
                          {t('common.successful')}
                        </label>
                      </div>
                      <div className=''>
                        <div className='inline-block mr-3'>
                          <input
                            id='pending'
                            name='pending'
                            type='radio'
                            value='pending'
                            className='h-4 w-4 text-secondary-900 outline-none focus:outline-none focus:ring-0 border-gray-300 cursor-pointer'
                            checked={filters.status === 'pending'}
                            onChange={handleStatusChange}
                          />
                        </div>
                        <label className='font-gotham-rounded-book text-medium'>
                          {t('common.pending')}
                        </label>
                      </div>
                      <div className=''>
                        <div className='inline-block mr-3'>
                          <input
                            id='cancelled'
                            name='cancelled'
                            type='radio'
                            value='cancelled'
                            className='h-4 w-4 text-secondary-900 outline-none focus:outline-none focus:ring-0 border-gray-300 cursor-pointer'
                            checked={filters.status === 'cancelled'}
                            onChange={handleStatusChange}
                          />
                        </div>
                        <label className='font-gotham-rounded-book text-medium'>
                          {t('common.cancelled')}
                        </label>
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <h3 className='lex justify-between w-full text-left focus:outline-none font-gotham-rounded-medium py-2'>
                    {t('common.transaction_type')}
                  </h3>

                  <div className='py-2 rounded-md'>
                    <div className='grid grid-cols-3'>
                      <div className=''>
                        <div className='inline-block mr-3'>
                          <input
                            id='buy_voucher'
                            name='buy_voucher'
                            type='radio'
                            value='buy_voucher'
                            className='h-4 w-4 text-secondary-900 outline-none focus:outline-none focus:ring-0 border-gray-300 cursor-pointer'
                            checked={filters.action_type === 'buy_voucher'}
                            onChange={handleActionTypeChange}
                          />
                        </div>
                        <label className='font-gotham-rounded-book text-medium'>
                          {t('pata_voucher.buy_voucher')}
                        </label>
                      </div>
                      <div className=''>
                        <div className='inline-block mr-3'>
                          <input
                            id='gift_voucher'
                            name='gift_voucher'
                            type='radio'
                            value='gift_voucher'
                            className='h-4 w-4 text-secondary-900 outline-none focus:outline-none focus:ring-0 border-gray-300 cursor-pointer'
                            checked={filters.action_type === 'gift_voucher'}
                            onChange={handleActionTypeChange}
                          />
                        </div>
                        <label className='font-gotham-rounded-book text-medium'>
                          {t('pata_voucher.gift_voucher')}
                        </label>
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <h3 className='lex justify-between w-full text-left focus:outline-none font-gotham-rounded-medium py-2'>
                    {t('common.sort')}
                  </h3>

                  <div className='py-2 rounded-md'>
                    <div className='grid grid-cols-3'>
                      <div className=''>
                        <div className='inline-block mr-3'>
                          <input
                            id='-created_at'
                            name='sort'
                            type='radio'
                            value='-created_at'
                            className='h-4 w-4 text-secondary-900 outline-none focus:outline-none focus:ring-0 border-gray-300 cursor-pointer'
                            checked={filters.sort === '-created_at'}
                            onChange={handleSortChange}
                          />
                        </div>
                        <label className='font-gotham-rounded-book text-medium'>
                          {t('common.latest_first')}
                        </label>
                      </div>
                      <div className=''>
                        <div className='inline-block mr-3'>
                          <input
                            id='created_at'
                            name='sort'
                            type='radio'
                            value='created_at'
                            className='h-4 w-4 text-secondary-900 outline-none focus:outline-none focus:ring-0 border-gray-300 cursor-pointer'
                            checked={filters.sort === 'created_at'}
                            onChange={handleSortChange}
                          />
                        </div>
                        <label className='font-gotham-rounded-book text-medium'>
                          {t('common.oldest_first')}
                        </label>
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <h3 className='lex justify-between w-full text-left focus:outline-none font-gotham-rounded-medium py-2'>
                    {t('common.date_range')}
                  </h3>
                  <div className='py-2 flex justify-between items-center gap-2'>
                    <DatePicker
                      placeholder={t('common.start_date')}
                      onChange={handleFromDateChange}
                      className='bg-white border border-secondary-300 text-body rounded focus:ring-0 outline-none hover:border-secondary-300 focus:outline-none focus:border-secondary-500 block w-full pr-4 py-2 font-gotham-rounded-book text-xs placeholder:text-body'
                      name='from'
                      showToday={true}
                      {...(filters.from !== '' && { value: filters.from })}
                    />
                    <span className='font-gotham-rounded-book text-medium'>
                      {t('common.to')}
                    </span>
                    <DatePicker
                      placeholder={t('common.end_date')}
                      onChange={handleToDateChange}
                      className='bg-white border border-secondary-300 text-body rounded focus:ring-0 outline-none hover:border-secondary-300 focus:outline-none focus:border-secondary-500 block w-full pr-4 py-2 font-gotham-rounded-book text-xs placeholder:text-body'
                      name='to'
                      showToday={true}
                      {...(filters.to !== '' && { value: filters.to })}
                    />
                  </div>
                </section>
              </div>
            </div>
          </>
        }
        onReset={handleReset}
        onSubmit={onApplyFilters}
      />
    </>
  )
}

VoucherHistory.propTypes = {
  t: PropTypes.func,
  okoaUser: PropTypes.object
}

export default VoucherHistory
