import * as yup from 'yup'
import i18n from '../../../i18n'

export const validateSearchPremise = (data) => {
  const schema = yup.object().shape({
    paylend_number: yup
      .number()
      .typeError(i18n.t('errors.paylend_number_must_be_a_number'))
      .required(i18n.t('errors.paylend_number_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateVerifyPremise = (data) => {
  const schema = yup.object().shape({
    business_permit: yup
      .boolean()
      .equals([true], i18n.t('errors.ensure_all_documents_are_present'))
      .required(i18n.t('errors.ensure_all_documents_are_present')),
    mpesa_statement: yup
      .boolean()
      .equals([true], i18n.t('errors.ensure_all_documents_are_present'))
      .required(i18n.t('errors.ensure_all_documents_are_present')),
    manager_id: yup
      .boolean()
      .equals([true], i18n.t('errors.ensure_all_documents_are_present'))
      .required(i18n.t('errors.ensure_all_documents_are_present'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateAddPremiseType = (data) => {
  const schema = yup.object().shape({
    phone: yup
      .string()
      .min(12, i18n.t('errors.phone_must_be_valid'))
      .max(12, i18n.t('errors.phone_must_be_valid'))
      .required(i18n.t('errors.phone_required')),
    premise_type: yup.string().required(i18n.t('errors.premise_type_required'))
  })

  return schema.validate(data, { abortEarly: true })
}
