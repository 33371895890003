import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'ramda'
import { Table, TableSkeleton, EmptyContent, Badge } from '../..'
import { loadNextShops, loadPrevShops } from '../../../containers/OkoaShopping/store'
import { formatAmount } from '../../../helpers/utils'

function Shops({ t }) {
  const dispatch = useDispatch()
  const {
    shops,
    isLoading,
    shopsPagination: pagination
  } = useSelector((state) => state.shopping)
  const { currency } = useSelector((state) => state.auth)

  return (
    <>
      {isLoading ? (
        <>
          <TableSkeleton showSearch={false} showPagination={true} />
        </>
      ) : (
        <>
          {isEmpty(shops) ? (
            <div className='flex flex-col'>
              <div className='overflow-x-auto shadow-md sm:rounded-lg'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='p-4'>
                    <h2 className='font-gotham-rounded-medium text-body text-base'>
                      {t('okoa_shopping.shops')}
                    </h2>
                    <EmptyContent
                      title={t('common.no_shops')}
                      subTitle={t('common.no_shops_help_text')}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='flex flex-col'>
              <div className='overflow-x-auto shadow-md sm:rounded-lg w-full'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='p-4 flex'>
                    <h2 className='font-gotham-rounded-medium text-body text-base mb-2'>
                      {t('okoa_shopping.shops')}
                    </h2>
                  </div>
                  <div className='overflow-hidden p-4'>
                    <Table
                      header={
                        <tr>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_shopping.shop_name')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_shopping.location')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_shopping.shop_number')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('common.monthly_income')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('common.premise_verification')}
                          </th>
                          <th scope='col' className='p-4'>
                            <span className='sr-only'>{t('okoa_shopping.action')}</span>
                          </th>
                        </tr>
                      }
                      body={
                        <>
                          {shops.map((shop) => (
                            <tr key={shop.id}>
                              <td className='pl-2 py-4 whitespace-nowrap'>
                                <div className='text-medium font-medium text-body'>
                                  {shop.premiseName}
                                </div>
                                {(shop.managerEmail || shop.managerPhoneNumber) && (
                                  <>
                                    <div className='text-xs text-gray-500'>
                                      {shop.managerEmail
                                        ? t('common.email')
                                        : t('common.phone')}
                                      : {shop.managerEmail || shop.managerPhoneNumber}
                                    </div>
                                  </>
                                )}
                              </td>
                              <td className='pl-2 py-4 w-40 text-medium text-body'>
                                {shop.premiseAddress || t('common.not_available')}
                              </td>
                              <td className='pl-2 py-4 text-medium text-body'>
                                {shop.paylendNumber}
                              </td>
                              <td className='pl-2 py-4 text-medium text-body'>
                                {currency} {formatAmount(shop.monthlyTransactionIncome)}
                              </td>
                              <td className='pl-2 py-4 text-medium text-body'>
                                {shop.isPremiseVerified ? (
                                  <Badge type={'success'} label={t('common.verified')} />
                                ) : (
                                  <Badge
                                    type={'warning'}
                                    label={t('common.unverified')}
                                  />
                                )}
                              </td>
                              <td className='pl-2 py-4 text-medium text-body'>
                                {/* <button className='px-2 py-2 border border-secondary-700 rounded-md font-gotham-rounded-medium capitalize text-medium'>
                                  {t('common.view')}
                                </button> */}
                              </td>
                            </tr>
                          ))}
                        </>
                      }
                      pagination={
                        <div className='flex justify-between pl-2 pr-6 py-4 w-full border border-gray-200 border-t-0'>
                          <div className='text-medium text-muted'>
                            {pagination.count} {t('common.of')} {pagination.total}{' '}
                            {t('okoa_shopping.shops')}
                          </div>
                          <div className='flex justify-between gap-4'>
                            <button
                              className={`text-medium ${
                                pagination.hasPrev
                                  ? 'text-secondary-900'
                                  : 'text-muted pointer-events-none'
                              }`}
                              onClick={() => dispatch(loadPrevShops())}
                            >
                              {t('common.prev')}
                            </button>
                            <button
                              className={`text-medium ${
                                pagination.hasNext
                                  ? 'text-secondary-900'
                                  : 'text-muted pointer-events-none'
                              }`}
                              onClick={() => dispatch(loadNextShops())}
                            >
                              {t('common.next')}
                            </button>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

Shops.propTypes = {
  t: PropTypes.func
}

export default Shops
