import { Fragment, useState, useEffect, useCallback } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import { isEmpty, omit } from 'ramda'
import { useSelector, useDispatch } from 'react-redux'
import { Alert, Button, FormGroup, Input } from '../..'
import { icons } from '../../../assets/img'
import { getUserDetails } from '../../../helpers/utils'
import { setUser } from '../../../containers/Auth/store'
import { updateAlert } from '../../../containers/DashboardLayout/store'
import { sendOtp } from '../../../api/auth'

function OkoaRequestSummaryModal({
  translate,
  showModal,
  onCancel,
  onSubmit,
  modalTitle,
  amountText,
  chargeSummary,
  dueDate,
  feeCharged,
  submittingRequest,
  errors,
  interestBreakDown
}) {
  const user = getUserDetails()
  const dispatch = useDispatch()
  const { firstname, lastname } = user
  const [error, setError] = useState('')
  const [state, setState] = useState({
    otp: ''
  })
  const [timer, setTimer] = useState(0)
  const timeOutCallback = useCallback(() => setTimer((currTimer) => currTimer - 1), [])

  const { okoaRequest } = useSelector((state) => state.okoaFees)
  const { alert: notification } = useSelector((state) => state.dashboard)

  const [alert, setAlert] = useState({
    type: '',
    message: ''
  })

  const startTimer = () => {
    if (!timer) {
      setTimer(60)
    }
  }

  useEffect(() => {
    setError(errors.apiError)
  }, [errors.apiError])

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000)
  }, [timer, timeOutCallback])

  useEffect(() => {
    setAlert({
      type: notification.type,
      message: notification.message
    })
  }, [notification, dispatch])

  const isInvalidOtp = () => isEmpty(state.otp) || state.otp.length < 4

  const onResendOtp = async () => {
    try {
      setState({ otp: '' })
      await sendOtp({ phone: user.phone })
      startTimer()
      dispatch(
        updateAlert({
          message: translate('common.complete_okoa_request_help_text'),
          type: 'info',
          show: false,
          duration: 'long'
        })
      )
    } catch (error) {}
  }

  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={onCancel}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block p-6 w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button onClick={onCancel}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>

                <div className=''>
                  <div className='mt-4 font-gotham-rounded-bold text-primary text-3xl text-center'>
                    {modalTitle}
                  </div>
                  {isEmpty(okoaRequest) ? (
                    <>
                      <div className='mt-4 flex justify-center'>
                        <img
                          src={user.avatar || icons.iconElsie}
                          className='w-20'
                          alt='Lady'
                          onError={() => dispatch(setUser(omit(['avatar'], user)))}
                        />
                      </div>
                      <h3 className='font-gotham-rounded-medium mt-2 text-2xl text-body text-center'>
                        {firstname} {lastname}
                      </h3>
                      <div className='mt-2 text-center'>
                        <i className='bi bi-star-fill text-primary text-base'></i>
                        <i className='bi bi-star-fill text-primary text-base'></i>
                        <i className='bi bi-star-fill text-primary text-base'></i>
                        <i className='bi bi-star-fill text-primary text-base'></i>
                        <i className='bi bi-star-fill text-primary text-base'></i>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='mt-4 font-gotham-rounded-bold text-body opacity-80 text-2xl sm:px-6 text-center'>
                        {okoaRequest?.school} - {okoaRequest?.paylend_number}
                      </div>
                      <div className='mt-2 font-gotham-rounded-bold text-body opacity-80 text-2xl sm:px-6 text-center'>
                        C/o {translate('okoa_fees.school_manager')}
                      </div>
                    </>
                  )}

                  <div className='mt-2 leading-3'>
                    <Alert
                      type={alert.type}
                      message={alert.message}
                      onCancel={() => {
                        setAlert({ type: '', message: '' })
                        dispatch(
                          updateAlert({
                            type: 'default',
                            message: '',
                            show: false,
                            duration: 'long'
                          })
                        )
                      }}
                    />
                    {error && (
                      <Alert type='error' message={error} onCancel={() => setError('')} />
                    )}
                    <div className='text-center'>
                      <p className='text-sm'>{amountText}</p>
                      <p className='text-sm'>
                        {translate('okoa_shopping.fee_charged', {
                          amount: feeCharged,
                          kiasi: feeCharged
                        })}{' '}
                        {`(${interestBreakDown})`}
                        {'.'} {chargeSummary}{' '}
                        {dueDate ? `${translate('common.due_on')} ${dueDate}` : ''}
                      </p>
                    </div>
                  </div>

                  <div className='mt-4'>
                    <FormGroup>
                      <Input
                        type='number'
                        placeholder={translate('otp_verification.otp_code')}
                        onChange={(e) => setState({ ...state, otp: e.target.value })}
                        name='otp'
                        hasError={Object.keys(errors).includes('otp')}
                        errorMessage={errors.otp}
                        value={state.otp}
                      />
                      {timer === 0 && (
                        <button
                          className='text-secondary-900 text-xs text-center font-gotham-rounded-medium'
                          onClick={() => {
                            onResendOtp()
                          }}
                        >
                          {translate('otp_verification.resend_otp')}
                        </button>
                      )}
                      {timer > 0 && (
                        <span className='text-xs'>
                          {translate('otp_verification.resend_otp')}{' '}
                          {translate('common.in')} {timer} {translate('common.seconds')}
                        </span>
                      )}
                    </FormGroup>
                  </div>

                  <div className='mt-6'>
                    <div className=''>
                      <Button
                        label={translate('okoa_shopping.send_request')}
                        classes='uppercase'
                        type='primary'
                        onClick={() => onSubmit(state)}
                        disabled={submittingRequest || isInvalidOtp()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

OkoaRequestSummaryModal.propTypes = {
  showModal: PropTypes.bool,
  translate: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancelRequest: PropTypes.func,
  modalTitle: PropTypes.string,
  amountText: PropTypes.string,
  chargeSummary: PropTypes.string,
  dueDate: PropTypes.string,
  feeCharged: PropTypes.string,
  submittingRequest: PropTypes.bool,
  errors: PropTypes.object,
  currentUser: PropTypes.object,
  interestBreakDown: PropTypes.string
}

export default OkoaRequestSummaryModal
