import * as yup from 'yup'
import i18n from '../../../i18n'

export const validateLogin = (data) => {
  const schema = yup.object().shape({
    login_type: yup.string().required(i18n.t('errors.login_type_required')),
    password: yup.string().required(i18n.t('errors.password_required')),
    email: yup.string().when('login_type', {
      is: 'email',
      then: yup
        .string()
        .email(i18n.t('errors.invalid_email'))
        .required(i18n.t('errors.email_required'))
    }),
    phone: yup.string().when('login_type', {
      is: 'phone',
      then: yup
        .string()
        .min(12, i18n.t('errors.phone_must_be_valid'))
        .max(12, i18n.t('errors.phone_must_be_valid'))
        .required(i18n.t('errors.phone_required'))
    })
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateSignUp = (data) => {
  const schema = yup.object().shape({
    confirm_password: yup
      .string()
      .min(8, i18n.t('errors.password_length_error'))
      .oneOf([yup.ref('password')], i18n.t('errors.password_match'))
      .required(i18n.t('errors.confirm_password_required')),
    password: yup
      .string()
      .min(8, i18n.t('errors.password_length_error'))
      .required(i18n.t('errors.password_required')),
    phone: yup
      .string()
      .min(12, i18n.t('errors.phone_must_be_valid'))
      .max(12, i18n.t('errors.phone_must_be_valid'))
      .required(i18n.t('errors.phone_required')),
    country: yup
      .string()
      .oneOf(['Kenya', 'Zambia'], i18n.t('signup.country_not_supported'))
      .required(i18n.t('errors.country_required')),
    identification: yup
      .string()
      .required(i18n.t('errors.identification_number_required')),
    email: yup.string().email(i18n.t('errors.invalid_email')),
    lastname: yup.string().required(i18n.t('errors.last_name_required')),
    firstname: yup.string().required(i18n.t('errors.first_name_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateForgotPassword = (data) => {
  const schema = yup.object().shape({
    type: yup.string().required(i18n.t('errors.type_required')),
    email: yup.string().when('type', {
      is: 'email',
      then: yup
        .string()
        .email(i18n.t('errors.invalid_email'))
        .required(i18n.t('errors.email_required'))
    }),
    phone: yup.string().when('type', {
      is: 'phone',
      then: yup
        .string()
        .min(12, i18n.t('errors.phone_must_be_valid'))
        .max(12, i18n.t('errors.phone_must_be_valid'))
        .required(i18n.t('errors.phone_required'))
    })
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateResetPassword = (data) => {
  const schema = yup.object().shape({
    token: yup.string().required(),
    confirm_password: yup
      .string()
      .min(8, i18n.t('errors.password_length_error'))
      .oneOf([yup.ref('password')], i18n.t('errors.password_match'))
      .required(i18n.t('errors.confirm_password_required')),
    password: yup
      .string()
      .min(8, i18n.t('errors.password_length_error'))
      .required(i18n.t('errors.password_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateVerifyOtp = (data) => {
  const schema = yup.object().shape({
    type: yup.string().required(),
    code: yup.string().when('type', {
      is: 'verify',
      then: yup.string().required(i18n.t('errors.otp_required'))
    }),
    phone: yup.string().when('type', {
      is: 'resend',
      then: yup
        .string()
        .min(12, i18n.t('errors.phone_must_be_valid'))
        .max(12, i18n.t('errors.phone_must_be_valid'))
        .required(i18n.t('errors.phone_required'))
    })
  })

  return schema.validate(data, { abortEarly: true })
}

export const validateCompleteRegistration = (data) => {
  const schema = yup.object().shape({
    identification: yup.string().required(i18n.t('errors.id_images_required')),
    income: yup.string().required(i18n.t('errors.income_required')),
    occupation: yup.string().required(i18n.t('errors.occupation_required')),
    gender: yup.string().required(i18n.t('errors.gender_required')),
    dob: yup.string().required(i18n.t('errors.dob_required'))
  })

  return schema.validate(data, { abortEarly: true })
}

export const validatePayment = (data) => {
  const schema = yup.object().shape({
    phone: yup.string().when('transaction_mode', {
      is: 'stk_push',
      then: yup
        .string()
        .min(12, i18n.t('errors.phone_must_be_valid'))
        .max(12, i18n.t('errors.phone_must_be_valid'))
        .required(i18n.t('errors.phone_required'))
    }),
    amount: yup
      .number(i18n.t('errors.amount_must_be_a_number'))
      .min(1, i18n.t('errors.amount_must_be_greater_than_zero'))
      .required(i18n.t('errors.amount_required'))
  })

  return schema.validate(data, { abortEarly: true })
}
