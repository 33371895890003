import { useState, useEffect } from 'react'
import { Provider, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import Routes from './Routes'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { updateNotificationState } from '../DashboardLayout/store'
import { getFcmToken } from '../../helpers/firebase'
import { getLocationDetails } from '../Auth/store'

const App = ({ store }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    newVersionAvailable: false,
    waitingWorker: {}
  })

  const onServiceWorkerUpdate = (registration) => {
    setState({
      waitingWorker: registration && registration.waiting,
      newVersionAvailable: true
    })
  }

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      console.log = () => {}
      console.error = () => {}
      console.debug = () => {}
      console.warn = () => {}
    }
  }, [])

  const updateServiceWorker = () => {
    const { waitingWorker } = state
    waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' })
    setState({ newVersionAvailable: false })
    window.location.reload()
  }

  useEffect(() => {
    const { newVersionAvailable } = state
    serviceWorkerRegistration.register({ onUpdate: onServiceWorkerUpdate })

    if (newVersionAvailable) {
      dispatch(
        updateNotificationState({
          type: 'info',
          message: t('common.new_version_available'),
          duration: 'long',
          show: true
        })
      )

      updateServiceWorker()
    }
  })

  useEffect(() => {
    getFcmToken()
  }, [])

  useEffect(() => {
    dispatch(getLocationDetails())
  }, [dispatch])

  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  )
}

App.propTypes = {
  store: PropTypes.object.isRequired
}

export default App
