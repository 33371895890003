import { Fragment, useState, useRef, useEffect } from 'react'
import { isEmpty } from 'ramda'
import { Dialog, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import { Button, FormGroup, Input } from '../..'
import { icons } from '../../../assets/img'

function SearchInitiativeModal({
  translate,
  showModal,
  onCancel,
  onClickStepTwo,
  stepOneButtonDisabled,
  errors,
  searchInitiative,
  searchingInitiative,
  currentState
}) {
  const inputRef = useRef()
  const timeout = useRef()

  const [state, setState] = useState({
    initiative_number: currentState.initiative_number || ''
  })

  const [selectedInitiative, setSelectedInitiative] = useState(
    currentState.initiative || {}
  )

  const handleDebounceSearch = (e) => {
    clearTimeout(timeout.current)
    setState({
      ...state,
      [e.target.name]: e.target.value
    })

    if (inputRef.current.value.length >= 5) {
      timeout.current = setTimeout(() => {
        searchInitiative({ initiative_number: inputRef.current.value })
      }, 100)
    }
  }

  useEffect(() => {
    setState({
      initiative_number: currentState.initiative_number || ''
    })
    setSelectedInitiative(currentState.initiative || {})
  }, [currentState])

  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-40 overflow-y-auto'
          onClose={onCancel}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block p-6 w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button onClick={onCancel} className='focus:outline-none outline-none'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>

                <div className='text-center'>
                  <header className='uppercase text-medium text-muted font-gotham-rounded-medium mx-auto flex justify-center'>
                    <img src={icons.iconInitiative} alt='Shop' className='w-20 h-20' />
                  </header>

                  {searchingInitiative && (
                    <div className='mt-4 flex justify-center motion-safe:animate-spin'>
                      <img src={icons.iconSpinner} alt='spinner' className='w-12 h-12' />
                    </div>
                  )}

                  {!isEmpty(selectedInitiative) && (
                    <>
                      <div className='mt-4 font-gotham-rounded-bold text-primary text-3xl'>
                        {selectedInitiative.name}
                      </div>

                      <div className='mt-4 font-gotham-rounded-medium text-body text-xl px-10'>
                        {translate('changisha.target_amount')}: Ksh{' '}
                        {selectedInitiative.targetAmount}
                      </div>
                    </>
                  )}

                  <div className='mt-4 text-body text-sm sm:px-10'>
                    {translate('changisha.please_confirm_name')}
                  </div>

                  <section className='mt-6'>
                    <div className='text-sm text-body'>
                      {translate('changisha.enter_initiative_number')}
                    </div>

                    <div className='mt-3 sm:px-6'>
                      <FormGroup>
                        <Input
                          type='number'
                          placeholder='0713552'
                          classes='font-gotham-rounded-bold text-secondary-900 text-center text-sm placeholder:opacity-50'
                          value={state.initiative_number}
                          name={'initiative_number'}
                          onChange={handleDebounceSearch}
                          hasError={Object.keys(errors).includes('initiative_number')}
                          errorMessage={errors.initiative_number}
                          inputRef={inputRef}
                        />
                      </FormGroup>
                    </div>

                    <div className='w-full sm:w-48 flex justify-center mx-auto'>
                      <Button
                        label={translate('okoa_shopping.next')}
                        classes='uppercase'
                        onClick={() => onClickStepTwo(state)}
                        disabled={stepOneButtonDisabled}
                      />
                    </div>
                  </section>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

SearchInitiativeModal.propTypes = {
  showModal: PropTypes.bool,
  translate: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onClickStepTwo: PropTypes.func,
  stepOneButtonDisabled: PropTypes.bool,
  errors: PropTypes.object,
  searchInitiative: PropTypes.func,
  searchingInitiative: PropTypes.bool,
  currentState: PropTypes.object
}

export default SearchInitiativeModal
