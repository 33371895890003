import { Fragment, useState, useRef, useEffect } from 'react'
import { isEmpty } from 'ramda'
import { Dialog, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import { Button, FormGroup, Alert } from '../..'
import { icons } from '../../../assets/img'

function VerifyPremiseModal({
  translate,
  showModal,
  onCancel,
  onSubmit,
  errors,
  searchPremise,
  searchingPremise,
  currentState,
  verificationState,
  handleChange,
  verifyingPremise
}) {
  const inputRef = useRef()
  const timeout = useRef()

  const [state, setState] = useState({
    paylend_number: currentState.paylend_number || ''
  })

  const [error, setError] = useState('')

  const [selectedPremise, setSelectedPremise] = useState(currentState.premise || {})

  const handleDebounceSearch = (e) => {
    clearTimeout(timeout.current)
    setState({
      ...state,
      [e.target.name]: e.target.value
    })

    if (!isEmpty(selectedPremise) || inputRef.current.value.length >= 6) {
      timeout.current = setTimeout(() => {
        searchPremise({ paylend_number: inputRef.current.value })
      }, 100)
    }
  }

  useEffect(() => {
    setState({
      paylend_number: currentState.paylend_number || ''
    })
    setSelectedPremise(currentState.premise || {})
  }, [currentState])

  useEffect(() => {
    setError(errors.apiError)
  }, [errors.apiError])

  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-40 overflow-y-auto'
          onClose={onCancel}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-40' />
            </Transition.Child>

            <span className='inline-block h-screen align-middle' aria-hidden='true'>
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block p-6 w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <div className='flex justify-end'>
                  <button onClick={onCancel} className='focus:outline-none outline-none'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6 hover:text-secondary-900'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>

                <div className=''>
                  <header className='uppercase text-medium text-muted font-gotham-rounded-medium mx-auto flex justify-center'>
                    <img src={icons.iconShopPrimary} alt='Shop' className='w-20 h-20' />
                  </header>

                  {searchingPremise && (
                    <div className='mt-4 flex justify-center motion-safe:animate-spin'>
                      <img src={icons.iconSpinner} alt='spinner' className='w-12 h-12' />
                    </div>
                  )}

                  {!isEmpty(selectedPremise) && (
                    <>
                      <div className='mt-4 font-gotham-rounded-bold text-primary text-3xl text-center'>
                        {selectedPremise.premiseName}
                      </div>

                      {selectedPremise.location && (
                        <div className='mt-4 font-gotham-rounded-medium text-body text-xl px-10 text-center'>
                          {translate('okoa_shopping.located_at')}{' '}
                          {selectedPremise.premiseAddress}
                        </div>
                      )}
                    </>
                  )}

                  {error && (
                    <div className='sm:px-6'>
                      <Alert type='error' message={error} onCancel={() => setError('')} />
                    </div>
                  )}

                  <section className='mt-6'>
                    <div className='text-sm text-body text-center'>
                      {translate('agent_dashboard.enter_the_premise_number')}
                    </div>

                    <div className='mt-3 sm:px-6'>
                      <FormGroup>
                        <input
                          type={'number'}
                          className={`bg-white border placeholder:opacity-50 ${
                            Object.keys(errors).includes('paylend_number')
                              ? 'border-red-300'
                              : 'border-secondary-300'
                          } text-secondary-900 rounded focus:ring-0 outline-none focus:outline-none ${
                            Object.keys(errors).includes('paylend_number')
                              ? 'focus:border-red-500'
                              : 'focus:border-secondary-500'
                          }  block w-full px-4 py-3 text-center font-gotham-rounded-bold text-sm`}
                          placeholder='071355'
                          onChange={handleDebounceSearch}
                          name={'paylend_number'}
                          value={state.paylend_number}
                          ref={inputRef}
                        />
                        {Object.keys(errors).includes('paylend_number') && (
                          <span className='text-xs font-gotham-rounded-book block mt-1 text-red-600'>
                            {errors.paylend_number}
                          </span>
                        )}
                      </FormGroup>
                    </div>

                    {!isEmpty(selectedPremise) && (
                      <div className='mb-4'>
                        <div className='text-medium text-body text-center'>
                          {translate('agent_dashboard.verify_docs_help_text')}
                        </div>

                        <div className='my-2'>
                          <input
                            type='checkbox'
                            className='border-secondary-300 text-secondary-600 focus:ring-0 rounded-sm cursor-pointer'
                            checked={verificationState.business_permit}
                            onChange={handleChange}
                            name='business_permit'
                          />
                          <span className='pl-2 text-xs'>
                            {translate('agent_dashboard.business_permit')}{' '}
                          </span>
                        </div>

                        <div className='mb-2'>
                          <input
                            type='checkbox'
                            className='border-secondary-300 text-secondary-600 focus:ring-0 rounded-sm cursor-pointer'
                            checked={verificationState.mpesa_statement}
                            onChange={handleChange}
                            name='mpesa_statement'
                          />
                          <span className='pl-2 text-xs'>
                            {translate('agent_dashboard.mpesa_statement')}{' '}
                          </span>
                        </div>
                        <div className='mb-2'>
                          <input
                            type='checkbox'
                            className='border-secondary-300 text-secondary-600 focus:ring-0 rounded-sm cursor-pointer'
                            checked={verificationState.manager_id}
                            onChange={handleChange}
                            name='manager_id'
                          />
                          <span className='pl-2 text-xs'>
                            {translate('agent_dashboard.manager_id')}{' '}
                          </span>
                        </div>
                      </div>
                    )}

                    <div className='w-full sm:px-6 flex justify-center mx-auto'>
                      <Button
                        label={translate('agent_dashboard.verify')}
                        classes='uppercase'
                        onClick={() =>
                          onSubmit({
                            ...state,
                            premise_id: selectedPremise.premiseId
                          })
                        }
                        disabled={verifyingPremise || isEmpty(selectedPremise)}
                      />
                    </div>
                  </section>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

VerifyPremiseModal.propTypes = {
  showModal: PropTypes.bool,
  translate: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onClickStepTwo: PropTypes.func,
  stepOneButtonDisabled: PropTypes.bool,
  errors: PropTypes.object,
  searchPremise: PropTypes.func,
  searchingPremise: PropTypes.bool,
  currentState: PropTypes.object,
  verificationState: PropTypes.object,
  handleChange: PropTypes.func,
  verifyingPremise: PropTypes.bool
}

export default VerifyPremiseModal
