import PropTypes from 'prop-types'

function Alert({ type, onCancel, message, action }) {
  return (
    <>
      {type === 'error' && (
        <div
          className='flex items-center p-4 my-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800'
          role='alert'
        >
          <svg
            className='inline flex-shrink-0 mr-3 w-5 h-5'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z'
              clipRule='evenodd'
            ></path>
          </svg>
          <div>
            {message} {action}
          </div>
          <div className='ml-auto'>
            <button onClick={onCancel}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-5 hover:text-red-600'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </button>
          </div>
        </div>
      )}

      {type === 'success' && (
        <div
          className='flex items-center p-4 my-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800'
          role='alert'
        >
          <svg
            className='inline flex-shrink-0 mr-3 w-5 h-5'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z'
              clipRule='evenodd'
            ></path>
          </svg>
          <div>
            {message} {action}
          </div>
          <div className='ml-auto'>
            <button onClick={onCancel}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-5 hover:text-green-600'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </button>
          </div>
        </div>
      )}

      {type === 'info' && (
        <div
          className='flex items-center p-4 my-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800'
          role='alert'
        >
          <svg
            className='inline flex-shrink-0 mr-3 w-5 h-5'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z'
              clipRule='evenodd'
            ></path>
          </svg>
          <div>
            {message} {action}
          </div>
          <div className='ml-auto'>
            <button onClick={onCancel}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-5 hover:text-blue-600'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </button>
          </div>
        </div>
      )}

      {type === 'warning' && (
        <div
          className='flex items-center p-4 my-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800'
          role='alert'
        >
          <i className='bi bi-exclamation-triangle-fill text-yellow-700 mr-3 w-5 h-5'></i>
          <div>
            {message}
            {action}
          </div>
        </div>
      )}
    </>
  )
}

Alert.propTypes = {
  type: PropTypes.string,
  onCancel: PropTypes.func,
  message: PropTypes.string,
  action: PropTypes.node
}

export default Alert
