import { Suspense, lazy } from 'react'
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom'
import { FallBackLoader } from '../../components'
import {
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  OTP_VERIFICATION,
  SIGN_UP,
  EMAIL_SENT,
  INDEX,
  HOME,
  OTP_SENT,
  CREATE_PASSWORD
} from './RouteConstants'

const LoginPage = lazy(() => import('../Auth/Login'))
const ForgotPasswordPage = lazy(() => import('../Auth/ForgotPassword'))
const ResetPasswordPage = lazy(() => import('../Auth/ResetPassword'))
const EmailSentPage = lazy(() => import('../Auth/EmailSent'))
const OtpVerificationPage = lazy(() => import('../Auth/OtpVerification'))
const SignUpPage = lazy(() => import('../Auth/SignUp'))
const NotFoundPage = lazy(() => import('../NotFoundPage'))
const DashboardRoutes = lazy(() => import('./DashboardRoutes'))
const OtpSentPage = lazy(() => import('../Auth/OtpSent'))
const CreatePasswordPage = lazy(() => import('../Auth/CreatePassword'))

export default function AppRoutes() {
  return (
    <Suspense fallback={<FallBackLoader />}>
      <BrowserRouter>
        <Switch>
          <Route exact path={LOGIN} component={LoginPage} />
          <Route exact path={FORGOT_PASSWORD} component={ForgotPasswordPage} />
          <Route exact path={EMAIL_SENT} component={EmailSentPage} />
          <Route exact path={RESET_PASSWORD} component={ResetPasswordPage} />
          <Route exact path={OTP_VERIFICATION} component={OtpVerificationPage} />
          <Route exact path={SIGN_UP} component={SignUpPage} />
          <Route exact path={OTP_SENT} component={OtpSentPage} />
          <Route exact path={CREATE_PASSWORD} component={CreatePasswordPage} />
          <Redirect exact from={INDEX} to={HOME} />
          <Route component={DashboardRoutes} />
          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  )
}
