import PropTypes from 'prop-types'

function RightPageColumn({ content }) {
  return <div className='w-full block col-span-5'>{content}</div>
}

RightPageColumn.propTypes = {
  content: PropTypes.element
}

export default RightPageColumn
