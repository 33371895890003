import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'ramda'
import { Table, TableSkeleton, EmptyContent, Button } from '..'
import { formatTimestampToDate, formatAmount } from '../../helpers/utils'
import {
  loadNextUserInitiatives,
  loadPrevUserInitiatives
} from '../../containers/Changisha/store'

function ChangishaInitiatives({ t, onContribute }) {
  const dispatch = useDispatch()
  const {
    userInitiatives,
    isLoading,
    userInitiativesPagination: pagination
  } = useSelector((state) => state.changisha)
  const { currency } = useSelector((state) => state.auth)

  return (
    <>
      {isLoading ? (
        <TableSkeleton showSearch={true} showPagination={true} />
      ) : (
        <>
          {isEmpty(userInitiatives) ? (
            <div className='flex flex-col'>
              <div className='overflow-x-auto shadow-md sm:rounded-lg'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='p-4'>
                    <h2 className='font-gotham-rounded-medium text-body text-base'>
                      {t('changisha.initiatives')}
                    </h2>
                    <EmptyContent
                      title={t('common.no_initiatives')}
                      subTitle={t('common.no_initiatives_help_text')}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='flex w-full'>
              <div className='overflow-x-auto shadow-md sm:rounded-lg w-full'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='p-4'>
                    <div className='flex justify-between items-center'>
                      <h2 className='font-gotham-rounded-medium text-body text-base mb-2'>
                        {t('changisha.initiatives')}
                      </h2>
                      {/* <div className='ml-auto'>
                        <label htmlFor='table-search' className='sr-only'>
                          Search
                        </label>
                        <div className='mt-1'>
                          <Input
                            type='search'
                            placeholder={t('common.search_initiative')}
                            onChange={() => null}
                          />
                        </div>
                      </div> */}
                    </div>

                    <section className='mt-4'>
                      <Table
                        header={
                          <tr>
                            <th
                              scope='col'
                              className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                            >
                              {t('changisha.name')}
                            </th>
                            <th
                              scope='col'
                              className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                            >
                              {t('changisha.ends_on')}
                            </th>
                            <th
                              scope='col'
                              className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                            >
                              {t('changisha.initiative_number')}
                            </th>
                            <th
                              scope='col'
                              className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                            >
                              {t('common.amount')}
                            </th>
                            <th scope='col' className='p-4'>
                              <span className='sr-only'>{t('okoa_shopping.action')}</span>
                            </th>
                          </tr>
                        }
                        body={
                          <>
                            {userInitiatives.map((initiative) => (
                              <tr key={initiative.id}>
                                <td className='pl-2 py-4 w-40 text-medium text-body'>
                                  {initiative.name}
                                </td>
                                <td className='pl-2 py-4 w-40 text-medium uppercase text-body'>
                                  {formatTimestampToDate(initiative.endDate)}
                                </td>
                                <td className='pl-2 py-4 text-medium text-body'>
                                  {initiative.initiativeNumber}
                                </td>
                                <td className='pl-2 py-4 whitespace-nowrap'>
                                  <div className='text-medium font-medium text-body'>
                                    {t('common.target')}: {currency}{' '}
                                    {formatAmount(initiative.targetAmount)}
                                  </div>
                                  <div className='text-xs'>
                                    {t('common.raised')}: {currency}{' '}
                                    {formatAmount(initiative.totalRaised)}
                                  </div>
                                  <div className='text-xs'>
                                    {t('common.deficit')}: {currency}{' '}
                                    {formatAmount(
                                      initiative.targetAmount - initiative.totalRaised
                                    )}
                                  </div>
                                </td>
                                <td className='px-2 py-4 text-medium capitalize text-body'>
                                  <Button
                                    label={t('common.contribute')}
                                    onClick={() => onContribute(initiative)}
                                  />
                                </td>
                              </tr>
                            ))}
                          </>
                        }
                        pagination={
                          <div className='flex justify-between pl-2 pr-6 py-4 w-full border border-gray-200 border-t-0'>
                            <div className='text-medium text-muted'>
                              {pagination.count} {t('common.of')} {pagination.total}{' '}
                              {t('changisha.initiatives')}
                            </div>
                            <div className='flex justify-between gap-4'>
                              <button
                                className={`text-medium ${
                                  pagination.hasPrev
                                    ? 'text-secondary-900'
                                    : 'text-muted pointer-events-none'
                                }`}
                                onClick={() => dispatch(loadPrevUserInitiatives({}))}
                              >
                                {t('common.prev')}
                              </button>
                              <button
                                className={`text-medium ${
                                  pagination.hasNext
                                    ? 'text-secondary-900'
                                    : 'text-muted pointer-events-none'
                                }`}
                                onClick={() => dispatch(loadNextUserInitiatives({}))}
                              >
                                {t('common.next')}
                              </button>
                            </div>
                          </div>
                        }
                      />
                    </section>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

ChangishaInitiatives.propTypes = {
  t: PropTypes.func,
  onContribute: PropTypes.func
}

export default ChangishaInitiatives
