import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { isEmpty } from 'ramda'
import { icons } from '../../assets/img'
import { Metric, Table, EmptyContent } from '../index'
import {
  formatTimestampToDate,
  formatDateToTime,
  transformTransactionType,
  formatAmount
} from '../../helpers/utils'

function PataVoucherOverview({ t }) {
  const { metrics, currency } = useSelector((state) => state.auth)
  const { recentTransactions: transactions } = useSelector((state) => state.pataVoucher)

  return (
    <>
      <section className='mb-6'>
        <div className='w-full'>
          <div className='sm:grid sm:grid-cols-3 gap-2 last:border-0'>
            <Metric
              label={t('home.total_vouchers')}
              icon={icons.iconVoucher}
              showPercentage={false}
              count={metrics.pataVoucher.totalVouchers}
            />
            <Metric
              label={t('pata_voucher.redeemed')}
              icon={icons.iconMoneyArrow}
              showPercentage={false}
              count={`${currency} ${formatAmount(metrics.pataVoucher.totalRedeemed)}`}
            />
            <Metric
              label={t('pata_voucher.worth')}
              icon={icons.moneyActive}
              showPercentage={false}
              count={`${currency} ${formatAmount(metrics.pataVoucher.totalWorth)}`}
            />
          </div>
        </div>
      </section>

      <section>
        <div className='flex flex-col'>
          <div className='overflow-x-auto shadow-md sm:rounded-lg w-full'>
            <div className='inline-block min-w-full align-middle'>
              <div className='p-4 flex justify-between'>
                <h2 className='font-gotham-rounded-medium text-body text-base'>
                  {t('pata_voucher.recent_transactions')}
                </h2>
              </div>
              <div className='overflow-hidden p-4'>
                {isEmpty(transactions) ? (
                  <EmptyContent
                    title={t('common.no_recent_transactions')}
                    subTitle={t('common.no_recent_transactions_help_text')}
                  />
                ) : (
                  <>
                    <Table
                      header={
                        <tr>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_shopping.date')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_shopping.transaction_number')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_shopping.amount')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_shopping.status')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_shopping.type')}
                          </th>
                        </tr>
                      }
                      body={
                        <>
                          {transactions.map((transaction) => (
                            <tr key={transaction.id}>
                              <td className='pl-2 py-4 whitespace-nowrap'>
                                <div className='text-medium font-medium text-body'>
                                  {formatTimestampToDate(transaction.createdAt)}
                                </div>
                                <div className='text-xs text-gray-500'>
                                  {formatDateToTime(transaction.createdAt)}
                                </div>
                              </td>
                              <td className='pl-2 py-4 w-40 text-medium uppercase text-body'>
                                {transaction.receiptNumber}
                              </td>
                              <td className='pl-2 py-4 text-medium text-body'>
                                {transaction.actionType === 'buy_voucher' ? (
                                  <span className='text-green-500'>
                                    + {currency} {formatAmount(transaction.amount)}
                                  </span>
                                ) : (
                                  <span className='text-primary'>
                                    - {currency} {formatAmount(transaction.amount)}
                                  </span>
                                )}
                              </td>
                              <td className='pl-2 py-4 text-medium capitalize text-body'>
                                {transaction.status}
                              </td>
                              <td className='pl-2 py-4 text-medium capitalize text-body'>
                                {transformTransactionType(transaction.actionType)}
                              </td>
                            </tr>
                          ))}
                        </>
                      }
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

PataVoucherOverview.propTypes = {
  t: PropTypes.func
}

export default PataVoucherOverview
