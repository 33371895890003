export const INDEX = '/'
export const LOGIN = '/login'
export const HOME = '/home'
export const FORGOT_PASSWORD = '/forgot-password'
export const RESET_PASSWORD = '/reset-password'
export const SIGN_UP = '/sign-up'
export const OTP_VERIFICATION = '/account-verification'
export const PARTIAL_ACCESS = '/partial-access'
export const FULL_ACCESS = '/full-access'
export const EMAIL_SENT = '/email-sent'
export const PATA_VOUCHER = '/pata-voucher'
export const OKOA_SHOPPING = '/okoa-shopping'
export const OKOA_SHOPPING_SHOPPER = '/okoa-shopping/shopper'
export const OKOA_SHOPPING_SHOP = '/okoa-shopping/shop'
export const OKOA_FEES = '/okoa-school-fees'
export const OKOA_FEES_PARENT = '/okoa-school-fees/parent'
export const OKOA_FEES_SCHOOL = '/okoa-school-fees/school'
export const OKOA_KILIMO = '/okoa-kilimo'
export const OKOA_BILLS = '/okoa-bills'
export const OKOA_AFYA = '/okoa-afya'
export const PROFILE = '/profile'
export const SETTINGS = '/settings'
export const CHANGISHA = '/changisha'
export const CHANGISHA_INDIVIDUAL = '/changisha/individual'
export const CHANGISHA_ORGANIZATION = '/changisha/organization'
export const PROMOTE_DUKA = '/promote-duka'
export const PROMOTE_FRIEND = '/promote-friend'
export const SHARE_APP = '/share-app'
export const PRIVACY_POLICY = '/privacy-policy'
export const LIKE_US_ON_PLAYSTORE = '/like-us-on-playstore'
export const OKOA_SERVICES = '/okoa-services'
export const JAZA_SERVICES = '/jaza-services'
export const INUA_BIZNA = '/inua-biashara'
export const ABOUT = '/about'
export const OKOA_SALON = '/okoa-salon'
export const SELECT_OKOA_USER = '/select-okoa-user'
export const SELECT_OKOA_FEES_USER = '/select-okoa-fees-user'
export const SELECT_CHANGISHA_USER = '/select-changisha-user'
export const SHOPPING_TRANSACTIONS = '/okoa-shopping/transactions'
export const SHOPPING_RECENT_TRANSACTIONS = '/okoa-shopping/recent-transactions'
export const SHOPS_NEARBY = '/okoa-shopping/shops-nearby'
export const SHOPS = '/okoa-shopping/shops'
export const REDEEM_VOUCHER = '/redeem-voucher'
export const VOUCHER_HISTORY = '/voucher-history'
export const CHANGISHA_TRANSACTIONS = '/changisha-transactions'
export const CHANGISHA_INITIATIVES = '/initiatives'
export const VIEW_INITIATIVE = '/view-initiative'
export const OKOA_FEES_TRANSACTIONS = '/okoa-fees-transactions'
export const OKOA_FEES_BENEFICIARIES = '/okoa-fees-beneficiaries'
export const OKOA_FEES_SCHOOLS = '/okoa-fees-schools'
export const OKOA_FEES_REQUESTS = '/okoa-fees-requests'
export const OKOA_FEES_RECENT_TRANSACTIONS = '/okoa-fees-recent-transactions'
export const OTP_SENT = '/otp-sent'
export const OKOA_SHOPPING_REQUESTS = '/okoa-shopping-requests'
export const INUA_BIASHARA_TRANSACTIONS = '/inua-biashara-transactions'
export const INUA_BIASHARA_REQUESTS = '/inua-biashara-requests'
export const INUA_BIASHARA_PREMISES = '/inua-biashara-premises'
export const SEARCH_INITIATIVES = '/search-initiatives'
export const CHANGISHA_CHATS = '/changisha-chats'
export const CHANGISHA_MESSAGES = '/messages'
export const CREATE_PASSWORD = '/create-password'
export const AGENT_DASHBOARD = '/agent-dashboard'
export const SURVEYS = '/surveys'
export const UNVERIFIED_PREMISES = '/unverified-premises'
export const PARTNERS = '/partners'
