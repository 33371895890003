import { createSlice } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { isEmpty, splitEvery, flatten, dropLast, last, head, omit } from 'ramda'
import { cloneDeep } from 'lodash'
import { getUserDetails } from '../../../helpers/utils'
import { getUserTransactions } from '../../../api/transactions'
import { buyVoucher } from '../../../api/vouchers'
import {
  validatePayment,
  validateSearchPremise,
  validateRedeemVoucher
} from './validator'
import { fetchMetrics } from '../../Auth/store'
import { makePayment } from '../../../api/payments'
import { getPremises } from '../../../api/premises'

export const initialState = {
  isLoading: true,
  recentTransactions: [],
  errors: {},
  transactions: [],
  transactionsPagination: {
    hasNext: false,
    hasPrev: false,
    count: 0,
    total: 0,
    paginated: [],
    currentPage: 1
  },
  vouchers: {
    afya: 0,
    bills: 0,
    kilimo: 0,
    logistics: 0,
    salon: 0,
    schoolFees: 0,
    shopping: 0
  },
  buyingVoucher: false,
  hasBoughtVoucher: false,
  voucherState: {},
  stkPushRequestId: '',
  activeRedeemVoucher: '',
  searchingPremise: false,
  hasRedeemedVoucher: false
}

const PAGE_LIMIT = 10

export const pataVoucherSlice = createSlice({
  name: 'patavoucher',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setRecentTransactions: (state, action) => {
      state.recentTransactions = action.payload
    },
    setErrors: (state, action) => {
      state.errors = action.payload
    },
    setVouchers: (state, action) => {
      state.vouchers = action.payload
    },
    setTransactionsPagination: (state, action) => {
      state.transactionsPagination = action.payload
    },
    setTransactions: (state, action) => {
      state.transactions = action.payload
    },
    setBuyingVoucher: (state, action) => {
      state.buyingVoucher = action.payload
    },
    setHasBoughtVoucher: (state, action) => {
      state.hasBoughtVoucher = action.payload
    },
    setVoucherState: (state, action) => {
      state.voucherState = action.payload
    },
    setStkPushRequestId: (state, action) => {
      state.stkPushRequestId = action.payload
    },
    setActiveRedeemVoucher: (state, action) => {
      state.activeRedeemVoucher = action.payload
    },
    setSearchingPremise: (state, action) => {
      state.searchingPremise = action.payload
    },
    setHasRedeemedVoucher: (state, action) => {
      state.hasRedeemedVoucher = action.payload
    }
  }
})

export const {
  setIsLoading,
  setRecentTransactions,
  setErrors,
  setVouchers,
  setTransactionsPagination,
  setTransactions,
  setBuyingVoucher,
  setHasBoughtVoucher,
  setVoucherState,
  setStkPushRequestId,
  setActiveRedeemVoucher,
  setSearchingPremise,
  setHasRedeemedVoucher
} = pataVoucherSlice.actions

export const fetchRecentTransactions = (params) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true))
    const { id: userId } = getUserDetails()
    const { data } = await getUserTransactions({
      user_id: userId,
      reference_occasion: 'pata_voucher',
      limit: PAGE_LIMIT,
      sort: '-created_at',
      ...(!isEmpty(params) && { ...params })
    })

    dispatch(setRecentTransactions(data))

    dispatch(setIsLoading(false))
  } catch (error) {
    dispatch(setIsLoading(false))
    Sentry.captureException(error)
  }
}

export const fetchTransactions =
  (params, loading = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(loading))
      const { id: userId } = getUserDetails()
      const { data, count } = await getUserTransactions({
        user_id: userId,
        reference_occasion: 'pata_voucher',
        limit: PAGE_LIMIT,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setTransactions(data))

      dispatch(
        setTransactionsPagination({
          hasNext: data.length < count,
          hasPrev: false,
          count: data.length,
          total: count,
          paginated: [],
          currentPage: 1
        })
      )

      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }

export function loadNextTransactions(params = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))
      const { transactions, transactionsPagination: pagination } = cloneDeep(
        getState().pataVoucher
      )
      const transaction = transactions[transactions.length - 1]
      const cursor = transaction.id
      const { data, count } = await getUserTransactions({
        user_id: getUserDetails().id,
        reference_occasion: 'pata_voucher',
        limit: PAGE_LIMIT,
        after: cursor,
        sort: '-created_at',
        ...(!isEmpty(params) && { ...params })
      })

      dispatch(setTransactions(data))

      dispatch(
        setTransactionsPagination({
          hasNext: pagination.count + data.length < count,
          hasPrev: true,
          count: pagination.count > 0 ? pagination.count + data.length : data.length,
          total: count,
          paginated: [...pagination.paginated, ...transactions],
          currentPage: pagination.currentPage + 1
        })
      )
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      Sentry.captureException(error)
    }
  }
}

export function loadPrevTransactions(params = {}) {
  return async (dispatch, getState) => {
    const { transactionsPagination: pagination, transactions: stateTransactions } =
      getState().pataVoucher
    const transactions = pagination.paginated
    const transactionChunks = splitEvery(PAGE_LIMIT, transactions)

    if (isEmpty(transactionChunks) || transactionChunks.length === 1) {
      try {
        dispatch(setIsLoading(true))

        const { data, count } = await getUserTransactions({
          user_id: getUserDetails().id,
          reference_occasion: 'pata_voucher',
          limit: PAGE_LIMIT,
          sort: '-created_at',
          ...(!isEmpty(params) && { ...params })
        })

        dispatch(setTransactions(data))

        dispatch(
          setTransactionsPagination({
            hasNext: data.length < count,
            hasPrev: false,
            count: data.length,
            total: count,
            paginated: [],
            currentPage: pagination.currentPage - 1
          })
        )

        dispatch(setIsLoading(false))
      } catch (error) {
        dispatch(setIsLoading(false))
        Sentry.captureException(error)
      }
    } else {
      const previousTransactions = last(transactionChunks)
      dispatch(setTransactions(previousTransactions))

      dispatch(
        setTransactionsPagination({
          hasNext: transactions.length < pagination.total,
          hasPrev: pagination.count > PAGE_LIMIT,
          count:
            pagination.count -
            (stateTransactions.length < PAGE_LIMIT
              ? stateTransactions.length
              : PAGE_LIMIT),
          total: pagination.total,
          paginated: flatten(dropLast(1, transactionChunks)),
          currentPage: pagination.currentPage - 1
        })
      )
    }
  }
}

export function onBuyVoucher(payload) {
  return async (dispatch, getState) => {
    try {
      const user = getUserDetails()
      const { voucherState, activeRedeemVoucher } = getState().pataVoucher
      const { wallet } = getState().auth
      dispatch(setErrors({}))
      dispatch(setHasBoughtVoucher(false))
      dispatch(setBuyingVoucher(true))
      dispatch(setHasRedeemedVoucher(false))

      if (payload.action_type !== 'redeeming') {
        await validatePayment(payload)
      } else {
        await validateRedeemVoucher(
          payload,
          activeRedeemVoucher === 'schoolFees'
            ? wallet.pataVoucher.schoolFees
            : wallet.pataVoucher[activeRedeemVoucher]
        )
      }

      const voucher = await buyVoucher({
        ...omit(['shop'], voucherState),
        user_id: user.id,
        ...(voucherState.action_type === 'buying' && {
          action_type: 'buy'
        }),
        ...(voucherState.action_type === 'gifting' && {
          action_type: 'gifting',
          beneficiary_phone_number: voucherState.beneficiary_phone
        }),
        ...(payload.action_type === 'redeeming' && {
          action_type: 'redeem',
          voucher_type: payload.voucher_type,
          redeem_amount: payload.purchase_amount
        })
      })

      if (payload.action_type !== 'redeeming') {
        const payment = await makePayment({
          user_id: user.id,
          phone: payload.phone,
          reference_occasion: 'pata_voucher',
          transaction_mode: 'stk_push',
          amount: voucherState.purchase_amount,
          ...(voucherState.action_type === 'buying' && {
            action_type: 'buy_voucher'
          }),
          ...(voucherState.action_type === 'gifting' && {
            action_type: 'gift_voucher'
          }),
          reference_occasion_id: voucher.id
        })
        dispatch(setStkPushRequestId(payment.checkoutRequestId))
      }

      if (payload.action_type === 'redeeming') {
        dispatch(setHasRedeemedVoucher(true))
        dispatch(fetchTransactions())
        dispatch(fetchRecentTransactions())
        dispatch(fetchMetrics())
      } else {
        dispatch(setHasBoughtVoucher(true))
      }
      dispatch(setBuyingVoucher(false))
      dispatch(setErrors({}))
      dispatch(setVoucherState({}))
    } catch (error) {
      dispatch(setBuyingVoucher(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      Sentry.captureException(error)
      dispatch(setErrors({ apiError: error.message }))
    }
  }
}

export function searchPremiseByNumber(payload) {
  return async (dispatch, getState) => {
    try {
      dispatch(setSearchingPremise(true))
      dispatch(setErrors({}))
      await validateSearchPremise(payload)
      const { activeRedeemVoucher: activeVoucher } = getState().pataVoucher
      const prevVoucherState = getState().pataVoucher.voucherState
      const { data } = await getPremises({
        ...(activeVoucher === 'shopping' && {
          premise_type: 'shop'
        }),
        ...(activeVoucher === 'schoolFees' && {
          premise_type: 'school'
        }),
        ...(activeVoucher !== 'schoolFees' ||
          (activeVoucher !== 'shopping' && {
            premise_type: 'shop' // to be modified once we add more loan products
          })),
        ...payload,
        limit: 500000
      })

      dispatch(
        setVoucherState({
          ...prevVoucherState,
          shop: {
            premiseName: data[0].premiseName,
            premiseAddress: data[0].premiseAddress
          },
          paylend_number: payload.paylend_number
        })
      )

      dispatch(setSearchingPremise(false))
    } catch (error) {
      dispatch(setSearchingPremise(false))
      const isValidatorError = Array.isArray(error.errors)

      if (isValidatorError) {
        const message = { [error.path]: head(error.errors) }
        return dispatch(setErrors(message))
      }
      Sentry.captureException(error)
    }
  }
}

export default pataVoucherSlice.reducer
