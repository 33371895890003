import PropTypes from 'prop-types'

function TableSkeleton({ showSearch, showPagination }) {
  return (
    <section className='flex flex-col col-span-full bg-white shadow-md rounded-sm animate-pulse'>
      <div className='flex items-center'>
        <header className='px-4 py-4 w-60'>
          <div className='h-2 bg-slate-200 rounded'></div>
        </header>

        {showSearch && (
          <div className='ml-auto'>
            <header className='px-4 py-4 w-60'>
              <div className='h-2 bg-slate-200 rounded'></div>
            </header>
          </div>
        )}
      </div>

      <div className='animate-pulse flex space-x-4 px-4 py-4'>
        <div className='flex-1 flex-col space-y-10 py-1'>
          {Array.from({ length: 5 }).map((item, index) => (
            <div key={index} className='space-y-3'>
              <div className='grid grid-cols-6 gap-4'>
                <div className='h-2 bg-slate-200 rounded'></div>
                <div className='h-2 bg-slate-200 rounded'></div>
                <div className='h-2 bg-slate-200 rounded'></div>
                <div className='h-2 bg-slate-200 rounded'></div>
                <div className='h-2 bg-slate-200 rounded'></div>
                <div className='h-2 bg-slate-200 rounded'></div>
              </div>
            </div>
          ))}
          {showPagination && (
            <div className='flex items-center'>
              <header className='py-2 w-48'>
                <div className='h-2 bg-slate-200 rounded'></div>
              </header>

              <div className='ml-auto'>
                <header className='py-2 w-48'>
                  <div className='h-2 bg-slate-200 rounded'></div>
                </header>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

TableSkeleton.propTypes = {
  showSearch: PropTypes.bool,
  showPagination: PropTypes.bool
}

export default TableSkeleton
