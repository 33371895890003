import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'ramda'
import { useState } from 'react'
import { Table, TableSkeleton, EmptyContent, Button, ShopDirectionsModal } from '../..'
import {
  loadNextNearbyShops,
  loadPrevNearbyShops
} from '../../../containers/OkoaShopping/store'

function ShopsNearby({ t, onOkoa }) {
  const dispatch = useDispatch()
  const {
    shopsNearby,
    isLoading,
    nearbyShopsPagination: pagination
  } = useSelector((state) => state.shopping)
  const [shop, setShop] = useState({})
  const [showModal, setShowModal] = useState(false)

  const handleShopClicked = (shop) => {
    setShop(shop)
    setShowModal(true)
  }

  return (
    <>
      {isLoading ? (
        <>
          <TableSkeleton showSearch={false} showPagination={true} />
        </>
      ) : (
        <>
          {isEmpty(shopsNearby) ? (
            <div className='flex flex-col'>
              <div className='overflow-x-auto shadow-md sm:rounded-lg'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='p-4'>
                    <h2 className='font-gotham-rounded-medium text-body text-base'>
                      {t('okoa_shopping.shops_nearby')}
                    </h2>
                    <EmptyContent
                      title={t('common.no_shops_nearby')}
                      subTitle={t('common.no_shops_nearby_help_text')}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='flex flex-col'>
              <div className='overflow-x-auto shadow-md sm:rounded-lg w-full'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='p-4 flex items-center'>
                    <h2 className='font-gotham-rounded-medium text-body text-base mb-2'>
                      {t('okoa_shopping.shops_nearby')}
                    </h2>
                  </div>
                  <div className='overflow-hidden p-4'>
                    <Table
                      header={
                        <tr>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_shopping.shop_name')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_shopping.location')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('okoa_shopping.shop_number')}
                          </th>
                          <th
                            scope='col'
                            className='py-3 pl-2 text-xs font-medium tracking-wider text-left text-body uppercase'
                          >
                            {t('common.verified')}
                          </th>
                          <th scope='col' className='p-4'>
                            <span className='sr-only'>{t('okoa_shopping.action')}</span>
                          </th>
                        </tr>
                      }
                      body={
                        <>
                          {shopsNearby.map((shop) => (
                            <tr key={shop.id}>
                              <td className='pl-2 py-4 whitespace-nowrap'>
                                <div className='text-medium font-medium text-body'>
                                  {shop.premiseName}
                                </div>
                              </td>
                              <td className='pl-2 py-4 w-40 text-medium text-body'>
                                {shop.premiseAddress}
                              </td>
                              <td className='pl-2 py-4 text-medium text-body'>
                                {shop.paylendNumber}
                              </td>
                              <td className='pl-2 py-4 text-medium text-body'>
                                {shop.isPremiseVerified
                                  ? t('common.yes')
                                  : t('common.no')}
                              </td>
                              <td className='px-2 py-4 text-medium text-body flex gap-2 justify-end'>
                                <Button
                                  label={t('okoa_shopping.okoa')}
                                  classes='uppercase w-auto'
                                  onClick={() => onOkoa(shop)}
                                />
                                <Button
                                  label={t('okoa_shopping.view')}
                                  classes='uppercase w-auto'
                                  onClick={() => handleShopClicked(shop)}
                                />
                              </td>
                            </tr>
                          ))}
                        </>
                      }
                      pagination={
                        <div className='flex justify-between pl-2 pr-6 py-4 w-full border border-gray-200 border-t-0'>
                          <div className='text-medium text-muted'>
                            {pagination.count} {t('common.of')} {pagination.total}{' '}
                            {t('okoa_shopping.shops')}
                          </div>
                          <div className='flex justify-between gap-4'>
                            <button
                              className={`text-medium ${
                                pagination.hasPrev
                                  ? 'text-secondary-900'
                                  : 'text-muted pointer-events-none'
                              }`}
                              onClick={() => dispatch(loadPrevNearbyShops({}))}
                            >
                              {t('common.prev')}
                            </button>
                            <button
                              className={`text-medium ${
                                pagination.hasNext
                                  ? 'text-secondary-900'
                                  : 'text-muted pointer-events-none'
                              }`}
                              onClick={() => dispatch(loadNextNearbyShops({}))}
                            >
                              {t('common.next')}
                            </button>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <ShopDirectionsModal
        translate={t}
        showModal={showModal}
        shop={shop}
        onCancel={() => {
          setShowModal(false)
          setShop({})
        }}
      />
    </>
  )
}

ShopsNearby.propTypes = {
  t: PropTypes.func,
  okoaUser: PropTypes.object,
  onOkoa: PropTypes.func
}

export default ShopsNearby
